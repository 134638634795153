import './styles.css';
import { Button, Col, Row } from 'antd';
import { replaceWithBr } from "src/Utils/textUtils";
import { useTranslation } from 'react-i18next';
import QRCode from "qrcode.react";
import line from '../../../../assets/image/line_regis.svg';
import { PharmacyInfo } from 'src/Network/Model/PharmacyInfo';
import { useState } from 'react';
import PharmacyGuide from 'src/Component/Modal/PharmacyGuide';
import { Link } from 'react-router-dom';

type Props = {
  detailPharmacy?: PharmacyInfo
}

const ModalDetailPharmacy:React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {detailPharmacy} = props;
  const [showModalPharmacy, setShowModalPharmacy] = useState(false);
  
  return (
    <div className='detail_pharmacy'>
        <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
          <Col span={24} className='bodyModal'>
            <Row className='detailPharmacy' gutter={[{ xs: 16, sm: 16, md: 16 }, { xs: 16, sm: 16, md: 16 }]}>
              <Col xs={24} sm={24} md={10} className='detailPharmacy-left'>
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.pharmacy-name"), content: detailPharmacy?.pharmacy_name}} />
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.tel"), content: detailPharmacy?.tel}} />
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.fax"), content: detailPharmacy?.fax}} />
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.postal-code"), content: detailPharmacy?.postal_code}} />
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.location"), content: detailPharmacy?.address}} />
              </Col>
              <Col xs={24} sm={24} md={14} className='detailPharmacy-right'>
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.open"), content: detailPharmacy?.open?.replaceAll('）', ')<br/>')}} />
                <ItemInfo data={{title: t("receive-at-pharmacy.modal-detail-pharmacy.holiday"), content: detailPharmacy?.holiday}} />
                {detailPharmacy?.line_url?.length ? (
                  <>
                    <div className='qrcode_container'>
                      <div className='qrcode itemTitle'>
                        <QRCode
                          value={detailPharmacy?.line_url}
                          renderAs="svg"
                          bgColor="black"
                          fgColor="white"
                          level="H"
                          size={100}
                          fill='#565C67'
                        />
                      </div>
                      <div className='itemTitle'>
                        <span dangerouslySetInnerHTML={replaceWithBr(t("ReceptionInfo.request_url_line"))}></span>
                      </div>
                    </div>
                    <div className='line_container'>
                      <Button type='text' className='line_button'>
                        <Link to={detailPharmacy?.line_url || ''} target="_blank">
                          <img src={line} />
                          <span>{t("LetterPrescription.link2")}</span>
                        </Link>
                      </Button>
                      <div>
                        <div className='add_friend'>
                          <span dangerouslySetInnerHTML={replaceWithBr(t("ReceptionInfo.add_friend"))}></span>
                        </div>
                        <div className='request_family_pharmacy'>
                          <span dangerouslySetInnerHTML={replaceWithBr(t("ReceptionInfo.request_family_pharmacy"))}></span>
                        </div>
                      </div>
                    </div>
                    <div className='link_line'>
                      <span>
                        {t("ReceptionInfo.information_line")}
                        <span onClick={() => setShowModalPharmacy(true)} style={{ color: '#308AE6', cursor: 'pointer' }}>
                            {t("ReceptionInfo.link_line")}
                        </span>
                      </span>
                    </div>
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        {
          showModalPharmacy &&
          <PharmacyGuide changeVisibleModal={() => setShowModalPharmacy(false)} />
        }
    </div>
  )
}

const ItemInfo = ({data}:any) => {
  return (
    <div>
      <div className='itemTitle text-bold-styles'>
        <span dangerouslySetInnerHTML={replaceWithBr(data?.title)}></span>
      </div>
      <div className='itemContent'>
        <span dangerouslySetInnerHTML={replaceWithBr(data?.content)}></span>
      </div>
    </div>
  )
}


export default ModalDetailPharmacy;
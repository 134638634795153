import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useAddTreatmentMenuMutation,
  useUpdateTreatmentMenuMutation
} from 'src/Network/Home/DoctorSettingApi';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import ModalFooter from '../../agreementManagement/ModalFooter';
import ModalHeader from '../../agreementManagement/ModalHeader';
import {
  changeOpenConfirmModal,
  changeOpenErrorModal,
  changeOpenSuccessModal,
  selectDto,
  selectEditMedicalMenu,
  selectExamMethod,
  selectIsCreate,
  selectOpenConfirmModal
} from '../store/slice';
import { FormData, commonKey, inpersonKey, onlineKey } from './constants';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';

const ConfirmModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const editMedicalMenu = useAppSelector(selectEditMedicalMenu);
  const openConfirmModal = useAppSelector(selectOpenConfirmModal);
  const dto = useAppSelector(selectDto);
  const isCreate = useAppSelector(selectIsCreate);
  const clinicId = getClinicIdFromLocalStorage();
  const [addTreatmentMenu] = useAddTreatmentMenuMutation();
  const [updateTreatmentMenu] = useUpdateTreatmentMenuMutation();
  const examMethod = useAppSelector(selectExamMethod);

  const handleCloseConfirmModal = () => {
    dispatch(changeOpenConfirmModal(false));
  };

  const handleRegisterItem = () => {
    const inpersonData = {} as any;
    const onlineData = {} as any;
    const commonData = {} as any;
    inpersonKey.forEach((key) => {
      const data = dto?.[key as keyof FormData];
      inpersonData[key] = data === null ? undefined : data;
    });
    onlineKey.forEach((key) => {
      const data = dto?.[key as keyof FormData];
      onlineData[key] = data === null ? undefined : data;
      if (key === 'consent_form_id') {
        onlineData[key] =
          data === 0 || data === null || typeof data === 'string' ? null : data;
      }
    });
    commonKey.forEach((key) => {
      const data = dto?.[key as keyof FormData];
      commonData[key] = data === null ? undefined : data;
    });

    const paramData =
      examMethod === 1
        ? { ...commonData, ...inpersonData }
        : examMethod === 0
          ? { ...commonData, ...onlineData }
          : { ...commonData, ...inpersonData, ...onlineData };

    if (isCreate) {
      addTreatmentMenu({
        ...paramData,
        clinic_id: clinicId
      })
        .then((res: any) => {
          if (res.data.code === 200) {
            dispatch(changeOpenConfirmModal(false));
            dispatch(changeOpenSuccessModal(true));
          } else {
            dispatch(changeOpenConfirmModal(false));
            dispatch(changeOpenErrorModal(true));
          }
        })
        .catch(() => {
          dispatch(changeOpenConfirmModal(false));
          dispatch(changeOpenErrorModal(true));
        });
    } else {
      updateTreatmentMenu({
        ...paramData,
        id: editMedicalMenu?.id,
        clinic_id: clinicId
      })
        .then((res: any) => {
          if (res.data.code === 200) {
            dispatch(changeOpenConfirmModal(false));
            dispatch(changeOpenSuccessModal(true));
          } else {
            dispatch(changeOpenConfirmModal(false));
            dispatch(changeOpenErrorModal(true));
          }
        })
        .catch(() => {
          dispatch(changeOpenConfirmModal(false));
          dispatch(changeOpenErrorModal(true));
        });
    }
  };

  return (
    <Modal
      open={openConfirmModal}
      closeIcon={null}
      centered
      styles={{
        body: {
          marginTop: 24
        }
      }}
      title={
        <ModalHeader
          handleCloseModal={handleCloseConfirmModal}
          title={t('reExamCode.confirm')}
        />
      }
      footer={
        <ModalFooter
          okTitle={t('reExamCode.yes')}
          handleOK={handleRegisterItem}
          cancelTitle={t('reExamCode.no')}
          handleCancel={handleCloseConfirmModal}
        />
      }
      destroyOnClose
    >
      <div className="delete-agreement-modal_content">
        <h2>{t('reExamCode.confirmRegisterMedicalMenuMessage')}</h2>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

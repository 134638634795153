import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { revertAll } from '../hooks';

const initialState = {
  isFetching: false,
  user: {
    name: "collins",
    isAuthenticated: true
  },
}

const slice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
  }
});

export const selectIsFetching = (state:RootState) => state.common.userSlice.isFetching

export const {
  changeIsFetching,
} = slice.actions;

export const UserReducer = slice.reducer;
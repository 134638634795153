import { Col, Input, InputRef, Row } from "antd";
import { useTranslation } from "react-i18next";
import japan_mapIcon from '../../assets/image/japan-map.svg'
import magnifying_glass from '../../assets/image/magnifying_glass.svg'
import stethoscope from '../../assets/image/stethoscope.svg'
import './styles.css';
import { useEffect, useRef, useState } from "react";
import { useListPharmacyInfoMutation, useGetListPrefectureMutation, useGetListCityByPrefectureMutation } from "src/Network/Home/HomeClinicApi";
import { PharmacyInfo } from "src/Network/Model/PharmacyInfo";
import Loading from "../Modal/Loading";

type Props = {
    setDetailPharmacy?: Function,
    methodReceive: number
}

const SearchPharmacyGuidance:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {setDetailPharmacy, methodReceive} = props;
    const [isDivVisiblePref, setDivVisiblePref] = useState(false);
    const [isDivVisibleCity, setDivVisibleCity] = useState(false);
    const [isDivVisiblePharmacy, setDivVisiblePharmacy] = useState(false);
    const [inputValuePref, setInputValuePref] = useState('');
    const [inputValueCity, setInputValueCity] = useState('');
    const [inputValuePharmacy, setInputValuePharmacy] = useState('');
    const inputRefPref = useRef<InputRef>(null);
    const inputRefCity = useRef<InputRef>(null);
    const inputRefPharmacy = useRef<InputRef>(null);
    const [prefName, setPrefName] = useState<string>('');
    const [cityName, setCityName] = useState<string>('');
    const [GetListPrefecture] = useGetListPrefectureMutation();
    const [GetListCityByPrefecture] = useGetListCityByPrefectureMutation();
    const [ListPharmacyInfo] = useListPharmacyInfoMutation();
    const [dataPref, setDataPref] = useState<Array<{pref: string, name: string}>>([]);
    const [dataCity, setDataCity] = useState<Array<{name: string}>>([]);
    const [dataPharmacy, setDataPharmacy] = useState<PharmacyInfo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const handleInputClickPref = () => {
        const prefectures = [] as Array<{pref: string, name: string}>;
        setIsLoading(true);
        // data call api get list prefecture
        GetListPrefecture({}).then((res: any) => {
            if (!res?.error) {
                res?.data?.map((item:any) => {
                    prefectures.push({
                        pref: item.prefecture,
                        name: item.prefecture + '('+ item?.count +')'
                    });
                })
            }
            setDataPref(prefectures);
            setIsLoading(false);
        });
        setDivVisiblePref(true);
        setDivVisibleCity(false);
        setDivVisiblePharmacy(false);
    }
    const handleFocusOutPref = () => {
        setDivVisiblePref(false);
    }
    const handleInputChangePref = (event: any) => {
        setInputValuePref(event.target.value);
    }
    const filteredDataPref = dataPref.filter(item => {
        return item.pref.toLowerCase().includes(inputValuePref.toLowerCase());
    });
    const handleInputClickCity = () => {
        // data call api get list city
        if (prefName !== '') {
            const citys = [] as Array<{name: string}>;
            setIsLoading(true);
            GetListCityByPrefecture({ pref: prefName }).then((res: any) => {
                if (!res?.error) {
                    res.data?.map((item:any) => {
                        citys.push({
                            name: item.city
                        });
                    })
                }
            }).then(() => {
                setDataCity(citys);
                setIsLoading(false);
            });
        }
        setDivVisibleCity(true);
        setDivVisiblePref(false);
        setDivVisiblePharmacy(false);
    }
    const handleFocusOutCity = () => {
        setDivVisibleCity(false);
    }
    const handleInputChangeCity = (event: any) => {
        setInputValueCity(event.target.value);
    }
    const filteredDataCity = dataCity.filter(item => {
        return item.name.toLowerCase().includes(inputValueCity.toLowerCase());
    });
    const handleInputClickPharmacy = () => {
        // data call api get list pharmacy
        if (prefName !== '' && cityName !== '') {
            const pharmacys = [] as Array<PharmacyInfo>;
            setIsLoading(true);
            ListPharmacyInfo({ pref: prefName, city: cityName }).then((res: any) => {
                if (!res?.error) {
                    res.data?.map((item:any) => {
                        pharmacys.push(item);
                    })
                }
            }).then(() => {
                setDataPharmacy(pharmacys);
                setIsLoading(false);
            });
        }
        setDivVisiblePharmacy(true);
        setDivVisibleCity(false);
        setDivVisiblePref(false);
    }
    const handleFocusOutPharmacy = () => {
        setDivVisiblePharmacy(false);
    }
    const handleInputChangePharmacy = (event: any) => {
        setInputValuePharmacy(event.target.value);
    }
    const filteredDataPharmacy = dataPharmacy.filter(item => {
        return item?.title?.toLowerCase().includes(inputValuePharmacy.toLowerCase());
    });
    useEffect(() => {
        setDataPref([]);
        setDataCity([]);
        setDataPharmacy([]);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])
    const handleClickOutside = (event: MouseEvent) => {
        if ((inputRefPref.current && !inputRefPref.current.input?.contains(event.target as Node))
            || (inputRefCity.current && !inputRefCity.current.input?.contains(event.target as Node))
            || (inputRefPharmacy.current && !inputRefPharmacy.current.input?.contains(event.target as Node))
            ){
            const element = event.target as Element;
            const className = element.classList.value;
            if (className === 'modal-keyword-item modal-keyword-pref') {
                setPrefName(element.querySelector('.pref_name')?.getAttribute('value') as string);
                setInputValuePref(element.querySelector('.pref_name')?.getAttribute('value') as string);
            }
            if (className === 'modal-keyword-item modal-keyword-city') {
                setCityName(element.textContent as string);
                setInputValueCity(element.textContent as string);
            }
            if (className === 'modal-keyword-item modal-keyword-pharmacy') {
                setInputValuePharmacy(element.textContent as string);
                if (setDetailPharmacy) {
                    const title = element.querySelector('.title')?.getAttribute('value');
                    const tel = element.querySelector('.tel')?.getAttribute('value');
                    const fax = element.querySelector('.fax')?.getAttribute('value');
                    const zip = element.querySelector('.zip')?.getAttribute('value');
                    const address = element.querySelector('.address')?.getAttribute('value');
                    const open = element.querySelector('.open')?.getAttribute('value');
                    const holiday = element.querySelector('.holiday')?.getAttribute('value');
                    const line_url = element.querySelector('.line_url')?.getAttribute('value');
                    const pref_id = element.querySelector('.pref_id')?.getAttribute('value');
                    const city_id = element.querySelector('.city_id')?.getAttribute('value');
                    const store_id = element.querySelector('.store_id')?.getAttribute('value');
                    const pref_name = element.querySelector('.pref_name')?.getAttribute('value');
                    const city_name = element.querySelector('.city_name')?.getAttribute('value');
                    const addressFull = (pref_name??'') + (city_name??'') + address;
                    let idStr = null;
                    const userInfo = localStorage.getItem('user');
                    if (userInfo) {
                      idStr = JSON.parse(userInfo).user_id;
                    }
                    setDetailPharmacy({
                        pharmacy_name: title,
                        tel: tel,
                        fax: fax,
                        postal_code: zip,
                        address: addressFull,
                        open: open,
                        holiday: holiday,
                        line_url: line_url,
                        pref_id: Number(pref_id),
                        city_id: Number(city_id),
                        receive_at: methodReceive,
                        user_id: idStr,
                        store_id: store_id
                    });
                }
            }
            setDivVisiblePref(false);
            setDivVisibleCity(false);
            setDivVisiblePharmacy(false);
        }
    };

    return (
        <>
            <div className="prescription-info-row inline-row search_pharmacy">
                <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20, md: 20 }]} className="relativeRow">
                    <Col xs={24} sm={24} md={24} xl={8}>
                        <div className="prescription-info-label">
                            <span>{t("LetterPrescription.label1")}</span>
                            <Input
                                prefix={<img src={japan_mapIcon} />}
                                placeholder={t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.prefecture")}
                                className="prescription-info-input"
                                onClick={handleInputClickPref}
                                onBlur={handleFocusOutPref}
                                onChange={handleInputChangePref}
                                value={inputValuePref}
                                ref={inputRefPref}
                            />
                            {isDivVisiblePref && (
                            <div id="modal-area" className={filteredDataPref.length != 0 ? "search_pharmacy_regis result_pref" : "search-modal search-modal-none"}>
                                <div className="modal-container">
                                    <div className="modal-content">
                                        <h5 className="modal-heading">{t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.search_pref")}</h5>
                                        <ul className="modal-keyword-list">
                                            {filteredDataPref.map((item, index) => (
                                                <li className="modal-keyword-item modal-keyword-pref" key={index}>
                                                    {item.name}
                                                    <input type='hidden' className="pref_name" value={item?.pref}></input>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={8}>
                        <div className="prescription-info-label">
                            <span>{t("LetterPrescription.label2")}</span>
                            <Input
                                prefix={<img src={magnifying_glass} />}
                                placeholder={t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.city")}
                                className="prescription-info-input"
                                onClick={handleInputClickCity}
                                onBlur={handleFocusOutCity}
                                onChange={handleInputChangeCity}
                                value={inputValueCity}
                                ref={inputRefCity}
                            />
                            {isDivVisibleCity && (
                            <div className="search_pharmacy_regis result_city">
                                <div className="modal-container"></div>
                                <h4 className="modal-title">{t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.search_city")}</h4>
                                <div className="modal-content modal-subject">
                                    <ul className="modal-keyword-list">
                                        {filteredDataCity.map((item, index) => (
                                            <li className="modal-keyword-item modal-keyword-city" key={index}>
                                                {item.name}
                                                <input type='hidden' className="city_name" value={item?.name}></input>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={8}>
                        <div className="prescription-info-label">
                            <span>{t("LetterPrescription.label3")}</span>
                            <Input
                                prefix={<img src={stethoscope} />}
                                placeholder={t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.pharmacy")}
                                className="prescription-info-input"
                                onClick={handleInputClickPharmacy}
                                onBlur={handleFocusOutPharmacy}
                                onChange={handleInputChangePharmacy}
                                value={inputValuePharmacy}
                                ref={inputRefPharmacy}
                            />
                            {isDivVisiblePharmacy && (
                                <div className="search_pharmacy_regis result_pharmacy">
                                    <div className="modal-container"></div>
                                    <h4 className="modal-title">{t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.search_city")}</h4>
                                    <div className="modal-content modal-subject">
                                        <ul className="modal-keyword-list">
                                            {filteredDataPharmacy.map((item, index) => (
                                                <li className="modal-keyword-item modal-keyword-pharmacy" key={index}>
                                                    {item.title}
                                                    <input type='hidden' className="title" value={item?.title}></input>
                                                    <input type='hidden' className="tel" value={item?.tel}></input>
                                                    <input type='hidden' className="fax" value={item?.fax}></input>
                                                    <input type='hidden' className="zip" value={item?.zip}></input>
                                                    <input type='hidden' className="address" value={item?.address}></input>
                                                    <input type='hidden' className="open" value={item?.open}></input>
                                                    <input type='hidden' className="holiday" value={item?.holiday}></input>
                                                    <input type='hidden' className="line_url" value={item?.line_url}></input>
                                                    <input type='hidden' className="pref_id" value={item?.mprefs?.id}></input>
                                                    <input type='hidden' className="city_id" value={item?.mcitys?.id}></input>
                                                    <input type='hidden' className="store_id" value={item?.store_id}></input>
                                                    <input type='hidden' className="pref_name" value={item?.prefecture}></input>
                                                    <input type='hidden' className="city_name" value={item?.city}></input>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            {isLoading && <Loading />}
        </>
    )
}

export default SearchPharmacyGuidance;
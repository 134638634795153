import './styles.css';
import {Button, Modal, Select} from "antd";
import ModalWarning from 'src/Component/Modal/ModalWarning';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  BUTTON_CLOSE,
  HALF_AN_HOURS,
  HOURS_IN_DAY,
  MODAL_CONFIRM,
  MODAL_NEGATIVE,
  QUARTER_AN_HOURS
} from "../../../constant";
import {CloseOutlined} from "@ant-design/icons";
import {useUpdateClosedTimeMutation, useDeleteClosedTimeMutation} from "../../../Network/Home/DoctorApi";
import {getClinicIdFromLocalStorage} from "../../../common/Libs";
import dayjs from "dayjs";
import {ClosedTime} from "../../../Network/Model/ClosedTime";
import INFO_ICON from 'src/assets/image/info2.svg';

const APP = ({closedTimeData,
               onClose, onUpdateFinish, exmTime
             }: { closedTimeData: ClosedTime | undefined, onClose: (event: React.MouseEvent<HTMLInputElement>) => void, onUpdateFinish: (event: CustomEvent) => void, exmTime: number}) => {

  const {t} = useTranslation();
  const [optionTime, setOptionTime] = useState<any[]>([]);
  const [selectedStartTime, setSelectedStartTime] = useState(closedTimeData?.closed_start_time);
  const [selectedEndTime, setSelectedEndTime] = useState(closedTimeData?.closed_end_time);
  const [updateCloseTime] = useUpdateClosedTimeMutation();
  const [deleteCloseTime] = useDeleteClosedTimeMutation();
  const [modalMsg, setModalMsg] = useState<string>();
  const [modalType, setModalType] = useState<number>(1);
  const [modalBtnType, setModalBtnType] = useState<number>(1);
  const [modalTitle, setModalTitle] = useState<string>();
  const [errorTime, setErrorTime] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleUpdateClosedTime = () => {
    if (checkErrorTime())
      return;
    const param = {
      id: closedTimeData?.id,
      clinic_id: getClinicIdFromLocalStorage(),
      closed_start_time: selectedStartTime,
      closed_end_time: selectedEndTime,
    };
    updateCloseTime(param).then((res: any) => {
      if (res?.data?.code && res?.data?.code === 200) {
        setModalMsg(t("MESS_INF_UPDATE_CLOSED_FRAME_SUCCESS"));
        setModalType(MODAL_CONFIRM);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_SUCCESS"));
      } else {
        setModalMsg(t("MESS_ERR_CANNOT_UPDATE_CLOSED_FRAME"));
        setModalType(MODAL_NEGATIVE);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_FAIL"));
      }
    });
  }
  
  useEffect(()=>{
    setSelectedStartTime(closedTimeData?.closed_start_time)
    setSelectedEndTime(closedTimeData?.closed_end_time)
  },[closedTimeData])
  
  const handleDeleteClosedTime = () => { setShowConfirmModal(false)
    const param = {
      id: closedTimeData?.id
    };
    deleteCloseTime(param).then((res: any) => {
      if (res?.data?.code && res?.data?.code === 200) {
        setModalMsg(t("MESS_INF_DELETE_CLOSED_FRAME_SUCCESS"));
        setModalType(MODAL_CONFIRM);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_SUCCESS"));
      } else {
        setModalMsg(t("MESS_ERR_CANNOT_DELETE_CLOSED_FRAME"));
        setModalType(MODAL_NEGATIVE);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_FAIL"));
      }
    });
  }
  
  

  const checkErrorTime = () => {
    const start = dayjs(selectedStartTime, "HHmm")
    const end = dayjs(selectedEndTime, "HHmm")
    if (end.isSame(start) || end.isBefore(start)) {
      setErrorTime(true);
      return true;

    }
  }

  const generateTimeOptions = () => {
    const results: any = [];

    let minuteArr: string[];
    if (exmTime == 15) {
      minuteArr = QUARTER_AN_HOURS;
    } else {
      minuteArr = HALF_AN_HOURS;
    }

    for (const hour of HOURS_IN_DAY) {
      minuteArr.forEach((minus) => {
        results.push({
          value: hour + minus,
          label: hour + ':' + minus
        });
      })
    }

    return results;
  }

  useEffect(() => {
    setOptionTime(generateTimeOptions());
  }, [])

  return <div>
    <div className={"dt047"}>
      <div>
        <div className={"title"}>
          <span> {t("updateClosedTime.title")}</span>
          <span>
        <Button type='text' icon={<CloseOutlined/>} onClick={onClose} style={{color: '#878E9B'}}/>
      </span></div>
        <div style={{
          justifyContent: "space-between",
          display: "flex",
          background: "#F8F8F8",
          padding: "10px",
          margin: "20px 0 20px 0"
        }}>
          <Select
            value={selectedStartTime}
            options={optionTime}
            onChange={(e) => {
              setSelectedStartTime(e);
              setErrorTime(false);
            }} className={"selectTime"}> </Select>
          <span style={{fontSize: "16px", color: "#6C747B", marginLeft: "10px", marginRight: "10px"}}>~</span>


          <Select
            value={selectedEndTime}
            options={optionTime}
            onChange={(e) => {
              setSelectedEndTime(e)
              setErrorTime(false);
            }} className={"selectTime"}> </Select>
        </div>
        {
          errorTime &&
          <div className="errcode">{t("message.MESS_ERR_END_TIME_LESS_THAN_START_TIME.text")}</div>
        }
        <div className={"treatmentMenu"}>
          {closedTimeData?.treatment_menu_name}
        </div>
      </div>
      <div style={{display: "flex", justifyContent: "space-around"}}>
        <Button type="text" className={"updateBtn"} onClick={handleUpdateClosedTime}>{t("updateClosedTime.updateBtn")}</Button>
        <Button type="text" className={"deleteBtn"} onClick={() => setShowConfirmModal(true)}>{t("updateClosedTime.deleteBtn")}</Button>
      </div>
    </div>
    {modalMsg && modalMsg !== '' && (
      <ModalWarning
        message={modalMsg}
        typeModal={modalType}
        typeButton={modalBtnType}
        title={modalTitle}
        changeVisibleModal={() => {
          if(modalType === MODAL_CONFIRM){
            onUpdateFinish(new CustomEvent(''));
          } else {
              setModalMsg('');
            }
        }}
      />
    )}
    <Modal className={"modalConfirmDelete"} title={t("updateClosedTime.confirmTitle")} open={showConfirmModal} destroyOnClose={true}
             closeIcon={true} onCancel={() => setShowConfirmModal(false)}
             centered
           footer={[
             <Button  type="text" key="submit" style={{color: "white"}} onClick={(e) => {handleDeleteClosedTime();}}>
               {t("updateClosedTime.yes")}
             </Button>,
             <Button  type="text" key="back" onClick={() => setShowConfirmModal(false)}>
               {t("updateClosedTime.no")}
             </Button>,
           ]}
    >
        <p style={{color: "#2E3138", textAlign: "center"}}> {t("updateClosedTime.confirmMsg")}</p>
      <div style={{background: "#F8F8F8", color: "#565C67", padding: 24, lineHeight: "normal", marginBottom: 24, borderRadius: 7}}>
        <p style={{fontWeight: "bold", marginBottom: 0}}> {`【${t("updateClosedTime.exmMethod" + closedTimeData?.exm_method)}】${closedTimeData?.treatment_menu_name}`}</p>
        <p style={{marginBottom: 0}}> {dayjs(closedTimeData?.closed_start_time, "HHmm").format("HH:mm")}~{dayjs(closedTimeData?.closed_end_time, "HHmm").format("HH:mm")}</p>
      </div>
        <p style={{padding: 8}}><img src={INFO_ICON} alt="" /> {t("updateClosedTime.confirmMsg2")}</p>
    </Modal>
  </div>
}
export default APP;
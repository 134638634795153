import React, { useEffect, useState } from "react";
import './styles.css';
import { Button, Col, Modal, Row, Tabs } from "antd";
import CloseBtn from 'src/assets/image/closebtn.svg';
import TabContent1 from "./IshachokMember";
import TabContent2 from "./NotIshachokuMember";
import { useTranslation } from "react-i18next";
import { formatTimeRange } from "src/Utils/CalculateHourResevationUtils";
import { useSelector } from "react-redux";
import { selectSelectionRegistGuestUserName, selectSelectionRegistList } from "./slice";
import ModalWarning from "src/Component/Modal/ModalWarning";
import {lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import { BUTTON_ACCEPT, BUTTON_CLOSE, BUTTON_CONFIRM, MODAL_CONFIRM, MODAL_FAIL, MODAL_SUCCESS, MODAL_WARNING } from "src/constant";
import Loading from "src/Component/Modal/Loading";
import { useLazyClinicUsersByClinicIdQuery, useLazyCountOnClosedTimeQuery, useLazyReservationInfosByDateIdAndClinicIdQuery, useLazyTreatmentMenusByClinicIdQuery, useRegistReservationFrameMutation } from "src/Network/Home/DoctorApi";
import { getClinicIdFromLocalStorage } from "src/common/Libs";
import { CustomTreatmentMenuByDateResponse } from "src/Network/Model/TreatmentMenu";
import { RegExp, halfWidthCheck } from 'src/constant';
type Props = {
    exmTime: number;
    dateId: number;
    isShow: boolean;
    timeSelected: string;
    dateSelected: string;
    dayOfWeek: number;
    rsvFrameId: number[];
    onClose: (event: React.MouseEvent<HTMLInputElement>) => void;
    reloadRsvFrame: (event: CustomEvent) => void
};

const TabRegistMedical: React.FC<Props> = ({exmTime, dateId, isShow, timeSelected, dateSelected, dayOfWeek, rsvFrameId ,onClose, reloadRsvFrame}) => {
    const SelectionRegist = useSelector(selectSelectionRegistList);
    const UserNameRegist = useSelector(selectSelectionRegistGuestUserName);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [isOutsideClicked, setIsOutsideClicked] = useState(false);
    const [isOutsideClickedNonIshachoku, setIsOutsideClickedNonIshachoku] = useState(false);
    const [openConfirmIsOnClosedTimeModal, setOpenConfirmIsOnClosedTimeModal] = useState(false);
    const [isOnClosedTime, setIsOnClosedTime] = useState(false);
    const [rsvDiv, setRsvDiv] = useState(0);
    const [exmTimeRegist, setExmTimeRegist] = useState(0);
    const [treatmentMenuId, setTreatmentMenuId] = useState(0);
    const [treatmentMenuName, setTreatmentMenuName] = useState('');
    const [clinicUserId, setClinicUserId] = useState(0);
    const [clinicUserName, setClinicUserName] = useState('');
    
    const [rsvType, setRsvType] = useState(0);
    const [dayNote, setDayNote] = useState('');
    const [rsvDiv2, setRsvDiv2] = useState(0);
    const [exmTimeRegist2, setExmTimeRegist2] = useState(0);
    const [treatmentMenuId2, setTreatmentMenuId2] = useState(0);
    const [treatmentMenuName2, setTreatmentMenuName2] = useState('');
    const [clinicUserId2, setClinicUserId2] = useState(0);
    const [clinicUserName2, setClinicUserName2] = useState('');
    const [dayNote2, setDayNote2] = useState('');
    const [karuteId2, setKaruteId2] = useState('');
    const [email2, setEmail2] = useState('');
    const [rsvType2, setRsvType2] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [clinicId] = useState(getClinicIdFromLocalStorage());
    const { t } = useTranslation();
    const [mappingRSV, setMappingRSV] = useState<any>([]);
    const [countOnClosedTime] = useLazyCountOnClosedTimeQuery();
    const param = { clinic_id: clinicId, rsv_ymd: dateSelected.replace(/-/g, ''), rsv_time: timeSelected.replace(/:/g, ''), exm_time: exmTime};
    const CheckSelectionRegist = useSelector(selectSelectionRegistList);
    useEffect(() => {
        if (!CheckSelectionRegist.user_name) {
            countOnClosedTime(param).then((res: any) => {
            if (res.data.data.count > 0 || rsvFrameId.length === 0) {
                setIsOnClosedTime(true);
            } else {
                setIsOnClosedTime(false);
            }
            if (rsvFrameId.length !== 0) {
                setMappingRSV(res.data.data.rsv_mapping);
            }
            handleFrameDetailAPI(dateId, res.data.data.treatment_menu_ids, res.data.data.clinic_user_ids);
            });
        }
    }, [CheckSelectionRegist]);

    const handleInfomation = (treatment_menu_id: number, treatment_menu_name: string, rsv_div: number, exm_time: number, doctor_id: number, doctor_name: string, day_note: string, rsv_type: number) => {
        setTreatmentMenuId(treatment_menu_id);
        setTreatmentMenuName(treatment_menu_name);
        setRsvDiv(rsv_div);
        setExmTimeRegist(exm_time);
        setClinicUserId(doctor_id);
        setClinicUserName(doctor_name);
        setDayNote(day_note);
        setIsOutsideClicked(false);
        setRsvType(rsv_type);
        // Day note - text area - max length 2000 bytes (\1.docs\04_Design\Detail Design\Yost_詳細仕様書_DT-01 DT-02 DT-09（診療予約管理）.xlsx) 
        if (lengthFullAndHalfWidth(day_note) <= 2000) {
            setOpenConfirmModal(true);
        }
    };

    const handleInfomationNonIshachoku = (treatment_menu_id: number, treatment_menu_name: string, rsv_div: number, exm_time: number, doctor_id: number, doctor_name: string, karute_id: string, email: string, day_note: string, rsv_type: number) => {
        setTreatmentMenuId2(treatment_menu_id);
        setTreatmentMenuName2(treatment_menu_name);
        setRsvDiv2(rsv_div);
        setExmTimeRegist2(exm_time);
        setClinicUserId2(doctor_id);
        setClinicUserName2(doctor_name);
        setDayNote2(day_note);
        setKaruteId2(karute_id);
        setEmail2(email);
        setRsvType2(rsv_type);
        setIsOutsideClickedNonIshachoku(false);
        // Day note - text area - Required - max length 2000 bytes (\1.docs\04_Design\Detail Design\Yost_詳細仕様書_DT-01 DT-02 DT-09（診療予約管理）.xlsx) 
        // Patient name - text box - max length 50 bytes (\1.docs\04_Design\Detail Design\Yost_詳細仕様書_DT-01 DT-02 DT-09（診療予約管理）.xlsx) 
        // Mail address - text box - Required - max length 100 bytes (\1.docs\04_Design\Detail Design\Yost_詳細仕様書_DT-01 DT-02 DT-09（診療予約管理）.xlsx) 
        // Karute Id - text box - max length 10 bytes (\1.docs\04_Design\Detail Design\Yost_詳細仕様書_DT-01 DT-02 DT-09（診療予約管理）.xlsx) 

        if (lengthFullAndHalfWidth(day_note) <= 2000 && lengthFullAndHalfWidth(UserNameRegist) <= 50 && lengthFullAndHalfWidth(email) <= 100 && RegExp.test(email) && (karute_id === "" || halfWidthCheck.test(karute_id))) {
            setOpenConfirmModal(true);
        }
    };

    const [registRSV] = useRegistReservationFrameMutation();
    const [messRegistErr, setMessRegistErr] = useState('');
    const [visibleRegistSuccess, setVisibleRegistSuccess] = useState(false);
    const [visibleRegistWarning, setVisibleRegistWarning] = useState(false);
    const handleRegist = () => {
        setIsLoading(true);
        setOpenConfirmModal(false);
        setOpenConfirmIsOnClosedTimeModal(false);
        const registIshaChoku = {
            rsv_div: rsvDiv,
            rsv_ymd: dateSelected.replace(/-/g, ''),
            rsv_time: timeSelected.replace(/:/g, ''),
            examination_time: exmTimeRegist,
            clinic_user_id: clinicUserId,
            treatment_menu_id: treatmentMenuId,
            family_user_id: SelectionRegist?.family_user_id,
            day_note: dayNote,
            user_id: SelectionRegist?.user_id,
            rsv_type: rsvType,
            karute_id: SelectionRegist?.karute_id,
            rsv_frame_id: rsvFrameId.length === 0 ? null : mappingRSV.find((item: any) => item.treatment_menu_id === treatmentMenuId)?.id,
            patient_name: SelectionRegist?.user_name,
        }

        const registNoneIshaChoku = {
            rsv_div: rsvDiv2,
            rsv_ymd: dateSelected.replace(/-/g, ''),
            rsv_time: timeSelected.replace(/:/g, ''),
            examination_time: exmTimeRegist2,
            clinic_user_id: clinicUserId2,
            treatment_menu_id: treatmentMenuId2,
            day_note: dayNote2,
            user_name: UserNameRegist,
            mailaddress: email2,
            karute_id: karuteId2, 
            rsv_type: rsvType2,
            rsv_frame_id: rsvFrameId.length === 0 ? null : mappingRSV.find((item: any) => item.treatment_menu_id === treatmentMenuId2)?.id,
        }
        
        registRSV(selectedTabLabel === 1 ? registIshaChoku : registNoneIshaChoku)
            .then((res: any) => {
                if (!res?.data.error) {
                        setVisibleRegistSuccess(true);
                } else {
                    setVisibleRegistWarning(true);
                    if (res?.data.code === 4041) {
                        setMessRegistErr(t("dt09.MESS_ERR_MISS_CREDIT_CARD_INFO"));
                    } else if (res?.data.code === 4042) {
                        setMessRegistErr(t("dt09.MESS_ERR_LEFT_ACCOUNT"));
                    } else {
                        setMessRegistErr(t("dt09.MESS_RSV_REGIST_FAIL"));
                    }
                }
                setIsLoading(false);
            })
    };
    const [getTreatmentMenuArr] = useLazyTreatmentMenusByClinicIdQuery();
    const [getClinicUserArr] = useLazyClinicUsersByClinicIdQuery();
    const [treatmentMenuTableData, setTreatmentMenuTableData] = useState<CustomTreatmentMenuByDateResponse[]>([]);
    const [clinicUsers, setClinicUsers] = useState<any[]>([]);
    const [getReservationInfoByDateIdAndClinicId] = useLazyReservationInfosByDateIdAndClinicIdQuery();
    const handleFrameDetailAPI = (dateId: number, treatment_menu_ids: number[], clinic_user_ids: number[]) => {
        const param = {
        clinic_id: clinicId,
        date_id: dateId
        };

        const paramForAll = {
            clinic_id: clinicId
        };

        getReservationInfoByDateIdAndClinicId(param).then((res: any) => {
            if (res?.data?.data?.tm_menus.length !== 0 && dateId !== -1) {
                const filteredMenus = res.data.data.tm_menus.filter((menu: any) => 
                    !treatment_menu_ids.includes(menu.id)
                );
                if (filteredMenus.length === 0) {
                    getTreatmentMenuArr(paramForAll).then((res: any) => {
                        if (res?.data) {
                            setTreatmentMenuTableData(res.data.data || []);
                        }
                    });
                } else {
                    setTreatmentMenuTableData(filteredMenus);
                }
            } else {
                getTreatmentMenuArr(paramForAll).then((res: any) => {
                    if (res?.data) {
                        setTreatmentMenuTableData(res.data.data || []);
                    }
                });
            }
            if (res?.data?.data?.clinic_users.length !== 0 && dateId !== -1 && clinic_user_ids.length !== 0) {
                const filteredUsers = res.data.data.clinic_users.filter((menu: any) => 
                    clinic_user_ids.includes(menu.user_id)
                );
                setClinicUsers(filteredUsers || []);
            } else {
                getClinicUserArr(paramForAll).then((res: any) => {
                    if (res?.data) {
                        setClinicUsers(res.data.data || []);
                    }
                });
            }
        });
    };

    const tabsData = [
        {
            label: t("dt09.tab-name1"),
            key: 1,
            children: <TabContent1 treatmentMenuTableData={treatmentMenuTableData} clinicUsers={clinicUsers} onRegist={handleInfomation} isOutsideClicked={isOutsideClicked}/>,
        },
        {
            label: t("dt09.tab-name2"),
            key: 2,
            children: <TabContent2 treatmentMenuTableData={treatmentMenuTableData} clinicUsers={clinicUsers} onRegist={handleInfomationNonIshachoku} isOutsideClicked={isOutsideClickedNonIshachoku}/>,
        }
    ];

    const [selectedTabLabel, setSelectedTabLabel] = useState(tabsData[0].key);

    const handleTabChange = (key: string) => {
        setSelectedTabLabel(Number(key));
    };

    const handleSetErrMsg = () => {
        if(selectedTabLabel === 1) {
            setIsOutsideClicked(true);
        } else {
            setIsOutsideClickedNonIshachoku(true);
        }

    };
    const formattedDateLabel = formatDate(dateSelected, dayOfWeek, timeSelected, exmTime);
    const checkButtonAndTabs = (selectedTabLabel === 1 && SelectionRegist?.user_name === undefined) || (selectedTabLabel === 2 && UserNameRegist === '');
    return (
        <div>
        <Row className="tab-regist-medical" style={{width: '480px'}}>
            <Col span={24}>
                <div className='headerModal-regist-medical'>
                    <span className='headerModal-title text-bold-styles'>{t("dt09.tab-title")}</span>
                    <span>
                        <Button type='text' onClick={onClose} style={{ color: '#878E9B' }}><img src={CloseBtn} alt="" /></Button>
                    </span>
                </div>
            </Col>
            <Col span={24}>
                <Row className="row">
                    <Col span={24}>
                        <span className="text-bold-styles prescription-title2">{t("booking-info-hospital.time_frame_label")}</span>
                    </Col>
                </Row>
                <Row className="row">
                    <Col span={24}>
                        <div className='sub-text'>
                            <span className="text-normal-styles">{formattedDateLabel}</span>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Tabs
                    className="tab-tab-regist-medical"
                    defaultActiveKey="1"
                    type="card"
                    items={tabsData.map((tab) => {
                        return {
                            label: tab.label,
                            key: tab.key.toString(),
                            children: tab.children,
                        };
                    })}
                    onChange={handleTabChange}
                />
            </Col>
            <Col xs={24} sm={24} md={12} lg={24}  className="button-regist-medical-container">
            <Button
                className={checkButtonAndTabs ? "button-regist-medical button-disabled" : "button-regist-medical"}
                disabled={checkButtonAndTabs}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => handleSetErrMsg()}
            >
                <span className="text-normal-styles"> {t("dt09.button")} </span>
            </Button>
            </Col>
        </Row>
        {
            visibleRegistWarning &&
            <ModalWarning
                message={messRegistErr}
                typeModal={MODAL_FAIL}
                typeButton={BUTTON_ACCEPT}
                changeVisibleModal={() => {setVisibleRegistWarning(false)}}
            />
        }
        {
            visibleRegistSuccess &&
            <ModalWarning
                message={t("dt09.MESS_RSV_REGIST_SUCCESS")}
                typeModal={MODAL_SUCCESS}
                typeButton={BUTTON_CLOSE}
                changeVisibleModal={() => {
                    reloadRsvFrame(new CustomEvent(""));
                    setVisibleRegistSuccess(false);
                }}
            />
        }
        {openConfirmIsOnClosedTimeModal &&
            <ModalWarning
                message={t('dt09.MESS_CNF_REGIST_RSV_ON_CLOSED_TIME_FRAME')}
                title={t('message.MESS_CMN_CNF_DELETE.title')}
                typeModal={MODAL_CONFIRM}
                typeButton={BUTTON_CONFIRM}
                changeVisibleModal={() => {
                    setOpenConfirmIsOnClosedTimeModal(false);
                }}
                changeConfirm={handleRegist}
            />

        }
        {isLoading && <Loading />}
            <Modal className={"modalConfirm"} title="" open={openConfirmModal} destroyOnClose={true}
                closeIcon={true} onCancel={() => setOpenConfirmModal(false)} 
                onOk={(e) => {
                    if(isOnClosedTime) {
                        setOpenConfirmModal(false);
                        setOpenConfirmIsOnClosedTimeModal(true);
                    } else {
                        // handle regist
                        handleRegist();
                    }
                }}
                centered
                okText={t("cancelRsv.confirm")} cancelText={t("button-cancel.text")}>
                <div className="inline-confirm-box"><p style={{fontWeight: "bold"}}> {t("dt09.MESS_CNF_REGIST_RSV_BY_DOCTOR")}</p></div>
                <div className="inline-confirm-box"><p style={{fontWeight: "bold"}}> {t("dt09.time")}</p><p>{formattedDateLabel}</p></div>
                <div className="inline-confirm-box"><p style={{fontWeight: "bold"}}> {t("dt09.patient")}</p><p>{selectedTabLabel === 1 ? SelectionRegist?.user_name : UserNameRegist}</p></div>
                <div className="inline-confirm-box"><p style={{fontWeight: "bold"}}> {t("dt09.treatment_menu")}</p><p>{selectedTabLabel === 1 ? treatmentMenuName : treatmentMenuName2}</p></div>
                <div className="inline-confirm-box"><p style={{fontWeight: "bold"}}> {t("dt09.doctor")}</p><p>{selectedTabLabel === 1 ? clinicUserName : clinicUserName2}</p></div>
            </Modal>
        </div>
    );
};

function formatDate(dateString: string, dayOfWeek: number, timeSelectedLabel: string, exmTime: number) {
    const { t } = useTranslation();
    const dayOfWeekLabel = [
        t('admin-schedule.days-of-week.sun').trim(),
        t('admin-schedule.days-of-week.mon').trim(),
        t('admin-schedule.days-of-week.tue').trim(),
        t('admin-schedule.days-of-week.wed').trim(),
        t('admin-schedule.days-of-week.thu').trim(),
        t('admin-schedule.days-of-week.fri').trim(),
        t('admin-schedule.days-of-week.sat').trim()
    ];
    const formattedTimeSelectedLabel = timeSelectedLabel.replace(/:/g, '');
    const titleTimeLabel = formatTimeRange(
        formattedTimeSelectedLabel,
        exmTime,
    );

    const [year, month, day] = dateString.split("-");
    const formattedDate = `${year}年${month}月${day}日`;
    var stringLabel = formattedDate + ' (' + dayOfWeekLabel[dayOfWeek] + ") " + titleTimeLabel;
    return stringLabel;
  }

export default TabRegistMedical;
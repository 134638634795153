import './styles.css';
import { Col, Row, Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import RegisterAddressManual from '../RegisterAddressManual';
import RegisAddressOtherPharmacy from '../RegisAddressOtherPharmacy';
import NewDetailPharmacy from 'src/Component/PrescriptionReceive/NewDetailPharmacy';

const TabsRegisterAddress = (props: any) => {
    const {
        prefList,
        cityList,
        setCurrentTab,
        setVisibleSuccess,
        setIsModalOpenNewPharmacy,
        methodReceive,
        currentTab,
        prescriptionPharmacy,
        setIsModalOpenEditPharmacy,
        setCityList
    } = props;
    const { t } = useTranslation();
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <span>{t('ReceptionInfo.register_adress_new.tab_1')}</span>,
            children: 
            <NewDetailPharmacy
            setIsModalOpenNewPharmacy={setIsModalOpenNewPharmacy}
            />
        },
        {
            key: '2',
            label: <span>{t('ReceptionInfo.register_adress_new.tab_2')}</span>,
            children:
            <RegisAddressOtherPharmacy
                setVisibleSuccess={setVisibleSuccess}
                setIsModalOpenNewPharmacy={setIsModalOpenNewPharmacy}
                methodReceive={methodReceive}
            />
        },
        {
            key: '3',
            label: <span>{t('ReceptionInfo.register_adress_new.tab_3')}</span>,
            children:
            <RegisterAddressManual
                prefList={prefList}
                cityList={cityList}
                setVisibleSuccess={setVisibleSuccess}
                setIsModalOpenNewPharmacy={setIsModalOpenNewPharmacy}
                methodReceive={methodReceive}
                prescriptionPharmacy={prescriptionPharmacy}
                setIsModalOpenEditPharmacy={setIsModalOpenEditPharmacy}
                setCityList={setCityList}
            />
        }
    ];

    return (
        <div>
        <Row>
            <Col xs={24} sm={24} md={24} xl={24}>
                <Tabs
                    className="tabs_register_address_list"
                    type="card"
                    items={items}
                    defaultActiveKey={currentTab.toString()}
                    onChange={(v) => setCurrentTab(Number(v))}
                />
            </Col>
        </Row>
        </div>
    );
}

export default TabsRegisterAddress;
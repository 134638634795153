import { Controller, useFormContext } from 'react-hook-form';
import { Form, Radio } from 'antd';

const { Group } = Radio;

const FormRadioGroup = ({ radioGroupProps, radioProps, ...props }: any) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={props?.name}
      {...props}
      control={control}
      defaultValue={props?.defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Form.Item
          className={props?.className}
          validateStatus={error ? 'error' : 'validating'}
          help={error?.message}
        >
          <Group {...radioGroupProps} value={value} onChange={onChange}>
            {props.options.map(
              (option: { value: any; label: string }, index: number) => (
                <Radio key={index} value={option.value} {...radioProps}>
                  {option.label}
                </Radio>
              )
            )}
          </Group>
        </Form.Item>
      )}
    />
  );
};

export default FormRadioGroup;

import { Col, Row, Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.css';
import ReservationManagement from './reservationManagement';
import MedicalMenu from './medicalMenu';
import AgreementManagement from './agreementManagement';

const TabsSetting = () => {
  const { t } = useTranslation();
  const items: TabsProps['items'] = [
    {
      key: '0',
      label: <span>{t('tabs-setting.reservation-management')}</span>,
      children: <ReservationManagement />
    },
    {
      key: '1',
      label: <span>{t('tabs-setting.medical-menu')}</span>,
      children: <MedicalMenu />
    },
    {
      key: '2',
      label: <span>{t('tabs-setting.agreement-management')}</span>,
      children: <AgreementManagement />
    }
  ];

  return (
    <div className="tabs-setting">
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={24}
          className="text-bold-styles tabs-setting_container"
        >
          <Tabs className="tabs-setting-list" type="card" items={items} />
        </Col>
      </Row>
    </div>
  );
};

export default TabsSetting;

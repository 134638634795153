import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Redux/store';
import { revertAll } from 'src/Redux/hooks';

const initialState = {
  selectedTime: '',
  QRValidate: '',
  checkStepFooter: false,
  reservationInfoId: 0,
  currentStep: 0,
}

const slice = createSlice({
  name: "reservationSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    setQRValidate: (state, action) => {
      state.QRValidate = action.payload;
    },
    changeCheckStepFooter: (state, action) => {
      state.checkStepFooter = action.payload;
    },
    setReservationInfoId: (state, action) => {
      state.reservationInfoId = action.payload;
    },  
    changeCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },  
  }
});

export const selectSelectedTime = (state:RootState) => state.common[slice.name].selectedTime
export const selectQRValidate = (state:RootState) => state.common[slice.name].QRValidate
export const selectCheckStepFooter = (state:RootState) => state.common[slice.name].checkStepFooter
export const selectReservationInfoId = (state:RootState) => state.common[slice.name].reservationInfoId
export const selectCurrentStep = (state:RootState) => state.common[slice.name].currentStep

export const {
    changeSelectedTime,
    setQRValidate,
    changeCheckStepFooter,
    setReservationInfoId,
    changeCurrentStep,
} = slice.actions;

export const ReservationReducer = slice.reducer;
type Props = {
  okTitle: string;
  handleOK: () => void;
  cancelTitle: string;
  handleCancel: () => void;
};

const ModalFooter: React.FC<Props> = ({
  cancelTitle,
  handleCancel,
  handleOK,
  okTitle
}) => {

  return (
    <div className="agreement-management_modal-footer_container h-60px">
      <button
        onClick={handleOK}
        className="agreement-management_modal-footer_create-button"
      >
        {okTitle}
      </button>
      <button
        onClick={handleCancel}
        className="agreement-management_modal-footer_cancel-button"
      >
        {cancelTitle}
      </button>
    </div>
  );
};

export default ModalFooter;

import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Row, Col } from 'antd';
import ReservationList from './ReservationList';
import ReservationSetting from './ReservationSetting';
import { Tabs, TabsProps } from 'antd/lib';
import TabbarSearch from "src/Component/TabbarSearch";
import dayjs, { Dayjs } from "dayjs";
import './styles.css';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CustomReservationFrameResponse } from '../../../Network/Model/CustomReservationFrameResponse';
import {
  useLazyExmTimeByClinicIdQuery,
  useLazyReservationDataQuery
} from '../../../Network/Home/DoctorApi';
import DoctorConfirmPreOrder from 'src/Component/Modal/DoctorConfirmPreOrder';
import { useTranslation } from 'react-i18next';
import ModalWarning from '../../../Component/Modal/ModalWarning';
import { BUTTON_ACCEPT, MODAL_WARNING } from '../../../constant';
import { getClinicIdFromLocalStorage } from '../../../common/Libs';
import Loading from "../../../Component/Modal/Loading";
import { changeCheckStepFooter } from "src/Component/ArrowStepper/Slice/Slice";
import { useDispatch } from 'react-redux';

const APP = () => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [selectedClinicUserId, setSelectedClinicUserId] = useState<number>(-1);
  const [selectedTreatmentMenuId, setSelectedTreatmentMenuId] =
    useState<number>(-1);
  const dispatch = useDispatch();
  dispatch(changeCheckStepFooter(false));
  const [exmTime, setExmTime] = useState<number>(0);
  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };
  const [clinicId] = useState(getClinicIdFromLocalStorage());
  const [checked, setChecked] = useState<boolean>(false);
  const [param, setParam] = useState<any>({
    date: currentDate.format('YYYY-MM-DD'),
    clinic_id: clinicId
  });
  const [reservationData] = useLazyReservationDataQuery();

  const [data, setData] = useState<CustomReservationFrameResponse[]>([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [getExmTime] = useLazyExmTimeByClinicIdQuery();
  const [errMsg, setErrMsg] = useState<string>();
  const [valueClinicUserDefault, setValueClinicUserDefault] = useState(-1);
  const [valueTreatmentMenuDefault, setValueTreatmentMenuDefault] = useState(-1);
  const [clinicUserForModal, setClinicUserForModal] = useState(-1);
  const [treatmentMenuForModal, setTreatmentMenuForModal] = useState(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    handleResultApi();
    handleResultClinicApi();
  }, []);

  useEffect(() => {
    if(currentTab ===  1){
      handleResultApi();
    }
  }, [param, currentTab]);

  const handleResultApi = () => {
    setIsLoading(true);
    reservationData(param).then((res: any) => {
      if (res?.data?.error) {
        setErrMsg(res?.data?.message);
        setIsLoading(false);
      } else {
        setData(res?.data?.data || []);
        setIsLoading(false);
      }
    });
  };
  const handleResultClinicApi = () => {
    const param = {
      clinic_id: clinicId
    };
    getExmTime(param).then((res: any) => {
      if (res?.data?.error) {
        setErrMsg(res?.data?.message);
      } else {
        setExmTime(res?.data?.data?.exm_time || 0);
      }
    });
  }
  const handleShowModalConfirm = (value: boolean) => {
    if (selectedClinicUserId === -1) {
      setClinicUserForModal(valueClinicUserDefault);
    } else {
      setClinicUserForModal(selectedClinicUserId);
    }
    if (selectedTreatmentMenuId === -1) {
      setTreatmentMenuForModal(valueTreatmentMenuDefault);
    } else {
      setTreatmentMenuForModal(selectedTreatmentMenuId);
    }
    
    setShowModalConfirm(value);
  };
  useEffect(() => {
    setParam({
      ...param,
      date: currentDate.format('YYYY-MM-DD')
    });
  }, [currentDate]);

  const moveToSettingTab = () => {
    setCurrentTab(2);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('admin-schedule.reservation-list'),
      children: (
        <ReservationList
          isChecked={checked}
          reservationData={data}
          selectedClinicUserId={selectedClinicUserId}
          selectedTreatmentMenuId={selectedTreatmentMenuId}
          exmTime={exmTime}
          selectedDate={currentDate.format('YYYY-MM-DD')}
          moveToSettingTab={moveToSettingTab}
          reloadRsvFrame={() => {handleResultApi();}}
        />
      )
    },
    {
      key: '2',
      label: t('admin-schedule.reservation-setting'),
      children: (
        <ReservationSetting
          isChecked={checked}
          selectedClinicUserId={selectedClinicUserId}
          selectedTreatmentMenuId={selectedTreatmentMenuId}
          selectedDate={currentDate.format('YYYY-MM-DD')}
          exmTime={exmTime}
          isTabActive={currentTab === 2}
        />
      )
    }
  ];
  const handleChangeClinicUser = (value: number) => {
    setSelectedClinicUserId(value ? value : -1);
    setClinicUserForModal(value ? value : -1);
  };
  const handleChangeTreatmentMenu = (value: number) => {
    setSelectedTreatmentMenuId(value ? value : -1);
    setTreatmentMenuForModal(value ? value : -1);
  };
  const getDefaultValueClinicUser = (value: number) => {
    setValueClinicUserDefault(value);
  }
  const getDefaultValueTreatmentMenu = (value: number) => {
    setValueTreatmentMenuDefault(value);
  }

  
  const left = {
    left: (
      <Row className="tabs-extra-schedule-button">
        <Col className='fieldSearchDate'>
            <TabbarSearch handleSearch={(date:Dayjs = dayjs(), clinicUser:number=-1, clinic:number = -1) => {
                  setCurrentDate(date);
                  handleChangeClinicUser(clinicUser);
                  handleChangeTreatmentMenu(clinic);
                }
              }
              isNextDay={currentTab === 2}
              getDefaultValueClinicUser={(clinicUserId: number = -1) => {
                getDefaultValueClinicUser(clinicUserId);
              }}
              getDefaultValueTreatmentMenu={(treatMentMenuId: number = -1)=>{
                getDefaultValueTreatmentMenu(treatMentMenuId);
              }}
            />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={0}></Col>
        <Col>
          <Checkbox onChange={onChangeCheckbox} style={{fontWeight: 400, fontSize: 15 }}>
            {t('admin-schedule.show-all-frames')}
          </Checkbox>
        </Col>
      </Row>
    ),
    right: (
      <div style={{ marginBottom: 10 }}>
          {currentTab === 2 && <Button
              className="buttonShowModal text-bold-styles"
              onClick={() => handleShowModalConfirm(true)}
          >
            {t('admin.show-modal-confirm')}
          </Button>
          }
        </div>
    )
  };

  const handleChangeTab = (value: string) => {
    setCurrentTab(Number(value));
  };

  return (
    <div className="scheduleTabs-container">
      <Tabs
        className="scheduleTabs"
        tabBarExtraContent={left}
        tabPosition="top"
        type="card"
        items={items}
        activeKey={currentTab.toString()}
        onChange={handleChangeTab}
      />
      {showModalConfirm && (
        <DoctorConfirmPreOrder
          closeModal={() => handleShowModalConfirm(false)}
          searchDate={currentDate.format('YYYY-MM-DD')}
          doctorId={clinicUserForModal}
          treatmentMenuId={treatmentMenuForModal}
          exmTime={exmTime}
        />
      )}
      {errMsg && errMsg !== '' && (
        <ModalWarning
          message={errMsg}
          typeModal={MODAL_WARNING}
          typeButton={BUTTON_ACCEPT}
          changeVisibleModal={() => {
            setErrMsg('');
          }}
        />
      )}
      {isLoading && <Loading />}
    </div>
  );
};

export default APP;

import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import UserIcon2 from 'src/assets/image/user2.svg';
import PlusIcon from 'src/assets/image/plus-icon.svg';
import SettingIcon from 'src/assets/image/setting2.svg';
import './styles.css';
import {
  CustomReservationFrameResponse,
  ReservationFrameDetail
} from '../../../../Network/Model/CustomReservationFrameResponse';
import dayjs from 'dayjs';
import DT_01_1 from 'src/Screen/Doctor/Schedule/DT-01-1/';
import { useTranslation } from 'react-i18next';
import {
  HALF_AN_HOURS,
  HOURS_IN_DAY,
  isFutureDate,
  QUARTER_AN_HOURS
} from 'src/constant';
import TabRegistMedical from '../../DT-09';
import { useDispatch } from 'react-redux';
import { changeSelectionGuestUserName, changeSelectionRegistList } from '../../DT-09/slice';
import {ReservationInfoResponse} from "../../../../Network/Model/ReservationInfo";

const { Column } = Table;

const APP = ({
  isChecked,
  reservationData,
  selectedClinicUserId,
  selectedTreatmentMenuId,
  exmTime,
  selectedDate,
  moveToSettingTab,
  reloadRsvFrame
}: {
  isChecked: boolean;
  reservationData: CustomReservationFrameResponse[];
  selectedClinicUserId: number;
  selectedTreatmentMenuId: number;
  exmTime: number;
  selectedDate: string;
  moveToSettingTab: (event: React.MouseEvent<HTMLInputElement>) => void;
  reloadRsvFrame: (event: CustomEvent) => void
}) => {
  interface ReservationRow {
    // key: string;
    hour: string;
    sunday: ReservationFrameToDisplay;
    monday: ReservationFrameToDisplay;
    tuesday: ReservationFrameToDisplay;
    wednesday: ReservationFrameToDisplay;
    thursday: ReservationFrameToDisplay;
    friday: ReservationFrameToDisplay;
    saturday: ReservationFrameToDisplay;
  }

  interface DateData {
    displayDate: string;
    dateString: string;
    isFutureDate: boolean;
  }

  interface ReservationFrameToDisplay {
    appointmentCount: number;
    maxAppointmentCount: number;
    clinic_users: number[];
    treatment_menu_id: number[];
    time: string;
    isActive: boolean;
    isShowBtnAdd: boolean;
    reservation_frame_ids: number[];
    date_id: number;
  }

  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<ReservationRow[]>([]);
  const [dateArrays, setDateArrays] = useState<DateData[]>([]);
  const [stringDate, setStringDate] = useState('');
  const [stringTimeSelected, setStringTimeSelected] = useState('');
  const [dayOfWeekSelected, setDayOfWeekSelected] = useState(-1);
  const [rsvFrameId, setRsvFrameId] = useState<any>([]);
  const [hideScrollBarXOfTable, setHideScrollBarXOfTable] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setDateArrays(getDates());
    if (reservationData) {
      setDataSource(genData());
    }
  }, [
    reservationData,
    selectedClinicUserId,
    selectedTreatmentMenuId,
    isChecked,
    selectedDate,
    exmTime
  ]);

  useEffect(()=>{
    setIsOpenDT011(false);
    setOpenDt_09(false);
  }, [selectedDate])

  const genData = () => {
    let noFrameData = true;
    reservationData.map((rsvFr: CustomReservationFrameResponse, i: number) => {
      if (rsvFr.reservation_frame){
        noFrameData = false
      }
    })
    const dates = getDates();
    let data: ReservationRow[] = [];
    let minuteArr: string[];
    if (exmTime == 30) {
      minuteArr = HALF_AN_HOURS;
    } else {
      minuteArr = QUARTER_AN_HOURS;
    }
    for (const hour of HOURS_IN_DAY) {
      let hourHasData = false;
      let hourData : ReservationRow [] = [];
      minuteArr.forEach((value) => {
        const obj: ReservationRow = {
          hour: hour,
          sunday: getFrameData(dates[0].dateString || '', hour + ':' + value),
          monday: getFrameData(dates[1].dateString || '', hour + ':' + value),
          tuesday: getFrameData(dates[2].dateString || '', hour + ':' + value),
          wednesday: getFrameData(
            dates[3].dateString || '',
            hour + ':' + value
          ),
          thursday: getFrameData(dates[4].dateString || '', hour + ':' + value),
          friday: getFrameData(dates[5].dateString || '', hour + ':' + value),
          saturday: getFrameData(dates[6].dateString || '', hour + ':' + value)
        };
        hourData.push(obj);
        if (
          obj.sunday.reservation_frame_ids.length > 0 ||
          obj.monday.reservation_frame_ids.length > 0 ||
          obj.tuesday.reservation_frame_ids.length > 0 ||
          obj.wednesday.reservation_frame_ids.length > 0 ||
          obj.thursday.reservation_frame_ids.length > 0 ||
          obj.friday.reservation_frame_ids.length > 0 ||
          obj.saturday.reservation_frame_ids.length > 0
        ) {
         hourHasData = true;
        }
      });
      if ( noFrameData || isChecked || hourHasData) {
        data = data.concat(hourData)
      }
    }
    return data;
  };

  const getDates = () => {
    const dateArr: DateData[] = [];
    for (let i = 0; i < 7; i++) {
      dateArr.push({
        dateString: dayjs(selectedDate).day(i).format('YYYY-MM-DD'),
        displayDate: dayjs(selectedDate).day(i).format('MM/DD'),
        isFutureDate: isFutureDate(
          dayjs(selectedDate).day(i).format('YYYY-MM-DD')
        )
      });
    }
    return dateArr;
  };

  const getFrameData = (
    dateStr: string,
    time: string
  ): ReservationFrameToDisplay => {
    const data: ReservationFrameToDisplay = {
      time: time,
      appointmentCount: 0,
      maxAppointmentCount: 0,
      treatment_menu_id: [],
      clinic_users: [],
      isActive: false,
      isShowBtnAdd: isFutureDate(dateStr),
      reservation_frame_ids: [],
      date_id: -1
    };

    for (const element of reservationData) {
      if (element.date === dateStr && time === element.time) {
        const reservation_frame_obj: ReservationFrameDetail = JSON.parse(
          element.reservation_frame
        );
        if (reservation_frame_obj) {
        data.time = time;
        data.date_id = element.date_id;

        if (reservation_frame_obj && reservation_frame_obj.treatment_menu_id) {
          data.treatment_menu_id.push(reservation_frame_obj.treatment_menu_id);
        }
        if (reservation_frame_obj && reservation_frame_obj.clinic_user_ids) {
          reservation_frame_obj.clinic_user_ids.split(',').forEach(function (
            item: string
          ) {
            if (parseInt(item, 10)) {
              data.clinic_users.push(parseInt(item, 10));
            }
          });
        }
          const checkTreatmentMenu = !selectedTreatmentMenuId || selectedTreatmentMenuId < 0 || reservation_frame_obj.treatment_menu_id === selectedTreatmentMenuId;
          const checkClinicUser = !selectedClinicUserId || selectedClinicUserId < 0 || reservation_frame_obj.clinic_user_ids.split(',').includes(selectedClinicUserId.toString());
          if (checkTreatmentMenu && checkClinicUser) {
            data.maxAppointmentCount =
                data.maxAppointmentCount + (reservation_frame_obj ? reservation_frame_obj.upper_limit_frame : 0);
          }
        if (
          reservation_frame_obj &&
          reservation_frame_obj.reservation_frame_id
        ) {
          data.reservation_frame_ids.push(
            reservation_frame_obj.reservation_frame_id
          );
        }
        if (
          data.reservation_frame_ids.length > 0 &&
          (!selectedClinicUserId ||
            selectedClinicUserId < 0 ||
            data.clinic_users.includes(selectedClinicUserId)) &&
          (!selectedTreatmentMenuId ||
            selectedTreatmentMenuId < 0 ||
            data.treatment_menu_id.includes(selectedTreatmentMenuId))
        ) {
          data.isActive = true;
        }
        }
        data.appointmentCount = countActiveRsvInfo(element.rsv_infos);
      }
    }
    return data;
  };

  const countActiveRsvInfo = (rsvInfos : ReservationInfoResponse[]) : number => {
    let count = 0;
    if (!rsvInfos) return count;
    for (const info of rsvInfos){
      if ((!selectedTreatmentMenuId || selectedTreatmentMenuId < 0 || info.treatment_menu_id == selectedTreatmentMenuId) &&
          (!selectedClinicUserId || selectedClinicUserId < 0 || info.clinic_user_id == selectedClinicUserId)){
        count++
      }
    }
    return count;
  }

  const framePerOneHour = exmTime === 30 ? 2 : 4;

  const [isOpenDT011, setIsOpenDT011] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState<number>(-1);
  const showDT011 = (dateId: number, time?: string, date?: string ) => {
    setStringDate(date || '')
    setStringTimeSelected(time || '')
    setSelectedDateId(dateId);
    setIsOpenDT011(true);
    setOpenDt_09(false);
  };

  const [isOpenDt09, setOpenDt_09] = useState(false);
  const open_dt_09 = (dateId: number, dayOfWeek: number, timeSelected: string, rsvFrameId: number[]) => {
    const dateString = getDates()[dayOfWeek]?.dateString ?? '';
    setStringDate(dateString);
    setStringTimeSelected(timeSelected);
    setDayOfWeekSelected(dayOfWeek);
    setSelectedDateId(dateId);
    setRsvFrameId(rsvFrameId);
    dispatch(changeSelectionRegistList({}));
    dispatch(changeSelectionGuestUserName(''));
    setOpenDt_09(true);
    setIsOpenDT011(false);
  };

  useEffect(() => {
    if (!isOpenDT011 && !isOpenDt09) {
      setSelectedDateId(-1);
    }
    if (isOpenDT011 || isOpenDt09) {
      setHideScrollBarXOfTable(false);
    } else {
      setHideScrollBarXOfTable(true);
    }
  }, [isOpenDT011, isOpenDt09, selectedDateId]);

  return (
    <div className={'frameList'}>
      <Table
        className={hideScrollBarXOfTable? 'rsvFrameTbl noScrollBarX': 'rsvFrameTbl'}
        dataSource={dataSource}
        pagination={false}
      >
        <Column
          title=" "
          className={'text-right-align'}
          dataIndex="hour"
          key="hour"
          onCell={(_, index) => ({
            rowSpan:
              index === undefined || index % framePerOneHour !== 0
                ? 0
                : framePerOneHour,
          style: {width: "90px", textAlign: 'center'}
          })}
        />
        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ color: '#FF1493', fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.sun')}
                </span>
                <br />
                {dateArrays[0] ? dateArrays[0].displayDate : ''}
              </div>
              {dateArrays[0] &&
                (dateArrays[0].isFutureDate
                  ? dateArrays[0].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="sunday"
          key="sunday"
          render={(sunday: ReservationFrameToDisplay) => {
            return {
              props: {className: sunday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (sunday.reservation_frame_ids.length === 0 && (sunday.appointmentCount === 0 || !sunday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(sunday.date_id, sunday.time, dateArrays[0].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (sunday.reservation_frame_ids.length === 0 && (sunday.appointmentCount === 0 || !sunday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(sunday.date_id, sunday.time, dateArrays[0].dateString);
                         }
                       }
                       }>
                  <span>{sunday.time} </span>
                  {sunday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {sunday.appointmentCount}/
                      {sunday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {sunday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(sunday.date_id, 0, sunday.time, sunday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />

        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.mon')}
                </span>
                <br /> {dateArrays[1] ? dateArrays[1].displayDate : ''}
              </div>
              {dateArrays[1] &&
                (dateArrays[1].isFutureDate
                  ? dateArrays[1].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="monday"
          key="monday"
          render={(monday: ReservationFrameToDisplay) => {
            return {
              props: {className: monday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (monday.reservation_frame_ids.length === 0 && (monday.appointmentCount === 0 || !monday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(monday.date_id, monday.time, dateArrays[1].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (monday.reservation_frame_ids.length === 0 && (monday.appointmentCount === 0 || !monday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(monday.date_id, monday.time, dateArrays[1].dateString);
                         }
                       }
                       }>
                  <span>{monday.time} </span>
                  {monday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {monday.appointmentCount}/
                      {monday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {monday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(monday.date_id, 1, monday.time, monday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />
        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.tue')}
                </span> <br />{' '}
                {dateArrays[2] ? dateArrays[2].displayDate : ''}
              </div>
              {dateArrays[2] &&
                (dateArrays[2].isFutureDate
                  ? dateArrays[2].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="tuesday"
          key="tuesday"
          render={(tuesday: ReservationFrameToDisplay) => {
            return {
              props: {className: tuesday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (tuesday.reservation_frame_ids.length === 0 && (tuesday.appointmentCount === 0 || !tuesday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(tuesday.date_id, tuesday.time, dateArrays[2].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (tuesday.reservation_frame_ids.length === 0 && (tuesday.appointmentCount === 0 || !tuesday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(tuesday.date_id, tuesday.time, dateArrays[2].dateString);
                         }
                       }
                       }>
                  <span>{tuesday.time} </span>
                  {tuesday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {tuesday.appointmentCount}/
                      {tuesday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {tuesday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(tuesday.date_id, 2, tuesday.time, tuesday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />
        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.wed')}
                </span>
                <br /> {dateArrays[3] ? dateArrays[3].displayDate : ''}
              </div>
              {dateArrays[3] &&
                (dateArrays[3].isFutureDate
                  ? dateArrays[3].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="wednesday"
          key="wednesday"
          render={(wednesday: ReservationFrameToDisplay) => {
            return {
              props: {className: wednesday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (wednesday.reservation_frame_ids.length === 0 && (wednesday.appointmentCount === 0 || !wednesday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(wednesday.date_id, wednesday.time, dateArrays[3].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (wednesday.reservation_frame_ids.length === 0 && (wednesday.appointmentCount === 0 || !wednesday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(wednesday.date_id, wednesday.time, dateArrays[3].dateString);
                         }
                       }
                       }>
                  <span>{wednesday.time} </span>
                  {wednesday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {wednesday.appointmentCount}
                      /{wednesday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {wednesday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(wednesday.date_id, 3, wednesday.time, wednesday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />
        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.thu')}
                </span>
                 <br />{' '}
                {dateArrays[4] ? dateArrays[4].displayDate : ''}
              </div>
              {dateArrays[4] &&
                (dateArrays[4].isFutureDate
                  ? dateArrays[4].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="thursday"
          key="thursday"
          render={(thursday: ReservationFrameToDisplay) => {
            return {
              props: {className: thursday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (thursday.reservation_frame_ids.length === 0 && (thursday.appointmentCount === 0 || !thursday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(thursday.date_id, thursday.time, dateArrays[4].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (thursday.reservation_frame_ids.length === 0 && (thursday.appointmentCount === 0 || !thursday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(thursday.date_id, thursday.time, dateArrays[4].dateString);
                         }
                       }
                       }>
                  <span>{thursday.time} </span>
                  {thursday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {thursday.appointmentCount}
                      /{thursday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {thursday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(thursday.date_id, 4, thursday.time, thursday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />
        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.fri')}
                </span>
                <br /> {dateArrays[5] ? dateArrays[5].displayDate : ''}
              </div>
              {dateArrays[5] &&
                (dateArrays[5].isFutureDate
                  ? dateArrays[5].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="friday"
          key="friday"
          render={(friday: ReservationFrameToDisplay) => {
            return {
              props: {className: friday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (friday.reservation_frame_ids.length === 0 && (friday.appointmentCount === 0 || !friday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(friday.date_id, friday.time, dateArrays[5].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (friday.reservation_frame_ids.length === 0 && (friday.appointmentCount === 0 || !friday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(friday.date_id, friday.time, dateArrays[5].dateString);
                         }
                       }
                       }>
                  <span>{friday.time} </span>
                  {friday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {friday.appointmentCount}
                      /{friday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {friday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(friday.date_id, 5, friday.time, friday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />
        <Column
          width={250}
          title={
            <div style={{ whiteSpace: 'nowrap', position: 'relative' }}>
              <div>
                <span style={{ color: '#308AE6', fontWeight: 400 }}>
                  {t('admin-schedule.days-of-week.sat')}
                </span>
                <br /> {dateArrays[6] ? dateArrays[6].displayDate : ''}
              </div>
              {dateArrays[6] &&
                (dateArrays[6].isFutureDate
                  ? dateArrays[6].isFutureDate
                  : false) && (
                  <div>
                    <Button className={'settingBtn'} onClick={moveToSettingTab}>
                      <img src={SettingIcon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          }
          dataIndex="saturday"
          key="saturday"
          render={(saturday: ReservationFrameToDisplay) => {
            return {
              props: {className: saturday.isActive ? '' : 'deActiveFrame' },
              children: (
                <div>
                  <div tabIndex={0} 
                       className = {"frameBoxClick"}
                       onClick={(ev) => {
                         if (saturday.reservation_frame_ids.length === 0 && (saturday.appointmentCount === 0 || !saturday.appointmentCount)) {
                           setIsOpenDT011(false);
                           return;
                         }
                         showDT011(saturday.date_id, saturday.time, dateArrays[6].dateString);
                       }}
                       onKeyDown={(e) => {
                         if (e.code === "Enter") {
                           if (saturday.reservation_frame_ids.length === 0 && (saturday.appointmentCount === 0 || !saturday.appointmentCount)) {
                             setIsOpenDT011(false);
                             return;
                           }
                           showDT011(saturday.date_id, saturday.time, dateArrays[6].dateString);
                         }
                       }
                       }>
                  <span>{saturday.time} </span>
                  {saturday.appointmentCount > 0 && (
                    <div className="appointment-count">
                      <img src={UserIcon2} alt="" /> {saturday.appointmentCount}
                      /{saturday.maxAppointmentCount}
                    </div>
                  )}
                </div>
                  {saturday.isShowBtnAdd && (
                    <Button className={"addRsvBtn"}
                            onClick={() => open_dt_09(saturday.date_id, 6, saturday.time, saturday.reservation_frame_ids)}>
                      <img src={PlusIcon} alt=""/>
                    </Button>
                  )}
                </div>
              )
            };
          }}
          onCell={() => {
            return {
              style: {
                padding: "0",
                minWidth: "180px"
              }
            };
          }}
        />

        <Column
          title=""
          dataIndex="hour"
          key="hour"
          onCell={(_, index) => ({
            rowSpan:
              index === undefined || index % framePerOneHour !== 0
                ? 0
                : framePerOneHour,
            style: {
              width: "90px",
              textAlign: 'center'
            }
          })}
        />
      </Table>
      <DT_01_1
        exmTime={exmTime}
        dateId={selectedDateId}
        isShow={isOpenDT011}
        onClose={(e: React.MouseEvent<HTMLInputElement>) =>
          setIsOpenDT011(false)
        }
        rsvDate={stringDate}
        rsvTime={stringTimeSelected}
        moveToSettingTab={moveToSettingTab}
        reloadRsvFrame={()=>{reloadRsvFrame(new CustomEvent("")); setIsOpenDT011(false)}}
        selectedClinicUserId={selectedClinicUserId}
        selectedTreatmentMenuId={selectedTreatmentMenuId}
      />
      {isOpenDt09 === true ? (
        <TabRegistMedical
          exmTime={exmTime}
          dateId={selectedDateId}
          isShow={isOpenDt09}
          timeSelected={stringTimeSelected}
          dateSelected={stringDate}
          dayOfWeek={dayOfWeekSelected}
          rsvFrameId={rsvFrameId}
          onClose={(e: React.MouseEvent<HTMLInputElement>) =>
            {
              setOpenDt_09(false);
              dispatch(changeSelectionRegistList({}));
              dispatch(changeSelectionGuestUserName(''));
            }
          }
          reloadRsvFrame={() => {reloadRsvFrame(new CustomEvent(""))}}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default APP;

import { Col, Row } from 'antd';
import './styles.css'
import StarRating from 'src/Component/StarRating'
import Avatar from "src/Component/Avatar";
import CardInfoHospital from "src/Component/CardInfoHospital";
import { Clinic } from "src/Network/Model/Clinic";

type Props = {
  data: Clinic | undefined;
};

const InfoHospital: React.FC<Props> = ({ data }) => {
  const logoImg = data?.logo_image;
  const clinic_name = data?.clinic_name;
  const logoImgAlt = data?.logo_image_alt;

  return (
    <div className='infoHospital-wrap'>
      <Row className='infoHospital' gutter={[{}, { xs: 36, sm: 36 }]}>
        <Col xs={24} sm={24} md={4} className='booking-avatar-wrap'>
          <Avatar
            logoImg={logoImg}
            logoImgAlt={logoImgAlt}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <StarRating clinic_name={clinic_name} />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <CardInfoHospital data={data} />
        </Col>
      </Row>
    </div>
  );
};

export default InfoHospital;
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form } from 'antd';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import FormInput from 'src/Component/common/form/FormInput';
import {
  useGetClinicAddressQuery,
  useGetReservationQuery,
  useLazyGenerateReExamCodeQuery,
  useUpdateReservationMutation
} from 'src/Network/Home/DoctorSettingApi';
import { ReservationUpdateDto } from 'src/Network/Model/DoctorSetting';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import { ReactComponent as PrintIcon } from '../../../../../assets/image/printer.svg';
import { ReactComponent as RotateCwIcon } from '../../../../../assets/image/rotate_cw.svg';
import ErrorModal from '../ErorrModal';
import { CreateReExamCodeSchema, FormData } from '../constant';
import PrintReExamCode from './PrintReExamCode';
import ReservationURL from './ReservationURL';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  changeReExamCode,
  selectEndValue,
  selectHomepageUrl,
  selectStartValue
} from '../../medicalMenu/store/slice';
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';

const ReExaminationCode: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const componentRef = useRef<any>(null);
  const clinicId = getClinicIdFromLocalStorage();
  const [isChangeCode, setIsChangeCode] = useState(false);
  const [openErrorModal, setOpenErorrModal] = useState(false);
  const [erorrMessage, setErorrMessage] = useState('');
  const { data: reservationData } = useGetReservationQuery(clinicId, {
    skip: clinicId < 0
  });
  const [genExamCode, { data: newExamCode }] = useLazyGenerateReExamCodeQuery();
  const [updateReservation] = useUpdateReservationMutation();
  const { data: clinicAddressData } = useGetClinicAddressQuery(clinicId, {
    skip: clinicId < 0
  });
  const homepageUrl = useAppSelector(selectHomepageUrl);
  const startValue = useAppSelector(selectStartValue);
  const endValue = useAppSelector(selectEndValue);

  const formMethods = useForm<FormData>({
    resolver: yupResolver(CreateReExamCodeSchema)
  });
  const { handleSubmit, setValue, watch, trigger } = formMethods;

  const re_exm_code = watch('re_exm_code');
  const codeLength = lengthFullAndHalfWidth(re_exm_code);

  const updateUrl = (dto: ReservationUpdateDto) => {
    updateReservation(dto).then((res: any) => {
      if (!res?.data?.data) {
        setErorrMessage(res?.data?.message);
        setOpenErorrModal(true);
      }
    });
  };

  const onFinish = (values: FormData) => {
    if (reservationData) {
      setIsChangeCode(true);
      const dto: ReservationUpdateDto = {
        clinic_id: reservationData.clinic_id,
        rsv_acceptance_pediod_from: reservationData.rsv_acceptance_pediod_from,
        rsv_acceptance_pediod_to: reservationData.rsv_acceptance_pediod_to,
        url_public_status: reservationData.url_public_status,
        home_page_url: reservationData.home_page_url,
        re_exm_code: values.re_exm_code
      };
      updateUrl(dto);
    }
    if (!reservationData) {
      const dto: ReservationUpdateDto = {
        clinic_id: clinicId,
        re_exm_code: values.re_exm_code,
        rsv_acceptance_pediod_from: startValue,
        rsv_acceptance_pediod_to: endValue,
        url_public_status: 1,
        home_page_url: homepageUrl
      };
      updateUrl(dto);
    }
  };

  useEffect(() => {
    if (newExamCode) {
      setValue('re_exm_code', newExamCode, {shouldValidate: true});
    }
  }, [newExamCode]);

  const updateAndPrint = (dto: ReservationUpdateDto) => {
    updateReservation(dto).then((res: any) => {
      if (!res?.data?.data) {
        setErorrMessage(res?.data?.message);
        setOpenErorrModal(true);
      }
      if (res?.data?.code === 200) {
        handlePrint();
      }
    });
  };

  const handleClickPrint = () => {
    if (reservationData && codeLength === 8) {
      setIsChangeCode(true);
      const dto: ReservationUpdateDto = {
        clinic_id: reservationData.clinic_id,
        rsv_acceptance_pediod_from: reservationData.rsv_acceptance_pediod_from,
        rsv_acceptance_pediod_to: reservationData.rsv_acceptance_pediod_to,
        url_public_status: reservationData.url_public_status,
        home_page_url: reservationData.home_page_url,
        re_exm_code: re_exm_code
      };
      updateAndPrint(dto);
    }
    if (codeLength !== 8 && !reservationData) {
      const triggerReExamCode = document.getElementById('triggerReExamCode');
      if (triggerReExamCode) {
        triggerReExamCode.click();
      }
    }
    if (!reservationData && codeLength === 8) {
      const dto: ReservationUpdateDto = {
        clinic_id: clinicId,
        re_exm_code: re_exm_code
      };
      updateAndPrint(dto);
    }
  };

  useEffect(() => {
    dispatch(changeReExamCode(re_exm_code ?? ''));
  }, [re_exm_code]);

  const handleOnChange = (e: any) => {
    setValue('re_exm_code', e.target.value.trim().toUpperCase(), {
      shouldValidate: true
    });
  };

  const handleChangeCode = () => {
    genExamCode({});
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  useEffect(() => {
    if (reservationData?.re_exm_code && (!re_exm_code || isChangeCode)) {
      setValue('re_exm_code', reservationData.re_exm_code, {
        shouldValidate: true
      });
    }
    setIsChangeCode(false);
  }, [reservationData]);

  return (
    <Col span={8} className="re-examination-code_conatiner">
      <div className="re-examination-code_infor_container">
        <p className="re-examination-code_title">
          {t('reExamCode.reExaminationCode')}
        </p>
        <div className="re-examination-code_content">
          <FormProvider {...formMethods}>
            <Form name="reExamForm" onFinish={handleSubmit(onFinish)}>
              <div className="re-examination-code">
                <FormInput
                  inputProps={{
                    maxLength: 8,
                    onChange: handleOnChange,
                    onKeyDown: (event: KeyboardEvent<HTMLElement>) => {
                      const regex = /^[a-zA-Z0-9]*$/;
                      if (event.key === 'Backspace') return;
                      if (event.key === 'Tab') return;
                      if (event.key === 'Delete') return;
                      if (event.key === 'Escape') return;
                      if (!regex.test(event.key)) {
                        event.preventDefault();
                      }
                    }
                  }}
                  name="re_exm_code"
                />
                <button className="keep-re-exam-code_button">
                  {t('reExamCode.keepButton')}
                </button>
              </div>
              <div className="re-examination-code_print">
                <button
                  onClick={handleChangeCode}
                  type="button"
                  className="print-code_button"
                >
                  <RotateCwIcon />
                  <span className="print-code_text">
                    {t('reExamCode.reCreateCodeButton')}
                  </span>
                </button>
                <button
                  type="button"
                  onClick={handleClickPrint}
                  className="print-code_button"
                >
                  <PrintIcon />
                  <span className="print-code_text">
                    {t('reExamCode.printCodeButton')}
                  </span>
                </button>
              </div>
              <button
                className="display-none"
                id="triggerReExamCode"
                type="button"
                onClick={() => {
                  trigger('re_exm_code');
                }}
              />
            </Form>
          </FormProvider>
        </div>
        <ReservationURL />
      </div>
      <div className="display-none">
        {!!clinicAddressData &&
          PrintReExamCode(
            componentRef,
            clinicAddressData,
            t,
            re_exm_code,
            reservationData?.tel
          )}
      </div>
      <ErrorModal
        message={erorrMessage}
        open={openErrorModal}
        setClose={() => setOpenErorrModal(false)}
      />
    </Col>
  );
};

export default ReExaminationCode;

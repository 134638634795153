import React, { useState } from 'react';
// import 'antd/dist/antd.css';
import 'antd/dist/reset.css';
import './styles.module.css';
import { Form, Input, Button, Checkbox, Layout } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const Login = () => {
  const [result, setResult] = useState({});
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  const post = async (url: string, data: { token: string }) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      return response.json();
    } catch (error) {
      return '';
    }
  };

  const checkApi = async () => {
    const rp = await post('https://yost-api.ominext.dev/user', {
      token: '<?= $token ?>',
    });
    console.log(rp);
    if (rp === '') {
      await alert('Unauthorized');
      window.open('https://yost-wp.ominext.dev/u-login/?redirect_login=new');
      return;
    }
    await alert('token ok');
    setResult(rp);
  };

  const logout = () => {
    window.location.href = 'https://yost-wp.ominext.dev/u-login/?logout=1';
  };

  return (
    <Layout className="container">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        style={{ backgroundColor: 'white' }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="">
            Forgot password
          </a>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
          Or <a href="">register now!</a>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="button"
            className="login-form-button"
            onClick={checkApi}
          >
            test Api
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="button"
            className="login-form-button"
            onClick={logout}
          >
            logout
          </Button>
        </Form.Item>
        <div>{`${result}`}</div>
      </Form>
    </Layout>
  );
};

export default Login;

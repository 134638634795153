import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../Redux/store';
import { revertAll } from 'src/Redux/hooks';
const initialState = {
    activeKey: String as any,
}

const slice = createSlice({
  name: "tabsSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeTabs : (state, action) => {
        state.activeKey = action.payload
    },
  },
});

export const selectTab = (state:RootState) => state.common.tabsSlice.activeKey

export const {
    changeTabs
} = slice.actions;

export const TabsReducer = slice.reducer;
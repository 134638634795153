import { Controller, useFormContext } from 'react-hook-form';
import { Form, Select } from 'antd';

const FormInputSelect = ({ inputProps, ...props }: any) => {
  const { control, register } = useFormContext();
  return (
    <Controller
      name={props.name}
      {...props}
      control={control}
      defaultValue={props.defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error }
      }) => (
        <Form.Item
          className={props.className}
          validateStatus={error ? 'error' : 'validating'}
          help={error?.message}
        >
          <Select
            defaultValue={props.defaultValue}
            ref={register(props?.name).ref}
            options={props.options}
            disabled={props.disabled}
            placeholder={props.placeholder}
            suffixIcon={props.suffixIcon}
            {...{ value, onBlur }}
            {...inputProps}
            onChange={(e) => {
              onChange(e);
              if (inputProps?.onChange) {
                inputProps?.onChange(e);
              }
            }}
          />
        </Form.Item>
      )}
    />
  );
};

export default FormInputSelect;

import { Button, Row, Col, Table, Tooltip } from "antd";
import Plus2 from 'src/assets/image/plus2.svg';
import Warning from 'src/assets/image/warning-full.svg';
import { TreatmentMenu } from "src/Network/Model/TreatmentMenu";
import { useTranslation } from "react-i18next";
import { replaceWithBr } from "src/Utils/textUtils";
import { EXM_TIME } from "src/constant";

const { Column } = Table;

export const renderColumnHour = (isLeft:boolean, exmTime:number) => {
    return (
        <Column dataIndex="time" key="time" 
            onCell= {(_, index) => {
                    if(index !== undefined && exmTime === EXM_TIME.HALF_AN_HOURS && index % 2 === 0) {
                        return { rowSpan: 2, style: {zIndex: 10} };
                    } else if(index !== undefined && exmTime !== EXM_TIME.HALF_AN_HOURS && index % 4 === 0) {
                        return { rowSpan: 4, style: {zIndex: 10}  };
                    } else {
                        return {rowSpan: 0, style: {zIndex: 10} };
                    }
                }
            }
            width={60} 
            fixed={isLeft ? 'left' : 'right'}
        />
    )
};

export const renderTitleText = (title: String) => {
    return (
        <div className="titleColum text-bold-styles">
            {title}
        </div>
    )
}

export const renderColumnsEmpty = () => (
    {
        className: 'grayBox',
        rowSpan: 1
    }
);

export const renderTitleAdd = (title: String, handleNewTreatMentMenu: Function, disablePastDay = false) => {
    return (
        title 
        &&
        <Button type="text" 
                className="newTreatMentMenu text-bold-styles" 
                onClick={() => handleNewTreatMentMenu()}
                disabled={disablePastDay}
        >
            <img src={Plus2} alt="" />
            <span>{title}</span>
        </Button>
    )
}

const renderTooltip = (treatMentMenu: TreatmentMenu) => {
    const { t } = useTranslation();
    const content_line_1 = treatMentMenu.public_status ? t('schedule.reservation-setting.tooltip.line-1.content-1') : t('schedule.reservation-setting.tooltip.line-1.content-0');
    
    const content_line_2 = treatMentMenu.reservation_frame_public ? t('schedule.reservation-setting.tooltip.line-2.content-1') : t('schedule.reservation-setting.tooltip.line-2.content-0');
   
    let content_line_3;
    if (!treatMentMenu.exm_method) {
        content_line_3 = t('schedule.reservation-setting.tooltip.line-3.content-0');
    } else if (treatMentMenu.exm_method === 1) {
        content_line_3 = t('schedule.reservation-setting.tooltip.line-3.content-1');
    } else {
        content_line_3 = t('schedule.reservation-setting.tooltip.line-3.content-2');
    }

    const content_line_4 = treatMentMenu.first_exm_flg === 1 ? t('schedule.reservation-setting.tooltip.line-4.content-1') : t('schedule.reservation-setting.tooltip.line-4.content-2');

    let content_line_5 = '';
    if (treatMentMenu.exm_method === 1 || treatMentMenu.exm_method === 2) {
        content_line_5 += t('schedule.reservation-setting.tooltip.line-5.title-1');
        content_line_5 += treatMentMenu.f_consul_time_first_exm;
        content_line_5 += t('schedule.reservation-setting.tooltip.suffix');
        content_line_5 += '<br/>' + t('schedule.reservation-setting.tooltip.line-5.title-2');
        content_line_5 += treatMentMenu.f_consul_time_re_exm;
        content_line_5 += t('schedule.reservation-setting.tooltip.suffix');
    }
    if (treatMentMenu.exm_method === 0 || treatMentMenu.exm_method === 2) {
        content_line_5 += '<br/>' + t('schedule.reservation-setting.tooltip.line-5.title-3');
        content_line_5 += treatMentMenu.consul_time_first_exm;
        content_line_5 += t('schedule.reservation-setting.tooltip.suffix');
        content_line_5 += '<br/>' + t('schedule.reservation-setting.tooltip.line-5.title-4');
        content_line_5 += treatMentMenu.consul_time_re_exm;
        content_line_5 += t('schedule.reservation-setting.tooltip.suffix');
    }

    return ( 
        <div className="text-bold-styles toolTipText">
            <Row>
             <Col xs={12} sm={12} md={12}>{t('schedule.reservation-setting.tooltip.line-1.title')}</Col>
             <Col xs={12} sm={12} md={12}>{content_line_1}</Col>
            </Row>
            <Row>
             <Col xs={12} sm={12} md={12}>{t('schedule.reservation-setting.tooltip.line-2.title')}</Col>
             <Col xs={12} sm={12} md={12}>{content_line_2}</Col>
            </Row>
            <Row>
             <Col xs={12} sm={12} md={12}>{t('schedule.reservation-setting.tooltip.line-3.title')}</Col>
             <Col xs={12} sm={12} md={12}>{content_line_3}</Col>
            </Row>
            <Row>
             <Col xs={12} sm={12} md={12}>{t('schedule.reservation-setting.tooltip.line-4.title')}</Col>
             <Col xs={12} sm={12} md={12}>{content_line_4}</Col>
            </Row>
            <Row>
            
             <Col xs={12} sm={12} md={12}>{t('schedule.reservation-setting.tooltip.line-5.title')}</Col>
             <Col xs={12} sm={12} md={12}>
                <span dangerouslySetInnerHTML={replaceWithBr(content_line_5)}></span>
            </Col>
            </Row>
        </div>
    );
}

export const TitleIcon = ({treatMentMenu}: {treatMentMenu:TreatmentMenu}) => {
    return (
        <Tooltip 
            placement="right" 
            title={renderTooltip(treatMentMenu)} 
            overlayInnerStyle={{
                width: '350px',
                backgroundColor: '#5F6D7D',
                opacity: 0.95
            }}
        >
            <img src={Warning} alt='' className="treatMentMenuInfo"/>
        </Tooltip>
    );
}
import { Controller, useFormContext } from "react-hook-form";
import { Form, Input } from "antd";

const FormInPutPassWord = ({ inputProps, ...props }: any) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={props?.name}
      {...props}
      control={control}
      defaultValue={props?.defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <Form.Item
          className={props?.className}
          validateStatus={error ? "error" : "validating"}
          help={error?.message}
        >
          <Input.Password
            disabled={props?.disabled}
            placeholder={props?.placeholder}
            {...{ value, onChange, onBlur }}
            {...inputProps}
          />
        </Form.Item>
      )}
    />
  );
};

export default FormInPutPassWord;

import { BASE_URL, DoctorSettingApi } from '../Api';
import { ApiResponse } from '../Model/ApiResponse';
import {
  AddUpdateConsentFormType,
  ClinicAddressType,
  ConsentFormDataType,
  ConsentFormDtoType,
  ReservationType,
  ReservationUpdateDto,
  TreatmentMenuDtoType,
  TreatmentMenuType
} from '../Model/DoctorSetting';
import axiosBaseQuery from '../axiosBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

const doctorSettingApi = createApi({
  reducerPath: 'doctorSettingApi',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL
  }),
  tagTypes: ['TreatmentMenu', 'ConsentForm', 'Reservation'],
  endpoints: (builder) => ({
    getTreatmentMenuListByClinicId: builder.query<
      TreatmentMenuType[] | undefined,
      unknown
    >({
      query: (clinic_id: number) => ({
        url: DoctorSettingApi.treatmentMenu.getList(clinic_id),
        method: 'GET'
      }),
      providesTags: ['TreatmentMenu'],
      transformResponse: (response: ApiResponse<TreatmentMenuType[]>) =>
        response?.data
    }),
    addTreatmentMenu: builder.mutation<
      ApiResponse<boolean> | undefined,
      unknown
    >({
      query: (params: TreatmentMenuDtoType) => ({
        url: DoctorSettingApi.treatmentMenu.addItem,
        method: 'POST',
        data: params
      }),
      invalidatesTags: ['TreatmentMenu']
    }),
    updateTreatmentMenu: builder.mutation<
      ApiResponse<boolean> | undefined,
      unknown
    >({
      query: (params: TreatmentMenuDtoType) => ({
        url: DoctorSettingApi.treatmentMenu.updateItem,
        method: 'PUT',
        data: params
      }),
      invalidatesTags: ['TreatmentMenu']
    }),

    // ST-05,07
    getListConsentForm: builder.query<
      ConsentFormDataType[] | undefined,
      unknown
    >({
      query: (clinic_id: number) => ({
        url: DoctorSettingApi.consentForm.getListConsent(clinic_id),
        method: 'GET'
      }),
      providesTags: ['ConsentForm'],
      transformResponse: (response: ApiResponse<ConsentFormDataType[]>) =>
        response?.data
    }),
    updateConsentForm: builder.mutation<
      ApiResponse<AddUpdateConsentFormType> | undefined,
      unknown
    >({
      query: (params: ConsentFormDtoType) => ({
        url: DoctorSettingApi.consentForm.updateItem,
        method: 'PUT',
        data: params
      }),
      invalidatesTags: ['ConsentForm']
    }),
    addConsentForm: builder.mutation<
      ApiResponse<AddUpdateConsentFormType> | undefined,
      unknown
    >({
      query: (params: ConsentFormDtoType) => ({
        url: DoctorSettingApi.consentForm.addItem,
        method: 'POST',
        data: params
      }),
      invalidatesTags: ['ConsentForm']
    }),

    deleteConsentForm: builder.mutation<string | undefined, unknown>({
      query: (id: string | number) => ({
        url: DoctorSettingApi.consentForm.deleteItem,
        method: 'delete',
        data: { id: id }
      }),
      invalidatesTags: ['ConsentForm']
    }),

    // reservation management
    getReservation: builder.query<ReservationType | undefined, unknown>({
      query: (clinic_id: number) => ({
        url: DoctorSettingApi.reservationManagement.getReservation(clinic_id),
        method: 'GET'
      }),
      providesTags: ['Reservation'],
      transformResponse: (rawResult: ApiResponse<ReservationType>) => {
        return rawResult.data;
      }
    }),
    updateReservation: builder.mutation<
      ApiResponse<ReservationType> | undefined,
      unknown
    >({
      query: (params: ReservationUpdateDto) => ({
        url: DoctorSettingApi.reservationManagement.updateReservation,
        method: 'PUT',
        data: params
      }),
      invalidatesTags: ['Reservation']
    }),
    getClinicAddress: builder.query<ClinicAddressType | undefined, unknown>({
      query: (clinic_id: number) => ({
        url: DoctorSettingApi.reservationManagement.getClinicAddress(clinic_id),
        method: 'GET'
      }),
      transformResponse: (rawResult: ApiResponse<ClinicAddressType>) => {
        return rawResult.data;
      }
    }),
    generateReExamCode: builder.query<string | undefined, unknown>({
      query: () => ({
        url: DoctorSettingApi.reservationManagement.generateReExamCode,
        method: 'GET'
      }),
      transformResponse: (rawResult: ApiResponse<string>) => {
        return rawResult.data;
      }
    })
  })
});

export const {
  useGetTreatmentMenuListByClinicIdQuery,
  useAddTreatmentMenuMutation,
  useUpdateTreatmentMenuMutation,
  useGetListConsentFormQuery,
  useAddConsentFormMutation,
  useUpdateConsentFormMutation,
  useDeleteConsentFormMutation,
  useGetClinicAddressQuery,
  useGetReservationQuery,
  useUpdateReservationMutation,
  useLazyGenerateReExamCodeQuery
} = doctorSettingApi;

export default doctorSettingApi;

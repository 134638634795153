import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, Form } from "antd";

const FormInputCheckbox = ({ children, inputProps, ...props }: any) => {
  const { control, register } = useFormContext();
  return (
    <Controller
      name={props.name}
      {...props}
      control={control}
      defaultValue={props.defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error }
      }) => (
        <Form.Item
          className={props.className}
          validateStatus={error ? 'error' : 'validating'}
          help={error?.message}
        >
          <Checkbox
            defaultValue={props.defaultValue}
            ref={register(props?.name).ref}
            disabled={props.disabled}
            checked={value}
            {...{ onBlur }}
            {...inputProps}
            onChange={(e) => {
              onChange(e.target.checked);
              inputProps?.onChange(e);
            }}
          >
            {children}
          </Checkbox>
        </Form.Item>
      )}
    />
  );
};

export default FormInputCheckbox;

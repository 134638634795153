import "./styles.css";
import { Button, Modal, Col, Row, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from "src/Utils/textUtils";
import Warning from "src/assets/image/warning.svg";
import { ReservationFrameRepeat } from "src/Network/Model/ReservationFrameRepeat";
import React, { useState } from "react";
import { BUTTON_ACCEPT, MODAL_FAIL, contentRepeat, formatYYYYMMDD, renderTreatmentValueEdit } from "src/constant";
import { TreatmentMenu } from "src/Network/Model/TreatmentMenu";
import { TreatMentMenuItem } from "src/Network/Model/TreatMentMenuItem";
import { useReservationFrameRepeatUpdateMutation } from "src/Network/Home/DoctorApi";
import Loading from "../Loading";
import dayjs from 'dayjs';
import ModalWarning from "src/Component/Modal/ModalWarning";

type Props = {
    isDeleteModal: boolean,
    nextToConfirm: Function,
    closeModal: Function,
    dataReservationFrameRepeat: ReservationFrameRepeat,
    treatMentMenuList: TreatmentMenu[],
    repeatOnDayOfWeekOptions:Array<{value: number, label: string}>,
    dataReservationFrameRepeatOld: ReservationFrameRepeat,
    repeatOnDayOfWeekOptionsOld:Array<{value: number, label: string}>
    examOld: TreatMentMenuItem[],
    setVisibleSuccess: Function,
    visibleNotice: boolean
}

const APP: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [visibleWarning, setVisibleWarning] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const data = props?.dataReservationFrameRepeat;
    const [isLoading, setIsLoading] = useState(false);
    const treatmentList = data?.exam ? data.exam.map((i:TreatMentMenuItem) => Number(i.id)) : [];
    const uniqueTreatmentList = [...new Set(treatmentList)];
    const treatmentNameList = uniqueTreatmentList.map((item) => {
        const treatmentItem = props.treatMentMenuList.find((i:TreatmentMenu) => i.id === Number(item))
        return treatmentItem?.treatment_menu_name
    })
    const dataOld = props?.dataReservationFrameRepeatOld;
    const [reservationFrameRepeatUpdate] = useReservationFrameRepeatUpdateMutation();
    const [codeError, setCodeError] = useState(false);
    const handleEdit = () => {
        const reservationFrameRepeatList = uniqueTreatmentList?.map((item) => {
            const treatMentMenuList = data?.exam?.filter((i:TreatMentMenuItem) =>  i.id === Number(item)) as Array<any>
            const face_to_face_first_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '1') ? 1: 0;
            const online_first_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '2') ? 1: 0;
            const face_to_face_re_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '3') ? 1: 0;
            const online_re_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '4') ? 1: 0;
            return {
                id: data?.id,
                clinic_id: data?.clinic_id,
                treatment_menu_id: Number(item),
                rsv_start_ymd: dayjs(data?.rsv_start_ymd).format(formatYYYYMMDD).replaceAll('/', ''),
                rsv_end_ymd: dayjs(data?.repeat_type === 0 ? data?.rsv_start_ymd : data?.rsv_end_ymd).format(formatYYYYMMDD).replaceAll('/', ''),
                rsv_start_time: data?.rsv_start_time,
                rsv_end_time: data?.rsv_end_time,
                repeat_type: data?.repeat_type,
                is_public_on_holiday: data?.repeat_type === 0 ? 1 : (data?.is_public_on_holiday ? 1 : 0),
                upper_limit_frame: Number(data?.upper_limit_frame),
                repeat_on_day_of_week: data?.repeat_type === 0 ? null : data?.repeat_on_day_of_week,
                face_to_face_first_exm: face_to_face_first_exm,
                online_first_exm: online_first_exm,
                face_to_face_re_exm: face_to_face_re_exm,
                online_re_exm: online_re_exm,
                clinic_user_ids: data?.clinicUser
            }
        })
        setIsLoading(true);
        reservationFrameRepeatList.map((item: any) => {
            reservationFrameRepeatUpdate(item).then((res: any) => {
                if (!res?.data?.error) {
                    props.setVisibleSuccess(true);
                    props.closeModal();
                } else {
                    // TODO show error
                    setVisibleWarning(true);
                    if (res?.data?.code === 400 || res?.data?.code === 40004 || res?.data?.code === 40006) {
                        setCodeError(true);
                    }
                }
                setIsLoading(false);
            })
        });
    };

    const handleClose = () => {
        props.closeModal();
    };

    function handleCheckboxChange() {
        setIsChecked(!isChecked);
    }

    return (
        <>
            <Modal
                maskClosable={false}
                title = {t("modal-delete-order.title")}
                centered
                open={true}
                footer={null}
                onCancel={handleClose}
                className='modalEditReversion'
            >
                <Row gutter={[{ }, { xs: 32, sm: 24, md: 32 }]}>
                    <Col xs={24} sm={24} md={24}></Col>
                    <Col xs={24} sm={24} md={24}>
                        <Row gutter={[{ }, { xs: 24, sm: 24, md: 24 }]}>
                            {
                                props.visibleNotice &&
                                <Col xs={24} sm={24} md={24} className="notice">
                                    <span><img src={Warning} alt='' /></span>
                                    <span dangerouslySetInnerHTML={replaceWithBr(t("modal-doctor-edit.notice"))}></span>
                                </Col>
                            }
                            <Col xs={24} sm={24} md={24} style={{textAlign: 'center'}}>
                                {t("modal-doctor-edit.title")}
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Row gutter={[{ xs: 24, sm: 24, md: 24 }, { xs: 24, sm: 24, md: 24 }]}>
                                    <Col xs={12} sm={12} md={12}>
                                        <div className='titleReservation text-bold-styles'>
                                            {t("modal-doctor-edit.title-reservation.before")}
                                        </div>
                                        <div className='orderList'>
                                            <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-1.title"), content: treatmentNameList?.toString().replaceAll(',','<br/>') }} />
                                            <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-2.title"), content: contentRepeat(dataOld, props.repeatOnDayOfWeekOptionsOld) }} />
                                            <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-3.title"), content: null }} />
                                            {renderTreatmentValueEdit(props.examOld)}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <div className='titleReservation text-bold-styles'>
                                            {t("modal-doctor-edit.title-reservation.after")}
                                        </div>
                                        <div className='orderList'>
                                            <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-1.title"), content: treatmentNameList?.toString().replaceAll(',','<br/>') }} />
                                            <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-2.title"), content: contentRepeat(data, props.repeatOnDayOfWeekOptions) }} />
                                            <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-3.title"), content: null }} />
                                            {renderTreatmentValueEdit(data?.exam)}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} className="policy">
                                <Checkbox onChange={handleCheckboxChange}>{t("modal-doctor-edit.policy")}</Checkbox>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} className='footerModal'>
                        <Row gutter={[{ xs: 20, sm: 20 }, { xs: 20, sm: 20 }]}>
                            <Col xs={12} sm={12}>
                                <Button type='text' onClick={handleEdit} className={!isChecked ? 'buttonPt10-left-disable text-bold-styles' : 'buttonPt10-left text-bold-styles'} disabled={!isChecked}>
                                    {t("modal-doctor-edit.footer.button-edit")}
                                </Button>
                            </Col>
                            <Col xs={12} sm={12}>
                                <Button type='text' onClick={handleClose} className='buttonPt10-right text-bold-styles'>
                                    {t("modal-delete-order.footer.button-cancel")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {isLoading && <Loading />}
            </Modal>
            {
                visibleWarning &&
                <ModalWarning
                    message={codeError ? t("message.MESS_ERR_RSV_FRAME_UPDATE_FAIL") : t("message.MESS_ERR_CANNOT_UPDATE_RSV_FRAME")}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {setVisibleWarning(false)}}
                />
            }
        </>
    )
}

const ItemInfo = ({ data }: any) => {
    return (
        <div>
            <div className='itemTitle text-bold-styles'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.title)}></span>
            </div>
            {data?.content &&
            <div className='itemContent'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.content)}></span>
            </div>
            }
        </div>
    )
}

export default APP;
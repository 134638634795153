import { QRCodeCanvas } from 'qrcode.react';
import { Trans } from 'react-i18next';
import { ClinicAddressType } from 'src/Network/Model/DoctorSetting';

const PrintReExamCode = (
  ref: any,
  clinicAddressData: ClinicAddressType,
  t: Function,
  reExamCode?: string,
  tel?:string
) => {
  const { address, city_name, prefectures_name, clinic_id, clinic_name } =
    clinicAddressData;
  const clinicAddress = prefectures_name + city_name + address;
  const reservationSiteHost = process.env.REACT_APP_APP_URL;
  const qrValueCode = `${reservationSiteHost}/?clinic_id=${clinic_id}&re_exm_code=${reExamCode}`;

  return (
    <div ref={ref} className="print-re-exam-code-container">
      <div className="print-clinic-title">{clinic_name}</div>
      <div className="print-clinic-address">{clinicAddress}</div>
      <div className="print-clinic-title">
        {t('reExamCode.printClinicTitle')}
      </div>
      <div className="print-clinic-guild">
        {t('reExamCode.printClinicGuild')}
      </div>
      <table className="print-re-exam-table">
        <thead>
          <tr>
            <th>{t('reExamCode.reExaminationCode')}</th>
            <th>{t('reExamCode.reExamQRCode')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>{reExamCode}</div>
            </td>
            <td>
              <div>
                <QRCodeCanvas value={qrValueCode} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="print-note-text">{t('reExamCode.printClinicNote')}</p>
      <div className="print-link-ishachoku">
        <Trans
          i18nKey={'reExamCode.printClinicLinkDes'}
          components={{
            p: <p />,
            span: <span />
          }}
          values={{ tel: tel }}
        />
      </div>
    </div>
  );
};

export default PrintReExamCode;

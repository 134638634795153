import { createSlice } from '@reduxjs/toolkit';
import { TreatmentMenuType } from 'src/Network/Model/DoctorSetting';
import { RootState } from 'src/Redux/store';
import { FormData } from '../medicalMenuForm/constants';

const slice = createSlice({
  name: 'medicalMenuSlice',
  initialState: {
    isListMode: true,
    isCreate: true,
    selectedUpdateId: undefined as number | undefined,
    editMedicalMenu: undefined as TreatmentMenuType | undefined,
    dto: undefined as FormData | undefined,
    openConfirmModal: false,
    openErrorModal: false,
    openSuccessModal: false,
    examMethod: 1,
    reExamCode: '',
    homepageUrl: '',
    endValue: 0,
    startValue: 0
  },
  reducers: {
    changeIsListMode: (state, action) => {
      state.isListMode = action.payload;
    },
    changeIsCreate: (state, action) => {
      state.isCreate = action.payload;
    },
    changeSelectedUpdateId: (state, action) => {
      state.selectedUpdateId = action.payload;
    },
    changeEditMedicalMenu: (state, action) => {
      state.editMedicalMenu = action.payload;
    },
    changeDto: (state, action) => {
      state.dto = action.payload;
    },
    changeOpenConfirmModal: (state, action) => {
      state.openConfirmModal = action.payload;
    },
    changeOpenErrorModal: (state, action) => {
      state.openErrorModal = action.payload;
    },
    changeOpenSuccessModal: (state, action) => {
      state.openSuccessModal = action.payload;
    },
    changeExamMethod: (state, action) => {
      state.examMethod = action.payload;
    },
    changeReExamCode: (state, action) => {
      state.reExamCode = action.payload;
    },
    changeHomepageUrl: (state, action) => {
      state.homepageUrl = action.payload;
    },
    changeStartValue: (state, action) => {
      state.startValue = action.payload;
    },
    changeEndValue: (state, action) => {
      state.endValue = action.payload;
    }
  }
});

export const selectIsListMode = (state: RootState) =>
  state[slice.name].isListMode;
export const selectIsCreate = (state: RootState) => state[slice.name].isCreate;
export const selectSelectedUpdateId = (state: RootState) =>
  state[slice.name].selectedUpdateId;

export const selectDto = (state: RootState) => state[slice.name].dto;
export const selectEditMedicalMenu = (state: RootState) =>
  state[slice.name].editMedicalMenu;
export const selectOpenConfirmModal = (state: RootState) =>
  state[slice.name].openConfirmModal;
export const selectOpenErrorModal = (state: RootState) =>
  state[slice.name].openErrorModal;
export const selectOpenSuccessModal = (state: RootState) =>
  state[slice.name].openSuccessModal;
export const selectExamMethod = (state: RootState) =>
  state[slice.name].examMethod;
export const selectReExamCode = (state: RootState) =>
  state[slice.name].reExamCode;
export const selectHomepageUrl = (state: RootState) =>
  state[slice.name].homepageUrl;
export const selectStartValue = (state: RootState) => state[slice.name].startValue;
export const selectEndValue = (state: RootState) =>
  state[slice.name].endValue;

export const {
  changeIsListMode,
  changeIsCreate,
  changeSelectedUpdateId,
  changeEditMedicalMenu,
  changeDto,
  changeOpenConfirmModal,
  changeOpenErrorModal,
  changeOpenSuccessModal,
  changeExamMethod,
  changeReExamCode,
  changeEndValue,
  changeStartValue,
  changeHomepageUrl
} = slice.actions;

export const MedicalMenuSliceReducer = slice.reducer;

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as InfoOutlineIcon } from '../../../../../assets/image/info_outline.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/image/plus2.svg';

import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import FormInput from 'src/Component/common/form/FormInput';
import FormInputNumber from 'src/Component/common/form/FormInputNumber';
import {
  useAddConsentFormMutation,
  useUpdateConsentFormMutation
} from 'src/Network/Home/DoctorSettingApi';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  AgreementScheme,
  DEFALT_AMOUNT_DES,
  DEFAULT_TITLE_FOR_PATIENT,
  FormData
} from '../constant';
import {
  changeIsModalOpen,
  changeOpenSuccessModal,
  selectIsCreate,
  selectSelectedUpdateRecord
} from '../store/slice';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';

const ModalBody: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isCreate = useAppSelector(selectIsCreate);
  const clinicId = getClinicIdFromLocalStorage();
  const selectedUpdateRecord = useAppSelector(selectSelectedUpdateRecord);
  const [addConsentForm] = useAddConsentFormMutation();
  const [updateConsentForm] = useUpdateConsentFormMutation();
  const formMethods = useForm<FormData>({
    resolver: yupResolver(AgreementScheme),
    defaultValues: {
      non_insurance_amounts: [
        { amount_description: DEFALT_AMOUNT_DES, amount: 0 }
      ],
      title: t('reExamCode.defaultConsentFormTitle')
    }
  });

  const { control, handleSubmit, watch, reset } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'non_insurance_amounts'
  });

  const non_insurance_amounts = watch('non_insurance_amounts');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...non_insurance_amounts?.[index]
    };
  });

  const onFinish = (values: any) => {
    const non_insurance_amounts = values?.non_insurance_amounts?.map(
      (value: any) => {
        return {
          amount_description: value.amount_description,
          amount: value.amount
        };
      }
    );
    const commonDto = {
      clinic_id: clinicId,
      non_insurance_amounts,
      title: values.title
    };

    if (isCreate) {
      const createDto = {
        ...commonDto,
        title_for_patient: DEFAULT_TITLE_FOR_PATIENT
      };
      addConsentForm(createDto).then((res: any) => {
        if (res.data.code === 200) {
          dispatch(changeIsModalOpen(false));
          dispatch(changeOpenSuccessModal(true));
        }
      });

      return;
    } else {
      const updateDto = {
        ...commonDto,
        id: values.id,
        title_for_patient: values.title_for_patient
      };
      updateConsentForm(updateDto).then((res: any) => {
        if (res.data.code === 200) {
          dispatch(changeIsModalOpen(false));
          dispatch(changeOpenSuccessModal(true));
        }
      });
    }
  };

  useEffect(() => {
    if (!isCreate && selectedUpdateRecord) {
      reset(selectedUpdateRecord);
    } else {
      reset();
    }
  }, [isCreate, selectedUpdateRecord]);

  return (
    <div className="agreement-management_modal-body">
      <div className="agreement-management_modal_note">
        <InfoOutlineIcon width={18} height={18} />
        <p>{t('reExamCode.agreementModalNote')}</p>
      </div>
      <FormProvider {...formMethods}>
        <Form name="agreementForm" onFinish={handleSubmit(onFinish)}>
          <div className="form-item">
            <div className="form-item_title">
              <span>{t('reExamCode.titleHospitalDisplay')}</span>
              <span className="title_tag">{t('reExamCode.need')}</span>
            </div>
            <FormInput
              name="title"
              inputProps={{
                className: 'agreement-management_form-input'
              }}
            />
          </div>
          <div className="form-item_title">
            <span>{t('reExamCode.itemNoInsurance')}</span>
            <span className="title_tag">{t('reExamCode.need')}</span>
          </div>
          <Row>
            <Col span={16}>
              <div className="form-item_title">
                <span>{t('reExamCode.explainNumber')}</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="form-item_title">
                <span>{t('reExamCode.amountPrice')}</span>
              </div>
            </Col>
          </Row>
          {controlledFields.map((field, index) => {
            return (
              <Row key={field.id}>
                <Col span={16}>
                  <FormInput
                    inputProps={{
                      className: 'agreement-management_form-input mr-20px',
                      defaultValue: DEFALT_AMOUNT_DES
                    }}
                    name={`non_insurance_amounts.${index}.amount_description`}
                  />
                </Col>
                <Col span={8} className="agreement-form_amount-wrapper">
                  <div className="agreement-form_amount-col">
                    <FormInputNumber
                      inputProps={{
                        className: 'agreement-management_form-input mw-120',
                        defaultValue: 0,
                        formatter: (value: number | string) => {
                          if (Number(value) % 1 !== 0) {
                            return 0;
                          }
                          return value;
                        },
                        max: 2147483647,
                        min: 0
                      }}
                      name={`non_insurance_amounts.${index}.amount`}
                    />
                  </div>
                  <div>
                    <div
                      style={{ marginTop: fields.length > 1 ? 6 : 18 }}
                      className="agreement-form_unit"
                    >
                      <div>{t('reExamCode.yenIncludeVAT')}</div>
                      {fields.length > 1 ? (
                        <button
                          className="agreement-form_unit-delete-btn"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          {t('reExamCode.delete')}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
          <div className="add-agreement-form-button_container">
            <button
              className="add-agreement-form_button"
              type="button"
              onClick={() =>
                append({ amount_description: DEFALT_AMOUNT_DES, amount: 0 })
              }
              disabled={fields.length >= 10}
              style={{
                backgroundColor: fields.length >= 10 ? '#a7a7a7' : '#ff1493',
                cursor: fields.length >= 10 ? 'not-allowed' : 'pointer'
              }}
            >
              <PlusIcon />
              <span className="add-agreement-form_button-name">
                {t('reExamCode.addCategory')}
              </span>
            </button>
            <div className="add-agreement-form-note_container">
              <InfoOutlineIcon width={18} height={18} />
              <span>{t('reExamCode.addAgreementNote')}</span>
            </div>
          </div>
          <button
            style={{ display: 'none' }}
            id="submitAgreeFormBtn"
            type="submit"
          />
        </Form>
      </FormProvider>
      <div className="agreement-management_modal_title-display-patient">
        <h2>{t('reExamCode.titleDisplayPatient')}</h2>
        <div className="title-display-patient_container">
          <p>
            {!isCreate && selectedUpdateRecord
              ? selectedUpdateRecord.title_for_patient
              : DEFAULT_TITLE_FOR_PATIENT}
          </p>
        </div>
      </div>
      <div className="agreement-management_modal_title-display-patient">
        <h2>{t('reExamCode.displayPatientContentTitle')}</h2>
        <div className="title-display-patient_container">
          <p>
            <Trans
              i18nKey={'reExamCode.displayPatientContent'}
              components={{ br: <br /> }}
            />
            {controlledFields?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Trans
                    i18nKey={'reExamCode.itemInAgreementContent'}
                    components={{ br: <br /> }}
                    values={{
                      name: item.amount_description,
                      value: item.amount
                    }}
                  />
                </React.Fragment>
              );
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalBody;

import { WeekData } from "../../Network/Model/WeekData";
import empty from "../../assets/image/empty.svg";
import full from "../../assets/image/full.svg";

const weekDay = ["日", "月", "火", "水", "木", "金", "土"];
export const createTempDateData = () => {
  var data: WeekData[] = [];
  var date = new Date();
  for (var i = 0; i < 7; i++) {
    var w: WeekData = {
      dayInWeek: date.getDay(),
      month: date.getMonth(),
      year: date.getFullYear(),
      day: date.getDate(),
      isAvailale: false
    }
    data.push(w);
    date.setDate(date.getDate() + 1);
  }
  return data;
}

export const columns = (weekData: WeekData[]) => (
  weekData.map((item, index) => {
    let className = "";
    if (item.dayInWeek == 6) {
      className = " saturday";
    } else if (item.dayInWeek == 0) {
      className = " sunday";
    }
    const _data = item.month + "/" + item.day;
    const col = {
      title: (
        <div style={{ textAlign: 'center' }}>
          <p className={`text-bold-styles${className}`}>{weekDay[item.dayInWeek]}</p>
          <p className="text-normal-styles daysNumber">{_data}</p>
        </div>
      ),
      dataindex: _data,
      key: index + 1,
      render: () => {
        return (
          <div key={index} style={{ textAlign: 'center' }}>
            {!item.isAvailale ? <img src={full} /> : <img src={empty} />}
          </div>
        );
      },
    };
    return col;
  }));
export const tableColumn = (weekData: WeekData[]) => [
  ...columns(weekData),
];

export const dataTable = (weekData: WeekData[], key: Number) => {
  var data: any = [];
  var object: any = {};
  object['key'] = key;
  weekData.map((item) => {
    const _data = item.month + "/" + item.day;
    object[_data] = item.isAvailale;
  });
  data.push(object);
  return data;
};
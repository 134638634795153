import React, { useEffect, useRef, useState } from 'react';
import { Input, AutoComplete } from 'antd';
import magnifying_glass from 'src/assets/image/magnifying_glass.svg';
import { useTranslation } from 'react-i18next';
import './styles.css';
import { useSearchIshachokuUserMutation } from 'src/Network/Home/DoctorApi';
import JapaneseEraCalculator from '../JapaneseEraCalculator';
import { useAppDispatch } from 'src/Redux/hooks';
import { changeSelectionRegistList, selectSelectionRegistList } from '../slice';
import { useSelector } from 'react-redux';

interface Props {
  setSelectedOptionLabel: (karute_id: string, name: string, gender: string, birth: string, status_card: string) => void;
}

const SearchBar: React.FC<Props> = ({ setSelectedOptionLabel }) => {
  const SelectionRegist = useSelector(selectSelectionRegistList);
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [searchIshachokuUser] = useSearchIshachokuUserMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
      if (!SelectionRegist.user_name) {
        setSelectedOption('');
        setSearchResults([]);
        setSelectedOptionLabel("-", "-", "-", "-", "-");
      }
  }, [SelectionRegist]);

  const handleSearch = async (value: any) => {
    if ((value !== '' || selectedOption !== '') && ( value !== null && selectedOption !== null )) {
      try {
        const response = await searchIshachokuUser({ keyword: value });
        if ('data' in response) {
          const data = response.data;
          setSearchResults(data || []);
        } else {
          console.error('Error occurred:', response.error);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setSearchResults([]);
      setSelectedOptionLabel("-", "-", "-", "-", "-");
      dispatch(changeSelectionRegistList({}));
    }
  };

  const optionLabel = (d: {
    user_name: string;
    sex: number;
    birth_year: number;
    birth_month: number;
    birth_day: number;
  }) => {
    const { user_name, sex, birth_year, birth_month, birth_day } = d;
    const genderLabel = sex === 1 ? t('register.male') : t('register.woman');
    const japaneseEra = JapaneseEraCalculator(birth_year);
    const age = calculateAge(birth_year, birth_month, birth_day);
    return (
      <>
        <div className='user-name-search-box'>{user_name}</div>
        <div className='info-search-box'>{genderLabel} {japaneseEra} ({birth_year}) {birth_month}月{birth_day}日 {age}</div>
      </>
    );
  };

  const handleOptionSelect = (value: any) => {
    const selected = searchResults.find((result) => (result.karute_id === value));
    setSelectedOption(selected?.user_name);
    const genderLabel = selected?.sex === 1 ? t('register.male') : t('register.woman');
    const japaneseEra = JapaneseEraCalculator(selected?.birth_year ?? 0);
    const birth = japaneseEra + " (" + selected?.birth_year + ") " + selected?.birth_month + "月" + selected?.birth_day + "日";
    const karute_id = selected?.karute_id;
    const card_id = selected?.card_id;
    const status = selected?.status;
    var status_card_id = "";
    if (card_id === null || card_id === 0) {
      if (status == 1) {
        status_card_id = t('ishachoku-member-tab.label-search_status1') + t('ishachoku-member-tab.label-search_status3');
      } else if (status == 2) {
        status_card_id = t('ishachoku-member-tab.label-search_status2') + t('ishachoku-member-tab.label-search_status3');
      }
    } else {
      if (status == 1) {
        status_card_id = t('ishachoku-member-tab.label-search_status1') + t('ishachoku-member-tab.label-search_status4');
      } else if (status == 2) {
        status_card_id = t('ishachoku-member-tab.label-search_status2') + t('ishachoku-member-tab.label-search_status4');
      }
    }
    setSelectedOptionLabel(karute_id, selected?.user_name, genderLabel, birth, status_card_id);
    dispatch(changeSelectionRegistList(selected));
  };

  useEffect(() => {
    setSelectedOption(selectedOption);
    if (selectedOption !== null) {
      handleSearch(selectedOption);
    }
  }, [selectedOption]);

  return (
    <div className='search-box'>
      <AutoComplete
        className='select-search-user'
        placeholder={t('ishachoku-member-tab.placeholder1')}
        defaultActiveFirstOption={false}
        suffixIcon={<img src={magnifying_glass} alt='magnifying glass' />}
        notFoundContent={null}
        onSearch={handleSearch}
        onSelect={handleOptionSelect}
        onChange={setSelectedOption}
        value={selectedOption}
        options={(searchResults || []).map((d) => ({
          value: d.karute_id,
          label: <div>{optionLabel(d)}</div>,
        }))}
      ></AutoComplete>
    </div>
  );
};

function calculateAge(birthYear: number, birthMonth: number, birthDay: number) {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDay = today.getDate();

  let age = currentYear - birthYear;
  let month = currentMonth - birthMonth;
  let day = currentDay - birthDay;

  if (month < 0 || (month === 0 && day < 0)) {
    age--;
    month += 12;
  }

  if (day < 0) {
    const daysInPreviousMonth = new Date(
      currentYear,
      currentMonth - 1,
      0
    ).getDate();
    day += daysInPreviousMonth;
    month--;
  }

  const ageString = `${age}歳${month}月`;
  return ageString;
}

export default SearchBar;
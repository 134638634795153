import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
  dataRegister: {} as any,
  pref_name: "",
  city_name: ""
}

const slice = createSlice({
  name: "registerSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeDataRegister : (state, action) => {
        state.dataRegister = action.payload
    },
    changePrefName : (state, action) => {
      state.pref_name = action.payload
    },
    changeCityName : (state, action) => {
      state.city_name = action.payload
    },
  },
});

export const selectDataRegister = (state:RootState) => state.common.registerSlice.dataRegister;
export const selectPrefname = (state:RootState) => state.common.registerSlice.pref_name;
export const selectCityName = (state:RootState) => state.common.registerSlice.city_name;

export const {
  changeDataRegister
} = slice.actions;
export const {
  changePrefName
} = slice.actions;
export const {
  changeCityName
} = slice.actions;

export const RegisterReducer = slice.reducer;
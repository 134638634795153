import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '../Model/ApiResponse';
import { Clinic as api, BASE_URL } from '../Api';
import axiosBaseQuery from '../axiosBaseQuery';
import { CustomTreatmentMenuByDateResponse, TreatmentMenu } from '../Model/TreatmentMenu';
import { CustomClinicUserResponse } from '../Model/User';
import { CustomReservationFrameResponse } from '../Model/CustomReservationFrameResponse';
import {
  ReservationInfoDetailResponse,
  ReservationInfoRequest,
  ReservationInfoResponse,
} from '../Model/ReservationInfo';
import { ReservationFrameRepeat } from '../Model/ReservationFrameRepeat';
import {ClosedTime} from "../Model/ClosedTime";
import { DataTypeTable } from '../Model/WeekReservationFrame';

export const doctorApi = createApi({
  reducerPath: 'doctorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Doctor', 'ReservationInfo', 'ScheduleInfo'],
  endpoints: (builder) => ({
    reservationData: builder.query({
      query: (data) => ({
        url: api.reservationFrameData,
        method: 'GET',
        data: null,
        params: data,
      }),
      providesTags: ['ScheduleInfo'],
      transformResponse: (
        response: ApiResponse<CustomReservationFrameResponse[]>,
      ) => response,
    }),
    treatmentMenusByClinicId: builder.query({
      query: (data) => ({
        url: api.treatmentMenusByClinicId,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (
        response: ApiResponse<CustomTreatmentMenuByDateResponse[]>,
      ) => response,
    }),
    clinicUsersByClinicId: builder.query({
      query: (data) => ({
        url: api.clinicUsersByClinicId,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response: ApiResponse<CustomClinicUserResponse[]>) =>
        response,
    }),
    exmTimeByClinicId: builder.query({
      query: (data) => ({
        url: api.exmTimeByClinicId,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    reservationInfosByDateIdAndClinicId: builder.query({
      query: (data) => ({
        url: api.reservationInfosByDateIdAndClinicId,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response: ApiResponse<ReservationInfoResponse[]>) =>
        response,
    }),
    doctorLogin: builder.mutation({
      query: (params) => ({
        url: api.doctorLogin,
        method: 'POST',
        data: params,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    countPatientsCompletedExaminationToDay: builder.query({
      query: (params) => ({
        url: api.countPatientsCompletedExaminationToDay,
        method: 'GET',
        data: null,
        params: params,
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data,
    }),
    countPatientsBookingToDay: builder.query({
      query: (params) => ({
        url: api.countPatientsBookingToDay,
        method: 'GET',
        data: null,
        params: params,
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data,
    }),
    reservationInfoDetailById: builder.query({
      query: (data: ReservationInfoRequest) => ({
        url: api.getReservationInfoDetail(data.id),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (
        response: ApiResponse<ReservationInfoDetailResponse>,
      ) => response,
    }),
    updateReservationInfo: builder.mutation({
      query: (param: any) => ({
        url: api.reservationInfo,
        method: 'PUT',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    reservationInfoList: builder.query({
      query: (params) => ({
        url: api.reservationInfoList,
        method: 'GET',
        data: null,
        params: params,
      }),
      providesTags: ['ReservationInfo'],
      transformResponse: (response: ApiResponse<any>) => response?.data,
    }),
    searchIshachokuUser: builder.mutation({
      query: (param) => ({
        url: api.getSearchIshachokuUser(),
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data,
    }),
    findTreatmentMenu: builder.mutation({
      query: (param) => ({
        url: api.getFindTreatmentMenu,
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<[TreatmentMenu]>) => response?.data,
    }),
    reservationFrameRepeat: builder.query({
      query: (param) => ({
        url: api.reservationFrameRepeat,
        method: 'GET',
        data: null,
        params: param,
      }),
      providesTags: ['Doctor'],
      transformResponse: (response: ApiResponse<[ReservationFrameRepeat]>) => response?.data,
    }),
    addCloseTime: builder.mutation({
      query: (param: any) => ({
        url: api.closedTime,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    reservationFrameRepeatCreate: builder.mutation({
      query: (param) => ({
        url: api.reservationFrameRepeat,
        method: 'POST',
        data: {params: param},
        params: null,
      }),
      invalidatesTags: ['Doctor'],
      transformResponse: (response: ApiResponse<ReservationFrameRepeat>) => response,
    }),
    reservationFrameRepeatUpdate: builder.mutation({
      query: (param) => ({
        url: api.reservationFrameRepeat,
        method: 'PUT',
        data: {params: param},
        params: null,
      }),
      invalidatesTags: ['Doctor'],
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    updateClosedTime: builder.mutation({
      query: (param: any) => ({
        url: api.closedTime,
        method: 'PUT',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    deleteClosedTime: builder.mutation({
      query: (param: any) => ({
        url: api.closedTime,
        method: 'DELETE',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    getClosedTimeByDateAndClinic: builder.query({
      query: (param) => ({
        url: api.getClosedTimeByDateAndClinic,
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<[ClosedTime]>) => response?.data,
    }),
    reservationFrameRepeatDelete: builder.mutation({
      query: (param: number) => ({
        url: api.reservationFrameRepeatDelete(param),
        method: 'DELETE',
        data: param,
      }),
      invalidatesTags: ['Doctor'],
      transformResponse: (response: ApiResponse<ReservationFrameRepeat>) => response,
    }),
    getWeekReservationFrame: builder.query({
      query: (param) => ({
        url: api.weekReservationFrame,
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<DataTypeTable>) => response?.data,
    }),
    doctorInfo: builder.query({
      query: () =>({
        url: api.doctor,
        method: 'GET',
        data: null,
        params: null
      })
    }),
    cancelReservationInfo: builder.mutation({
      query: (param: any) => ({
        url: api.reservationInfo,
        method: 'PUT',
        data: param,
        params: null,
      }),
      invalidatesTags: ['ReservationInfo'],
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    reservationInfoListByDateTime: builder.query({
      query: (params) => ({
        url: api.getRsvInfoByDatetime,
        method: 'GET',
        data: null,
        params: params,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    countOnClosedTime: builder.query({
      query: (params) => ({
        url: api.getCountOnClosedTime,
        method: 'GET',
        data: null,
        params: params,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    registReservationFrame: builder.mutation({
      query: (params: any) => ({
        url: api.reservationInfo,
        method: 'POST',
        data: params,
        params: null,
      }),
      invalidatesTags: ['ScheduleInfo'],
      transformResponse: (response: ApiResponse<any>) => response,
    }),
  }),
});

export const {
  useLazyReservationDataQuery,
  useLazyClinicUsersByClinicIdQuery,
  useLazyExmTimeByClinicIdQuery,
  useLazyTreatmentMenusByClinicIdQuery,
  useDoctorLoginMutation,
  useLazyReservationInfosByDateIdAndClinicIdQuery,
  useCountPatientsCompletedExaminationToDayQuery,
  useCountPatientsBookingToDayQuery,
  useLazyReservationInfoDetailByIdQuery,
  useUpdateReservationInfoMutation,
  useReservationInfoListQuery,
  useSearchIshachokuUserMutation,
  useFindTreatmentMenuMutation,
  useAddCloseTimeMutation,
  useReservationFrameRepeatQuery,
  useReservationFrameRepeatCreateMutation,
  useReservationFrameRepeatUpdateMutation,
  useUpdateClosedTimeMutation,
  useDeleteClosedTimeMutation,
  useLazyGetClosedTimeByDateAndClinicQuery,
  useReservationFrameRepeatDeleteMutation,
  useGetWeekReservationFrameQuery,
  useLazyDoctorInfoQuery,
  useCancelReservationInfoMutation,
  useLazyReservationInfoListByDateTimeQuery,
  useLazyCountOnClosedTimeQuery,
  useRegistReservationFrameMutation,
} = doctorApi;

export default doctorApi;

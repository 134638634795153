import './styles.css';
import { Button, Modal, Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Warning from 'src/assets/image/warning-rectangle.svg';
import Negative from 'src/assets/image/negative-circle.svg';
import Confirm from 'src/assets/image/confirm-circle.svg';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { MODAL_WARNING, MODAL_NEGATIVE, MODAL_CONFIRM, BUTTON_CLOSE, BUTTON_ACCEPT, BUTTON_CHOOSE, BUTTON_WARNING, MODAL_SUCCESS, MODAL_FAIL, BUTTON_CONFIRM, BUTTON_WARNING_DUPLICATE } from 'src/constant';
import { replaceWithBr } from 'src/Utils/textUtils';

type Props = {
    message: string,
    typeModal?: number,
    typeButton?: number,
    changeVisibleModal: Function,
    title?: string,
    handleWarning?: Function,
    changeConfirm?: Function
    changeCancel?: Function
}

const APP:React.FC<Props> = (props) => {

    const { t } = useTranslation();
    const [typeModal]  = useState(props.typeModal);
    const [typeButton]  = useState(props.typeButton);

    const handleClose = () => {
      props.changeVisibleModal();
    };
    const handleConfirm = () => {
      if (props.changeConfirm) {
        props.changeConfirm();
      } else {
        props.changeVisibleModal();
      }
    };
    const handleCancel = () => {
      if (props.changeCancel) {
        props.changeCancel();
      } else {
        props.changeVisibleModal();
      }
    };
    const handleWarning = () => {
      if (props.handleWarning) {
        props.handleWarning();
      }
    }
    const handleWarningDuplicate = () => {
      if (props.handleWarning) {
        props.handleWarning();
      }
    }

    const renderTitle = () => {
      if(props.title){
        return props.title;
      }
      if(typeModal === MODAL_WARNING) {
        return t("message.MESS_NO_HSP_CLC_FOUND.title");
      }
      if(typeModal === MODAL_NEGATIVE) {
        return t("message.MESS_NO_HSP_CLC_FOUND.title");
      }
      if(typeModal === MODAL_CONFIRM) {
        return t("message.MESS_NO_HSP_CLC_FOUND.title");
      }
      if (typeModal === MODAL_SUCCESS) {
        return t("message.TITLE_SUCCESS");
      }
      if (typeModal === MODAL_FAIL) {
        return t("message.TITLE_FAIL");
      }
    }

    const renderImage = () => {
      if(typeModal === MODAL_WARNING || typeModal === MODAL_FAIL) {
        return (
          <img src={Warning} alt="" />
        )
      }
      if(typeModal === MODAL_NEGATIVE) {
        return (
          <img src={Negative} alt="" />
        )
      }
      if(typeModal === MODAL_CONFIRM || typeModal === MODAL_SUCCESS) {
        return (
          <img src={Confirm} alt="" />
        )
      }
    }

    const renderButton = () => {
      if(typeButton === BUTTON_CLOSE) {
        return (
          <Button onClick={handleClose} className='buttonWarningClose text-bold-styles'>
            {t("button-close.text")}
          </Button>
        )
      }
      if(typeButton === BUTTON_ACCEPT) {
        return (
          <Button onClick={handleClose} className='buttonWarningAccept text-bold-styles'>
            {t("button-ok.text")}
          </Button>
        )
      }
      if(typeButton === BUTTON_CHOOSE) {
        return (
          <Row gutter={[{ xs: 20, sm: 20 }, { xs: 20, sm: 20 }]}>
            <Col xs={12} sm={12}>
              <Button onClick={handleConfirm} className='buttonPt10-left text-bold-styles'>
                {t("button-ok.text")}
              </Button>
            </Col>
            <Col xs={12} sm={12}>
              <Button type='text' onClick={handleClose} className='buttonPt10-right text-bold-styles'>
                {t("button-cancel.text")}
              </Button>
            </Col>
          </Row>
        )
      }
      if(typeButton === BUTTON_CONFIRM) {
        return (
          <Row gutter={[{ xs: 20, sm: 20 }, { xs: 20, sm: 20 }]}>
            <Col xs={12} sm={12}>
              <Button onClick={handleConfirm} className='buttonPt10-left text-bold-styles'>
                {t("button-ok.text")}
              </Button>
            </Col>
            <Col xs={12} sm={12}>
              <Button type='text' onClick={handleCancel} className='buttonPt10-right text-bold-styles'>
                {t("button-cancel.text")}
              </Button>
            </Col>
          </Row>
        )
      }
      if(typeButton === BUTTON_WARNING) {
        return (
          <Button onClick={handleWarning} className='buttonWarningAccept text-bold-styles'>
            {t("button-ok.text")}
          </Button>
        )
      }
      if(typeButton === BUTTON_WARNING_DUPLICATE) {
        return (
          <Button onClick={handleWarningDuplicate} className='buttonWarningAccept text-bold-styles'>
            {t("button-ok.text")}
          </Button>
        )
      }
    }

    return (
      <Modal maskClosable={false} title closeIcon={null} centered open={true} footer={null} style={{padding: "20px 0"}}>
        <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
          <Col span={24}>
            <div className='headerModal'>
              <span className='headerModal-title text-bold-styles'>{renderTitle()}</span>
              <span>
                <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
              </span>
            </div>
          </Col>
          <Col span={24} className='bodyModal text-normal-styles' style={{ paddingTop: 24, paddingBottom: 24, textAlign: 'center' }}>
              <Row gutter={[{ xs: 16, sm: 16 }, { xs: 16, sm: 16 }]}>
                  <Col xs={24} sm={24}>
                    {renderImage()}
                  </Col>
                  <Col xs={24} sm={24}>
                    {
                      <span dangerouslySetInnerHTML={replaceWithBr(props.message)}></span>
                    }
                  </Col>
              </Row>
          </Col>
          <Col span={24} className='footerModal' style={{ textAlign: 'center' }}>
            {renderButton()}
          </Col>
        </Row>
      </Modal>
    )
}

export default APP;
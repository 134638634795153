import {Button, Input, Modal, Select} from "antd";
import {useTranslation} from "react-i18next";
import './styles.css';
import React, {useEffect, useState} from "react";
import visa from "../../../assets/image/visaCard.png";
import masterCard from "../../../assets/image/masterCard.png";
import jcbCard from "../../../assets/image/jcbCard.png";
import americanCard from "../../../assets/image/americanExpressCard.png";
import dinerCard from "../../../assets/image/dinersClubCard.png";
import infoIcon from "../../../assets/image/info2.svg";
import ConfirmEditCard from '../ConfirmEditCard'
import {CardData} from "../../../Network/Model/Card";

const APP = ({
							 isOpen,
							 onClose,
							 onEditFinish,
							 currentCard
						 }: {
	isOpen: boolean,
	onClose: Function,
	onEditFinish: (event: CustomEvent) => void,
	currentCard: CardData | undefined
}) => {
	const handleClose = () => {
		onClose();
	};
	const [cardNumbers, setCardNumbers] = useState('');
	const [limitMonth, setLimitMonth] = useState('');
	const [limitYear, setLimitYear] = useState('');
	const [cvvNumber, setCvvNumber] = useState('');
	const [enableNext, setEnableNext] = useState(true);
	const MONTH_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const YEAR_ARRAY = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
	const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
	const onlyNumberDigit = (str: string): string => {
		const numberStr = '0123456789';
		let result = '';
		str.split('').map((c) => {
			if (numberStr.includes(c)) {
				result += c;
			}
		})
		return result;
	}
	const {t} = useTranslation();
	useEffect(() => {
			checkEnableNext();
		}
		, [cardNumbers, limitYear, limitMonth, cvvNumber])
	const checkEnableNext = () => {
		const validCardNumber = cardNumbers.length >= 1 && cardNumbers.length <= 19;
		const validMonth = limitMonth !== '';
		const validYear = limitYear !== '';
		const validCvv = cvvNumber.length >= 1 && cvvNumber.length <= 4;
		setEnableNext(validCardNumber && validMonth && validYear && validCvv);
	}
	return <Modal
		className={'editCard'}
		centered
		open={isOpen}
		title={t("card_payment.register-title")}
		closeIcon={true}
		footer={<div style={{display: "flex", justifyContent: "center"}}><Button disabled={!enableNext} type={"text"}
																																						 className={'confirmBtn'}
																																						 onClick={() => setIsOpenConfirmModal(true)}>{t("card_payment.confirm-btn")}</Button>
		</div>} onCancel={handleClose}>
		<div className={'cardList'}>
			<img src={visa} alt={''}/>
			<img src={masterCard} alt={''}/>
			<img src={jcbCard} alt={''}/>
			<img src={americanCard} alt={''}/>
			<img src={dinerCard} alt={''}/>
		</div>
		<table className={"infoTable"}>
			<tr>
				<td>{t('card_payment.registered-card-number')}</td>
				<td>{currentCard?.cardNumber}</td>
			</tr>
			<tr>
				<td>{t('card_payment.limit-date')}</td>
				<td>{currentCard?.cardExpire}</td>
			</tr>
		</table>

		<table style={{width: "100%"}} className={"formTable"}>
			<tr>
				<td colSpan={2}>{t("card_payment.card-numbers")}</td>
			</tr>
			<tr>
				<td colSpan={2}><Input value={cardNumbers} style={{width: "100%"}} maxLength={19}
															 onChange={(e) => {
																 setCardNumbers(onlyNumberDigit(e.target.value))
															 }}/></td>
			</tr>
			<tr>
				<td>
					{t("card_payment.limit-date")}
				</td>
				<td>
					{t("card_payment.cvv-numbers")}
				</td>
			</tr>
			<tr>
				<td style={{width: "50%", verticalAlign: "center"}}>
					<div style={{display: "flex"}}>
						<Select options={MONTH_ARRAY.map((m) => ({
							value: m.toString(),
							label: m.toString()
						}))}
										onChange={(e) => {
											setLimitMonth(e)
										}} placeholder={t("card_payment.month")}/>
						<div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
							<span style={{margin: "5px", height: "fit-content"}}>/</span></div>
						<Select options={YEAR_ARRAY.map((m) => ({
							value: m.toString(),
							label: m.toString()
						}))}
										onChange={(e) => {
											setLimitYear(e)
										}} placeholder={t("card_payment.year")}/>
					</div>
				</td>
				<td>
					<Input value={cvvNumber} maxLength={4} style={{width: "100%"}}
								 onChange={(e) => {
									 setCvvNumber(onlyNumberDigit(e.target.value))
								 }}/>
				</td>
			</tr>
		</table>
		<p style={{marginTop: 24}}>
			<img src={infoIcon} alt={''}/><span className={'info1'}>{t('card_payment.info1')}</span>
			<br/>
			<span className={'info2'}>{t('card_payment.info2')}</span>
		</p>
		{isOpenConfirmModal &&
    <ConfirmEditCard currentCard={currentCard} isOpen={isOpenConfirmModal}
                     onClose={() => setIsOpenConfirmModal(false)}
                     onEditFinish={() => {
											 setIsOpenConfirmModal(false);
											 onEditFinish(new CustomEvent(''))
										 }}
                     newCardData={{
											 cardNumbers: cardNumbers,
											 limitMonth: limitMonth,
											 limitYear: limitYear,
											 cvvNumbers: cvvNumber
										 }}
    />}
	</Modal>
}

export default APP;
import { FieldErrors, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'src/Component/common/form/FormInput';
import {
  FormData,
  examMethod,
  publicStatus,
  rsvFrameStatus,
  treatmentCategoryOptions
} from '../constants';
import RadioGroupForm from './formItem/RadioGroupForm';

type Props = {
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
};

const CommonForm: React.FC<Props> = ({ errors, watch }) => {
  const { t } = useTranslation();
  const public_status = watch('public_status');
  const treatment_menu_name = watch('treatment_menu_name');

  return (
    <div className="medical-menu-form_common-container">
      <div className="medical-menu-form_medical-menu-name-container">
        <p className="medical-menu-form_medical-menu-name_title">
          {t('reExamCode.medicalMenuName')}
        </p>
        <FormInput
          className="medical-menu-form_input medical-menu-form_medical-menu-name"
          placeholder={t('reExamCode.medicalMenuName')}
          name="treatment_menu_name"
        />
        <p
          style={{ marginTop: errors.treatment_menu_name ? 0 : -20 }}
          className="medical-menu-form_count-letter"
        >
          {t('reExamCode.medicalMenuCountInput', {
            number: treatment_menu_name?.length || 0,
            maxNumber: 30
          })}
        </p>
      </div>
      <div className="medical-menu-form_common-part-container">
        <div className="medical-menu-form_common-left-container">
          <RadioGroupForm
            inputTitle={t('reExamCode.treatmentCategory')}
            name="treatment_div"
            options={treatmentCategoryOptions}
          />

          <RadioGroupForm
            inputTitle={t('reExamCode.publicMedicalMenuStatus')}
            name="public_status"
            options={publicStatus}
          />
        </div>
        <div className="medical-menu-form_common-right-container">
          <RadioGroupForm
            inputTitle={t('reExamCode.examMethod')}
            name="exm_method"
            options={examMethod}
          />

          <RadioGroupForm
            inputTitle={t('reExamCode.rsvFrameStatus')}
            name="reservation_frame_public"
            options={rsvFrameStatus}
          />
        </div>
      </div>
      {public_status === 0 && (
        <p className="not-public-medical-menu_note">
          {t('reExamCode.privateNoteForm')}
        </p>
      )}
    </div>
  );
};

export default CommonForm;

import React, { useEffect, useState } from "react";
import './styles.css';
import { Button, Col, Row, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs, {Dayjs} from 'dayjs';
import Clock from 'src/assets/image/clock.svg';
import { HALF_AN_HOURS, HOURS_IN_DAY, MODAL_CONFIRM, QUARTER_AN_HOURS, formatYYYYMMDD, BUTTON_CHOOSE, placeholderyyyymmdd, DAY_EXPIRED, EXM_TIME } from "src/constant";
import { CustomClinicUserResponse } from "src/Network/Model/User";
import { useLazyClinicUsersByClinicIdQuery } from "src/Network/Home/DoctorApi";
import DoctorConfirmReservation from "src/Component/Modal/DoctorConfirmReservation";
import ModalWarning from 'src/Component/Modal/ModalWarning';
import FormInputSelect from 'src/Component/common/form/FormInputSelect';
import FormInputCheckbox from 'src/Component/common/form/FormInputCheckbox';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxValueType } from "antd/es/checkbox/Group";
import FormInputNumber from "src/Component/common/form/FormInputNumber";
import FormInputCalendarDays from "src/Component/common/form/FormInputCalendarDays";
import { ReservationFrameRepeat } from "src/Network/Model/ReservationFrameRepeat";
import { TreatmentMenu } from "src/Network/Model/TreatmentMenu";
import { formatDisplayTime, isFullWidth } from "src/Utils/textUtils";
import { getClinicIdFromLocalStorage } from "src/common/Libs";
import DoctorEditReservation from "src/Component/Modal/DoctorEditReservation";
import { TreatMentMenuItem } from "src/Network/Model/TreatMentMenuItem";
import { ReservationFrame } from "src/Network/Model/ReservationFrame";
import { ClinicWithPivot } from "src/Network/Model/ClinicsWithPivot";

type Props = {
    exmTime: number,
    onClose: Function,
    isDt0402Screen: boolean,
    treatMentMenuList: TreatmentMenu[]
    reservationFrameRepeat: ReservationFrameRepeat | undefined,
    setVisibleSuccess: Function,
    setVisibleDeleteSuccess: Function,
    isFrameRepeatDetail?: number,
    // userIdList?: Array<number>,
    // setUserIdList?: Function,
    selectedDate: string
};

const SideBarReservation: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [clinicId] = useState(getClinicIdFromLocalStorage());
    const [doctors, setDoctor] = useState<CustomClinicUserResponse[]>([]);
    const [getClinicUserArr] = useLazyClinicUsersByClinicIdQuery();
    const [optionTime, setOptionTime] = useState([]);
    const [repeatTypeInValid, setRepeatTypeInValid] = useState(true);
    const [checkedPeriod, setCheckedPeriod] = useState(false);
    const [checkedFormInput2, setCheckedFormInput2] = useState(false);
    const [errorTime, setErrorTime] = useState(false);
    const [errorYmd, setErrorYmd] = useState(false);
    const [showModalProcess, setShowModalProcess] = useState(false);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [checkboxValueTreatMent, setCheckboxValueTreatMent] = useState<any>({});
    // const [checkboxValueUserId, setCheckboxValueUserId] = useState<Array<any>>([]);
    const {treatMentMenuList, isFrameRepeatDetail} = props;
    const [dataReservationFrameRepeat, setDataReservationFrameRepeat] = useState<ReservationFrameRepeat>({});
    const [examOld, setExamOld] = useState<TreatMentMenuItem[]>([]);
    const [userIdList, setUserIdList] = useState<Array<CheckboxValueType>>([]);
    const [visibleNotice, setVisibleNotice] = useState(false);
    const repeatTypeOptions = [
        {
            value: 0,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-type.type-0")
        },
        {
            value: 1,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-type.type-1")
        },
        {
            value: 2,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-type.type-2")
        }
    ];

    const repeatOnDayOfWeekOptions = [
        {
            value: 1,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-1")
        },
        {
            value: 2,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-2")
        },
        {
            value: 3,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-3")
        },
        {
            value: 4,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-4")
        },
        {
            value: 5,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-5")
        },
        {
            value: 6,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-6")
        },
        {
            value: 7,
            label: t("schedule.reservation-setting.treatmentmenu.repeat-on-day-of-week.day-7")
        }
    ]

    const handleCalculateRepeatOnDayOfWeek = () => {
        return dayjs(props.selectedDate).day() + 1;
    }

    const defaultValues = {
        rsv_start_time: '0000',
        rsv_end_time: '0000',
        repeat_type: 0,
        repeat_on_day_of_week: handleCalculateRepeatOnDayOfWeek(),
        is_public_on_holiday: false,
        rsv_start_ymd: dayjs(props.selectedDate).format(formatYYYYMMDD),
        rsv_end_ymd: dayjs(props.selectedDate).format(formatYYYYMMDD),
        period: false,
        upper_limit_frame: undefined,
    };

    type FormData = yup.InferType<typeof schema>;

    const schema = yup.object({
        rsv_start_time: yup
          .string()
          .required(
            t('register.input-required').replace(
              '{0}',
              t('schedule.sidebar-reservation.start_time'),
            ),
          ),
        rsv_end_time: yup
          .string()
          .required(
            t('register.input-required').replace(
              '{0}',
              t('schedule.sidebar-reservation.end_time'),
            ),
          ),
        repeat_type: yup
          .number()
          .required(
            t('register.input-required').replace(
              '{0}',
              t('settingPassword.fieldInput.re-newpassword-placeholder'),
            ),
          ),
        rsv_start_ymd: yup
          .string()
          .required(
            t('register.input-required').replace(
              '{0}',
              t('settingPassword.fieldInput.re-newpassword-placeholder'),
            ),
          ),
        rsv_end_ymd: yup.mixed(),
        repeat_on_day_of_week: yup.number(),
        is_public_on_holiday: yup.boolean(),
        period: yup.boolean(),
        upper_limit_frame: yup
            .string()
            .required(
                t('register.input-required').replace(
                    '{0}',
                    t('admin-schedule.reservation-frame-detail.upper-limit-frame'),
                ),
            ),
        exam: yup.array().of(
            yup.object().shape({
              id: yup.number(),
              value: yup.string(),
              treatment_menu_name: yup.string(),
            })
          )
            .required(t('register.select-required').replace('{0}', t('schedule.sidebar-reservation.checkbox_exam')))
            .test({
                message: t('register.select-required').replace('{0}', t('schedule.sidebar-reservation.checkbox_exam')),
                test: arr => arr?.length > 0
            }),
        clinicUser: yup.array()
            .required(t('register.select-required').replace('{0}', t('schedule.sidebar-reservation.doctor.title')))
            .test({
                message: t('register.select-required').replace('{0}', t('schedule.sidebar-reservation.doctor.title')),
                test: arr => arr?.length > 0
            })
    });
    const methods = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(schema),
        mode: 'all',
    });

    const exam = methods.watch('exam');
    
    const param = { clinic_id: clinicId };

    useEffect(() => {
        getClinicUserArr(param).then((res: any) => {
            if (res?.data) {
                setDoctor(res.data.data || []);
            }
        });
    }, []);
    useEffect(() => {
        methods.setValue('rsv_start_time', props.reservationFrameRepeat?.rsv_start_time ? props.reservationFrameRepeat?.rsv_start_time : '0000');
        methods.setValue('rsv_end_time', props.reservationFrameRepeat?.rsv_end_time ? props.reservationFrameRepeat?.rsv_end_time : '0000');
        const repeatType = props.reservationFrameRepeat?.repeat_type ? props.reservationFrameRepeat?.repeat_type : 0;
        methods.setValue('repeat_type', repeatType);
        methods.setValue('repeat_on_day_of_week', props.reservationFrameRepeat?.repeat_on_day_of_week ? props.reservationFrameRepeat?.repeat_on_day_of_week : handleCalculateRepeatOnDayOfWeek());
        methods.setValue('rsv_start_ymd', props.reservationFrameRepeat?.rsv_start_ymd ? props.reservationFrameRepeat?.rsv_start_ymd : props.selectedDate);
        methods.setValue('rsv_end_ymd', props.reservationFrameRepeat?.rsv_end_ymd ? props.reservationFrameRepeat?.rsv_end_ymd : props.selectedDate);
        methods.setValue('upper_limit_frame', props.reservationFrameRepeat?.upper_limit_frame ? props.reservationFrameRepeat?.upper_limit_frame.toString() : '');
        methods.setValue('period', props.reservationFrameRepeat?.rsv_end_ymd === DAY_EXPIRED);
        const isPublicOnHoliday = props.reservationFrameRepeat?.is_public_on_holiday === 1;
        handleRepeatTypeEdit(repeatType, isPublicOnHoliday);
        methods.setValue('is_public_on_holiday', isPublicOnHoliday);
        const arrValue = [];
        if (props.reservationFrameRepeat?.face_to_face_first_exm === 1) {
            arrValue.push('1')
        }
        if (props.reservationFrameRepeat?.online_first_exm === 1) {
            arrValue.push('2')
        }
        if (props.reservationFrameRepeat?.face_to_face_re_exm === 1) {
            arrValue.push('3')
        }
        if (props.reservationFrameRepeat?.online_re_exm === 1) {
            arrValue.push('4')
        }
        const id = Number(props.reservationFrameRepeat?.treatment_menu?.id);
        setCheckboxValueTreatMent({[id]: arrValue});
        const treatmentMenu = {[id]: arrValue}
        const examArray = [] as TreatMentMenuItem[]
        Object.keys(treatmentMenu).forEach((key: any) => {
            treatmentMenu[key].forEach((item: string | number) => {
                examArray.push({id: Number(key), value: item.toString(), treatment_menu_name: props.reservationFrameRepeat?.treatment_menu?.treatment_menu_name??''})
            }) 
        })
        methods.setValue('exam', examArray);
        setExamOld(examArray);
        const frames = props.reservationFrameRepeat?.frames;
        const clinicUsers = frames?.map((item: ReservationFrame) => {
            const user = item?.users?.map((i: ClinicWithPivot) => {
                return i?.pivot?.clinic_user_id;
            });
            return user;
        });
        const arrayUser = [] as Array<number>;
        clinicUsers?.forEach((i:any) => {
            if (i) {
                arrayUser.push(...i);
            }
        });
        const newArrayUser = [...new Set(arrayUser)];
        setUserIdList(newArrayUser);
        }, [props.reservationFrameRepeat]
    );
    useEffect(() => {
        if (userIdList && userIdList?.length > 0) {
            methods.setValue('clinicUser', userIdList);
            methods.clearErrors('clinicUser');
        }
    }, [userIdList]);
    useEffect(() => {
        if (exam?.length !== 0) {
            methods.clearErrors('exam');
        }
    }, [exam]);

    useEffect(() => {
        if (props.reservationFrameRepeat?.upper_limit_frame) {
            methods.clearErrors('upper_limit_frame');
        }
    }, [props.reservationFrameRepeat?.upper_limit_frame]);

    useEffect(() => {
        setOptionTime(generateTimeOptions(props.exmTime));
    }, [props.exmTime]);

    useEffect(() => {
        if (isFrameRepeatDetail !== 0) {
            setCheckboxValueTreatMent({});
            methods.setValue('clinicUser', []);
            methods.setValue('exam', []);
            methods.setValue('upper_limit_frame', '');
            methods.clearErrors('clinicUser');
            methods.clearErrors('exam');
            methods.clearErrors('upper_limit_frame');
        }
    }, [isFrameRepeatDetail]);

    const generateTimeOptions = (exmTime: number) => {
        const results:any = [];
      
        let minuteArr: string[];
        if (exmTime == EXM_TIME.HALF_AN_HOURS) {
            minuteArr = HALF_AN_HOURS;
        } else {
            minuteArr = QUARTER_AN_HOURS;
        }
      
        for (const hour of HOURS_IN_DAY) {
          minuteArr.forEach((minus) => {
            const data =    hour + minus;
            results.push({
                value: data,
                label: formatDisplayTime(data)
            });
          })
        }
      
        return results;
    }

    const handleClose = () => {
        props.onClose();
    }

    const handleRsvStartTime = () => { 
        if (methods.getValues('rsv_end_time'))
            handleValidateTime();
    }

    const handleRsvEndTime = () => { 
        if (methods.getValues('rsv_start_time'))
            handleValidateTime();
    }

    const handleValidateTime = () => {
        const hourStart = Number(methods.getValues('rsv_start_time').substring(0, 2));
        const minuteStart = Number(methods.getValues('rsv_start_time').substring(2, 4));
        const hourEnd = Number(methods.getValues('rsv_end_time').substring(0, 2));
        const minuteEnd = Number(methods.getValues('rsv_end_time').substring(2, 4));

        if (hourStart < hourEnd)
            setErrorTime(false);
        else if (hourStart == hourEnd && minuteStart < minuteEnd)
            setErrorTime(false);
        else
            setErrorTime(true);
    }

    const handleStartYmd = () => { 
        if (methods.getValues('rsv_end_ymd') && methods.getValues('repeat_type') !== 0)
            handleValidateYmd();
    }

    const handleEndYmd = () => { 
        if (methods.getValues('rsv_start_ymd'))
            handleValidateYmd();
    }

    const handleValidateYmd = () => {
        if (dayjs(methods.getValues('rsv_start_ymd')).isAfter(dayjs(methods.getValues('rsv_end_ymd'))))
            setErrorYmd(true);
        else
            setErrorYmd(false);
    }

    const handleKeyDown = (e: any) => {
        const regex = /^[0-9]+$/;
        if (e.key === 'Backspace') return;
        if (e.key === 'Delete') return;
        if (e.key === 'Tab') return;
        if (e.key === 'ArrowLeft') return;
        if (e.key === 'ArrowRight') return;
        if (!regex.test(e.key)) {
          e.preventDefault();
        }
    };

    const handleRepeatTypeEdit = (repeatType: number, isPublicOnHoliday: boolean) => { 
        setRepeatTypeInValid(!(repeatType === 1 || repeatType === 2));
        setCheckedFormInput2(isPublicOnHoliday);
        setVisibleNotice(repeatType === 1 || repeatType === 2);
    }

    const handleRepeatType = (element: number) => { 
        setRepeatTypeInValid(!(element === 1 || element === 2));
        if(element === 1 || element === 2) {
            methods.setValue('is_public_on_holiday', false);
            setCheckedFormInput2(false);
            setCheckedPeriod(true);
            methods.setValue('rsv_end_ymd', dayjs(DAY_EXPIRED));
            methods.setValue('period', true);
        } else {
            methods.setValue('is_public_on_holiday', true);
            setCheckedFormInput2(true);
            if (!props.isDt0402Screen) {
                methods.setValue('rsv_end_ymd', dayjs(props.selectedDate).format(formatYYYYMMDD));
            }
            methods.setValue('period', false);
            setErrorYmd(false);
        }
    }

    const handlePeriod = (element:boolean) => {
        setCheckedPeriod(element);
        if (element) {
            methods.setValue('rsv_end_ymd', dayjs(DAY_EXPIRED));
        }
    }
    const onChangeCbExam = (checkedValues: CheckboxValueType[], index: number, name: string|undefined) => {
        const remainExam = exam ? exam.filter(i => i.id !== index) : [];
        setCheckboxValueTreatMent({...checkboxValueTreatMent, [index]: checkedValues});
        const newExam = checkedValues.map((i => {
            return { id: index, value: i.toString(), treatment_menu_name: name};
        }))
        methods.setValue('exam', [...remainExam, ...newExam]);
        if ([...remainExam, ...newExam].length > 0) {
            methods.clearErrors('exam');
        } else {
            methods.setError('exam',  { type: 'custom', message: t('register.select-required').replace('{0}', t('schedule.sidebar-reservation.checkbox_exam'))});
        }
    };
    const onChangeUserId = (checkedValues: CheckboxValueType[]) => {
        methods.setValue('clinicUser', checkedValues);
        if (setUserIdList) {
            setUserIdList(checkedValues);
        }
        // setCheckboxValueUserId(checkedValues);
        if (checkedValues.length > 0) {
            methods.clearErrors('clinicUser');
        } else {
            methods.setError('clinicUser',  { type: 'custom', message: t('register.select-required').replace('{0}', t('schedule.sidebar-reservation.checkbox_user'))});
        }
    };
    const onSubmit = (data: any) => {
        if (errorTime || errorYmd) return;
        props.isDt0402Screen ? setShowModalEdit(true) : setShowModalProcess(true);
        const dto = {
            id: props.reservationFrameRepeat?.id,
            ...data,
            clinic_id: clinicId
        };
        setDataReservationFrameRepeat(dto);
    }
    const handleChangeMaxNumber = (maxNumber: string) => {
        if (Number(maxNumber) < 1 || isFullWidth(maxNumber)) {
            methods.setValue('upper_limit_frame', '1');
        } else if (Number(maxNumber) > 999) {
            methods.setValue('upper_limit_frame', '999');
        }
    }
    const handleNewReservation = () => {
        setCheckboxValueTreatMent({});
        methods.setValue('clinicUser', []);
        methods.setValue('upper_limit_frame', '');
        methods.setValue('exam', []);
        methods.clearErrors();
    }

    return (
        <div>
            <Row className="editReservation text-normal-styles">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Col span={24} className="headerElement">
                            <span className='headerModal-title text-bold-styles'>
                                {
                                    props.isDt0402Screen ? 
                                    t("schedule.sidebar-reservation.title-edit") : t("schedule.sidebar-reservation.title-new")
                                }
                            </span>
                            <span>
                                <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
                            </span>
                        </Col>
                        <Col span={24} className="bodyElement">
                            <Row gutter={[{}, { xs: 24, sm: 24, md: 24 }]}>
                                {
                                    props.isDt0402Screen &&
                                    <Col xs={24} sm={24} md={24} lg={24} className="examMethod text-bold-styles">
                                        {props.reservationFrameRepeat?.treatment_menu?.treatment_menu_name}
                                    </Col>
                                }
                                <Col xs={24} sm={24} md={24} lg={24} className='fieldInput'>
                                    <div className="inputContent">
                                        <Col xs={11} sm={11} md={11} lg={11}>
                                            <FormInputSelect
                                                options={optionTime}
                                                suffixIcon={<img src={Clock} />} 
                                                placeholder={t("schedule.sidebar-reservation.treatment-menu.time-placeholder")}
                                                inputProps = {{
                                                    onChange: () => handleRsvStartTime()
                                                }}
                                                name="rsv_start_time"
                                            />
                                        </Col>
                                        <Col xs={1} sm={1} md={1} lg={1} className="fromto">
                                            ～
                                        </Col>
                                        <Col xs={11} sm={11} md={11} lg={11}>
                                            <FormInputSelect 
                                                options={optionTime}
                                                placeholder={t("schedule.sidebar-reservation.treatment-menu.time-placeholder")}
                                                suffixIcon={<img src={Clock} />} 
                                                inputProps = {{
                                                    onChange: () => handleRsvEndTime()
                                                }}
                                                name="rsv_end_time"
                                            />
                                        </Col>
                                    </div>
                                    {
                                        errorTime &&
                                        <div className="errcode">{t("message.MESS_ERR_END_TIME_LESS_THAN_START_TIME.text")}</div>
                                    }
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} className='fieldInput'>
                                    <Row gutter={[{xs: 24, sm: 24, md: 24}, { }]}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <FormInputSelect 
                                                options={repeatTypeOptions}
                                                inputProps = {{
                                                    onChange: (e:number) => handleRepeatType(e)
                                                }}
                                                name="repeat_type"
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <FormInputSelect
                                                options={repeatOnDayOfWeekOptions}
                                                disabled={repeatTypeInValid}
                                                name="repeat_on_day_of_week"
                                            />
                                        </Col>
                                    </Row>
                                    <FormInputCheckbox 
                                        disabled={repeatTypeInValid}
                                        className="checkboxElement"
                                        name="is_public_on_holiday"
                                        inputProps={{
                                            onChange: (e: any) => setCheckedFormInput2(e.target.checked),
                                            checked: checkedFormInput2,
                                          }}
                                    >
                                        {t("schedule.sidebar-reservation.treatment-menu.public-holiday")}
                                    </FormInputCheckbox>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} className='fieldInput'>
                                    <div className="inputContent">
                                        <Col xs={11} sm={11} md={11} lg={11}>
                                            <FormInputCalendarDays
                                                name="rsv_start_ymd"
                                                placeholder={placeholderyyyymmdd}
                                                format={formatYYYYMMDD}
                                                inputProps = {{
                                                    onChange: (e: Dayjs) => 
                                                    {
                                                        methods.setValue('rsv_start_ymd', (e.isAfter(dayjs(DAY_EXPIRED)) ? DAY_EXPIRED : e.format(formatYYYYMMDD)));
                                                        handleStartYmd();
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col xs={1} sm={1} md={1} lg={1} className="fromto">
                                            ～
                                        </Col>
                                        <Col xs={11} sm={11} md={11} lg={11}>
                                            <FormInputCalendarDays
                                                name="rsv_end_ymd"
                                                placeholder={placeholderyyyymmdd}
                                                format={formatYYYYMMDD}
                                                disabled={repeatTypeInValid || methods.getValues('period') || checkedPeriod}
                                                inputProps = {{
                                                    onChange: (e: Dayjs) => 
                                                    {
                                                        methods.setValue('rsv_end_ymd', (e.isAfter(dayjs(DAY_EXPIRED)) ? DAY_EXPIRED : e.format(formatYYYYMMDD)));
                                                        handleEndYmd();
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </div>
                                    {
                                        errorYmd &&
                                        <div className="errcode">{t("message.MESS_ERR_END_DATE_LESS_THAN_START_DATE.text")}</div>
                                    }
                                    <FormInputCheckbox 
                                        disabled={repeatTypeInValid}
                                        className="checkboxElement"
                                        name="period"
                                        inputProps = {{
                                            onChange: (e: any) => handlePeriod(e.target.checked),
                                          }}
                                    >
                                        {t("schedule.sidebar-reservation.treatment-menu.period")}
                                    </FormInputCheckbox>
                                </Col>
                                {treatMentMenuList?.map((item: TreatmentMenu, index: number) => {
                                    return <div key={index}>
                                    {isFrameRepeatDetail !== 0 &&
                                    <Col xs={24} sm={24} md={24} lg={24} className="examMethod text-bold-styles">
                                        {item?.treatment_menu_name}
                                    </Col>
                                    }
                                    <Checkbox.Group name="exam" value={checkboxValueTreatMent?.[item.id] || []} onChange={(checkedValue) => onChangeCbExam(checkedValue, item.id, item?.treatment_menu_name)}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Checkbox disabled={item.exm_method === 0} value="1">{t("schedule.sidebar-reservation.exam-method.exam.offline")}</Checkbox>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Checkbox disabled={item.exm_method === 1} value="2">{t("schedule.sidebar-reservation.exam-method.exam.online")}</Checkbox>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Checkbox disabled={item.exm_method === 0} value="3">{t("schedule.sidebar-reservation.exam-method.re-exam.offline")}</Checkbox>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Checkbox disabled={item.exm_method === 1} value="4">{t("schedule.sidebar-reservation.exam-method.re-exam.online")}</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                    </div>
                                })}
                                {methods.formState.errors.exam?.message &&
                                    <span className="messErr">{methods.formState.errors.exam?.message}</span>
                                }
                                
                                <Col xs={24} sm={24} md={24} lg={24} className="examMethod text-bold-styles">
                                    {t("schedule.sidebar-reservation.doctor.title")}
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} className='fieldCheckbox'>
                                    {/* <Checkbox.Group name="clinicUser" value={checkboxValueUserId} onChange={onChangeUserId}> */}
                                    <Checkbox.Group name="clinicUser" value={userIdList} onChange={onChangeUserId}>
                                        <Row gutter={[{}, { xs: 12, sm: 12, md: 12 }]}>
                                            {
                                                doctors.length == 1 ?
                                                    <Col xs={24} sm={24} md={24}>
                                                        <Checkbox value={doctors[0]?.user_id}>
                                                            {doctors[0]?.user_name}
                                                        </Checkbox>
                                                    </Col>
                                                    :
                                                    doctors.map((element, index) => {
                                                        return (
                                                            <Col xs={12} sm={12} md={12} key={index}>
                                                                <Checkbox value={element?.user_id}>
                                                                    {element?.user_name}
                                                                </Checkbox>
                                                            </Col>
                                                        )
                                                    })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    {methods.formState.errors.clinicUser?.message &&
                                        <span className="messErr">{methods.formState.errors.clinicUser?.message}</span>
                                    }
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} className="numberReservation">
                                    <span>{t("schedule.sidebar-reservation.max-count-frame")}</span>
                                    <FormInputNumber
                                        placeholder={t("schedule.sidebar-reservation.max-count-frame")}
                                        name='upper_limit_frame'    
                                        onKeyDown={handleKeyDown}
                                        inputProps = {{
                                            onChange: (e: any) => handleChangeMaxNumber(e)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} className="footerElement">
                            <Button type='text' htmlType="submit" className="button-setting" onClick={handleValidateTime} disabled={treatMentMenuList.length === 0 || doctors.length === 0}>
                                <span className="text-bold-styles">{t("schedule.sidebar-reservation.button-setting")}</span>
                            </Button>
                            {
                                props.isDt0402Screen ? 
                                <Button type='text' className="button-delete" onClick={() => setShowModalDelete(true)}>
                                <span className="text-bold-styles">{t("schedule.sidebar-reservation.button-delete")}</span>
                                </Button> 
                                :
                                <></>
                            }

                        </Col>
                    </form>
                </FormProvider>
            </Row>
            {
                showModalProcess &&
                <DoctorConfirmReservation
                    isDeleteModal={false}
                    nextToConfirm={() => {
                        //TODO: action with DT-04-2_Process_Setting_Reservation_Frame
                        setShowModalProcess(false)}
                    }
                    closeModal={() => setShowModalProcess(false)}
                    dataReservationFrameRepeat={dataReservationFrameRepeat}
                    treatMentMenuList = {treatMentMenuList}
                    repeatOnDayOfWeekOptions={repeatOnDayOfWeekOptions}
                    setVisibleSuccess={props.setVisibleSuccess}
                    // reset={methods.reset}
                    onClose={props.onClose}
                    titleModal={t("modal-doctor-order.title")}
                    titleBody={t("modal-doctor-order.body.title")}
                    titteFooter={t("modal-doctor-order.footer.button-order")}
                    // setCheckboxValueTreatMent={setCheckboxValueTreatMent}
                    // setCheckboxValueUserId={setCheckboxValueUserId}
                />
            }
            {
                showModalEdit &&
                <DoctorEditReservation
                    isDeleteModal={false}
                    nextToConfirm={() => {
                        //TODO: action with DT-04-2_Process_Update_Reservation_Frame
                        setShowModalEdit(false)}
                    }
                    closeModal={() => setShowModalEdit(false)}
                    dataReservationFrameRepeat = {dataReservationFrameRepeat}
                    treatMentMenuList = {treatMentMenuList}
                    repeatOnDayOfWeekOptions = {repeatOnDayOfWeekOptions}
                    dataReservationFrameRepeatOld = {props.reservationFrameRepeat ?? {}}
                    repeatOnDayOfWeekOptionsOld = {repeatOnDayOfWeekOptions}
                    examOld={examOld}
                    setVisibleSuccess={props.setVisibleSuccess}
                    visibleNotice={visibleNotice}
                />
            }
            {
                showModalDelete &&
                <DoctorConfirmReservation
                    isDeleteModal={true}
                    nextToConfirm={() => {
                        //TODO: action with DT-04-2_Process_Setting_Reservation_Frame
                        setShowModalDelete(false)}
                    }
                    closeModal={() => setShowModalDelete(false)}
                    dataReservationFrameRepeat={props.reservationFrameRepeat ?? {}}
                    repeatOnDayOfWeekOptions={repeatOnDayOfWeekOptions}
                    setVisibleDeleteSuccess={props.setVisibleDeleteSuccess}
                    onClose={props.onClose}
                    titleModal={t("modal-delete-order.title")}
                    titleBody={t("modal-delete-order.body.title")}
                    titteFooter={t("modal-delete-order.footer.button-delete")}
                    treatMentMenu={examOld}
                />
            }
            {
                showModalWarning &&
                <ModalWarning
                message={t('message.MESS_CMN_CNF_DELETE.text').replace('{0}', String(props.reservationFrameRepeat?.treatment_menu?.treatment_menu_name))}
                title={t('message.MESS_CMN_CNF_DELETE.title')}
                typeModal={MODAL_CONFIRM}
                typeButton={BUTTON_CHOOSE}
                changeVisibleModal={() => {
                    //TODO: action with DT-04-2_Process_Delete_Reservation_Frame
                    setShowModalWarning(false);
                }}
                handleWarning={() => {
                    //TODO: action with DT-04-2_Process_Delete_Reservation_Frame
                    setShowModalWarning(true);
                }}
              />
            }
            {
            // button clear errors
            <button type="button" style={{display: 'none'}} id="newReservationButton" onClick={handleNewReservation}></button>
            }
        </div>
    );
};

export default SideBarReservation;
import { Button } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { ReservationInfoList } from 'src/Network/Model/ReservationInfoList';
import { CSVLink } from "react-csv";
import ComponentToPrint from '../ComponentToPrint';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FileOutlined, PrinterOutlined } from '@ant-design/icons';

type Props = {
    countItems: number,
    isDownloadOrPrint: number,
    hiddenDownloadAndPrint: Function,
    listItem: ReservationInfoList[]
}

const DowloadCSVAndPrint:React.FC<Props> = ({countItems, isDownloadOrPrint, listItem, hiddenDownloadAndPrint}) => {
    const { t } = useTranslation();
    const exportList = listItem.map((item) => {
        return  {
            [t("register.postal_code")]: item?.postal_code,
            [t("reservation-list.wrapper.user_name")]: item?.user_name,
            [t("reservation-list.honorific_title")]: [t("reservation-list.mr")],
            [t("reservation-list.pref")]: item?.prefectures_name,
            [t("reservation-list.city")]: item?.city_name,
            [t("reservation-list.address")]: item?.address
        }
    })
    const componentPrintRef = useRef(null) as any;
    const handlePrint = useReactToPrint({
        content: () => componentPrintRef.current,
    });
    return (
        <div className='download_print'>
            <div>
                <div>
                    <span>{isDownloadOrPrint === 0 ? t("download_print.download_address") :  t("download_print.print_address")}（{countItems}{t("download_print.subject")}）</span>
                </div>
                <div>
                    <Button onClick={() => hiddenDownloadAndPrint()}>{t("modal-delete-order.footer.button-cancel")}</Button>
                    {
                        isDownloadOrPrint === 0 ? 
                            <Button disabled={countItems===0 ? true : false} className='download_csv'>
                                <CSVLink data={exportList} filename={`address`}>
                                    <FileOutlined />
                                    {t("download_print.download_csv")}
                                </CSVLink>
                            </Button>
                        : <Button disabled={countItems===0 ? true : false} onClick={handlePrint}>
                            <PrinterOutlined />
                            {t("download_print.print")}
                        </Button>
                    }
                </div>
                {/* component to be printed */}
                <div style={{display: 'none'}}>
                    {ComponentToPrint(componentPrintRef, listItem)}
                </div>
            </div>
        </div>
    )
}

export default DowloadCSVAndPrint;
import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
    registerSuccess: false
}

const slice = createSlice({
  name: "registerSuccessSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeRegisterSuccess: (state, action) => {
      state.registerSuccess = action.payload;
    }
  },
});

export const selectRegisterSuccess = (state:RootState) => state.common.registerSuccessSlice.registerSuccess;

export const {
    changeRegisterSuccess
} = slice.actions;


export const RegisterSuccessReducer = slice.reducer;
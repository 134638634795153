import SearchPharmacyGuidance from 'src/Component/SearchPharmacyGuidance';
import './styles.css';
import ModalDetailPharmacy from 'src/Component/PrescriptionReceive/PT-05-B/ModalDetailPharmacy';
import warning from 'src/assets/image/warning.svg';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from 'src/Utils/textUtils';
import back_white from '../../../assets/image/back_white.svg';
import { useState } from 'react';
import { PharmacyInfo } from 'src/Network/Model/PharmacyInfo';
import ModalWarning from "src/Component/Modal/ModalWarning";
import { BUTTON_ACCEPT, BUTTON_CONFIRM, MODAL_FAIL } from 'src/constant';
import { useCreatePrescriptionPharmacyMutation } from 'src/Network/Home/HomeClinicApi';
import Loading from 'src/Component/Modal/Loading';
import PharmacyGuide from 'src/Component/Modal/PharmacyGuide';

type Props = {
    setVisibleSuccess: Function,
    setIsModalOpenNewPharmacy: Function,
    methodReceive: number
}

const RegisAddressOtherPharmacy:React.FC<Props> = (props) => {
    const {setVisibleSuccess, setIsModalOpenNewPharmacy, methodReceive} = props;
    const { t } = useTranslation();
    const [detailPharmacy, setDetailPharmacy] = useState<PharmacyInfo>();
    const [visibleWarning, setVisibleWarning] = useState(false);
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [showModalPharmacy, setShowModalPharmacy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [createPrescriptionPharmacy] = useCreatePrescriptionPharmacyMutation();
    const handleOpenNotifi = () => {
        setVisibleWarning(true);
    }
    const handleSubmit = () => {
        setVisibleConfirm(true);
    }
    const changeConfirm = () => {
        if (detailPharmacy) {
            setIsLoading(true);
            setVisibleConfirm(false);
            createPrescriptionPharmacy(detailPharmacy).then((res: any) => {
                if (!res?.data?.error) {
                    setIsModalOpenNewPharmacy(false);
                    setVisibleSuccess(true);
                } else {
                    // TODO show error
                    setShowModalWarning(true);
                }
                setIsLoading(false);
            })
        }
    }
    
    const handleClose = () => {
        setIsModalOpenNewPharmacy(false);
    }

    return (
        <div className='regis_address_other'>
            <SearchPharmacyGuidance
                setDetailPharmacy={setDetailPharmacy}
                methodReceive={methodReceive}
            />
            {detailPharmacy ?
            <>
            <ModalDetailPharmacy detailPharmacy={detailPharmacy} />
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    <img src={warning} />
                </Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23} className='warning_line'>
                    <span>{t("ReceptionInfo.warning_line")}</span>
                    <span onClick={handleOpenNotifi} style={{cursor: 'pointer', color: '#308AE6' }}>{t("ReceptionInfo.online_medication")}</span>
                    <span dangerouslySetInnerHTML={replaceWithBr(t("ReceptionInfo.register_line"))}></span>
                </Col>
            </Row>
            <Row className="prescription-info-button" gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 16, sm: 16, md: 16 }]}>
                <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                    <Button icon={<img src={back_white} alt=''/>} className='buttonCancel text-bold-styles' onClick={() => handleClose()}>
                        {t('ReceptionInfo.register_adress_new.back')}
                    </Button>
                </Col>
                <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                    <Button className='buttonAccept text-bold-styles' onClick={() => handleSubmit()}>
                        {t('ReceptionInfo.register_adress_new.register')}
                    </Button>
                </Col>
            </Row>
            </> :
            <>
                <div className='detail_pharmacy_empty'></div>
                <Row className="prescription-info-button" style={{alignItems: 'unset'}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Button type='text' className='buttonCancel' onClick={handleClose} style={{width: '100%'}}>
                            <img src={back_white} />
                            <span className='text-bold-styles'>{t("ReceptionInfo.register_adress_new.back")}</span>
                        </Button>
                    </Col>
                </Row>
            </>
            }
            {
                visibleWarning &&
                <ModalWarning
                    message={t('ReceptionInfo.other_pharmacy.search_pharmacy_guidance.info_medication_guidance')}
                    title={t('ReceptionInfo.other_pharmacy.search_pharmacy_guidance.medication_guidance')}
                    changeVisibleModal={() => {setVisibleWarning(false)}}
                />
            }
            {
                visibleConfirm &&
                <ModalWarning
                    message={t("message.MESS_CNF_SEND_LINE")}
                    title={t('message.MESS_CMN_CNF_DELETE.title')}
                    typeButton={BUTTON_CONFIRM}
                    changeVisibleModal={() => {setVisibleConfirm(false)}}
                    changeConfirm={changeConfirm}
                    changeCancel={() => {
                        setVisibleConfirm(false);
                        setShowModalPharmacy(true);
                    }}
                />
            }
            {
                showModalWarning &&
                <ModalWarning
                    message={t("message.MESS_ERR_CANNOT_REGIS")}
                    title={t('message.TITLE_FAIL')}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {setShowModalWarning(false)}}
                />
            }
            {
                showModalPharmacy &&
                <PharmacyGuide changeVisibleModal={() => setShowModalPharmacy(false)} />
            }
            {isLoading && <Loading />}
        </div>
    )
}

export default RegisAddressOtherPharmacy;
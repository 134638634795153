import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'src/Component/common/form/FormInput';
import {
  useGetReservationQuery,
  useUpdateReservationMutation
} from 'src/Network/Home/DoctorSettingApi';
import { ReservationUpdateDto } from 'src/Network/Model/DoctorSetting';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import { ReactComponent as LinkIcon } from '../../../../../assets/image/link_icon.svg';
import ErrorModal from '../ErorrModal';
import { HomePageFormData, HomePageUrlSchema } from '../constant';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  changeHomepageUrl,
  selectEndValue,
  selectReExamCode,
  selectStartValue
} from '../../medicalMenu/store/slice';
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';

const ReExaminationHomePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isChangeUrl, setIsChangeUrl] = useState(false);
  const [openErrorModal, setOpenErorrModal] = useState(false);
  const [erorrMessage, setErorrMessage] = useState('');
  const clinicId = getClinicIdFromLocalStorage();
  const { data: reservationData } = useGetReservationQuery(clinicId, {
    skip: clinicId < 0
  });
  const [updateReservation] = useUpdateReservationMutation();
  const reExamCode = useAppSelector(selectReExamCode);
  const startValue = useAppSelector(selectStartValue);
  const endValue = useAppSelector(selectEndValue);
  const codeLength = lengthFullAndHalfWidth(reExamCode);

  const formMethods = useForm<HomePageFormData>({
    resolver: yupResolver(HomePageUrlSchema)
  });
  const { handleSubmit, setValue, watch } = formMethods;

  const homepageUrl = watch('home_page_url');

  const checkEnableButton = () => {
    if (reservationData) {
      if (
        (homepageUrl === undefined || homepageUrl === '') &&
        reservationData?.home_page_url === null
      ) {
        return false;
      }
      if (homepageUrl == reservationData?.home_page_url) {
        return false;
      } else return true;
    } else return true;
  };

  const enableButton = checkEnableButton();

  const updateUrl = (dto: ReservationUpdateDto) => {
    updateReservation(dto).then((res: any) => {
      if (!res?.data?.data) {
        setErorrMessage(res?.data?.message);
        setOpenErorrModal(true);
      }
    });
  };

  const handleChangeUrl = () => {
    if (reservationData && homepageUrl && homepageUrl?.length > 0) {
      setIsChangeUrl(true);
      const dto: ReservationUpdateDto = {
        clinic_id: reservationData.clinic_id,
        re_exm_code: reservationData.re_exm_code,
        rsv_acceptance_pediod_from: reservationData.rsv_acceptance_pediod_from,
        rsv_acceptance_pediod_to: reservationData.rsv_acceptance_pediod_to,
        url_public_status: reservationData.url_public_status,
        home_page_url: homepageUrl
      };
      updateUrl(dto);
    }
    if (codeLength === 8 && !reservationData) {
      const dto: ReservationUpdateDto = {
        clinic_id: clinicId,
        re_exm_code: reExamCode,
        home_page_url: homepageUrl,
        rsv_acceptance_pediod_from: startValue,
        rsv_acceptance_pediod_to: endValue,
        url_public_status: 1
      };
      updateUrl(dto);
    }
    if (codeLength !== 8 && !reservationData) {
      const triggerReExamCode = document.getElementById('triggerReExamCode');
      if (triggerReExamCode) {
        triggerReExamCode.click();
      }
    }
  };

  useEffect(() => {
    if (reservationData?.home_page_url && (isChangeUrl || !homepageUrl)) {
      setValue('home_page_url', reservationData?.home_page_url);
    }
    setIsChangeUrl(false);
  }, [reservationData]);

  useEffect(() => {
    dispatch(changeHomepageUrl(homepageUrl));
  }, [homepageUrl]);

  return (
    <div className="re-examination-home-page_container">
      <p className="re-examination-home-page_title">
        {t('reExamCode.homepageHeader')}
      </p>
      <p className="homepage-url_title">
        <span>{t('reExamCode.homepageUrl')}</span>
      </p>
      <FormProvider {...formMethods}>
        <Form name="homeUrlForm" onFinish={handleSubmit(handleChangeUrl)}>
          <div className="homepage-url_content">
            <FormInput
              inputProps={{
                addonBefore: <LinkIcon />,
                maxLength: 255,
                className: 'homepage-url'
              }}
              name="home_page_url"
            />
            <button
              disabled={!enableButton}
              className={
                enableButton
                  ? 'change-homepage_button active-button'
                  : 'change-homepage_button'
              }
            >
              {t('reExamCode.changeHomepageButton')}
            </button>
          </div>
        </Form>
      </FormProvider>
      <ErrorModal
        message={erorrMessage}
        open={openErrorModal}
        setClose={() => setOpenErorrModal(false)}
      />
    </div>
  );
};

export default ReExaminationHomePage;

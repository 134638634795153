import FormInputSelect from 'src/Component/common/form/FormInputSelect';

export type OptionType = {
  value: number | string | undefined;
  label: string;
};

type Props = {
  titleInput: string;
  name: string;
  defaultValue: string | number | undefined;
  options: OptionType[];
};

const InputSelectForm: React.FC<Props> = ({
  titleInput,
  name,
  options,
  defaultValue
}) => {
  return (
    <div>
      <div className="medical-menu-form_separate_title-form-item">
        <p className="mr-4">{titleInput}</p>
      </div>
      <div className="medical-menu-form_wrapper">
        <div className="medical-menu-form_input_wrapper w-full">
          <FormInputSelect
            inputProps={{
              className:
                'medical-menu-form_input medical-menu-form_input-select'
            }}
            name={name}
            defaultValue={defaultValue}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default InputSelectForm;

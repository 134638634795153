import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../Redux/store';
import { revertAll } from 'src/Redux/hooks';
import { ReservationFrameRequest } from 'src/Network/Model/ReservationFrameRequest';
const initialState = {
  selectedDate: null,
  dataHourInfo: {} as ReservationFrameRequest,
  isSelectedDate: false
}

const slice = createSlice({
  name: "calendarBookingSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    changeDataHourInfo: (state, action) => {
      state.dataHourInfo = action.payload;
    },
    changeIsSelectedDate: (state, action) => {
      state.isSelectedDate = action.payload;
    },
  }
});

export const selectSelectedDate = (state:RootState) => state.common[slice.name].selectedDate;
export const selectdataHourInfo = (state:RootState) => state.common[slice.name].dataHourInfo;
export const selectIsSelectedDate = (state:RootState) => state.common[slice.name].isSelectedDate;

export const {
    changeDate,
    changeDataHourInfo,
    changeIsSelectedDate
} = slice.actions;

export const CalendarBookingReducer = slice.reducer;
const halfWidthNumberpattern = /^[0-9]$/;
const fullWidthNumberPattern = /[０１２３４５６７８９]/;
const fullWidthPattern = /[^\x00-\x7F]/;

export const replaceWithBr = (text: any) => {
  if (text) {
    return {
      __html: text.replace(/\\n/g, '<br />'),
    };
  }
};

export const lengthFullAndHalfWidth = (text: any) => {
  if (!text) {
    return 0;
  }
  let length = 0;
  for (let i = 0; i < text.length; i++) {
    const c = text.charCodeAt(i);
    // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
    // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
    if (
      (c >= 0x0 && c < 0x81) ||
      c === 0xf8f0 ||
      (c >= 0xff61 && c < 0xffa0) ||
      (c >= 0xf8f1 && c < 0xf8f4)
    ) {
      length += 1;
    } else {
      length += 2;
    }
  }
  return length;
};

export const convertToHalf = (text: any) => {
  if (!text) {
    return '';
  }
  return text.replace(/[！-～]/g, (t: string) =>
    String.fromCharCode(t.charCodeAt(0) - 0xfee0),
  );
};

export const isFullWidth = (text: any) => {
  if (!text) {
    return false;
  }
  for (let i = 0; i < text.length; i++) {
    if (fullWidthPattern.test(text[i])) {
      return true;
    }
  }
  return false;
};

export const isNumberic = (text: any) => {
  if (!text) {
    return false;
  }
  for (let i = 0; i < text.length; i++) {
    if (
      halfWidthNumberpattern.test(text[i]) ||
      fullWidthNumberPattern.test(text[i])
    ) {
      continue;
    }
    return false;
  }
  return true;
};

export const formatDate = (text: any) => {
  if (!text || text.length < 8) {
    return '';
  }
  const year = text.slice(0, 4);
  const month = text.slice(4, 6);
  const day = text.slice(6, 8);
  const formattedDate = `${year}年${month}月${day}日`;
  return formattedDate;
};

export const formatDisplayTime = (time: String | undefined) => {
  return time ? time.substring(0, 2) + ':' + time.substring(2, 4) : time;
}

export const formatDisplayYmd = (time: String | undefined) => {
  return time ? time.substring(0, 4) + '/' + time.substring(4, 6)  + '/' + time.substring(6, 8) + time.substring(8, time.length) : time;
}
import { createSlice } from '@reduxjs/toolkit';
import { ConsentFormDataType } from 'src/Network/Model/DoctorSetting';
import { RootState } from 'src/Redux/store';

const slice = createSlice({
  name: 'agreementManagementSlice',
  initialState: {
    isModalOpen: false,
    isCreate: true,
    isCancelModalOpen: false,
    selectedDeleteId: undefined as number | undefined,
    selectedUpdateId: undefined as number | undefined,
    selectedUpdateRecord: undefined as ConsentFormDataType | undefined,
    openSuccessModal: false,
    openDeleteSuccessModal: false
  },
  reducers: {
    changeIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    changeIsCreate: (state, action) => {
      state.isCreate = action.payload;
    },
    changeIsCancelModalOpen: (state, action) => {
      state.isCancelModalOpen = action.payload;
    },
    changeSelectedDeleteId: (state, action) => {
      state.selectedDeleteId = action.payload;
    },
    changeSelectedUpdateRecord: (state, action) => {
      state.selectedUpdateRecord = action.payload;
    },
    changeSelectedUpdateId: (state, action) => {
      state.selectedUpdateId = action.payload;
    },
    changeOpenSuccessModal: (state, action) => {
      state.openSuccessModal = action.payload;
    },
    changeOpenDeleteSuccessModal: (state, action) => {
      state.openDeleteSuccessModal = action.payload;
    }
  }
});

export const selectIsModalOpen = (state: RootState) =>
  state[slice.name].isModalOpen;
export const selectIsCreate = (state: RootState) => state[slice.name].isCreate;
export const selectIsCancelModalOpen = (state: RootState) =>
  state[slice.name].isCancelModalOpen;
export const selectSelectedDeleteId = (state: RootState) =>
  state[slice.name].selectedDeleteId;
export const selectSelectedUpdateId = (state: RootState) =>
  state[slice.name].selectedUpdateId;
export const selectSelectedUpdateRecord = (state: RootState) =>
  state[slice.name].selectedUpdateRecord;

export const selectOpenSuccessModal = (state: RootState) =>
  state[slice.name].openSuccessModal;
export const selectOpenDeleteSuccessModal = (state: RootState) =>
  state[slice.name].openDeleteSuccessModal;

export const {
  changeIsModalOpen,
  changeIsCreate,
  changeIsCancelModalOpen,
  changeSelectedUpdateId,
  changeSelectedDeleteId,
  changeSelectedUpdateRecord,
  changeOpenSuccessModal,
  changeOpenDeleteSuccessModal
} = slice.actions;

export const AgreementManagementReducer = slice.reducer;

import { Row } from 'antd';
import ReExaminationCode from './reExaminationCode';
import ReExaminationContent from './reExaminationContent';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import {
  useGetClinicAddressQuery,
  useGetReservationQuery,
  useUpdateReservationMutation
} from 'src/Network/Home/DoctorSettingApi';
import Loading from 'src/Component/Modal/Loading';

const ReservationManagement: React.FC = () => {
  const clinicId = getClinicIdFromLocalStorage();
  const { isLoading, isFetching } = useGetReservationQuery(clinicId, {
    skip: clinicId < 0
  });
  const [_updateReservation, { isLoading: isUpdating }] =
    useUpdateReservationMutation();
  const { isLoading: isLoadingAddress, isFetching: isFetchingAddress } =
    useGetClinicAddressQuery(clinicId, {
      skip: clinicId < 0
    });

  const loading =
    isFetchingAddress ||
    isLoading ||
    isFetching ||
    isLoadingAddress ||
    isUpdating;

  return (
    <>
      <Row gutter={40} className="reservation-management_container">
        <ReExaminationCode />
        <ReExaminationContent />
      </Row>
      {loading && <Loading />}
    </>
  );
};

export default ReservationManagement;

import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
  showPharmacyGuige: false
}

const slice = createSlice({
  name: "pharmacyGuideSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeShowPharmacyGuide : (state, action) => {
        state.showPharmacyGuige = action.payload
    }
  },
});

export const selectShowPharmacyGuide = (state:RootState) => state.common.pharmacyGuideSlice.showPharmacyGuige;

export const {
    changeShowPharmacyGuide
} = slice.actions;

export const PharmacyGuideReducer = slice.reducer;
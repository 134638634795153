import "./styles.css";
import { Button, Col, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/Redux/hooks";
import { changeConfirmRegister, changeIsResetRegister, selectConfirmRegister } from "./slice";
import arrow_left from "../../assets/image/arrow-left.svg";
import {
  selectCityName,
  selectDataRegister,
  selectPrefname,
} from "../Register/slice";
import { changeModalRegister } from "../Header/slice";
import { useForm } from "react-hook-form";
import { BUTTON_ACCEPT, MODAL_WARNING, birthdayJanpan } from "src/constant";
import { useRegisterUserMutation } from "src/Network/Home/HomeClinicApi";
import { useState } from "react";
import ModalWarning from "src/Component/Modal/ModalWarning";
import Loading from "../Modal/Loading";
import { changeRegisterSuccess } from "../Modal/RegisterSuccess/slice";
import RegisterSuccess from "../Modal/RegisterSuccess";

const ConfirmRegister = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const statusConfirm = useAppSelector(selectConfirmRegister);
  const dataUser = useAppSelector(selectDataRegister).data;
  const [visibleWarning, setVisibleWarning] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const birthdayJP = birthdayJanpan(
    dataUser?.birth_year,
    dataUser?.birth_month,
    dataUser?.birth_day
  );

  const pref_name = useAppSelector(selectPrefname);
  const city_name = useAppSelector(selectCityName);
  const hideModal = () => {
    dispatch(changeConfirmRegister(false));
  };
  const prevStep = () => {
    dispatch(changeConfirmRegister(false));
    dispatch(changeModalRegister(true));
    dispatch(changeIsResetRegister(false));
  };
  const [userRegister] = useRegisterUserMutation();
  const { handleSubmit } = useForm();
  const onSubmit = () => {
    const data = {
      ...dataUser,
      redirect_url: getRedirectUrl(),
      agree: 1,
      nickname: t("confirm-register.title-nickname")
    };
    setIsLoading(true);
    userRegister(data).then((res: any) => {
      if(!res?.data?.error){
        dispatch(changeConfirmRegister(false));
        dispatch(changeRegisterSuccess(true));
      } else {
        //TODO show error
        setVisibleWarning(true);
      }
      setMessage(res?.data?.message);
      setIsLoading(false);
    });
  };

  const getRedirectUrl = () => {
    let url = process.env.REACT_APP_APP_URL;
    try {
      const currentUrl = new URL(window.location.href);
      //Check if current URL is PT-05 redirected from DT-09
      if (currentUrl.href.includes('/reservation') && currentUrl.searchParams.get('reservation_info_id')){
        return window.location.href;
      }
      let clinicId = currentUrl.searchParams.get("clinic_id");
      if (clinicId) {
        return url + '/?clinic_id=' + clinicId;
      }

      //check if there is redirect url param
      const decodedUrl = new URL(decodeURIComponent(window.location.href));
      const redirectUrl = decodedUrl.searchParams.get("redirect_url")
      if (redirectUrl) {
        return redirectUrl;
      }

    } catch (e) {
      return url;
    }
    return url;
  }

  return (
    <div>
      <Modal
        title={t("register.title")}
        onOk={hideModal}
        onCancel={hideModal}
        open={statusConfirm}
        footer={null}
        className="confirm-wrapper"
        style={{top: 0, paddingTop: 20, maxHeight: 'calc(75vh - 100px)'}}
        zIndex={998}
      >
        <div className="confirm-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Row className="title-body">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <span className="text-nornal-styles">
                    {t("confirm-register.title-body")}
                  </span>
                </Col>
              </Row>
            </div>
            <div className="body-content">
              <div className="info-user">
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="title-info text-bold-styles">
                      {t("register.user_name")}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="title-info text-bold-styles">
                      {t("register.gender")}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="content-info text-nornal-styles">
                      {dataUser?.user_name}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="content-info text-nornal-styles">
                      {dataUser?.sex === 1
                        ? t("register.woman")
                        : t("register.male")}
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="info-user">
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="title-info text-bold-styles">
                      {t("register.tel")}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="title-info text-bold-styles">
                      {t("register.birthday")}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="content-info text-nornal-styles">
                      {dataUser?.tel}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="content-info text-nornal-styles">
                      {birthdayJP}
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="info-user">
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="title-info text-bold-styles">
                      {t("register.title-address")}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="title-info text-bold-styles">
                      {t("confirm-register.title-nickname")}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="content-info text-nornal-styles">
                      {dataUser?.postal_code}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="content-info text-nornal-styles">
                      {t("confirm-register.nickname")}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span className="content-info text-nornal-styles">
                      {pref_name} {city_name} {dataUser?.address}
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="info-user">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span className="title-info text-bold-styles">
                      {t("register.mailaddress")}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span className="content-info text-nornal-styles">
                      {dataUser?.mailaddress}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="btn-submit">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    type="submit"
                    className="buttonPt10-left text-bold-styles"
                    value={t("confirm-register.step-next")}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Button
                    type="text"
                    className="buttonPt10-right text-bold-styles"
                    onClick={prevStep}
                  >
                    <img src={arrow_left} />
                    {t("confirm-register.step-prev")}
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
        {isLoading && <Loading />}
      </Modal>
      {
        visibleWarning ? 
        <ModalWarning
        message={message}
        typeModal={MODAL_WARNING}
        typeButton={BUTTON_ACCEPT}
        changeVisibleModal={() => {setVisibleWarning(false)}}
        />
        :
        <RegisterSuccess message={message} />
      }
    </div>
  );
};

export default ConfirmRegister;

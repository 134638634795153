import { AES, enc } from 'crypto-js';

const timezone = 'Asia/Tokyo'
const key = "t2&yb08;2LXqC;)j]P^.G6Y](F5-/4!Z";
const iv = '00112233445566778899aabbccddeeff';

const formatTimestamp = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}`;
}

const now = new Date();

const formattedTimestamp = formatTimestamp(new Date(now.toLocaleString('en-US', { timeZone: timezone })));

export const cipher = AES.encrypt(
    formattedTimestamp, 
    enc.Utf8.parse(key), 
    {
        iv: enc.Hex.parse(iv),
    }
);
import React from 'react';


function JapaneseEraCalculator( year : number) {
  const calculateJapaneseEra = (year: number) => {
    let eraName = '';
    let eraYear = '';

    if (year >= 2019) {
      eraName = '令和';
      eraYear = (year - 2018).toString() + '年';
    } else if (year >= 1989) {
      eraName = '平成';
      eraYear = (year - 1988).toString() + '年';
    } else if (year >= 1926) {
      eraName = '昭和';
      eraYear = (year - 1925).toString() + '年';
    } else if (year >= 1912) {
      eraName = '大正';
      eraYear = (year - 1911).toString() + '年';
    } else if (year >= 1868) {
      eraName = '明治';
      eraYear = (year - 1867).toString() + '年';
    } else {
      eraName = '西暦';
      eraYear = year.toString();
    }

    return eraName + eraYear;
  };

  const japaneseEra = calculateJapaneseEra(year);

  return japaneseEra;
}

export default JapaneseEraCalculator;
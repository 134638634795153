import { Table } from 'antd';
import './styles.css';
import { useTranslation } from "react-i18next";
import { useAppSelector } from 'src/Redux/hooks';
import { selectDoctorSelectionList, selectFilteredData, selectRadioIndex } from '../TimeBooking/slice';
import { formatTimeRange } from 'src/Utils/CalculateHourResevationUtils';
import { replaceWithBr } from 'src/Utils/textUtils';

type Props = {
  detail_info: any
};

const BookingInfoHospital: React.FC<Props> = ({ detail_info }) => {
  const { t } = useTranslation();
  const doctorSelectionList = useAppSelector(selectDoctorSelectionList);
  const filteredData = useAppSelector(selectFilteredData);
  const radioIndex = useAppSelector(selectRadioIndex);
  const frameTimeSelecttion = filteredData[radioIndex];
  const titleTimeLabel = formatTimeRange(frameTimeSelecttion?.rsv_time, frameTimeSelecttion?.clinics.exm_time);
  const doctorSelection= doctorSelectionList.find((i:any) => i.id === radioIndex);
  const columns = [
    {
      title: '',
      dataIndex: 'name'
    },
    {
      title: '',
      dataIndex: 'value'
    }
  ];

  const data = [
    {
      'key': 0,
      'name': <span className="text-normal-styles">{t("booking-info-hospital.clinic_name")}</span>,
      'value': <span className="text-normal-styles">{detail_info?.clinics?.clinic_name}</span>
    },
    {
      'key': 1,
      'name': <span className="text-normal-styles">{t("booking-info-hospital.treatment_menu_name")}</span>,
      'value': <span className="text-normal-styles">{detail_info?.treatment_menu_name}</span>
    },
    {
      'key': 2,
      'name': <span className="text-normal-styles">{t("booking-info-hospital.time_frame_label")}</span>,
      'value': <span className="text-normal-styles">{titleTimeLabel ? titleTimeLabel : '-'}</span>
    },
    {
      'key': 3,
      'name': <span className="text-normal-styles">{t("booking-info-hospital.doctor_label")}</span>,
      'value': <span className="text-normal-styles">{(doctorSelection && doctorSelection.selection?.user_name !== t("time-booking-info-prof.doctor-default")) ? doctorSelection.selection?.user_name : '-'}</span>
    }
  ];

  const generateInfoTable = () => {
    const introductionOnline = detail_info?.introduction; //introduction
    const riskSideEffectsOnline = detail_info?.risk_side_effects; //risk_side_effects
    const consulHoursOnline = detail_info?.consul_hours_pt; //consul_hours_pt
    const noteOnline = detail_info?.note; //note
    const unapprovedDevicesDrugs = detail_info?.unapproved_devices_drugs; //unapproved_devices_drugs
    if ((introductionOnline && introductionOnline !== "") || (riskSideEffectsOnline && riskSideEffectsOnline !== "") || 
    (consulHoursOnline && consulHoursOnline !== "") || (noteOnline && noteOnline !== "")) {
      return (
        <div className='text-normal-styles detail'>
          {
            riskSideEffectsOnline ? (
              <div className='detail-box'>
                <span className='text-bold-styles header-detail'>{t("booking-info-hospital.risk_side_effects")}</span>
                <span dangerouslySetInnerHTML={replaceWithBr(riskSideEffectsOnline)}></span>
              </div>
            ) : null
          }
          {
            consulHoursOnline ? (
              <div className='detail-box'>
                <span className='text-bold-styles header-detail'>{t("booking-info-hospital.consul_hours")}</span>
                <span>{consulHoursOnline}</span>
              </div>
            ) : null
          }
          {
            (noteOnline || unapprovedDevicesDrugs === 1) ? (
              <div className='detail-box'>
                    <span className='text-bold-styles header-detail'>{t("booking-info-hospital.note")}</span>
                {unapprovedDevicesDrugs === 1 && (
                  <>
                    <span dangerouslySetInnerHTML={replaceWithBr(t("booking-info-hospital.label_note1"))}></span>
                    <span dangerouslySetInnerHTML={replaceWithBr(t("booking-info-hospital.label_note2"))}></span>
                    <span dangerouslySetInnerHTML={replaceWithBr(t("booking-info-hospital.label_note3"))}></span>
                    <span style={{wordBreak: 'break-word', color: '#308AE6'}}><a href={t("booking-info-hospital.label_note4")}>{t("booking-info-hospital.label_note4")}</a></span>
                    <span dangerouslySetInnerHTML={replaceWithBr(t("booking-info-hospital.label_note5"))}></span>
                    <span dangerouslySetInnerHTML={replaceWithBr(t("booking-info-hospital.label_note6"))}></span>
                  </>
                )}
                {noteOnline && <span dangerouslySetInnerHTML={replaceWithBr(noteOnline)}></span>}
              </div>
            ) : null
          }
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='infoHos'>
      {generateInfoTable() === null ? <></> : generateInfoTable()}
      <Table columns={columns} dataSource={data} pagination={false} showHeader={false} />
    </div>
  )
}

export default BookingInfoHospital;
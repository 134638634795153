import './styles.css';
import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Col, Row } from 'antd';
import Scanner from "./Scanner";
import { useTranslation } from "react-i18next";

type Props = {
  changeVisibleModal: Function
}

const APP:React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleOpenEnterCode = (isScanner?:boolean) => {
    props.changeVisibleModal(true, false, isScanner);
  };

  const handleClose = () => {
    props.changeVisibleModal();
    setIsModalOpen(false);
  };

  return (
    <Modal maskClosable={false} title closeIcon={null} centered open={isModalOpen} footer={null} className='modalQRCode'>
      <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
        <Col span={24}>
          <div className='headerModal'>
            <span className='headerModal-title text-bold-styles'>{t("booking-QRCode.modal-title")}</span>
            <span>
              <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
            </span>
          </div>
        </Col>
        <Col span={24} className='bodyModal' style={{ textAlign: 'center' }}>
          <Row gutter={[{ xs: 12, sm: 12, md: 12 }, { xs: 12, sm: 12, md: 12 }]}>
            <Col xs={24} sm={24} md={24}>
              <div className='text-bold-styles' style={{ fontSize: 16, marginBottom: 8 }}>{t("booking-QRCode.modal-scan-qr.body-title")}</div>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Scanner changeVisibleModal={() => handleOpenEnterCode(true)}/>
            </Col>
            <Col xs={24} sm={24} md={24} style={{ padding: '24px 0' }}>
              <Button type='text' onClick={() => handleOpenEnterCode()} className='buttonPt01-code text-bold-styles'>
                {t("booking-QRCode.modal-scan-qr.body-button-qrenter")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default APP;
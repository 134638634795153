import { Controller, useFormContext } from "react-hook-form";
import { DatePicker, Form } from "antd";

const FormInputCalendar = ({ inputProps, ...props }: any) => {
  const { control, register } = useFormContext();
  return (
    <Controller
      name={props.name}
      {...props}
      control={control}
      defaultValue={props.defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Form.Item
          className={props.className}
          validateStatus={error ? 'error' : 'validating'}
          help={error?.message}
        >
          <DatePicker
            disabled={props.disabled}
            ref={register(props?.name).ref}
            placeholder={props.placeholder}
            {...{ value, onChange }}
            format={props.format}
            disabledDate={props.disabledDate}
            {...inputProps}
          />
        </Form.Item>
      )}
    />
  );
};

export default FormInputCalendar;

import { Button, Col, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Reservation from 'src/Component/Doctor/Reservation';
import calendar from '../../../assets/admin/tab_icon_calendar.svg';
import calendarActive from '../../../assets/admin/tab_icon_calendar_active.png';
import setting from '../../../assets/admin/tab_icon_setting.png';
import settingActive from '../../../assets/admin/tab_icon_setting_active.svg';
import user from '../../../assets/admin/tab_icon_user.png';
import userActive from '../../../assets/admin/tab_icon_user_active.svg';
import users from '../../../assets/admin/tab_icon_user_list.png';
import usersActive from '../../../assets/admin/tab_icon_user_list_active.svg';
import APP from '../Schedule';
import Setting from '../Setting/Setting';
import './styles.css';

const Home = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  useEffect(() => {
    if (currentTab === 1) {
      document.title = t('title_tabs_doctor.tab_1');
    } else if (currentTab === 2) {
      document.title = t('title_tabs_doctor.tab_2');
    } else if (currentTab === 3) {
      document.title = t('title_tabs_doctor.tab_3');
    } else if (currentTab === 4) {
      document.title = t('title_tabs_doctor.tab_4');
    }
    if ([1,2,3].includes(currentTab)) {
      document.body.classList.add('hideScrollbar');
    } else {
      document.body.classList.remove('hideScrollbar');
    }
  }, [currentTab]);

  const tabsData = [
    {
      label: (
        <>
          <img src={currentTab == 1 ? userActive : user} />{' '}
          <span
            style={
              currentTab == 1
                ? { color: '#308AE6', marginLeft: 3 }
                : { marginLeft: 3 }
            }
          >
            {t('admin.header.tabs-reservation-list')}
          </span>
        </>
      ),
      key: 1,
      children: (
        <div>
          <Reservation />
        </div>
      )
    },
    {
      label: (
        <>
          <img src={currentTab == 2 ? calendarActive : calendar} />
          <span
            style={
              currentTab == 2
                ? { color: '#308AE6', marginLeft: 3 }
                : { marginLeft: 3 }
            }
          >
            {t('admin.header.tabs_schedule')}
          </span>
        </>
      ),
      key: 2,
      children: (
        <div>
          <APP />
        </div>
      )
    },
    {
      label: (
        <>
          <img src={currentTab == 3 ? settingActive : setting} />
          <span
            style={
              currentTab == 3
                ? { color: '#308AE6', marginLeft: 3 }
                : { marginLeft: 3 }
            }
          >
            {t('admin.header.tabs-setting')}
          </span>
        </>
      ),
      key: 3,
      children: (
        <div>
          <Setting />
        </div>
      )
    },
    {
      label: (
        <Button className="button-in-tabs">
          <Link to={process.env.REACT_APP_PATIENT_CHART || ''} target="_blank">
            <span>
              {t('admin.header.tabs-medical-history')}
            </span>
          </Link>
        </Button>
      ),
      key: 4,
      children: <div></div>
    }
  ];

  return (
    <div className="schedule">
      <Row>
        <Col xs={24} sm={24} md={24} className="bookingTab text-bold-styles">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="middle"
            items={tabsData.map((tab) => {
              return {
                label: tab.label,
                key: tab.key.toString(),
                children: tab.children,
                disabled: tab.key.toString() === '4' ? true : false
              };
            })}
            onChange={(v) => setCurrentTab(Number(v))}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Home;

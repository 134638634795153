import { EXM_TIME, HALF_AN_HOURS, HOURS_IN_DAY, QUARTER_AN_HOURS } from "src/constant";

export function formatTimeRange(startTime: string, duration: number): string {
  const start = new Date();
  if (startTime !== undefined) {
    start.setHours(Number(startTime.substring(0, 2)));
    start.setMinutes(Number(startTime.substring(2)));

    const end = new Date(start.getTime() + duration * 60000);

    const formattedStart = start.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).replace(/^24:/, '00:');
    const formattedEnd = end.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).replace(/^24:/, '00:');

    return `${formattedStart} ~ ${formattedEnd}`;
  } else {
    return "";
  }

}

export const getListTimeOnDay = (exmTime: number) => {
  const results:string[] = [];

  let minuteArr: string[];
  if (exmTime == EXM_TIME.HALF_AN_HOURS) {
    minuteArr = HALF_AN_HOURS;
  } else {
    minuteArr = QUARTER_AN_HOURS;
  }

  for (const hour of HOURS_IN_DAY) {
    minuteArr.forEach((minus) => {
      results.push(hour + minus);
    })
  }

  return results;
}
import FormInputNumber from 'src/Component/common/form/FormInputNumber';

type InputNumberOption = {
  name: string;
  titleBefore?: string;
  unitName?: string;
  placeholder?: string;
  inputProps?: any;
};

type Props = {
  titleInput: string;
  inputTitleNote?: string;
  options: InputNumberOption[];
};

const InputNumberForm: React.FC<Props> = ({
  titleInput,
  inputTitleNote,
  options
}) => {
  return (
    <div>
      <div className="medical-menu-form_separate_title-form-item">
        <p>{titleInput}</p>
        {!!inputTitleNote && (
          <p className="medical-menu-form_separate_title-note">
            {inputTitleNote}
          </p>
        )}
      </div>
      <div className="medical-menu-form_wrapper">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={
                index === 0
                  ? 'medical-menu-form_input_wrapper'
                  : 'medical-menu-form_input_wrapper ml-100'
              }
            >
              {!!item.titleBefore && (
                <span className="medical-menu-form_title-before">
                  {item.titleBefore}
                </span>
              )}
              <div className="medical-menu-wrap-input-number">
                <FormInputNumber
                  inputProps={{
                    className:
                      'medical-menu-form_input medical-menu-form_input-number',
                    placeholder: item?.placeholder,
                    ...item?.inputProps
                  }}
                  name={item.name}
                />
              </div>
              <span className="medical-menu-form_unit-after">
                {item.unitName}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InputNumberForm;

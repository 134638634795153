import { Fragment, useEffect, useRef, useState } from "react";
import { Table, ConfigProvider, Dropdown, Row, Col, Tooltip } from "antd";
import type { MenuProps } from 'antd';
import Plus from 'src/assets/image/plus-icon.svg';
import { useTranslation } from "react-i18next";
import {
  BUTTON_CLOSE,
  EXM_TIME,
  HALF_AN_HOURS,
  HOURS_IN_DAY,
  isFutureDate,
  MODAL_SUCCESS,
  QUARTER_AN_HOURS
} from "src/constant";
import { ReservationFrameRepeat } from "src/Network/Model/ReservationFrameRepeat";
import { TreatMentMenus } from "./splitComponent/TreatMentMenus";
import './styles.css';
import { TreatmentMenu } from "src/Network/Model/TreatmentMenu";
import SideBarReservation from 'src/Component/Doctor/SideBarReservation';
import {
  useFindTreatmentMenuMutation,
  useLazyGetClosedTimeByDateAndClinicQuery,
  useReservationFrameRepeatQuery
} from "src/Network/Home/DoctorApi";
import AddCloseTime from 'src/Component/Doctor/AddCloseTime';
import UpdateClosedTime from 'src/Component/Doctor/UpdateClosedTime';
import Loading from "src/Component/Modal/Loading";
import { getClinicIdFromLocalStorage } from "src/common/Libs";
import { calculateRangeTimeOrdered, calculateRsvEndTime, DataType, renderColumnHour, renderColumnsEmpty, renderTitleText, renderTitleAdd, TitleIcon } from "./splitComponent";
import { formatDisplayTime, formatDisplayYmd } from "src/Utils/textUtils";
import {ClosedTime} from "../../../../Network/Model/ClosedTime";
import ModalWarning from 'src/Component/Modal/ModalWarning';
import dayjs from "dayjs";

const { Column } = Table;

const ReservationSetting = ({
    isChecked,
    selectedClinicUserId,
    selectedTreatmentMenuId,
    selectedDate,
    exmTime,
    isTabActive
  }: {
    isChecked: boolean;
    selectedClinicUserId: number;
    selectedTreatmentMenuId: number;
    selectedDate: string;
    exmTime: number;
    isTabActive: boolean;
  }) => {

    const { t } = useTranslation();
    const [clinicId] = useState(getClinicIdFromLocalStorage());
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [showSideBar, setShowSideBar] = useState(false);
    const [isDt0402Screen, setIsDt0402Screen] = useState(false);
    const [reservationFrameRepeat, setReservationFrameRepeat] = useState<ReservationFrameRepeat[]>([{}]);
    const [treatMentMenuList, setTreatMentMenuList] = useState<TreatmentMenu[]>([]);
    const [findTreatMentMenu] = useFindTreatmentMenuMutation();
    const [getClosedTime] = useLazyGetClosedTimeByDateAndClinicQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [reservationFrameRepeatSelected, setReservationFrameRepeatSelected] = useState<ReservationFrameRepeat>();
    const [showDT044, setShowDT044] = useState(false);
    const [selectedTreatmentIds, setSelectedTreatmentIds] = useState<number[]>([])
    const [dt044TreatmentMenuName, setDt044TreatmentMenuName] = useState<String>("")
    const [isDT044ApplyAllTreatmentMenus, setIsDT044ApplyAllTreatmentMenus] = useState(false);
    const [selectedClosedTime, setSelectedClosedTime] = useState<ClosedTime>();
    const [showDT047, setShowDT047] = useState(false);
    const [closedTimeList, setClosedTimeList] = useState<ClosedTime[]>([]);
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const [visibleDeleteSuccess, setVisibleDeleteSuccess] = useState(false);
    const [treatMentMenus, setTreatMentMenus] = useState<TreatMentMenus[]>([{reservationFrameRepeat: []}]);
    const [isFrameRepeatDetail, setIsFrameRepeatDetail] = useState<number>(0);
    // const [userIdList, setUserIdList] = useState<Array<number>>([]);
    const [selectedTime, setSelectedTime] = useState<string>();
    const [disablePastDay, setDisablePastDay] = useState(false);
    const newReservationButton = document.getElementById('newReservationButton');
    const dataTreatMentMenus = treatMentMenus.map((e) => {
        return {
            key: 'treatMentMenus',
            title: e.reservationFrameRepeat[0]?.treatment_menu?.treatment_menu_name,
            treatMentMenu: e.reservationFrameRepeat[0]?.treatment_menu
        }
    });
  
    const handleGetClosedTime = () => {
        const param = {
        clinic_id: getClinicIdFromLocalStorage(),
        closed_ymd: selectedDate.replaceAll("-","")
        }
        setIsLoading(true);
        getClosedTime(param).then((res) => {
        if (res?.data) {
                setClosedTimeList(res?.data);
            }
            setIsLoading(false);
        })
    }

    const handleResetState = () => {
        setIsDt0402Screen(false);
        setReservationFrameRepeatSelected(
            {
                id: 0,
                rsv_start_time: undefined,
                rsv_end_time: undefined,
                repeat_type: 0,
                repeat_on_day_of_week: undefined,
                is_public_on_holiday: 1,
                upper_limit_frame: 0,
                face_to_face_first_exm: 0,
                face_to_face_re_exm: 0,
                online_first_exm: 0,
                online_re_exm: 0
            }
        );
        setShowDT044(false);
        setShowDT047(false);
        setShowSideBar(true);
    }

    const handleNewTreatMentMenu = () => {
        //TODO: will action with DT-04_1
        handleResetState();
        setIsLoading(true);
        const date = selectedDate.replaceAll('-', '');
        findTreatMentMenu({clinic_id: clinicId, has_frame_repeat: 2, date: date}).then((res: any) => {
            if (res?.data) {
                setTreatMentMenuList(res?.data);
            }
            setIsLoading(false);
        })
        setIsFrameRepeatDetail(3);
        // setUserIdList([]);
        newReservationButton?.click();
    }

    const handleEditReservation = (reservationFrameRepeatSelected: ReservationFrameRepeat) => {
        //TODO: action with DT-04_2
        setIsDt0402Screen(true);
        // const frames = reservationFrameRepeatSelected.frames;
        // const clinicUsers = frames?.map((item: ReservationFrame) => {
        //     const user = item?.users?.map((i: ClinicWithPivot) => {
        //         return i?.pivot?.clinic_user_id;
        //     });
        //     return user;
        // });
        // const arrayUser = [] as Array<number>;
        // clinicUsers?.forEach((i:any) => {
        //     if (i) {
        //         arrayUser.push(...i);
        //     }
        // });
        // const newArrayUser = [...new Set(arrayUser)];
        setReservationFrameRepeatSelected(reservationFrameRepeatSelected);
        setTreatMentMenuList(reservationFrameRepeatSelected.treatment_menu?[reservationFrameRepeatSelected.treatment_menu]:[]);
        setShowSideBar(true);
        setShowDT044(false);
        setShowDT047(false);
        setIsFrameRepeatDetail(0);
        // setUserIdList(newArrayUser);
    } 

    const handleAddReservation = (rsvStartTimeSelected:string, inTreatMentMenu=false, treatmentMenu:TreatmentMenu|undefined = undefined) => {
        const rsvEndTime = calculateRsvEndTime(rsvStartTimeSelected, exmTime);

        if (inTreatMentMenu) {
            //TODO: will action with DT-04_1
            setTreatMentMenuList(treatmentMenu?[treatmentMenu]:[]);
            setReservationFrameRepeatSelected(
                {
                    rsv_start_time: rsvStartTimeSelected,
                    rsv_end_time: rsvEndTime,
                    is_public_on_holiday: 1
                }
            );
            setIsFrameRepeatDetail(1);
        } else {
            //TODO: will action with DT-04_6
            setIsLoading(true);
            findTreatMentMenu({clinic_id: clinicId}).then((res: any) => {
                if (res?.data) {
                    setTreatMentMenuList(res?.data);
                }
                setIsLoading(false);
            })
            setReservationFrameRepeatSelected(
                {
                    rsv_start_time: rsvStartTimeSelected,
                    rsv_end_time: rsvEndTime,
                    is_public_on_holiday: 1
                }
            );
            setIsFrameRepeatDetail(2);
        }
        setShowSideBar(true);
        setShowDT044(false);
        setShowDT047(false);
        // setUserIdList([]);
        setIsDt0402Screen(false);
        newReservationButton?.click();
    }

  const handleClosedHours = (time: string, isApplyToAllTreatmentMenus: boolean, treatmentMenu: TreatmentMenu | undefined) => {
    setShowDT044(true);
    setShowDT047(false);
    setShowSideBar(false);
    setIsDT044ApplyAllTreatmentMenus(isApplyToAllTreatmentMenus)
    const tmIds: number[] = [];
    if (isApplyToAllTreatmentMenus) {
      findTreatMentMenu({clinic_id: clinicId, has_frame_repeat: 1}).then((res: any) => {
        if (res?.data) {
          res?.data.map((tm: TreatmentMenu) => {
            if (tm.id != null) {
              tmIds.push(tm.id);
            }
          })
        }
      })
    } else {
      tmIds.push(treatmentMenu?.id as number);
      if (treatmentMenu?.treatment_menu_name) {
        setDt044TreatmentMenuName(treatmentMenu?.treatment_menu_name)
      }
    }
    setSelectedTreatmentIds(tmIds);
    setSelectedTime(time);
    }

    const handleReRenderTable = () => {
        setDataSource(genDataSource());
    }

    const handleCheckDisableButtonCloseTime = (rsvStartTime:string, inTreatMentMenu:boolean, treatmentMenu:TreatmentMenu|undefined = undefined) => {
        if (!inTreatMentMenu) return false;
        const elementInCloseTime = closedTimeList.filter(e => e.treatment_menu_id === treatmentMenu?.id)
                                .filter(e => (e.closed_start_time <= rsvStartTime) && (rsvStartTime < e.closed_end_time) );
        return elementInCloseTime.length > 0;
    }

    const dataReservationFrameRepeat = useReservationFrameRepeatQuery({
        clinic_id: clinicId,
        date: selectedDate.replaceAll('-', ''),
        clinic_user_id: selectedClinicUserId > 0 ? selectedClinicUserId : null,
        treatment_menu_id: selectedTreatmentMenuId > 0 ? selectedTreatmentMenuId : null
    });
    const isFetching = dataReservationFrameRepeat.isFetching;

    useEffect(() => {
        if (!isTabActive) return;
        setReservationFrameRepeat(dataReservationFrameRepeat?.data??[]);
    },[dataReservationFrameRepeat?.data]);

    useEffect(() => {
        setShowSideBar(false);
        setShowDT044(false);
        setShowDT047(false);
        setDisablePastDay(dayjs(selectedDate).isBefore(dayjs(), 'day'));
    }, [selectedDate]);

    useEffect(() => { 
        setTreatMentMenus(genTreatMentMenus());
    }, [reservationFrameRepeat]);

    useEffect(() => {
        handleReRenderTable();
    }, [treatMentMenus, closedTimeList, reservationFrameRepeat]);

    useEffect(() => {
        if (!isTabActive) return;
        dataReservationFrameRepeat?.refetch();
        handleReRenderTable();
        }, [
        isChecked,
        selectedClinicUserId,
        selectedTreatmentMenuId,
        selectedDate,
        isTabActive
    ]);
    
    useEffect(()=>{
      handleGetClosedTime();
    },[])
    useEffect(()=>{
        if (!isTabActive) return;
        handleGetClosedTime();
    },[selectedDate, isTabActive])

    const columns = [
        ...dataTreatMentMenus,
        {
            key: 'newTreatMentMenu',
            title: t('schedule.reservation-setting.button-new')
        },
        ...new Array(dataTreatMentMenus.length < 6 ? 6 - dataTreatMentMenus.length : 0).fill(undefined)
    ]

    const renderTreatMentMenus = (halfTime:String) => {
        const result:TreatMentMenus[] = new Array(treatMentMenus.length);
        treatMentMenus.forEach((treatMentMenu, index) => {
            treatMentMenu.reservationFrameRepeat.forEach(e => {
                if (!e.rsv_start_time || !e.rsv_end_time) return[];
                if (e.rsv_start_time === halfTime)
                    result[index] = 
                        {
                            treatmentMenuId: treatMentMenu.treatmentMenuId,
                            reservationFrameRepeat: [e]
                        };
                else if (e.rsv_end_time >= halfTime && e.rsv_start_time < halfTime)
                    result[index] = 
                    {
                        treatmentMenuId: treatMentMenu.treatmentMenuId,
                        reservationFrameRepeat: [e]
                    };
            })
        })
        return result;
    }

    const genDataSource = () => {
        const datas: DataType[] = [];
        let i = 0;
        let HOURS_RANGE = HOURS_IN_DAY;
        if (!isChecked && reservationFrameRepeat.length > 0) {
            const rangeTime = calculateRangeTimeOrdered(reservationFrameRepeat, closedTimeList);
            HOURS_RANGE = HOURS_IN_DAY.slice(rangeTime.timeStart, rangeTime.timeEnd + 1);
        }
        let minuteArr: string[];
        if (exmTime == EXM_TIME.HALF_AN_HOURS) {
            minuteArr = HALF_AN_HOURS;
        } else {
            minuteArr = QUARTER_AN_HOURS;
        }
        for (const hour of HOURS_RANGE) {
            minuteArr.forEach((halfTime) => {
                const data: DataType = {
                    key: i.toString(),
                    time: hour,
                    halfTime: hour + halfTime,
                    detail: {
                      treatMentMenus: renderTreatMentMenus(hour + halfTime),
                      time: hour + halfTime
                    }
                }
                datas.push(data);
                i++;
            })
        }
        return datas;
    }

    const genTreatMentMenus = () => {
        const datas:TreatMentMenus[] = [];

        reservationFrameRepeat.forEach(rsv => {
            const existingItem = datas.find(data => data.treatmentMenuId === rsv.treatment_menu_id);
            if (existingItem) {
                existingItem.reservationFrameRepeat.push(rsv);
            } else {
                datas.push({
                    treatmentMenuId: rsv.treatment_menu_id,
                    reservationFrameRepeat: [rsv],
                })
            }
        });

        return  datas;
    }

  const getClosedTimeRecord = (time: string, treatmentMenuId: number): ClosedTime[] => {
    let result: ClosedTime[] = [];
    closedTimeList.map((ct) => {
      if (treatmentMenuId < 0 && ct.closed_start_time === time) {
        result.push(ct);
      } else if (ct.closed_start_time === time && ct.treatment_menu_id === treatmentMenuId) {
        result.push(ct);
      }
    })

    return result;
  }
  
      
    const calculateHeight = (startTime:string, endTime: string) => {
      const numberOfTds = dayjs(endTime, "HHmm").diff(dayjs(startTime, "HHmm"), "minute") / exmTime;
      return 'calc('+  numberOfTds * 100 + '% + ' + numberOfTds + 'px )';
    }

    const renderButtonReservation = (rsvStartTime:string, inTreatMentMenu:boolean, treatmentMenu:TreatmentMenu|undefined = undefined) => {
        const items = renderItemButton(rsvStartTime, inTreatMentMenu, treatmentMenu);
        const disableButtonCloseTime = handleCheckDisableButtonCloseTime(rsvStartTime, inTreatMentMenu, treatmentMenu);
        const dropdownRef = useRef(null);
        return {
            props: { className: '' + ((disablePastDay || disableButtonCloseTime) && 'buttonDisablePastDay') },
            children: (
                <div>
                    <Dropdown 
                        menu={{ items }} 
                        trigger={['click']} 
                        disabled={disablePastDay || disableButtonCloseTime}
                        getPopupContainer={() => dropdownRef.current || document.body}
                        overlayStyle={{
                            width: 130
                        }}
                    >
                    {
                        disableButtonCloseTime ?
                        <Tooltip 
                            placement='right' 
                            title={t('schedule.reservation-setting.tooltip.notice')}
                            className='disablePastDay'
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <img src={Plus} alt="" />
                            </a>
                        </Tooltip>
                        :
                        <a onClick={(e) => e.preventDefault()}>
                            <img src={Plus} alt="" />
                        </a>
                    }
                    </Dropdown>
                    <div ref={dropdownRef}></div>
                </div>
            )
            }
    }

    const renderItemButton = (rsvStartTime:string, inTreatMentMenu=false, treatmentMenu:TreatmentMenu|undefined = undefined) => {
        const itemsNewOrder: MenuProps['items'] = [
            {
                label: <span onClick={() => handleAddReservation(rsvStartTime , inTreatMentMenu, treatmentMenu)}>{t("schedule.reservation-setting.button-order")}</span>,
                key: '0',
            },
            {
                type: 'divider',
            },
            {
                label: <span onClick={() => handleClosedHours(rsvStartTime, !inTreatMentMenu, treatmentMenu)}>{t("schedule.reservation-setting.button-order-holiday")}</span>,
                key: '1',
            },
        ];
        return itemsNewOrder;
    }
    
    const genTextClosedTime = (startTime: string, endTime: string) => {
      return `休診 ${dayjs(startTime, "HHmm").format("HH:mm")}~ ${dayjs(endTime, "HHmm").format("HH:mm")} `
    }


    const renderContentData = (detail: {treatMentMenus:TreatMentMenus[], time: string } , index: number) => {
      const closeTime = getClosedTimeRecord(detail.time, treatMentMenus[index].treatmentMenuId || -1);
      let elements: JSX.Element[] = [];
        for (const ct of closeTime) {
          if (ct.closed_start_time === detail.time) {
            elements.push(
                <div tabIndex={0} 
                    onClick={()=>{
                        if (disablePastDay) return;
                        handleShowUpdateClosedTime(ct);
                    }}
                    onKeyDown={(e) => {
                        if (disablePastDay) return;
                        if (e.code === "Enter") {
                            handleShowUpdateClosedTime(ct)
                        }
                     }}
                    className={`displayClosedTime ` + (disablePastDay && 'disablePastDay')} 
                    style={{ height: calculateHeight(ct.closed_start_time, ct.closed_end_time)}}>
                    {genTextClosedTime(ct.closed_start_time,ct.closed_end_time)}
                </div>
                )
          }
        }
      if (detail.treatMentMenus.length === 0 || !detail.treatMentMenus[index]) {
        return elements;
      }
      
        const data:ReservationFrameRepeat[] = detail.treatMentMenus[index]?.reservationFrameRepeat;
      if (data[0].rsv_start_time !== detail.time){
        return elements;
      }

        const titleOffline = t('schedule.reservation-setting.treatmentmenu.title-offline.title');
        let labelOffline;
        if (data[0].face_to_face_first_exm === 1) {
            labelOffline = data[0].face_to_face_re_exm ? t('schedule.reservation-setting.treatmentmenu.title-offline.label-1') : t('schedule.reservation-setting.treatmentmenu.title-offline.label-2');
        } else {
            labelOffline = data[0].face_to_face_re_exm ? t('schedule.reservation-setting.treatmentmenu.title-offline.label-3'): '';
        }

        const titleOnline = t('schedule.reservation-setting.treatmentmenu.title-online.title');
        let labelOnline;
        if (data[0].online_first_exm === 1) {
            labelOnline = data[0].online_re_exm ? t('schedule.reservation-setting.treatmentmenu.title-online.label-1') : t('schedule.reservation-setting.treatmentmenu.title-online.label-2');
        } else {
            labelOnline = data[0].online_re_exm ? t('schedule.reservation-setting.treatmentmenu.title-online.label-3'): '';
        }

        let repeatType = t('schedule.reservation-setting.treatmentmenu.repeat-type.type-0');
        if (data[0].repeat_type === 1) {
            repeatType = t('schedule.reservation-setting.treatmentmenu.repeat-type.type-1');
        } else if (data[0].repeat_type === 2) {
            repeatType = t('schedule.reservation-setting.treatmentmenu.repeat-type.type-2');
        }

        const rsvEndYmd = data[0].rsv_end_ymd === '21001231' ? t('schedule.reservation-setting.treatmentmenu.rsv-end-ymd.content-1') : data[0].rsv_end_ymd + t('schedule.reservation-setting.treatmentmenu.rsv-end-ymd.content-2');
        const isPublicOnHoliday = data[0].is_public_on_holiday === 1 ? t('schedule.reservation-setting.treatmentmenu.is-public-on-holiday') : null;
        let reservFrameRepeat = t('schedule.reservation-setting.treatmentmenu.reservation-frame-repeat');
        if (data[0].upper_limit_frame) reservFrameRepeat += data[0].upper_limit_frame;
        elements.push(
            <div 
                onClick={() => {
                    if(disablePastDay) return;
                    handleEditReservation(data[0]);
                }}
                className={`cellTreatMentMenuOrderedOver displayRsvRepeatFrame ` + (disablePastDay && 'disablePastDay')} 
                style={{height: calculateHeight(data[0].rsv_start_time|| '', data[0].rsv_end_time || '')}}
                >
                <Row gutter={[{}, { xs: 12, sm: 12, md: 12 }]}>
                    <Col xs={24} sm={24} md={24} className="text-bold-styles grayTextTitle">
                        <Row gutter={[{ xs: 10, sm: 10, md: 10 }, {}]}>
                            {
                                labelOffline &&
                                <Col xs={12} sm={12} md={12}>
                                    <div className="exmMethod">
                                        <span className="label labelOffline">{titleOffline}</span>
                                        <span>{labelOffline}</span>
                                    </div>
                                </Col>
                            }
                            {
                                labelOnline &&
                                <Col xs={12} sm={12} md={12}>
                                    <div className="exmMethod">
                                        <span className="label labelOnline">{titleOnline}</span>
                                        <span>{labelOnline}</span>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} className="text-bold-styles grayTextTitle">
                        {formatDisplayTime(data[0].rsv_start_time)}～{formatDisplayTime(data[0].rsv_end_time)}
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                        <div>{repeatType}</div>
                        <div>{(rsvEndYmd === t('schedule.reservation-setting.treatmentmenu.rsv-end-ymd.content-1')) ? rsvEndYmd : formatDisplayYmd(rsvEndYmd)}</div>
                        {
                            isPublicOnHoliday &&
                            <div>{isPublicOnHoliday}</div>
                        }
                        <div>{reservFrameRepeat}</div>
                    </Col>
                </Row>
            </div>)

        return elements;
    }
    
    const handleShowUpdateClosedTime = (ct: ClosedTime) => {
      if (!isFutureDate(selectedDate)) return;
      setSelectedClosedTime(ct);
      setShowDT044(false);
      setShowSideBar(false);
      setShowDT047(true);
  }

    return (
        <div className="frameList">
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBorderRadius: 0
                        },
                    },
                }}
                >
                <Table 
                    dataSource={dataSource} 
                    pagination={false} 
                    bordered
                    className={"tableReservationSetting text-normal-styles " + ((showSideBar || showDT044 || showDT047 )&& "tableReservationSettingHSB") }
                    scroll={{ y: '63vh' }}
                >
                    {renderColumnHour(true, exmTime)}

                    <Column title={renderTitleText('一括')} dataIndex="halfTime" key="halfTime" render={(time) => renderButtonReservation(time, false)} width={60}/>                    
                    <Column title='' dataIndex="halfTime" key="halfTime" width={80} render={(data) => formatDisplayTime(data)}/>
                    {
                        columns.map((value, index) => {
                            if (value?.key === 'newTreatMentMenu')
                                return (
                                    <Column title={renderTitleAdd(value?.title, handleNewTreatMentMenu, disablePastDay)} dataIndex={value?.key} key={index} width={250} onCell={renderColumnsEmpty}/>
                                )
                            else if (value?.key === 'treatMentMenus') {
                                if (!value?.treatMentMenu) return;
                                return (
                                        <Fragment key={index}>
                                            <Column 
                                                title={renderTitleText(value?.title)} dataIndex={"detail"} key={index}
                                                render={(data) => renderContentData(data, index)}
                                                width={250}
                                                />
                                            <Column title={<TitleIcon treatMentMenu={value?.treatMentMenu} />} dataIndex="halfTime" key={index} render={(time) => renderButtonReservation(time, true, value?.treatMentMenu)} width={60}/>
                                        </Fragment>
                                )
                            }
                            else
                                return (
                                    <Column title={renderTitleText(value?.title)} dataIndex={value?.key} key={index} width={250} onCell={renderColumnsEmpty}/>
                            )
                        })
                    }
                    
                    {renderColumnHour(false, exmTime)}
                </Table>
            </ConfigProvider>
            {
                showSideBar &&
                <SideBarReservation
                    exmTime={exmTime}
                    onClose={() => {setShowSideBar(false)}}
                    isDt0402Screen={isDt0402Screen}
                    treatMentMenuList={treatMentMenuList}
                    reservationFrameRepeat={reservationFrameRepeatSelected}
                    setVisibleSuccess={setVisibleSuccess}
                    setVisibleDeleteSuccess={setVisibleDeleteSuccess}
                    isFrameRepeatDetail={isFrameRepeatDetail}
                    // userIdList={userIdList}
                    // setUserIdList={setUserIdList}
                    selectedDate={selectedDate}
                />
            }
            {(isLoading || isFetching) && <Loading />}
          {showDT044 &&
          <AddCloseTime treatmentMenuIds={selectedTreatmentIds} selectedDate={selectedDate}
                        onClose={() => setShowDT044(false)} isApplyAllTreatmentMenu={isDT044ApplyAllTreatmentMenus} 
                        treatmentMenuName={dt044TreatmentMenuName} 
                        onAddFinish= {() => {handleGetClosedTime(); setShowDT044(false);}}
                        exmTime={exmTime}
                        initialTime={selectedTime || "0000"}
          />
          }
          {showDT047 &&
          <UpdateClosedTime closedTimeData={selectedClosedTime} 
                            onUpdateFinish= {() =>{handleGetClosedTime(); setShowDT047(false);}} 
                            onClose={() => setShowDT047(false)}
                            exmTime={exmTime}
          />
          }
          {
                visibleSuccess &&
                <ModalWarning
                    message={t("message.MESS_INF_RSV_FRAME_UPDATE_SUCCESS")}
                    typeModal={MODAL_SUCCESS}
                    typeButton={BUTTON_CLOSE}
                    changeVisibleModal={() => {
                        setVisibleSuccess(false)
                    }}
                />
            }
                        {
                visibleDeleteSuccess &&
                <ModalWarning
                    message={t("message.MESS_INF_RSV_FRAME_DELETE_SUCCESS")}
                    typeModal={MODAL_SUCCESS}
                    typeButton={BUTTON_CLOSE}
                    changeVisibleModal={() => {
                        setVisibleDeleteSuccess(false)
                    }}
                />
            }
        </div>
    )
}

export default ReservationSetting;
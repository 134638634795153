import Prescription from 'src/Component/Prescription';

const RegisterAddressManual = (props: any) => {
    const {
        prefList,
        cityList,
        methodReceive,
        setVisibleSuccess,
        setIsModalOpenNewPharmacy,
        prescriptionPharmacy,
        setIsModalOpenEditPharmacy,
        setCityList
    } = props;

    return (
        <Prescription
            prefList={prefList}
            cityList={cityList}
            methodReceive={methodReceive}
            setVisibleSuccess={setVisibleSuccess}
            setIsModalOpenNewPharmacy={setIsModalOpenNewPharmacy}
            prescriptionPharmacy={prescriptionPharmacy}
            setIsModalOpenEditPharmacy={setIsModalOpenEditPharmacy}
            setCityList={setCityList}
        />
    )
}

export default RegisterAddressManual;
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import i18n from 'src/i18n/i18n';
import * as yup from 'yup';

export const formKey = ['title', 'non_insurance_amounts'];

export const AgreementScheme = yup.object().shape({
  title: yup
    .string()
    .required(
      i18n.t('input-required', {
        inputName: i18n.t('reExamCode.titleHospitalDisplay')
      })
    )
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 100) {
          return ctx.createError({
            message: i18n.t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.titleHospitalDisplay'),
              value: 100
            })
          });
        }
        return true;
      }
    }),
  non_insurance_amounts: yup.array().of(
    yup.object().shape({
      amount_description: yup
        .string()
        .required(
          i18n.t('input-required', {
            inputName: i18n.t('reExamCode.explainNumber')
          })
        )
        .test({
          name: 'check-length',
          skipAbsent: true,
          test(value, ctx) {
            if (lengthFullAndHalfWidth(value) > 100) {
              return ctx.createError({
                message: i18n.t('inputErrorMaxLength', {
                  inputName: i18n.t('reExamCode.explainNumber'),
                  value: 100
                })
              });
            }
            return true;
          }
        }),
      amount: yup
        .number()
        .required(
          i18n.t('input-required', {
            inputName: i18n.t('reExamCode.amountPrice')
          })
        )
        .max(
          2147483647,
          i18n.t('inputErrorMaxValue', {
            inputName: i18n.t('reExamCode.amountPrice'),
            value: 2147483647
          })
        )
    })
  )
});

export type FormData = yup.InferType<typeof AgreementScheme>;

export const DEFAULT_TITLE_FOR_PATIENT = i18n.t(
  'reExamCode.insuranceAgreement'
);

export const DEFALT_AMOUNT_DES = i18n.t('reExamCode.defaultAmountDes');

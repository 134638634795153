import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../Redux/store';
import { revertAll } from 'src/Redux/hooks';
const initialState = {
  radioMedicalDetailSelected: 1,
}

const slice = createSlice({
  name: "medicalDetailReceiveSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeMedicalDetailRadioSelected: (state, action) => {
      state.radioMedicalDetailSelected = action.payload;
    },
  }
});

export const selectMedicalDetailRadioSelected = (state:RootState) => state.common[slice.name].radioMedicalDetailSelected;

export const {
  changeMedicalDetailRadioSelected
} = slice.actions;

export const MedicalDetailReceiveReducer = slice.reducer;
import TabsSetting from 'src/Component/Doctor/TabsSetting';

const Setting = () => {
  return (
    <div>
      <TabsSetting />
    </div>
  );
};

export default Setting;

const updateLink = (dataInfomation: any) => {
    const youtubeLink = 'https://www.youtube.com/embed/';
    const vimeoLink = 'https://player.vimeo.com/video/';

    let link = '' as Array<any> | string;

    if (dataInfomation?.mov_link1 && dataInfomation?.mov_link1 !== '') {
        const type1 = dataInfomation?.mov_type1;
        if (type1 === 1) {
            link = vimeoLink + dataInfomation?.mov_link1;
        } else if (type1 === 2) {
            link = youtubeLink + dataInfomation?.mov_link1;
        }
    } else if (dataInfomation?.mov_link2 && dataInfomation?.mov_link2 !== '') {
        const type2 = dataInfomation?.mov_type2;
        if (type2 === 1) {
            link = vimeoLink + dataInfomation?.mov_link2;
        } else if (type2 === 2) {
            link = youtubeLink + dataInfomation?.mov_link2;
        }
    } else if (dataInfomation?.highlight_video_url1 && dataInfomation?.highlight_video_url1 !== '') {
        const highlightType1 = dataInfomation?.highlight_video_type1;
        if (highlightType1 === 1) {
            link = vimeoLink + dataInfomation?.highlight_video_url1;
        } else if (highlightType1 === 2) {
            link = youtubeLink + dataInfomation?.highlight_video_url1;
        }
    } else {
        if (dataInfomation?.image1 && dataInfomation?.image1 !== '') {
            // link = [process.env.PUBLIC_URL + '/images/' + dataInfomation?.image1,
            //         process.env.PUBLIC_URL + '/images/' + dataInfomation?.image2,
            //         process.env.PUBLIC_URL + '/images/' + dataInfomation?.image3,
            //         process.env.PUBLIC_URL + '/images/' + dataInfomation?.image4,
            //         process.env.PUBLIC_URL + '/images/' + dataInfomation?.image5].filter(item => item !== null) as any[];
            link = process.env.PUBLIC_URL + '/images/' + dataInfomation?.image1;
        } else {
            link = process.env.PUBLIC_URL + '/images/sample.jpg';
        }
    }

    return link;
};

export { updateLink };
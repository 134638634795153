const generateTableData = (dataInfomation: any) => {
    const dataInfomationLength = dataInfomation?.open_infos.length;
    const result = [];

    for (let i = 0; i < dataInfomationLength; i++) {
        const x = [];

        const hoursBoolean = dataInfomation?.open_infos[i];

        if (hoursBoolean?.monday !== '-' && hoursBoolean?.monday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.tuesday !== '-' && hoursBoolean?.tuesday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.wednesday !== '-' && hoursBoolean?.wednesday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.thursday !== '-' && hoursBoolean?.thursday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.friday !== '-' && hoursBoolean?.friday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.saturday !== '-' && hoursBoolean?.saturday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.sunday !== '-' && hoursBoolean?.sunday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }

        if (hoursBoolean?.holiday !== '-' && hoursBoolean?.holiday !== 'ー') {
            x.push(false);
        } else {
            x.push(true);
        }
        x.push(hoursBoolean?.start_time.substring(0, 5));
        x.push(hoursBoolean?.end_time.substring(0, 5));

        result.push(x);
    }
    return result;
};

export default generateTableData;
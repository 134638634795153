import React from 'react';

const ErrorPage = () => {
  return (
    <>
      <div>
        <h1>Look like you are lost</h1>
        <span>the page you are looking for not available!</span>
      </div>
    </>
  );
};

export default ErrorPage;

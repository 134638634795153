import RadioGroupForm from './formItem/RadioGroupForm';
import TextAreaForm from './formItem/TextAreaForm';
import { ReactComponent as CheckCircleIcon } from '../../../../../../assets/image/check_circle.svg';
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ReactComponent as InforOutlineIcon } from '../../../../../../assets/image/info_outline.svg';
import { Col, Row } from 'antd';
import InputNumberForm from './formItem/InputNumberForm';
import { Trans, useTranslation } from 'react-i18next';
import {
  DEFAULT_NOT_SETTING_FORM,
  FormData,
  MAX_CONSULT_TIME,
  MAX_RSV_FEE,
  MIN_CONSULT_TIME,
  MIN_RSV_FEE,
  enterReExamCode,
  rsvAcceptancePediodType,
  unapprovedDevicesDrugs
} from '../constants';
import SelectAcceptTimeForm from './formItem/SelectAcceptTimeForm';
import InputSelectForm, { OptionType } from './formItem/InputSelectForm';
import { useGetListConsentFormQuery } from 'src/Network/Home/DoctorSettingApi';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';

type Props = {
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
};

const ScheduleOnlineForm: React.FC<Props> = ({ errors, watch, setValue }) => {
  const { t } = useTranslation();
  const exam_method = watch('exm_method');
  const clinicId = getClinicIdFromLocalStorage();
  const { data: consentFormData } = useGetListConsentFormQuery(clinicId, {
    skip: clinicId < 0
  });
  const rsv_acceptance_pediod_type = watch('rsv_acceptance_pediod_type');
  const reservation_frame_public = watch('reservation_frame_public');
  const unapproved_devices_drugs = watch('unapproved_devices_drugs');

  const consentFormOption = consentFormData
    ? consentFormData.map((item) => ({ value: item.id, label: item.title }))
    : [];
  const selectConsentFormOpt: OptionType[] = [
    { value: 0, label: DEFAULT_NOT_SETTING_FORM },
    ...consentFormOption
  ];

  const consultTimeOption = [
    {
      name: 'consul_time_first_exm',
      titleBefore: t('reExamCode.firstExam'),
      unitName: t('reExamCode.minute'),
      placeholder: t('reExamCode.firstExam'),
      inputProps: {
        max: MAX_CONSULT_TIME,
        min: MIN_CONSULT_TIME,
        onKeyDown: (event: any) => {
          const regex = /^[0-9.]+$/;
          if (event.key === 'Backspace') return;
          if (event.key === 'Tab') return;
          if (event.key === 'Delete') return;
          if (!regex.test(event.key)) {
            return setValue('consul_time_first_exm', 1);
          }
        }
      }
    },
    {
      name: 'consul_time_re_exm',
      titleBefore: t('reExamCode.reExam'),
      unitName: t('reExamCode.minute'),
      placeholder: t('reExamCode.reExam'),
      inputProps: {
        max: MAX_CONSULT_TIME,
        min: MIN_CONSULT_TIME,
        onKeyDown: (event: any) => {
          const regex = /^[0-9.]+$/;
          if (event.key === 'Backspace') return;
          if (event.key === 'Tab') return;
          if (event.key === 'Delete') return;
          if (!regex.test(event.key)) {
            return setValue('consul_time_re_exm', 1);
          }
        }
      }
    }
  ];

  return (
    <div className="medical-menu-form_separate-container">
      <div className="medical-menu-form_separate-header">
        <p>{t('reExamCode.setupRsvBookingOnline')}</p>
        <div className="medical-menu-form_separate_public-status_wrapper">
          <div className="medical-menu-form_separate_public-status-item mr-32">
            <CheckCircleIcon />
            <span>{t('reExamCode.hospistal')}</span>
          </div>
          {reservation_frame_public === 1 && (
            <div className="medical-menu-form_separate_public-status-item">
              <CheckCircleIcon />
              <span>{t('reExamCode.patient')}</span>
            </div>
          )}
        </div>
      </div>

      <div className="medical-menu-form_separate-content_wrapper">
        {[0, 2].includes(exam_method) ? (
          <>
            <RadioGroupForm
              inputTitle={t('reExamCode.enterReExamCode')}
              name="first_exm_flg"
              options={enterReExamCode}
              defaultValue={0}
            />
            <Row>
              <Col xl={11} sm={15}>
                <InputNumberForm
                  options={consultTimeOption}
                  titleInput={t('reExamCode.rsvTime')}
                />
              </Col>
              <Col span={2} />
              <Col xl={11} sm={7}>
                <InputNumberForm
                  options={[
                    {
                      name: 'rsv_fee',
                      unitName: t('reExamCode.yenUnit'),
                      inputProps: {
                        max: MAX_RSV_FEE,
                        min: MIN_RSV_FEE,
                        onKeyDown: (event: any) => {
                          const regex = /^[0-9.]+$/;
                          if (event.key === 'Backspace') return;
                          if (event.key === 'Tab') return;
                          if (event.key === 'Delete') return;
                          if (!regex.test(event.key)) {
                            return setValue('rsv_fee', 1);
                          }
                        }
                      }
                    }
                  ]}
                  titleInput={t('reExamCode.rsvFee')}
                  inputTitleNote={t('reExamCode.includeTax')}
                />
              </Col>
            </Row>
            <TextAreaForm
              name="introduction"
              errors={errors}
              placeholder={t('reExamCode.introduction')}
              titleInput={t('reExamCode.introduction')}
              watch={watch}
            />
            <div className="divider" />
            <InputNumberForm
              titleInput={t('reExamCode.rsvTime')}
              options={[
                {
                  name: 'consul_hours_pt',
                  unitName: t('reExamCode.aboutMinute'),
                  placeholder: t('reExamCode.consultTimePlaceholder'),
                  inputProps: {
                    max: MAX_CONSULT_TIME,
                    min: MIN_CONSULT_TIME,
                    onKeyDown: (event: any) => {
                      const regex = /^[0-9.]+$/;
                      if (event.key === 'Backspace') return;
                      if (event.key === 'Tab') return;
                      if (event.key === 'Delete') return;
                      if (!regex.test(event.key)) {
                        return setValue('consul_hours_pt', 1);
                      }
                    }
                  }
                }
              ]}
              inputTitleNote={t('reExamCode.toDisplayPatient')}
            />
            <TextAreaForm
              name="risk_side_effects"
              errors={errors}
              placeholder={t('reExamCode.riskSideEffectsPlaceholder')}
              titleInput={t('reExamCode.riskSideEffects')}
              watch={watch}
            />
            <RadioGroupForm
              inputTitle={t('reExamCode.unapprovedDevicesDrugs')}
              name="unapproved_devices_drugs"
              options={unapprovedDevicesDrugs}
              inputNote={t('reExamCode.unapprovedDevicesDrugsNote')}
            />
            {unapproved_devices_drugs === 1 && (
              <p className="not-public-medical-menu_note">
                <Trans
                  i18nKey={'reExamCode.ableTouseNote'}
                  components={{
                    infoIcon: <InforOutlineIcon />,
                    div: <div className="div-in-able-touse-note" />,
                    br: <br />,
                    span: <span />
                  }}
                />
              </p>
            )}
            <TextAreaForm
              name="note"
              errors={errors}
              titleInput={t('reExamCode.noteTitle')}
              watch={watch}
            />
            <RadioGroupForm
              inputTitle={t('reExamCode.reservationAcceptTime')}
              name="rsv_acceptance_pediod_type"
              options={rsvAcceptancePediodType}
            />
            {rsv_acceptance_pediod_type === 0 && (
              <SelectAcceptTimeForm
                inputNames={{
                  firstFrom: 'rsv_acceptance_pediod_first_exm_from',
                  firstTo: 'rsv_acceptance_pediod_first_exm_to',
                  reFrom: 'rsv_acceptance_pediod_re_exm_from',
                  reTo: 'rsv_acceptance_pediod_re_exm_to'
                }}
              />
            )}
            <InputSelectForm
              defaultValue={DEFAULT_NOT_SETTING_FORM}
              name="consent_form_id"
              options={selectConsentFormOpt}
              titleInput={t('reExamCode.consentForm')}
            />
          </>
        ) : (
          <p>{t('reExamCode.hideMedicalMenuFormNote')}</p>
        )}
      </div>
    </div>
  );
};

export default ScheduleOnlineForm;

import "./styles.css";
import Register from "../../Register";
import Login from "src/Screen/PT-04/Login";
import Menu from 'src/assets/image/menu.svg';
import CardHeaderMenu from 'src/Component/CardHeaderMenu';
import { changeModalLogin, toggleHeaderDropdown } from "src/Component/Header/slice";
import { closeAllModals } from "src/Component/Header/slice";
import { useEffect } from "react";
import { useAppDispatch } from "src/Redux/hooks";

const LoggedOut = () => {
  const dispatch = useAppDispatch();
  const toggleDropdownMenu = () => {
    dispatch(toggleHeaderDropdown(true));
  }
  const isLogin = localStorage.getItem("isLogin");
  useEffect(() => {
    //Hide when refresh page
    dispatch(closeAllModals())
    if (isLogin) {
      dispatch(changeModalLogin({
        show:true
      }));
      localStorage.removeItem("isLogin");
    } else {
      dispatch(changeModalLogin({
        show:false
      }));
    }
  }, [])

  return (
    <div className="userLoggedOut">
      <div className="spreadMenu">
        <CardHeaderMenu />
      </div>
      <div>
        <Register />
        <Login />
      </div>
      <div className="dropDownMenu-icon user-logged-out-menu">
        <img src={Menu} alt='' onClick={toggleDropdownMenu}/>
      </div>
    </div>
  );
};

export default LoggedOut;

import './styles.css';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Col, Row, Input } from 'antd';
import MailOutlined from 'src/assets/image/mail.svg';
import { useTranslation } from "react-i18next";
import ArrowLeft from 'src/assets/image/arrow-left.svg';
import { useAppSelector } from 'src/Redux/hooks';
import { changeForgetPassword, selectIsForgetPassword } from 'src/Component/Header/slice';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegExp } from 'src/constant';
import { Link, useSearchParams } from 'react-router-dom';
import FormInput from 'src/Component/common/form/FormInput';
import { User } from 'src/Network/Model/User';
import { useResetPasswordMutation } from 'src/Network/Home/HomeClinicApi';
import Loading from '../Loading';
import { replaceWithBr } from 'src/Utils/textUtils';

const ForgetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const clinic_id = searchParams.get("clinic_id");
  const showModalForgetPass = useAppSelector(selectIsForgetPassword);
  const [isDisabled, setIsDisabled] = useState(true);
  const [resetPassword] = useResetPasswordMutation();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const hideModal = () => {
    dispatch(changeForgetPassword(false));
    setSuccess(false);
    reset();
    setMessage("");
  };
  const onSubmit = (data: User) => {
    const params = {
      mailaddress: data.mailaddress,
      clinic_id: clinic_id,
      redirect_url: window.location.href
    }
    setIsLoading(true);
    resetPassword(params).then((res:any)=>{
      if(!res?.data?.error){
        setSuccess(true);
        // hideModal();
      }
      setMessage(res?.data?.message);
      setIsLoading(false);
    })
  }
  const defaultValues = {
    mailaddress: ""
  };
  const schema = yup.object().shape({
    mailaddress: yup
      .string()
      .required(
        t("register.input-required").replace("{0}", t("register.mailaddress"))
      )
      .matches(RegExp, t("register.email-invalid"))
  });
  type FormData = yup.InferType<typeof schema>;
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { watch, reset } = methods;
  const mailaddress = watch("mailaddress");
  useEffect(() => {
    if (mailaddress && RegExp.test(mailaddress)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [mailaddress]);

  return (
    <div>
      <Modal maskClosable={false} title closeIcon={null} centered open={showModalForgetPass} footer={null} zIndex={998} className='modalPt04_1'>
        <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
          <Col span={24}>
            <div className='headerModal'>
              <span className='headerModal-title text-bold-styles'>{t("pt04.modal-forgot-password.title")}</span>
              <span>
                <Button type='text' icon={<CloseOutlined />} onClick={hideModal} style={{ color: '#878E9B' }}></Button>
              </span>
            </div>
          </Col>
          <Col span={24}>
          <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Col span={24} className='bodyModal'>
                  <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 32, sm: 32, md: 32 }]} style={{ paddingBottom: 24 }}>
                    {!success ? <>
                    <Col xs={24} sm={24}  md={24} className='text-normal-styles' style={{ textAlign: 'center' }}>
                      {t("pt04.modal-forgot-password.body-content")}
                    </Col>
                    <Col xs={24} sm={24}  md={24}>
                      <FormInput
                        name="mailaddress"
                        inputProps={{
                          size: 'large',
                          placeholder: t("pt04.modal-forgot-password.body-input-placeholder"),
                          prefix: <img src={MailOutlined} alt='' />,
                          className: 'bodyModal-input',
                        }}
                      />
                      <span className='messErr'>{message}</span>
                    </Col>
                    </>
                    :
                    <Col xs={24} sm={24} md={24} style={{textAlign: 'center'}}>
                      <span dangerouslySetInnerHTML={replaceWithBr(message)}></span>
                    </Col>
                    }
                  </Row>
                </Col>
                <Col span={24} className='footerModal' style={{ textAlign: 'center' }}>
                  <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 16, sm: 16, md: 20 }]}>
                    {!success &&
                    <Col xs={24} sm={24} md={24}>
                      <Input type='submit' value={t("pt04.modal-forgot-password.button-send")} disabled={isDisabled} className={`buttonPt01-next text-bold-styles ${isDisabled && 'buttonPt01-next-disable'}`} />
                    </Col>
                    }
                    <Col xs={24} sm={24}  md={24}>
                      <Link to={process.env.REACT_APP_WP_URL || ''}>
                        <Button className="buttonPt04-cancel text-bold-styles">
                          <img src={ArrowLeft} alt="" style={{ marginRight: 12 }} />
                          {t("pt04.modal-forgot-password.button-home")}
                        </Button>
                      </Link>
                    </Col>
                  </Row> 
                </Col>
              </form>
          </FormProvider>
          </Col>
        </Row>
        {isLoading && <Loading />}
      </Modal>
    </div>
  )
}

export default ForgetPassword;
import star from 'src/assets/image/star.svg'
import star1 from 'src/assets/image/star1.svg'
import './styles.css'
import { Clinic } from 'src/Network/Model/Clinic'

const StarRating: React.FC<Clinic> = ({ clinic_name }) => {
  return (
    <div className="introduce-left">
      <div className="starrating">
        <div>
          <span className="text-bold-styles clinicName">{clinic_name}</span>
        </div>
        <div>
          <div className="rating" style={{ display: "none" }}>
            <div>
              <img src={star} alt=''/>
              <img src={star} alt=''/>
              <img src={star} alt=''/>
              <img src={star} alt=''/>
              <img src={star1} alt=''/>
            </div>
            <div>
              <span className="text-bold-styles">4.5</span>
            </div>
            <div>
              <span className="text-normal-styles">( 4件 )</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StarRating

import { Col, Row, Button, Table, Tag, Collapse, CollapseProps, ConfigProvider } from "antd";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { selectTab } from "../BookingContent/slice";
import { useTranslation } from "react-i18next";
import { Clinic } from "../../Network/Model/Clinic";
import { TreatmentMenu } from "../../Network/Model/TreatmentMenu";
import { dataTable, createTempDateData, tableColumn } from './BookingCalendarContainer';
import { replaceWithBr } from "src/Utils/textUtils";
import { useEffect, useState } from "react";
import { changeDoctorSelectionList, changeFilteredData, changeRadioIndex, changeUserIndex } from "../TimeBooking/slice";
import { changeDataHourInfo, changeDate, changeIsSelectedDate } from "../CalendarBooking/slice";
import dayjs from "dayjs";
import { t } from "i18next";
import { BUTTON_CLOSE, BUTTON_WARNING, MODAL_NEGATIVE, MODAL_WARNING } from "src/constant";
import ModalWarning from 'src/Component/Modal/ModalWarning';
import ModalEnterCode from 'src/Component/Modal/ModalQRCode/EnterCode';
import ModalScanQR from 'src/Component/Modal/ModalQRCode/ScanQR';
import { changeCurrentStep } from "../ArrowStepper/Slice/Slice";

type Props = {
  data: Clinic | undefined;
};

const BookingCalendar: React.FC<Props> = ({ data }) => {
  const treatment_menus = data?.treatment_menus;
  const items: CollapseProps["items"] = [];
  treatment_menus?.map((item: TreatmentMenu, index: any) => {
    items.push({
      key: index.toString(),
      label: item?.treatment_menu_name,
      children: (
        <BookingCalendarContent item={item} index={index} key={index} />
      ),
    });
  });

  const [isCollabType, setIsCollabType] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleScroll = () => {
      setIsCollabType(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleScroll);
    return () => window.removeEventListener("resize", handleScroll);
  }, [])

  return (
    <div>
      {treatment_menus?.length !== 0 && isCollabType ? (
        <Collapse
          accordion
          defaultActiveKey={["0"]}
          expandIconPosition="end"
          items={items}
        />
      ) : (
        treatment_menus?.map((item: TreatmentMenu, index: any) => (
          <BookingCalendarContent item={item} index={index} key={index} />
        ))
      )}
      {treatment_menus?.length === 0 && (
        <span className="treatment-menus-blank">{t("booking-introduce.treatment-menus-blank")}</span>
      )}
    </div>
  );
};

function ExmMethod({ exm_method }: any) {
  const { t } = useTranslation();
  if (exm_method === 0) {
    return (
      <Tag className="text-normal-styles pediatrics" bordered={false}>
        {t("booking-introduce.exm_method0")}
      </Tag>
    );
  } else if (exm_method === 1) {
    return (
      <Tag className="text-normal-styles pediatrics" bordered={false}>
        {t("booking-introduce.exm_method1")}
      </Tag>
    );
  } else if (exm_method === 2) {
    return (
      <span>
        <Tag className="text-normal-styles pediatrics" bordered={false}>
          {t("booking-introduce.exm_method0")}
        </Tag>
        <Tag className="text-normal-styles pediatrics" bordered={false}>
          {t("booking-introduce.exm_method1")}
        </Tag>
      </span>
    );
  }
  return <></>;
}
interface Inner {
  item: TreatmentMenu;
  index: any;
}
const BookingCalendarContent: React.FC<Inner> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const item = props.item;
  const { t } = useTranslation();
  const activeKey = useAppSelector(selectTab);
  const QRCodeValue = localStorage.getItem('qrCode');
  const checkQR = QRCodeValue?.length === 8 ? true : false;
  const [visibleScanQR, setVisibleScanQR] = useState(false);
  const [visibleEnterCode, setVisibleEnterCode] = useState(false);
  const [isScanner, setIsScanner] = useState(false);
  const [visibleWarning, setVisibleWarning] = useState(false);
  const changeVisibleModal = (
    enterCode = false,
    scanQR = false,
    isScanner = false
  ) => {
    setVisibleEnterCode(enterCode);
    setVisibleScanQR(scanQR);
    if (isScanner) setIsScanner(isScanner);
  };
  const [showWarningQR, setShowWarningQR] = useState(false);
  const getData = (item: TreatmentMenu) => {
    if (!item || !item?.week_data || item?.week_data.length <= 0) {
      return createTempDateData();
    }
    return item?.week_data;
  };

  let exm_classify = "";
  if (item?.treatment_div === 1) {
    exm_classify = t("booking-introduce.exm_classify0");
  } else if (item?.treatment_div === 0) {
    exm_classify = t("booking-introduce.exm_classify1");
  }

  const handleClearRSV = (first_exm_flg?: number, clinic_id?: number, id?: number) => {
    if (first_exm_flg === 1 && !checkQR) {
      setShowWarningQR(true);
    } else {
      dispatch(changeUserIndex(-1));
      dispatch(changeRadioIndex(-1));
      dispatch(changeDoctorSelectionList([]));
      dispatch(changeFilteredData([]));
      dispatch(changeDataHourInfo(null));
      dispatch(changeDate(dayjs()));
      dispatch(changeCurrentStep(0));
      navigate(`/reservation?clinic_id=${clinic_id}&treatmentmenu_id=${id}`);
      dispatch(changeIsSelectedDate(true));
    }
  }

  return (
    <div
      key={props.index}
      className="booking"
      id={`${props.index === 0 ? "bookFirst" : ""}`}
    >
      <Row className="headingRow">
        <Col
          xs={0}
          sm={0}
          md={10}
          className="text-bold-styles"
          style={{ fontSize: 17 }}
        >
          {item?.treatment_menu_name}
        </Col>
        <Col xs={0} sm={0} md={14} className="headingColReservation">
          <Button className="reservation-onl text-bold-styles"  onClick={() => handleClearRSV(item?.first_exm_flg, item.clinic_id, item.id)}>
            {t("reservation.text")}
          </Button>
        </Col>
      </Row>
      <Row className="pediatricsRow">
        <Col xs={24} sm={24} md={24}>
          <Tag className="pediatrics text-normal-styles" bordered={false}>
            {exm_classify}
          </Tag>
          {<ExmMethod exm_method={item?.exm_method} />}
        </Col>
      </Row>
      <Row
        className="introduceRow"
        gutter={[
          { xs: 24, sm: 24, md: 24, lg: 24, xl: 48 },
          { xs: 24, sm: 24, md: 24 },
        ]}
      >
        <Col xs={24} sm={24} md={12}>
          {((activeKey === "0" && item?.introduction) ||
            (activeKey === "1" && item?.f_introduction)) && (
            <div className="booking-introduce">
              <span className="text-bold-styles">
                {t("booking-introduce.title-intro")}
              </span>
            </div>
          )}
          {activeKey === "0" && item?.introduction && (
            <div className="introduction">
              <span className="text-normal-styles" dangerouslySetInnerHTML={replaceWithBr(item?.introduction)}></span>
            </div>
          )}
          {activeKey === "1" && item?.f_introduction && (
            <div className="introduction">
              <span className="text-normal-styles" dangerouslySetInnerHTML={replaceWithBr(item?.f_introduction)}></span>
            </div>
          )}
        </Col>
        <Col xs={24} sm={24} md={12} className="chargeAndSchedule">
          <div className="reservation">
            <span className="text-bold-styles">
              {t("booking-calendar.title-reservation")}
            </span>
            <br />
            <span className="text-normal-styles">
              {item?.rsv_fee}
              {t("booking-calendar.currency-unit")}
            </span>
          </div>
          <div className="table-weekday">
            <div className="table-weekday-left text-bold-styles"><span>{t("booking-calendar.titlecolum")}</span></div>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: '#FFFFFF',
                    headerBorderRadius: 0
                  },
                },
              }}
            >
              <Table
                size="small"
                columns={tableColumn(getData(item))}
                dataSource={dataTable(getData(item), props.index)}
                pagination={false}
                bordered
              />
            </ConfigProvider>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={0}>
          <Button className="reservation-onl text-bold-styles" onClick={() => handleClearRSV(item?.first_exm_flg, item?.clinic_id, item?.id)}>
            {t("reservation.text")}
          </Button>
        </Col>
      </Row>
      {showWarningQR &&
      <ModalWarning
        message={t('message.MESS_ERR_RSV_MISS_RE_EXM_CODE.text')}
        title={t('message.MESS_ERR_RSV_MISS_RE_EXM_CODE.title')}
        typeModal={MODAL_NEGATIVE}
        typeButton={BUTTON_WARNING}
        changeVisibleModal={() => {
          setShowWarningQR(false);
        }}
        handleWarning={() => {
          setVisibleEnterCode(true);
        }}
      />
      }
      {visibleEnterCode &&
        <ModalEnterCode
          changeVisibleModal={(enterCode: boolean, scanQR: boolean) => {
            changeVisibleModal(enterCode, scanQR);
          }}
          changeVisibleModalWarning={() => {setVisibleWarning(true)}}
          isScanner={isScanner}
        />
      }
      {visibleScanQR && (
        <ModalScanQR
          changeVisibleModal={(
            enterCode: boolean,
            scanQR: boolean,
            isScanner: boolean
          ) => {
            changeVisibleModal(enterCode, scanQR, isScanner);
          }}
        />
      )}
      {
        visibleWarning && 
        <ModalWarning
        message={t("message.MESS_NO_HSP_CLC_FOUND.text")}
        typeModal={MODAL_WARNING}
        typeButton={BUTTON_CLOSE}
        changeVisibleModal={() => {setVisibleWarning(false)}}
        />
      }
    </div>
  );
};

export default BookingCalendar;

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowUpRightIcon } from '../../../../../assets/image/arrow_up_right.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/image/copy_icon.svg';

type Props = {
  title: string;
  link: string;
};

const ReservationUrlItem: React.FC<Props> = ({ link, title }) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    messageApi.info(t('reExamCode.copySuccess'));
  };

  const handleRedirect = () => {
    window.open(link, '_blank');
  };

  return (
    <div className="reservation-url-item_container">
      {contextHolder}
      <div className="reservation-url-item_title">
        <h4 className="reservation-url-item_title_text">{title}</h4>
      </div>
      <div className="reservation-url-item_button_contianer">
        <button
          onClick={handleCopy}
          className="reservation-url-item_button mr-20px"
        >
          <CopyIcon />
          <span className="ml-12px">{t('reExamCode.copyButtontext')}</span>
        </button>
        <button
          onClick={handleRedirect}
          className="reservation-url-item_button"
        >
          <span className="mr-12px">{t('reExamCode.referancePage')}</span>
          <ArrowUpRightIcon />
        </button>
      </div>
    </div>
  );
};

export default ReservationUrlItem;

import { Button, Input, InputRef } from 'antd'
import './styles.css'
import japan_mapIcon from '../../assets/image/japan-map.svg'
import doctorIcon from '../../assets/image/doctor-avatar.svg'
import hospitalIcon from '../../assets/image/hospital.svg'
import searchIcon from '../../assets/image/search.svg'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchDepartmentMutation, useSearchMCityNameMutation } from 'src/Network/Home/HomeClinicApi'

const SearchBookingHistory = (props: any) => {
    const { t } = useTranslation();
    const [isDivVisibleArea, setDivVisibleArea] = useState(false);
    const [isDivVisibleSubject, setDivVisibleSubject] = useState(false);
    const [inputValueArea, setInputValueArea] = useState('');
    const [inputValueSubject, setInputValueSubject] = useState('');
    const [inputValueFreetxt, setInoutValueFreetxt] = useState('');
    const [dataArea, setDataArea] = useState<string[]>([]);
    const [dataSubject, setDataSubject] = useState<string[]>([]);
    const [searchCityName] = useSearchMCityNameMutation();
    const [searchDepartment] = useSearchDepartmentMutation();
    const inputRefArea = useRef<InputRef>(null);
    const inputRefSubject = useRef<InputRef>(null);
    const [prefIdValue, setPrefIdValue] = useState('');
    const [cityIdValue, setCityIdValue] = useState('');
    const [departmentIdValue, setDepartmentIdValue] = useState('');
    const [isTextEmptyArea, setIsTextEmptyArea] = useState(false);
    const [isTextEmptySubject, setIsTextEmptySubject] = useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);

    useEffect(() => {
        setDataArea([]);
        setDataSubject([]);
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const handleInputClick = () => {
        setDivVisibleArea(true);
    }
    const handleInputClickSubject = () => {
        setDivVisibleSubject(true);
    }
    const handleInputChangeArea = (event: any) => {
        setInputValueArea(event.target.value);
        setDataArea([]);
    }
    const handleInputChangeSubject = (event: any) => {
        setInputValueSubject(event.target.value);
        setDataSubject([]);
    }
    const handleInputChangeFreetxt = (event: any) => {
        setInoutValueFreetxt(event.target.value);
    }

    const handleClickOutside = (event: MouseEvent) => {
        if ((inputRefArea.current && !inputRefArea.current.input?.contains(event.target as Node)) ||
            (inputRefSubject.current && !inputRefSubject.current.input?.contains(event.target as Node))) {
            const element = event.target as Element;
            const className = element.classList.value;
            if (className === 'modal-keyword-button modal-keyword-item') {
                setInputValueArea(element.textContent as string);
                navigator.geolocation.getCurrentPosition(function(position) {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                });
            }
            if (className === 'modal-keyword-item') {
                setInputValueArea(element.textContent as string);
                setPrefIdValue(element.querySelector('.pref_id')?.getAttribute('value') as string);
                setCityIdValue(element.querySelector('.city_id')?.getAttribute('value') as string);
                setLatitude(0);
                setLongitude(0);
            }
            if (className === 'modal-keyword-item1') {
                setInputValueSubject(element.textContent as string);
                setDepartmentIdValue(element.querySelector('.department_id')?.getAttribute('value') as string);
            }
            setDivVisibleArea(false);
            setDivVisibleSubject(false);
        }
    };

    const handleSearch = () => {
        let ckEmpty = false;
        if (inputValueArea === '') {
            setIsTextEmptyArea(true);
            ckEmpty = true;
        }
        if (inputValueSubject === '') {
            setIsTextEmptySubject(true);
            ckEmpty = true;
        }
        if (!ckEmpty) {
            props.onClickSubmit(prefIdValue, cityIdValue, departmentIdValue, inputValueFreetxt, latitude, longitude)
        }
    }

    useEffect(() => {
        if (inputValueArea) {
            setIsTextEmptyArea(false);
            const indexOfFirstParenthesis = inputValueArea.indexOf('(');
            let txtSearch = inputValueArea;
            if (indexOfFirstParenthesis !== -1) {
                txtSearch = inputValueArea.substring(0, indexOfFirstParenthesis).trim();
            }
            searchCityName({city_name: txtSearch}).then((res: any) => {
                setDataArea(res?.data);
            })
        }
    }, [inputValueArea]);

    useEffect(() => {
        if (inputValueSubject) {
            setIsTextEmptySubject(false);
            searchDepartment({department_name: inputValueSubject}).then((res: any) => {
                setDataSubject(res?.data);
            })
        }
    }, [inputValueSubject]);

    useEffect(() => {
        if (isDivVisibleSubject && inputValueSubject === '') {
            setIsTextEmptySubject(false);
            searchDepartment({department_name: inputValueSubject}).then((res: any) => {
                setDataSubject(res?.data);
            })
        }
    }, [isDivVisibleSubject])

    return (
        <div className="box-search">
                <div className="box-search-title">
                    <div className="box-search-title-lv1"><span className="text-bold-styles">{t("clinic-search.header-box-search")}</span></div>
                    <div className="box-search-title-link"><a className="text-normal-styles" href={process.env.REACT_APP_WP_URL + "/waiting-search/?counseling_flag=1"} target='_blank' rel="noreferrer">{t("clinic-search.link-online")}</a></div>
                </div>
                <div className="box-search-input">
                    <div>
                        <div className="box-search-input-group">
                            <div className="word-search-header">
                                <span className="pc">{t("clinic-search.text-pc")}</span>
                            </div>
                            <div className="group-select-box">
                                <div className="input-wrap">
                                    <div>
                                        <Input
                                            placeholder={t("clinic-search.input-region")}
                                            prefix={<img src={japan_mapIcon} />}
                                            onClick={handleInputClick}
                                            onChange={handleInputChangeArea}
                                            value={inputValueArea}
                                            ref={inputRefArea}
                                            className='input-region'
                                        />
                                        {isTextEmptyArea && <span className="message-error">{t("clinic-search.area-empty")}</span>}
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={t("clinic-search.input-department")}
                                            prefix={<img src={doctorIcon} />}
                                            onClick={handleInputClickSubject}
                                            onChange={handleInputChangeSubject}
                                            value={inputValueSubject}
                                            ref={inputRefSubject}
                                            className='input-department'
                                        />
                                        {isTextEmptySubject && <span className="message-error">{t("clinic-search.subject-empty")}</span>}
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={t("clinic-search.input-clinic-name")}
                                            prefix={<img src={hospitalIcon} />}
                                            className='input-clinic'
                                            value={inputValueFreetxt}
                                            onChange={handleInputChangeFreetxt}
                                        />
                                    </div>
                                </div>
                                {isDivVisibleArea && (
                                    <>
                                    <div id="modal-area" className={(dataArea && dataArea.length != 0 || inputValueArea === '' || inputValueArea === '現在地') ? "search-modal" : "search-modal search-modal-none"}>
                                        <div className="modal-container">
                                            <h4 className="modal-title"><p className="modal-keyword-button modal-keyword-item">{t("clinic-search.modal-keyword")}</p> <span className="i-block">{t("clinic-search.modal-i-block")}</span></h4>
                                            <div className="modal-content">
                                                <h5 className="modal-heading">{t("clinic-search.modal-area-heading")}</h5>
                                                <ul className="modal-keyword-list">
                                                    {dataArea.map((item: any, index) => (
                                                        <li className="modal-keyword-item" key={index}>{item?.city_name}
                                                            <input type='hidden' className="pref_id" value={item?.pref_id}></input>
                                                            <input type='hidden' className="city_id" value={item?.city_id}></input>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {(dataArea && dataArea.length == 0 && inputValueArea !== '' && inputValueArea !== '現在地') && (
                                        <div id="modal-area-none" className="search-modal">
                                            <h4 className="modal-title"><span className="i-block">{t("clinic-search.modal-area-message")}</span></h4>
                                        </div>
                                    )}
                                    </>
                                )}
                                {isDivVisibleSubject && (
                                    <>
                                    <div id="modal-subject" className="search-modal">
                                        <div className="modal-container"></div>
                                        <h4 className="modal-title">{t("clinic-search.modal-subject-title")}</h4>
                                        <div className="modal-content modal-subject">
                                            <ul className="modal-keyword-list">
                                                {dataSubject && dataSubject.map((item: any, index) => (
                                                    <li className="modal-keyword-item1" key={index}>{item.department_name}
                                                        <input type='hidden' className="department_id" value={item?.id}></input>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    {dataSubject && dataSubject.length == 0 && (
                                        <div id="modal-subject-none" className="search-modal">
                                            <h4 className="modal-title"><span className="i-block">{t("clinic-search.modal-subject-message")}</span></h4>
                                        </div>
                                    )}
                                    </>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-search">
                    <Button icon={<img src={searchIcon} />}
                        onClick={() => handleSearch()}
                        style={{
                            display: 'flex',
                            width: '300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '60px',
                            backgroundColor: '#FF1493',
                            color: '#FFFFFF',
                            fontWeight: 600
                        }}>
                        {t("clinic-search.btn-name")}
                    </Button>
                </div>
            </div>
    )
}

export default SearchBookingHistory
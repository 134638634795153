import './styles.css';
import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Col, Row, Input } from 'antd';
import QRCode from 'src/assets/image/qr-code-gray.svg';
import { useSelector } from 'react-redux';
import { saveQRCode } from "src/Component/Modal/ModalQRCode/QRSlice";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/Redux/hooks";
import { useLazyScanCodeQuery } from 'src/Network/Home/HomeClinicApi';

type Props = {
  changeVisibleModal: Function,
  changeVisibleModalWarning?: Function,
  isScanner: boolean
}

const APP: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const QRCodeStore = useSelector((state: any) => state?.common.QRSlice.QRCodeStore);
  const [QRCodeValue, setQRCodeValue] = useState(() => props.isScanner ? QRCodeStore : '');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [disable, setDisable] = useState(() => QRCodeValue.length === 8 ? false : true);
  const [scanCode] = useLazyScanCodeQuery();

  const handleOpenScanQR = () => {
    props.changeVisibleModal(false, true);
  };

  const handleNext = () => {
    scanCode(QRCodeValue).then((res: any) => {
      if (res?.data?.clinic_id) {
        dispatch(saveQRCode(''));
        localStorage.setItem('qrCode', QRCodeValue);
        localStorage.setItem("clinic_id", res?.data?.clinic_id);
        handleClose();
        window.location.replace(process.env.REACT_APP_APP_URL + "?clinic_id=" + res?.data?.clinic_id);
      } else {
        if (props?.changeVisibleModalWarning) {
          props?.changeVisibleModalWarning();
        }
      }
    });
  };

  const handleClose = () => {
    props.changeVisibleModal();
    setIsModalOpen(false);
  };

  const handleInput = (e: any) => {
    setQRCodeValue(e.target.value);
    e.target.value.length === 8 ? setDisable(false) : setDisable(true);
  }

  return (
    <Modal maskClosable={false} title closeIcon={null} centered open={isModalOpen} footer={null} className='modalQRCode'>
      <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
        <Col span={24}>
          <div className='headerModal'>
            <span className='headerModal-title text-bold-styles'>{t("booking-QRCode.modal-title")}</span>
            <span>
              <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
            </span>
          </div>
        </Col>
        <Col span={24} className='bodyModal' style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: 12 }}>
            <div className='text-bold-styles' style={{ fontSize: 16, marginBottom: 8, color: '#6C747B' }}>{t("booking-QRCode.modal-enter-code.body-title")}</div>
            <div className='text-normal-styles'>
              {t("booking-QRCode.modal-enter-code.body-content-1")}
              <br />
              {t("booking-QRCode.modal-enter-code.body-content-2")}
            </div>
          </div>
          <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20, md: 20 }]} style={{ paddingTop: 24, paddingBottom: 24, justifyContent: 'center' }}>
            <Col xs={24} sm={24} md={14}>
              <Input placeholder={t("booking-QRCode.modal-enter-code.body-input-placeholder")} className='inputPt01-code' onChange={e => handleInput(e)} value={QRCodeValue} maxLength={8}></Input>
            </Col>
            <Col>
              <Button type='text' onClick={handleOpenScanQR} className='buttonPt01-qr text-bold-styles'>
                <img src={QRCode} alt="" style={{ marginRight: 12 }} />
                {t("booking-QRCode.modal-enter-code.body-button-qrscan")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} className='footerModal' style={{ textAlign: 'center' }}>
          <Button onClick={handleNext} disabled={disable} className={`buttonPt01-next text-bold-styles ${disable && 'buttonPt01-next-disable'}`}>
            {t("button-next.text")}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default APP;
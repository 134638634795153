import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const FormInputTextArea = ({ inputProps, ...props }: any) => {
  const { control, register } = useFormContext();
  return (
    <Controller
      name={props?.name}
      {...props}
      control={control}
      defaultValue={props?.defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error }
      }) => (
        <Form.Item
          className={props?.className}
          validateStatus={error ? 'error' : 'validating'}
          help={error?.message}
        >
          <TextArea
            disabled={props?.disabled}
            ref={register(props?.name).ref}
            placeholder={props?.placeholder}
            onKeyDown={props?.onKeyDown}
            {...{ value, onChange, onBlur }}
            {...inputProps}
            onBlur={props?.onBlur}
            rows={props.rows}
            onChange={(e) => {
              onChange(e);
              if (props?.onChange) {
                props?.onChange(e.target.value);
              }
            }}
            showCount={props?.showCount}
            style={props?.style}
          />
        </Form.Item>
      )}
    />
  );
};

export default FormInputTextArea;

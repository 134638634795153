import {Button, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import './styles.css';
import React, {useState} from "react";
import {useSendOTPMutation, useVerifyOTPEditCardMutation} from "../../../Network/Home/HomeClinicApi";
import {BUTTON_CLOSE, MODAL_NEGATIVE} from "../../../constant";
import Loading from "../../Modal/Loading";
import ModalWarning from "../../Modal/ModalWarning";

const APP = ({
							 isOpen,
							 onClose,
							 onVerifyOTPDone
						 }: {
	isOpen: boolean,
	onClose: Function,
	onVerifyOTPDone: (event: CustomEvent) => void,
}) => {
	const handleClose = () => {
		onClose();
	};
	const [verifyOTP] = useVerifyOTPEditCardMutation();
	const [isLoading, setIsLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [otp, setOtp] = useState('');
	const [sendMailOTPToEdit] = useSendOTPMutation();
	const [modalMsg, setModalMsg] = useState<string>('');
	const [modalType, setModalType] = useState<number>(1);
	const [modalBtnType, setModalBtnType] = useState<number>(1);
	const [modalTitle, setModalTitle] = useState<string>();

	const handleSendMailOTPToEdit = () => {
		setIsLoading(true);
		sendMailOTPToEdit({}).then((res: any) => {
			if (res?.data?.code && res?.data?.code === 200) {
				setIsLoading(false);
			} else {
				setModalMsg(t('errorMsg'));
				setModalType(MODAL_NEGATIVE);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_FAIL"));
				setIsLoading(false);
			}
		})
	}

	const handleCallAPIVerifyBeforeEditCard = () => {
		setIsLoading(true);
		verifyOTP({token: otp}).then((res: any) => {
			if (res?.data?.code && res?.data?.code === 200) {
				onVerifyOTPDone(new CustomEvent(''))
				onClose();
			} else {
				setErrMsg(t('MESS_ERR_INVALID_TOKEN'));
				setIsLoading(false);
			}
		})
	}
	const {t} = useTranslation();
	return <Modal className={"confirmBeforeEditCard"} title={t("card_payment.edit-title")}
								open={isOpen}
								centered
								onCancel={handleClose}
								footer={<div style={{display: "flex", justifyContent: "center", marginTop: 40}}>
									<Button type={"text"} className={'confirmBtn'}
													onClick={handleCallAPIVerifyBeforeEditCard}>{t("card_payment.confirm-btn")}</Button>
								</div>}>
		<div className={"msg"}><p style={{whiteSpace: "pre-line"}}>{t("card_payment.info4")}</p></div>
		<div style={{display: "flex", justifyContent: "space-between"}}>
			<Input value={otp}
						 onChange={(e) => {
						 	setOtp(e.target.value);
						 	setErrMsg('')}}
						 type={'text'}
						 style={{width: 460, height: 58}}
						 placeholder={t('card_payment.otp')}/>
			<Button style={{color: "#6C747B", background: "#F0F5F8", fontWeight: 700, width: 240, height: 60}}
							type={'text'}
							onClick={handleSendMailOTPToEdit}>{t('card_payment.gen-otp')}</Button>
		</div>
		{
			errMsg !== '' &&
      <div className={'errMsg'}>{errMsg}</div>
		}
		{isLoading && <Loading/>}
		{modalMsg && modalMsg !== '' && (
			<ModalWarning
				message={modalMsg}
				typeModal={modalType}
				typeButton={modalBtnType}
				title={modalTitle}
				changeVisibleModal={() => {
					setModalMsg('');}
				}
			/>
		)}
	</Modal>
}

export default APP;
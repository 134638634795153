import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsJP from '../locales/jp/transaltion.json'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'jp',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            description: {
                part1: '© 2021 オンラインドクター.com',
              }
          // here we will place our translations...
        }
      },
      jp: {
        translation: translationsJP,
      }
    }
  });

export default i18n;
import { Button, Spin } from 'antd';
import HealthcareIcon from '../../assets/image/healthcare.svg';
import CameraDefaultImg from '../../assets/image/camera-default.png';
import CameraNotSupportImg from '../../assets/image/device-not-support.jpg';
import './styles.css';
import 'video.js/dist/video-js.min.css';
import React, { useState } from 'react';
import VideoJSComponent from './VideoJSComponent';
import Player from 'video.js/dist/types/player';
import { useAppDispatch } from 'src/Redux/hooks';
import { changeBiologicalData, selectBiologicalData } from './slice';
import { useAppSelector } from 'src/Redux/hooks';
import { useTranslation } from 'react-i18next';
import { useVitalSignalMutation } from 'src/Network/Home/VitalSignalApi';

interface PlayerCustom extends Player {
  record?: () => any;
}

interface BioType {
  heartbeat: String;
  bloodPressure1: String;
  bloodPressure2: String;
  acidSaturatio: String;
  acidConcentration: String;
}

const BiologicalData = (props: any) => {
  const [turnOnCamera, setTurnOnCamrera] = useState(false);
  const [deviceError, setDeviceError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const bioData = useAppSelector(selectBiologicalData);
  const { t } = useTranslation();
  const { currentStep } = props;

  const [biometricDataFromAPI, setBiometricData] = useState<BioType>();
  const playerRef: React.MutableRefObject<Player | null> = React.useRef(null);
  const [vitalSignal] = useVitalSignalMutation();

  const videoOptions = {
    controls: true,
    bigPlayButton: false,
    width: 360,
    height: 360,
    fluid: false,
    plugins: {
      record: {
        audio: false,
        video: true,
        maxLength: 6,
        debug: true,
      },
    },
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
    player.record().getDevice();

    player.on('deviceReady', () => {
      console.log('device is ready!');
      player.record().enumerateDevices();
    });

    player.on('startRecord', async () => {
      console.log('started recording!');
    });

    player.on('finishRecord', () => {
      setLoading(true);
      sensingProcess(player.recordedData);
      setTurnOnCamrera(false);
    });
    player.on('error', (element: any, error: any) => {
      setDeviceError(true);
      setTurnOnCamrera(false);
    });

    player.on('deviceError', () => {
      setDeviceError(true);
      setTurnOnCamrera(false);
    });
  };

  const handleClickCamera = () => {
    if (deviceError) {
      return;
    }
    setTurnOnCamrera(true);
    const player = playerRef.current as PlayerCustom;
    const videojsModal = player.createModal('');
    videojsModal.addClass('vjs-my-fancy-modal');
    let countdown = 3;
    const countEl = document.querySelector(
      '.vjs-my-fancy-modal .vjs-modal-dialog-content',
    );
    const countdownInterval = setInterval(() => {
      if (countdown > 0) {
        if (countEl) {
          countEl.innerHTML = countdown.toString();
        }
        countdown--;
      } else {
        clearInterval(countdownInterval);
        videojsModal.close();
        if (player && player.record) {
          player.record().start();
        }
      }
    }, 1000);
  };

  const sensingProcess = (blob: any) => {
    const data = new FormData();
    data.append("file", blob, 'video.webm');
    data.append('DataGUID', 'Jidkceji39');
    vitalSignal(data).then((res: any) => {
      let s2 = res.data?.S2;
      if (s2) {
        s2 = s2.replace("[", "");
        s2 = s2.replace("]", "");
      }
      const fakeDataBio = {
        heartbeat: res.data?.bpm ? res.data?.bpm + ' bpm' : "計測不能",
        bloodPressure1: res.data?.bpv1 ? res.data?.bpv1 + ' mmHg' : "計測不能",
        bloodPressure2: res.data?.bpv0 ? res.data?.bpv0 + ' mmHg' : "計測不能",
        acidSaturatio: s2 ? s2 + ' %' : "計測不能",
        acidConcentration: res.data?.LTv ? res.data?.LTv + ' mg/dL' : "計測不能",
      };
      setBiometricData(fakeDataBio);
      dispatch(changeBiologicalData(fakeDataBio));
      setLoading(false);
    })
  };

  return (
    <div className="biological-data-container">
      <div className="biological-data-title">
        <div className="title">{t('biological.title')}</div>
      </div>
      { (
        <div className="biological-data-note">
          <p>{t('biological.note1')}</p>
          <p>{t('biological.note2')}</p>
        </div>
      )}
      <div className="biological-data-box">
        <div className="biological-data-box-result">
          <div className="result-header">
            <span>{t('biological.result-header')}</span>
          </div>
          <div className="result-content">
            <div className="result heartbeat">
              <span>
                {t('biological.heartbeat')}
                {currentStep !== 1 && (
                  <span className="explain">
                    {t('biological.heartbeat-note')}
                  </span>
                )}
              </span>
              <span className="heartbeat-data">
                {( !bioData?.heartbeat)
                  ? biometricDataFromAPI?.heartbeat ?? '-'
                  : bioData?.heartbeat}
              </span>
            </div>
            <div className="result blood-pressure-1">
              <span>
                {t('biological.blood-pressure-1')}
                {currentStep !== 1 && (
                  <span className="explain">
                    {t('biological.blood-pressure-1-note')}
                  </span>
                )}
              </span>
              <span className="blood-pressure-1-data">
                {( !bioData?.bloodPressure1)
                  ? biometricDataFromAPI?.bloodPressure1 ?? '-'
                  : bioData?.bloodPressure1}
              </span>
            </div>
            <div className="result blood-pressure-2">
              <span>
                {t('biological.blood-pressure-2')}
                {currentStep !== 1 && (
                  <span className="explain">
                    {t('biological.blood-pressure-1-note')}
                  </span>
                )}
              </span>
              <span className="blood-pressure-2-data">
                {( !bioData?.bloodPressure2)
                  ? biometricDataFromAPI?.bloodPressure2 ?? '-'
                  : bioData?.bloodPressure2}
              </span>
            </div>
            <div className="result acid-saturation">
              <span>
                {t('biological.acid-saturation')}
                {currentStep !== 1 && (
                  <span className="explain">
                    {t('biological.acid-saturation-note')}
                  </span>
                )}
              </span>
              <span className="acid-saturation-data">
                {( !bioData?.acidSaturatio)
                  ? biometricDataFromAPI?.acidSaturatio ?? '-'
                  : bioData?.acidSaturatio}
              </span>
            </div>
            <div className="result acid-concentration">
              <span>
                {t('biological.acid-concentration')}
                {currentStep !== 1 && (
                  <span className="explain">
                    {t('biological.acid-concentration-note')}
                  </span>
                )}
              </span>
              <span className="acid-concentration-data">
                {( !bioData?.acidConcentration)
                  ? biometricDataFromAPI?.acidConcentration ?? '-'
                  : bioData?.acidConcentration}
              </span>
            </div>
          </div>
        </div>
        {currentStep === 1 && (
          <div className="biological-data-box-camera">
            <div>
              {loading && <Spin className="loading" size="large" />}
              {deviceError && (
                <img className="image" src={CameraNotSupportImg} />
              )}
              {!deviceError && !turnOnCamera && (
                <img className="image" src={CameraDefaultImg} />
              )}
              <VideoJSComponent
                options={videoOptions}
                onReady={handlePlayerReady}
                turnOnCamera={turnOnCamera}
              />
            </div>
            <div>
              {!deviceError && 
              <Button
                icon={<img src={HealthcareIcon} />}
                onClick={handleClickCamera}
              >
                {t('biological.btn-name')}
              </Button>
              }
              
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BiologicalData;

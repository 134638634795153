import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import { ACCESS_TOKEN } from 'src/common/Constant';
const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      let _newUrl = '';
      if (url.startsWith('http')) {
        _newUrl = url;
      } else {
        _newUrl = baseUrl + url;
      }
      const accessToken = localStorage.getItem(ACCESS_TOKEN);
      if (!headers) {
        headers = {};
      }
      if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken;
      }
      const result = await axios({
        url: _newUrl,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;

import { Button, Col, Modal, Row } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import ArrowLeft from 'src/assets/image/arrow-left.svg';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { changeRegisterSuccess, selectRegisterSuccess } from "./slice";
import alert_circle from 'src/assets/image/alert_circle.svg';
import './styles.css';
import { replaceWithBr } from "src/Utils/textUtils";
import { useAppDispatch } from "src/Redux/hooks";

type Props = {
    message: string
}
const RegisterSuccess:React.FC<Props> = ( {message }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const registerSuccess = useSelector(selectRegisterSuccess);
    const hideModal = () => {
        dispatch(changeRegisterSuccess(false));
    }
    return (
        <div>
            <Modal maskClosable={false} title closeIcon={null} centered open={registerSuccess} footer={null} zIndex={998} className='modalSuccsess'>
                <Row gutter={[{ xs: 0, sm: 0, md: 0 }, { xs: 24, sm: 24, md: 32 }]}>
                    <Col span={24}>
                        <div className='headerModal'>
                        <span className='headerModal-title text-bold-styles' style={{lineHeight: '24px'}}>{t("pt04.modal-forgot-password.title")}</span>
                        <span>
                            <Button type='text' icon={<CloseOutlined />} onClick={hideModal} style={{ color: '#878E9B' }}></Button>
                        </span>
                        </div>
                    </Col>
                    <Col span={24} className='bodyModalSuccess'>
                        <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 8, sm: 8, md: 8 }]} style={{ paddingBottom: 11 }}>
                            <Col xs={24} sm={24}  md={24}>
                                <span className="content-title text-bold-styles">{t("register.title")}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24}  md={24} className="notifi">
                                <img src={alert_circle}/>
                                <span>{t("register-success.notifi")}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24}  md={24}>
                                <span className='message text-normal-styles' dangerouslySetInnerHTML={replaceWithBr(message)}></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24}  md={24}></Col>
                    <Col span={24}>
                        <Col span={24} className='footerModal' style={{ textAlign: 'center' }}>
                            <Row>
                                <Col xs={24} sm={24}  md={24}>
                                <Link to={process.env.REACT_APP_WP_URL || ''}>
                                    <Button className="modalSuccsess-cancel text-bold-styles">
                                    <img src={ArrowLeft} alt="" />
                                    {t("register-success.home-prev")}
                                    </Button>
                                </Link>
                                </Col>
                            </Row> 
                        </Col>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default RegisterSuccess;
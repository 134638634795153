import { useState } from "react";
import QrReader from "react-web-qr-reader";
import { useAppDispatch } from "src/Redux/hooks";
import { saveQRCode } from "src/Component/Modal/ModalQRCode/QRSlice";
import { useTranslation } from "react-i18next";

type Props = {
  changeVisibleModal: Function
}

const Scanner:React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [result, setResult] = useState("");
  const delay = 500;
  const dispatch = useAppDispatch()
  const previewStyle = {
    height: 360,
    width: 360
  };

  const handleScan = (result: any) => {
    if (result) {
      setResult('');
      let qrCode = '';
      const re_exm_code = result.data.split('re_exm_code=')[1];
      if (re_exm_code && re_exm_code.length >= 8) { // The URL is in the correct format, such as https://yost.ominext.dev/?clinic_id=80&re_exm_code=XJ546DEU
        qrCode = re_exm_code.substring(0, 8);
      } else { // Still standing at the QRCode scanning screen
        return;
      }
      dispatch(saveQRCode(qrCode));
      props.changeVisibleModal();
    }
  };

  const handleError = () => {
    setResult(t("booking-QRCode.modal-scan-qr.body-error"));
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span className="scannerPt01-message">{result}</span>
      </div>
      <div className="scannerPt01-camera">
        <QrReader
          delay={delay}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
        />
      </div>
    </>
  );
};

export default Scanner;

import InfoHospital from "../../Component/InfoHospital";
import BookingContent from "../../Component/BookingContent";
import Banner from "../../Component/Banner";
import "./styles.css";
import {
  useClinicsQuery
} from "../../Network/Home/HomeClinicApi";
import { useEffect } from "react";
import { useAppDispatch } from "src/Redux/hooks";
import {
  changeForgetPassword,
  changeModalRegister,
} from "src/Component/Header/slice";
import { useSearchParams } from "react-router-dom";
import Loading from "src/Component/Modal/Loading";
import { changeRegisterSuccess } from "src/Component/Modal/RegisterSuccess/slice";
import { changeCheckStepFooter } from "src/Component/ArrowStepper/Slice/Slice";
import { useTranslation } from "react-i18next";

const Booking = () => {
  const {t} = useTranslation();
  document.title = t('title_tabs_patient.pt_01');
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const clinic_id = searchParams.get('clinic_id')??"";
  const { data, isLoading } = useClinicsQuery({ id: Number.parseInt(clinic_id ?? "0") });
  
  dispatch(changeCheckStepFooter(false));
  useEffect(() => {
    if (!data) {
      dispatch(changeModalRegister(false));
      dispatch(changeForgetPassword(false));
      dispatch(changeRegisterSuccess(false));
    }
  }, [data]);
  const localClinicId = localStorage.getItem("clinic_id");
  if (clinic_id && clinic_id !== localClinicId) {
    localStorage.removeItem("clinic_id");
    localStorage.removeItem('qrCode');
  }

  return (
    <div className="booking-wrapper">
      <div className="booking-header">
        <Banner
          backgroundImg={data?.background_image}
          backgroundImgAlt={data?.background_image_alt}
        />
        <InfoHospital data={data} />
      </div>
      <BookingContent data={data} />
      {isLoading && <Loading />}
    </div>
  );
};

export default Booking;
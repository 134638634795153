import './styles.css';
import { Col, Input, Row, Tag, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PostalCode from 'src/Component/PostalCode';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from 'react';
import { isFullWidth, lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import { FormProvider, useForm } from 'react-hook-form';
import ArrowLeft from 'src/assets/image/arrow-left-white.svg';
import FormInput from "src/Component/common/form/FormInput";
import { useCreatePrescriptionAddressMutation, useMCitysByIdPrefQuery, useUpdatePrescriptionAddressMutation } from 'src/Network/Home/HomeClinicApi';
import Loading from "src/Component/Modal/Loading";
import { getUserIdFromLocalStorage } from 'src/common/Libs';
import ModalWarning from 'src/Component/Modal/ModalWarning';
import { BUTTON_ACCEPT, BUTTON_CLOSE, MODAL_FAIL, MODAL_SUCCESS, getCityList } from 'src/constant';
import { PrescriptionAddress } from 'src/Network/Model/PrescriptionAddress';

const ModalNewAddress = (props:any)  => {
    const { t } = useTranslation();
    const [errCode, setErrCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const {prefList} = props;
    const prescriptionAddress:PrescriptionAddress = props.prescriptionAddress;
    const [createPrescriptionAddress] = useCreatePrescriptionAddressMutation();
    const [updatePrescriptionAddress] = useUpdatePrescriptionAddressMutation();
    const [userId] = useState(getUserIdFromLocalStorage());
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [showModalFailWarning, setShowModalFailWarning] = useState(false);
    const [disableAddress, setDisableAddress] = useState(false);
    const [cityList, setCityList] = useState<Array<{ value: number; label: string }>>([{ value: 0, label: t("register.text-default-city") }]);
    let cityListNew = [] as Array<{ value: number; label: string }>;
    const [prefId, setPrefId] = useState<number>();
    const dataCitys = useMCitysByIdPrefQuery({id: Number(prefId)});
    useEffect(() => {
        if (prescriptionAddress && !props.typeModalNew) {
            const pref_id = prescriptionAddress?.pref_id;
            setPrefId(pref_id);
            dataCitys.refetch();
        } else {
            setCityList([{ value: 0, label: t("register.text-default-city") }]);
        }
    }, [prescriptionAddress])
    useEffect(() => {
        if (dataCitys?.data && dataCitys?.data.length > 0) {
            cityListNew = getCityList(dataCitys?.data, t("register.text-default-city"), 1);
            setCityList(cityListNew);
        } else {
            setCityList([{ value: 0, label: t("register.text-default-city") }]);
        }
    }, [dataCitys]);
    useEffect(() => {
        if (!prescriptionAddress && props.typeModalNew) {
            setDisableAddress(true);
        }
        if (prescriptionAddress && !props.typeModalNew) {
            methods.setValue('regist_name', prescriptionAddress.regist_name ? prescriptionAddress.regist_name.toString() : '');
            methods.setValue('postal_code', prescriptionAddress.postal_code ? prescriptionAddress.postal_code.toString() : '');
            methods.setValue('city_id', prescriptionAddress.city_id ? prescriptionAddress.city_id : 0);
            methods.setValue('pref_id', prescriptionAddress.pref_id ? prescriptionAddress.pref_id : 0);
            methods.setValue('address', prescriptionAddress.address ? prescriptionAddress.address.toString() : '');
            methods.setValue('send_name', prescriptionAddress.send_name ? prescriptionAddress.send_name.toString() : '');
        }
    }, [prescriptionAddress]);
    const handleCancel = () => {
        props.closeModal();
    }
    const defaultValues = {
        regist_name: '',
        postal_code: '',
        pref_id: 0,
        city_id: 0,
        address: '',
        send_name: ''
    };
    const schema = yup.object({
        regist_name: yup.string()
        .test(
            'regist_name',
            t("register.input-required").replace("{0}", t("ModalNewAddress.label1")),
            (value) =>  {
                if (disableAddress) return true;
                if (value?.length) return true;
                else return false;
            }
        )
        .test(
            "lengthFullAndHalfWidth",
            t("register.label-maxlength")
            .replace("{0}", t("ModalNewAddress.label1"))
            .replace("{1}", "50"),
            (value) => {
            return lengthFullAndHalfWidth(value) <= 50;
            }
        ),
        postal_code: yup
        .string()
        .required(
            t("register.input-required").replace("{0}", t("register.postal_code"))
        )
        .test(
            "isFullWidth",
            t("register.fullwidth-invalid")
            .replace("{0}", t("register.postal_code")),
            (value) => {
            return !isFullWidth(value);
            }
        ),
        pref_id: yup
        .number()
        .required().oneOf(prefList.filter((item:any)=>item.value !== 0).map((item:any)=>item.value), t("register.select-required").replace(
            "{0}",
            t("register.text-default-pref")
        )),
        city_id: yup
        .number()
        .required().oneOf(cityList.filter((item:any)=>item.value !== 0).map((item:any)=>item.value), t("register.select-required").replace(
            "{0}",
            t("register.text-default-city")
        )),
        address: yup
        .string()
        .required(
            t("register.input-required").replace("{0}", t("register.address"))
        )
        .test(
            "lengthFullAndHalfWidth",
            t("register.label-maxlength")
            .replace("{0}", t("register.address"))
            .replace("{1}", "64"),
            (value) => {
            return lengthFullAndHalfWidth(value) <= 64;
            }
        ),
        send_name:  yup
        .string()
        .required(t("register.input-required").replace("{0}", t("ModalNewAddress.label6")))
        .test(
            "lengthFullAndHalfWidth",
            t("register.label-maxlength")
            .replace("{0}", t("ModalNewAddress.label6"))
            .replace("{1}", "50"),
            (value) => {
            return lengthFullAndHalfWidth(value) <= 50;
            }
        )
    })
    type FormData = yup.InferType<typeof schema>;       
    const methods = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(schema),
        mode: "all",
    });
    const { setValue, watch, clearErrors } = methods;
    const onSubmit = (data:any) => {
        let dto = {user_id: userId, ...data};
        setIsLoading(true);
        if (disableAddress) {
            dto = {...dto, regist_name: t('PrescriptionReceiveNew.default-address')};
        }
        if (!props.typeModalNew) {
            //This is modal edit address
            updatePrescriptionAddress({id: prescriptionAddress.prescription_address_id, ...dto}).then((res: any) => {
                if(!res?.data?.error){
                    setShowModalWarning(true);
                } else {
                    setShowModalFailWarning(true);
                }
                setIsLoading(false);
            })
        } else {
            createPrescriptionAddress(dto).then((res: any) => {
                if(!res?.data?.error){
                    setShowModalWarning(true);
                } else {
                    setShowModalFailWarning(true);
                }
                setIsLoading(false);
            })
        }
    }
    return (
        <Row>
            <Col span={24} className="text-bold-styles titleModalContent">
                {t('ModalNewAddress.title-content')}
            </Col>
            <Col span={24}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className={'prescription-address ' + (props.typeModal && 'prescription-address-modal')}>
                            {!props.typeModal && <span className="text-bold-styles">{t("ModalNewAddress.title")}</span>}
                            <div className="prescription-info-row">
                                <div className="prescription-info-label-first">
                                    <span>{t("ModalNewAddress.label1")}</span>
                                    <Tag className="text-bold-styles need title-label">{t("register.tag")}</Tag>
                                    <FormInput
                                        name="regist_name"
                                        placeholder={t("ModalNewAddress.placeholder1")}
                                        disabled={disableAddress}
                                    />
                                </div>
                            </div>
                            <PostalCode
                                watch={watch}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                setErrCode={setErrCode}
                                errCode={errCode}
                                cityList={cityList}
                                prefList={prefList}
                                setCityList={setCityList}
                            />
                            <div className="prescription-info-row">
                                <div className="prescription-info-label-first">
                                    <span>{t("ModalNewAddress.label6")}</span>
                                    <Tag className="text-bold-styles need title-label">{t("register.tag")}</Tag>
                                    <FormInput
                                        name="send_name"
                                        placeholder={t("ModalNewAddress.placeholder6")}
                                    />
                                </div>
                            </div>
                            <Row className="prescription-info-button" gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 16, sm: 16, md: 16 }]}>
                                <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                                    <Button icon={<img src={ArrowLeft} alt=''/>} className='buttonCancel text-bold-styles' onClick={() => handleCancel()}>{t('LetterPrescription.button-cancel')}</Button>
                                </Col>
                                <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                                    <Input type='submit' className='buttonAccept text-bold-styles' value={t('LetterPrescription.button-accept')} />
                                </Col>
                            </Row>
                        </div>
                    </form>
                </FormProvider>
            </Col>
            {isLoading && <Loading />}
            {
                showModalWarning &&
                <ModalWarning
                    message={t('message.MESS_INF_ADD_ADDRESS_SUCCESS.text')}
                    title={t('message.MESS_INF_ADD_ADDRESS_SUCCESS.title')}
                    typeModal={MODAL_SUCCESS}
                    typeButton={BUTTON_CLOSE}
                    changeVisibleModal={() => {
                        setShowModalWarning(false); 
                        props.closeModal();
                    }}
                />
            }
            {
                showModalFailWarning &&
                <ModalWarning
                    message={t("message.MESS_ERR_CANNOT_REGIS")}
                    title={t('message.TITLE_FAIL')}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => setShowModalFailWarning(false)}
                />
            }
        </Row>
    )
}

export default ModalNewAddress;

const BookingOffice = () => {
    return (
      <div style={{
        height: '1000px'
      }}>
        BookingOffice
      </div>
    )
  }
  
export default BookingOffice;
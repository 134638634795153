export const isDoctorRoute = () => {
  return window.location.pathname.includes('/admin');
};
export const linkToWP = () => {
  return process.env.REACT_APP_WP_URL || process.env.REACT_APP_APP_URL + '/error';
};
export const linkToLoginDoctorWP = (blank = false) => {
  if (blank)
    return process.env.REACT_APP_WP_URL + '/c-login/?logout=1' || process.env.REACT_APP_APP_URL + '/error';
  return (
    process.env.REACT_APP_WP_URL +
      '/c-login?redirect_to=' +
      window.location.href || process.env.REACT_APP_APP_URL + '/error'
  );
};

export const getClinicIdFromLocalStorage = () => {
  const doctorInfo = localStorage.getItem('doctor');
  if (doctorInfo) {
    const idStr = JSON.parse(doctorInfo).clinic_id;
    return parseInt(idStr) ? parseInt(idStr) : -1;
  }
  return -1;
};

export const getUserIdFromLocalStorage = () => {
  const userInfo = localStorage.getItem('user');
  if (userInfo) {
    const idStr = JSON.parse(userInfo).user_id;
    return parseInt(idStr) ? parseInt(idStr) : -1;
  }
  return -1;
};

export const isHistoryRoute = () => {
  return window.location.pathname.includes('/history');
}
export const linkToLoginUserWP = () => {
  return process.env.REACT_APP_WP_URL + '/u-login/?logout=1' || process.env.REACT_APP_APP_URL + '/error';
}

export const linkToTermOfUser = () => {
  return process.env.REACT_APP_TERM_OF_USER;
}

export const linkToPsftHowToUse = () => {
  return process.env.REACT_APP_PSFT_HOWTOUSE;
}

export const linkToMypage = () => {
  return process.env.REACT_APP_MYPAGE;
};
import './styles.css';
import { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Col, Row } from 'antd';
import { MOBILE_SIZE } from "src/constant";
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { changeModalLogin, changeModalRegister, changeRequestLogin, selectIsRequestLogin } from 'src/Component/Header/slice';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

const RequestLogin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isRequestLogin = useAppSelector(selectIsRequestLogin);
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < MOBILE_SIZE);
  const handleOk = () => {
    dispatch(changeRequestLogin(false));
  };

  const handleCancel = () => {
    dispatch(changeRequestLogin(false));
  };

  const handleClose = () => {
    dispatch(changeRequestLogin(false));
  };

  const resizeWindow = () => setIsMobile(() => window.innerWidth < MOBILE_SIZE);

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  })

  const handleLogin = () => {
    dispatch(changeRequestLogin(false));
    dispatch(changeModalLogin({
      show: true
    }));
  }

  const handleRegister = () => {
    dispatch(changeRequestLogin(false));
    dispatch(changeModalRegister(true));
  }

  return (
    <div>
      <Modal maskClosable={false} title closeIcon={null} centered open={isRequestLogin} onOk={handleOk} onCancel={handleCancel} footer={null} width={isMobile ? 400 : 800}>
        <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
          <Col span={24}>
            <div className='headerModal'>
              <span className='headerModal-title text-bold-styles'>{t("request-login.title")}</span>
              <span>
                <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
              </span>
            </div>
          </Col>
          <Col span={24} className='bodyModal' style={{ paddingTop: 24, paddingBottom: 24, textAlign: 'center' }}>
            <div className='textPt03 text-normal-styles'>
              {t("request-login.content-request")}
              {isMobile && <br />}
              {t("request-login.required")}
              <br />
              {t("request-login.text-loginorregster")}
              {isMobile && <br />}
              {t("request-login.text-ishachoku")}<Link to={process.env.REACT_APP_ISHACHOKU_AGREEMENT_USER || ''} style={{ color: '#308AE6' }} target='_blank'> {t("request-login.text-service")} </Link>および
              {isMobile && <br />}
              <Link to={process.env.REACT_APP_ISHACHOKU_PRIVACY || ''} style={{ color: '#308AE6' }} target='_blank'>{t("register.privacy-policy")} </Link>{t("request-login.text-agree")}
            </div>
          </Col>
          <Col span={24} className='footerModal'>
            <Row gutter={[{ xs: 16, sm: 16, md: 20 }, { xs: 16, sm: 16, md: 20 }]}>
              <Col xs={24} sm={24} md={24}>
                <Button onClick={handleLogin} className='buttonPt03-login text-bold-styles'>
                  {t("request-login.text-login")}
                </Button>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Button onClick={handleRegister} className='buttonPt03 text-bold-styles'>
                  {t("request-login.text-register")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default RequestLogin;
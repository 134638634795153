import './styles.css';
import { useEffect, useState } from 'react';
import { Button, Col, Row, message } from 'antd';
import ArrowLeft from 'src/assets/image/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import FormInPutPassWord from '../common/form/FormInPutPassWord';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useLoginUserMutation,
  useRegisterUserMutation,
  useValidateTokenMutation,
} from 'src/Network/Home/HomeClinicApi';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeIsLogin, changeModalLogin } from '../Header/slice';
import { User } from 'src/Network/Model/User';
import { BUTTON_ACCEPT, MODAL_WARNING } from 'src/constant';
import ModalWarning from 'src/Component/Modal/ModalWarning';
import { revertAll } from 'src/Redux/hooks';
import { isFullWidth } from 'src/Utils/textUtils';
import { ACCESS_TOKEN, KEY_USER } from 'src/common/Constant';
import Loading from '../Modal/Loading';

type Props = {
  newUser: boolean;
};

const SettingPassword: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [messageApi, contextMessage] = message.useMessage();
  const [errorPasswordMatch, setErrorPasswordMatch] = useState(false);
  const [registerUser] = useRegisterUserMutation();
  const [searchParams] = useSearchParams();
  const clinic_id = searchParams.get('clinic_id');
  let redirect_url = searchParams.get('redirect_url');
  const token = searchParams.get('token');
  const mailaddress = searchParams.get('email');
  const user_id = searchParams.get('user_id');
  const [validateToken] = useValidateTokenMutation();
  const [visibleWarning, setVisibleWarning] = useState<Boolean>(false);
  const [isLoading, setLoading] = useState<Boolean>(true);
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [messageFail, setMessageFail] = useState('');
  const [userLogin] = useLoginUserMutation();
  useEffect(() => {
    const qrCode = localStorage.getItem('qrCode') ?? '';
    const localClinicId = localStorage.getItem('clinic_id') ?? '';
    localStorage.clear();
    localStorage.setItem('qrCode', qrCode);
    localStorage.setItem('clinic_id', localClinicId);
    dispatch(revertAll());
    dispatch(changeIsLogin(false));
    validateToken({
      mailaddress,
      token,
    }).then((res: any) => {
      setLoading(false);
      //URLが無効です。
      setVisibleWarning(res?.data?.error);
    });
  }, []);

  const onSubmit = (data: any) => {
    setLoading(true);
    if (
      methods.getValues('reNewPassword') !== methods.getValues('newPassword')
    ) {
      setErrorPasswordMatch(true);
    } else {
      const params: User = {
        mailaddress: decodeURIComponent(mailaddress || ''),
        password: data?.newPassword,
        token: token,
      };
      if (user_id) {
        params['user_id'] = parseInt(user_id);
      }
      registerUser(params).then((res: any) => {
        if (!res?.data?.error) {
          if (!redirect_url) {
            if (clinic_id) {
              redirect_url = process.env.REACT_APP_APP_URL + '?clinic_id=' + clinic_id;
            } else {
              // eslint-disable-next-line  @typescript-eslint/no-extra-non-null-assertion
              redirect_url = process.env.REACT_APP_APP_URL!!;
            }
          }
          if (redirect_url) {
            // redirect to target url
            // window.location.href = redirect_url;
            // dispatch(changeModalLogin({
            //   show: true,
            //   // redirectUrl: redirect_url
            // }));
            // localStorage.setItem('isLogin', '1');

            //01 tu dong login
            //02 redirect ve redirect_url
            autoLogin(params.mailaddress, params.password, redirect_url);
          }
        } else {
          //TODO show message
          setLoading(false);
          setVisibleWarningModal(true);
          setMessageFail(res?.data?.message);
        }
      });
    }
  };

  const autoLogin = (mailaddress?: any, password?: string, redirectUrl?: any) => {
    if (!mailaddress || !password) return;
    let dto = {
      'mailaddress': mailaddress,
      'password': password
    };
    userLogin(dto).then(async (res: any) => {
      const data = res?.data?.user;
      if (data && data.status === 1) {
        localStorage.setItem(KEY_USER, JSON.stringify(data));
        if (res?.data?.access_token) {
          localStorage.setItem(ACCESS_TOKEN, res?.data?.access_token);
        }
        // const redirectUrl = showModalLogin.redirectUrl;
        dispatch(changeModalLogin({
          show: false
        }));
        dispatch(changeIsLogin(false));
        const dataToSend = {
          email: mailaddress,
          password: password,
        };
        let logoutURL = process.env.REACT_APP_WP_LOGOUT_URL;
        if (!logoutURL) return;
        await fetch(logoutURL, {
          method: 'GET',
          credentials: 'include'
        });
        let authenURL = process.env.REACT_APP_WP_AUTHEN_URL;
        if (!authenURL) return;
        await fetch(authenURL, {
          body: new URLSearchParams(dataToSend),
          method: 'POST',
          credentials: 'include'
        });
        setLoading(false);
        if (redirectUrl) {
          // truong hop url la dang ky benh nhan o man hinh DT-09
          if (redirectUrl.includes('reservation_info_id')) {
            redirectUrl += '&isLogin=1';
          }
          window.location.replace(redirectUrl);
        }
      } else {
        if (res?.data?.error && res?.data?.message) {
          // TODO
          // setLoginFail(res?.data?.message);
        } else {
          // TODO
          // setLoginFail(t("login.passerr"));
        }
      }
      // setLoading(false);
      // setIsLoading(false);
    }).then(() => {
      // const json = localStorage.getItem(KEY_USER);
      // if (json) {
      //   document.getElementById('currentStep0')?.click();
      // }
      // setLoading(false);
    });
  }

  const handleCancel = () => {
    // TODO
  };

  const handleNewPassword = (e: any) => {
    setErrorPasswordMatch(false);
    methods.setValue('newPassword', e.target.value);
  };

  const handleReNewPassword = (e: any) => {
    setErrorPasswordMatch(false);
    methods.setValue('reNewPassword', e.target.value);
  };

  const defaultValues = {
    newPassword: '',
    reNewPassword: '',
    // newPassword: "Aa@123456",
    // reNewPassword: "Aa@123456"
  };
  type FormData = yup.InferType<typeof schema>;
  const schema = yup.object({
    newPassword: yup
      .string()
      .required(
        t('register.input-required').replace(
          '{0}',
          t('settingPassword.fieldInput.newpassword-placeholder'),
        ),
      )
      .matches(/(?=.*[A-Za-z])(?=.*\d)\w+/, t('register.password-policy'))
      .test(
        'isFullWidth',
        t('register.password-policy'),
        (e) => !isFullWidth(e),
      )
      .min(
        8,
        t('register.label-betweenlength')
          .replace('{0}', '8')
          .replace('{1}', '20'),
      )
      .max(
        20,
        t('register.label-betweenlength')
          .replace('{0}', '8')
          .replace('{1}', '20'),
      ),

    reNewPassword: yup
      .string()
      .required(
        t('register.input-required').replace(
          '{0}',
          t('settingPassword.fieldInput.re-newpassword-placeholder'),
        ),
      )
      .matches(/(?=.*[A-Za-z])(?=.*\d)\w+/, t('register.password-policy'))
      .test(
        'isFullWidth',
        t('register.password-policy'),
        (e) => !isFullWidth(e),
      )
      .min(
        8,
        t('register.label-betweenlength')
          .replace('{0}', '8')
          .replace('{1}', '20'),
      )
      .max(
        20,
        t('register.label-betweenlength')
          .replace('{0}', '8')
          .replace('{1}', '20'),
      ),
  });
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'all',
  });

  return (
    <div className="settingPassword-wrap settingPassword">
      {contextMessage}
      <Row gutter={[{}, { xs: 40, sm: 40, md: 24 }]}>
        <Col xs={24} sm={24} md={24} className="text-bold-styles title">
          {t('settingPassword.title')}
        </Col>
        <Col xs={24} sm={24} md={24}>
          <div className="fieldName">
            <div className="text-bold-styles name">
              {props?.newUser
                ? t('settingPassword.fieldName.name-new-member')
                : t('settingPassword.fieldName.name-reset')}
            </div>
            {!isLoading && !visibleWarning && (
              <div className="text-normal-styles notice">
                {t('settingPassword.fieldName.notice')}
              </div>
            )}
            {!isLoading && visibleWarning && (
              <div className="text-normal-styles error">
                {t('message.MESS_RESET_PSW_MAIL_EXPIRED.text')}
              </div>
            )}
          </div>
        </Col>
        {isLoading && <Loading />}
        {!isLoading && !visibleWarning && (
          <Col xs={24} sm={24} md={24}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={24} sm={24} md={14} className="fieldInput">
                    <FormInPutPassWord
                      inputProps={{
                        size: 'large',
                        placeholder: t(
                          'settingPassword.fieldInput.newpassword-placeholder',
                        ),
                        onChange: (e: any) => handleNewPassword(e),
                      }}
                      name="newPassword"
                    />
                    <div className="text-normal-styles suggest">
                      {t('settingPassword.fieldInput.suggest')}
                    </div>
                    <FormInPutPassWord
                      inputProps={{
                        size: 'large',
                        placeholder: t(
                          'settingPassword.fieldInput.re-newpassword-placeholder',
                        ),
                        onChange: (e: any) => handleReNewPassword(e),
                      }}
                      name="reNewPassword"
                    />
                    {errorPasswordMatch && (
                      <div className="text-normal-styles errorPasswordMatched">
                        {t('register.password-matched')}
                      </div>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={10} className="fieldButton">
                    <Button
                      htmlType="submit"
                      className={`text-bold-styles buttonRegist`}
                    >
                      {t('settingPassword.fieldButton.regist')}
                    </Button>
                    <Button
                      type="text"
                      onClick={handleCancel}
                      className="buttonCancel text-bold-styles"
                    >
                      <img src={ArrowLeft} alt="" style={{ marginRight: 12 }} />
                      {t('settingPassword.fieldButton.cancel')}
                    </Button>
                  </Col>
                </Row>
              </form>
            </FormProvider>
          </Col>
        )}
      </Row>
      {visibleWarningModal && (
        <ModalWarning
          message={messageFail}
          typeModal={MODAL_WARNING}
          typeButton={BUTTON_ACCEPT}
          changeVisibleModal={() => {
            setVisibleWarningModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingPassword;

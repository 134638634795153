import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
  biologicalData: {} as any,
}

const slice = createSlice({
  name: "biologicalData",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeBiologicalData: (state, action) => {
      state.biologicalData = action.payload;
    }
  },
});

export const selectBiologicalData = (state:RootState) => state.common.biologicalData.biologicalData;

export const {
  changeBiologicalData
} = slice.actions;

export const BiologicalDataReducer = slice.reducer;
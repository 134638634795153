import { ReservationFrameRepeat } from 'src/Network/Model/ReservationFrameRepeat';
import { getListTimeOnDay } from 'src/Utils/CalculateHourResevationUtils';
import { ClosedTime } from '../../../../../Network/Model/ClosedTime';

export const calculateRsvEndTime = (
  rsvStartTime: String | undefined,
  exmTime: number
) => {
  const TIME_ON_DAY = getListTimeOnDay(exmTime);
  if (!rsvStartTime) return '';
  const indexStartTime = TIME_ON_DAY.findIndex((e) => e === rsvStartTime);
  return indexStartTime === TIME_ON_DAY.length - 1
    ? TIME_ON_DAY[0]
    : TIME_ON_DAY[indexStartTime + 1];
};

export const calculateRangeTimeOrdered = (
  reservationFrameRepeat: ReservationFrameRepeat[],
  closedTimes: ClosedTime[]
) => {
  let timeStart = Number(
    reservationFrameRepeat[0]?.rsv_start_time?.substring(0, 2)
  );
  //   let timeEnd = Number(
  //     reservationFrameRepeat[0]?.rsv_end_time?.substring(0, 2)
  //   );

  const endtimeReservationFrameRepeatList = reservationFrameRepeat.map((item) =>
    Number(item.rsv_end_time)
  );
  const endtimeClosedTimesList = closedTimes.map((item) =>
    Number(item.closed_end_time)
  );
  const latestTime = Math.max(
    ...endtimeReservationFrameRepeatList,
    ...endtimeClosedTimesList
  );
  console.log(
    Math.max(...endtimeReservationFrameRepeatList, ...endtimeClosedTimesList)
  );
  const timeEnd =
    Number(latestTime.toString().substring(2)) === 0
      ? Math.floor(latestTime / 100) - 1
      : Math.floor(latestTime / 100);
  reservationFrameRepeat.forEach((e) => {
    if (Number(e.rsv_start_time?.substring(0, 2)) < timeStart)
      timeStart = Number(e.rsv_start_time?.substring(0, 2));
    // if (Number(e.rsv_end_time?.substring(0, 2)) >= timeEnd) {
    //   //check if rsv_end_time is xx:00 sharp. if yes, let the time frame previous to rsv_end_time be the end time. otherwise, let rsv_end_time be the end time.
    //   console.log('timeEnd' + timeEnd);

    //   if (Number(e.rsv_end_time?.substring(2)) === 0) {
    //     timeEnd = Number(e.rsv_end_time?.substring(0, 2)) - 1;
    //   } else {
    //     timeEnd = Number(e.rsv_end_time?.substring(0, 2));
    //   }
    // }
  });

  //   console.log(timeEnd);

  closedTimes.forEach((e) => {
    if (Number(e.closed_start_time?.substring(0, 2)) < timeStart)
      timeStart = Number(e.closed_start_time?.substring(0, 2));
    // if (Number(e.closed_end_time?.substring(0, 2)) > timeEnd) {
    //   //check if closed_end_time is xx:00 sharp. if yes, let the time frame previous to closed_end_time be the end time. otherwise, let closed_end_time be the end time.
    //   if (Number(e.closed_end_time?.substring(2)) === 0) {
    //     timeEnd = Number(e.closed_end_time?.substring(0, 2)) - 1;
    //   } else {
    //     timeEnd = Number(e.closed_end_time?.substring(0, 2));
    //   }
    // }
  });

  return { timeStart, timeEnd };
};

import { Col } from 'antd';
import ReExaminationHomePage from './ReExaminationHomePage';
import ReservationAcceptTime from './ReservationAcceptTime';

const ReExaminationContent: React.FC = () => {
  return (
    <Col span={16} className="re-examination_content_container">
      <div className="re-examination_content">
        <ReExaminationHomePage />
        <ReservationAcceptTime />
      </div>
    </Col>
  );
};

export default ReExaminationContent;

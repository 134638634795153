import './styles.css';
import { Col, Radio, Row, Button, Card, Modal } from 'antd';
import { useEffect, useState } from 'react';
import Plus from 'src/assets/image/plus.svg';
import Edit from 'src/assets/image/edit_icon.svg';
import Trash from 'src/assets/image/trash.svg';
import { useTranslation } from 'react-i18next';
import { PrescriptionAddress } from 'src/Network/Model/PrescriptionAddress';
import { PrescriptionPharmacyNew } from 'src/Network/Model/PrescriptionPharmacyNew';
import { replaceWithBr } from 'src/Utils/textUtils';
import { getUserIdFromLocalStorage } from 'src/common/Libs';
import { useDeletePrescriptionAddressMutation, usePrescriptionAddressByUserIdQuery, usePrescriptionPharmacyByUserIdQuery, useDeletePrescriptionPharmacyMutation } from 'src/Network/Home/HomeClinicApi';
import ModalNewAddress from '../PT-05-E/ModalNewAddress';
import RegisterAddress from 'src/Component/ReceptionInfo/RegisterAddress';
import Loading from "src/Component/Modal/Loading";
import ModalWarning from 'src/Component/Modal/ModalWarning';
import { BUTTON_ACCEPT, BUTTON_CHOOSE, BUTTON_CLOSE, MODAL_FAIL, MODAL_SUCCESS, MODAL_WARNING, RECEIVE_ADDRESS_PHARMACY, RECEIVE_PHARMACY } from 'src/constant';

//PrescriptionReceiveNew: PrescriptionReceive at step 2
const PrescriptionReceiveNew = (props: any) => {
    const { t } = useTranslation();
    const [methodReceive, setMethodReceive] = useState(1);
    const [isModalOpenNewAddress, setIsModalOpenNewAddress] = useState(false);
    const [isModalOpenNewPharmacy, setIsModalOpenNewPharmacy] = useState(false);
    const [storePharmacy, setStorePharmacy] = useState<Number | undefined>();
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const [radioPrescriptionAddress, setRadioPrescriptionAddress] = useState<Number | undefined>();
    const [radioPharmacyAddress, setRadioPharmacyAddress] = useState<Number | undefined>();
    const [userId] = useState(getUserIdFromLocalStorage());
    const prescriptionAddresses = usePrescriptionAddressByUserIdQuery(({user_id: userId})).data;
    const prescriptionPharmacyByUserId = usePrescriptionPharmacyByUserIdQuery({user_id: userId, receive_at: methodReceive});
    const pharmacyData = prescriptionPharmacyByUserId?.data;
    const [errorMessageNextScreen, setErrorMessageNextScreen] = useState('');
    const {addressStore} = props;

    const handleChangeReceive = (value: number) => {
        setMethodReceive(value);
    }

    const handleSaveAddressToForm = (methodReceiveChange: Number, radioPrescriptionAddressChange: Number | undefined, radioPharmacyChange: Number | undefined) => {
        const addressStore = {
            methodReceive: methodReceiveChange,
            prescriptionAddresses: methodReceive == RECEIVE_PHARMACY ?
                undefined : prescriptionAddresses?.find(e => e.prescription_address_id == radioPrescriptionAddressChange),
                //receive only at pharmacy, not available prescriptionAddresses => save prescriptionAddresses = undefined
            prescriptionPharmacy: pharmacyData?.find(e => e.pharmacy_id == radioPharmacyChange)
        };
        props.methods.setValue('prescription_receive_data', addressStore);
    };

    const isFetching = prescriptionPharmacyByUserId?.isFetching;
    useEffect(() => {
        prescriptionPharmacyByUserId?.refetch();
    }, [methodReceive]);

    useEffect(() => {
        props.methods.clearErrors('prescription_receive_validate');
        let isCheckedAddress = false;
        let isCheckedPharmacy = false;
        let radioPrescriptionAddressDefault = undefined;
        let radioPharmacyDefault = undefined;

        if (prescriptionAddresses?.length) {
            isCheckedAddress = true;
            setRadioPrescriptionAddress(prescriptionAddresses[0].prescription_address_id);
            radioPrescriptionAddressDefault = prescriptionAddresses[0].prescription_address_id;
        }

        if (pharmacyData?.length) {
            isCheckedPharmacy = true;
            radioPharmacyDefault = pharmacyData[0].pharmacy_id;
            setRadioPharmacyAddress(pharmacyData[0].pharmacy_id);
        }

        if (methodReceive == RECEIVE_PHARMACY) {
            if (isCheckedPharmacy) {
                props.methods.setValue('prescription_receive_validate', 'true');
            } else {
                props.methods.setValue('prescription_receive_validate', '');
                setErrorMessageNextScreen(t('message.MESS_ERR_MISS_INPUT_PHARMACY'));
            }
        } else {
            if (isCheckedPharmacy && isCheckedAddress) {
                props.methods.setValue('prescription_receive_validate', 'true');
            } else {
                props.methods.setValue('prescription_receive_validate', '');
            }

            if (!isCheckedAddress && !isCheckedPharmacy) {
                setErrorMessageNextScreen(t('message.MESS_ERR_MISS_INPUT_PHARMACY_AND_RECIEVE_ADDRESS'));
            } else if (!isCheckedAddress) {
                setErrorMessageNextScreen(t('message.MESS_ERR_MISS_INPUT_RECIEVE_ADDRESS'));
            } else if (!isCheckedPharmacy) {
                setErrorMessageNextScreen(t('message.MESS_ERR_MISS_INPUT_PHARMACY'));
            }
        }
        handleSaveAddressToForm(methodReceive, radioPrescriptionAddressDefault, radioPharmacyDefault);

    }, [prescriptionAddresses, pharmacyData]);
    
    useEffect(() => {
        //fill data from store
        if (addressStore) {
            if (addressStore.methodReceive) {
                setMethodReceive(addressStore.methodReceive);
            }
            if (addressStore.prescriptionAddresses?.prescription_address_id) {
                setRadioPrescriptionAddress(addressStore.prescriptionAddresses.prescription_address_id);
            }
            if (addressStore.prescriptionPharmacy?.pharmacy_id) {
                setStorePharmacy(addressStore.prescriptionPharmacy.pharmacy_id);
            }
            
        }
    }, [addressStore]);

    useEffect(() => {
        if (storePharmacy) {
            setRadioPharmacyAddress(storePharmacy);
            handleSaveAddressToForm(methodReceive, radioPrescriptionAddress, storePharmacy);
            setStorePharmacy(undefined);
        }
    }, [storePharmacy]);

    return (
        <div className='prescriptionReceiveNew'>
            <Row gutter={[{}, { xs: 20, sm: 20, md: 20 }]}>
                <Col span={24} className='text-bold-styles title'>
                    {t('PrescriptionReceiveNew.title')}
                </Col>
                <Col span={24}>
                    <Radio.Group className='radioOptions' value={methodReceive} onChange={(e) => {
                            //save to store
                            handleSaveAddressToForm(e.target.value, radioPrescriptionAddress, radioPharmacyAddress);
                            handleChangeReceive(e.target.value);
                        }}>
                        <Radio value={1} className='radio'>{t('PrescriptionReceiveNew.radio1')}</Radio>
                        <Radio value={2} className='radio'>{t('PrescriptionReceiveNew.radio2')}</Radio>
                    </Radio.Group>
                </Col>
                {
                    (props.methods.formState.errors.prescription_receive_validate && errorMessageNextScreen) &&
                    <div className='message-error'>
                        {errorMessageNextScreen}
                    </div>
                }
                {
                    methodReceive == RECEIVE_ADDRESS_PHARMACY &&
                    <Radio.Group className='radioOptions' value={radioPrescriptionAddress} onChange={(e) => {
                            //save to store
                            handleSaveAddressToForm(methodReceive, e.target.value, radioPharmacyAddress);
                            setRadioPrescriptionAddress(e.target.value);
                        }}>
                        <PrescriptionInfo 
                            title={t('PrescriptionReceiveNew.info1.title')} 
                            button={t('PrescriptionReceiveNew.info1.button')} 
                            handleClick={() => setIsModalOpenNewAddress(true)} 
                            prescriptionAddresses={prescriptionAddresses}
                            prescriptionPharmacies={undefined}
                            radioPrescriptionAddress={radioPrescriptionAddress}
                            radioPharmacyAddress={undefined}
                            props={props}
                        />
                    </Radio.Group>
                }
                <Radio.Group className='radioOptions' value={radioPharmacyAddress} onChange={(e) => {
                        //save to store
                        handleSaveAddressToForm(methodReceive, radioPrescriptionAddress, e.target.value);
                        setRadioPharmacyAddress(e.target.value);
                    }}>
                    <PrescriptionInfo 
                        title={t('PrescriptionReceiveNew.info2.title')} 
                        button={t('PrescriptionReceiveNew.info2.button')} 
                        handleClick={() => setIsModalOpenNewPharmacy(true)} 
                        prescriptionAddresses={undefined}
                        prescriptionPharmacies={pharmacyData}
                        radioPrescriptionAddress={undefined}
                        radioPharmacyAddress={radioPharmacyAddress}
                        props={props}
                        methodReceive={methodReceive}
                    />
                </Radio.Group>
            </Row>
            {
                isModalOpenNewAddress &&
                <Modal
                    maskClosable={false}
                    title={t("ModalNewAddress.title")}
                    centered
                    open={true}
                    onCancel={() => setIsModalOpenNewAddress(false)}
                    footer={null}
                    className='modalNewAddress wrapModalNewAddress'
                >
                    <ModalNewAddress
                        cityList={props.cityList}
                        prefList={props.prefList}
                        typeModal
                        typeModalNew
                        closeModal={() => setIsModalOpenNewAddress(false)}
                        prescriptionAddress={prescriptionAddresses ? prescriptionAddresses[0] : undefined}
                        />
                </Modal>
            }
            {
                isModalOpenNewPharmacy &&
                <Modal
                    maskClosable={false}
                    title={t("ModalNewAddress.title")}
                    centered
                    open={true}
                    onCancel={() => setIsModalOpenNewPharmacy(false)}
                    footer={null}
                    className='modalNewAddress'
                >
                    <RegisterAddress
                        prefList={props.prefList}
                        cityList={props.cityList}
                        typeModal
                        setIsModalOpenNewPharmacy={setIsModalOpenNewPharmacy}
                        setIsModalOpenEditPharmacy={setIsModalOpenNewPharmacy}
                        methodReceive={methodReceive}
                        setVisibleSuccess={setVisibleSuccess}
                        setCityList={props.setCityList}
                    />
                </Modal>
            }
            {
            visibleSuccess &&
                <ModalWarning
                    message={t("message.MESS_INF_ADD_ADDRESS_SUCCESS.text")}
                    typeModal={MODAL_SUCCESS}
                    typeButton={BUTTON_CLOSE}
                    changeVisibleModal={() => {
                        setVisibleSuccess(false)
                    }}
                />
            }
            {isFetching && <Loading />}
        </div>
    )
}

const PrescriptionInfo = (
    { title, button, handleClick, prescriptionAddresses, prescriptionPharmacies, radioPrescriptionAddress, radioPharmacyAddress, props, methodReceive } : {
        title: String,
        button: String,
        handleClick: Function,
        prescriptionAddresses: PrescriptionAddress[] | undefined,
        prescriptionPharmacies: PrescriptionPharmacyNew[] | undefined,
        radioPrescriptionAddress: Number | undefined,
        radioPharmacyAddress: Number | undefined,
        props: any,
        methodReceive?: number
    }) => {
    interface CardSelected {
        id?: Number,
        isAddresses: boolean
    }
    const { t } = useTranslation();
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [showModalFailWarning, setShowModalFailWarning] = useState(false);
    const [isModalOpenEditAddress, setIsModalOpenEditAddress] = useState(false);
    const [isModalOpenEditPharmacy, setIsModalOpenEditPharmacy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cardSelected, setCardSelected] = useState<CardSelected | undefined>(undefined);
    const [prescriptionAddressSelected, setPrescriptionAddressSelected] = useState<PrescriptionAddress>();
    const [prescriptionPharmacySelected, setPrescriptionPharmacySelected] = useState<PrescriptionPharmacyNew>();
    const [deletePrescriptionAddress] = useDeletePrescriptionAddressMutation();
    const [deletePrescriptionPharmacy] = useDeletePrescriptionPharmacyMutation();
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const handleEditCardInfo = (prescriptionAddresses: PrescriptionAddress | undefined,  prescriptionPharmacy: PrescriptionPharmacyNew | undefined) => {
        if (prescriptionAddresses) {
            // TODO: action with edit prescriptionAddresses
            setPrescriptionAddressSelected(prescriptionAddresses);
            setIsModalOpenEditAddress(true);
        }
        if (prescriptionPharmacy) {
            // TODO: action with edit prescriptionPharmacy
            setPrescriptionPharmacySelected(prescriptionPharmacy);
            setIsModalOpenEditPharmacy(true);
        }
    }

    const handleDeleteCardInfo = (prescriptionAddresses: PrescriptionAddress | undefined,  prescriptionPharmacy: PrescriptionPharmacyNew | undefined) => {
        // TODO: show modal confirm delete prescription
        setCardSelected({
            id: prescriptionAddresses ? prescriptionAddresses.prescription_address_id : prescriptionPharmacy?.pharmacy_id,
            isAddresses: prescriptionAddresses != undefined
        })
        setShowModalWarning(true);
    }

    const handleAcceptDeleteCardInfo = () => {
        if (!cardSelected) return;
        setIsLoading(true);
        if (cardSelected.isAddresses) {
            deletePrescriptionAddress({id: cardSelected.id}).then((res: any) => {
                setIsLoading(false);
                setShowModalWarning(false);
                if (res?.data?.error)
                    setShowModalFailWarning(true);
            });
        } else {
            deletePrescriptionPharmacy({id: cardSelected.id}).then((res: any) => {
                setIsLoading(false);
                setShowModalWarning(false);
                if (res?.data?.error)
                    setShowModalFailWarning(true);
            });
        }
    }

    const renderHeaderTitle = (prescriptionAddresses: PrescriptionAddress | undefined,  prescriptionPharmacies: PrescriptionPharmacyNew | undefined) => {
        return (
                prescriptionAddresses ? 
                <Radio value={prescriptionAddresses?.prescription_address_id} className='cardInfoTitle text-normal-styles'>
                    { 
                        radioPrescriptionAddress === prescriptionAddresses?.prescription_address_id &&
                        <span>{t('PrescriptionReceiveNew.radio-selected')}</span>
                    }
                </Radio>
                :
                <Radio value={prescriptionPharmacies?.pharmacy_id} className='cardInfoTitle text-normal-styles'>
                    { 
                        radioPharmacyAddress === prescriptionPharmacies?.pharmacy_id &&
                        <span>{t('PrescriptionReceiveNew.radio-selected')}</span>
                    }
                </Radio>
        )
    }

    const renderHeaderExtra = (prescriptionAddresses: PrescriptionAddress | undefined,  prescriptionPharmacy: PrescriptionPharmacyNew | undefined) => {
        return (
            <div>
                <Button onClick={() => handleEditCardInfo(prescriptionAddresses, prescriptionPharmacy)} type='text' icon={<img src={Edit} alt=''/>}/>
                <Button onClick={() => handleDeleteCardInfo(prescriptionAddresses, prescriptionPharmacy)} type='text' icon={<img src={Trash} alt=''/>}/>
            </div>
        )
    }

    const renderBodyContent = (prescriptionAddresses: PrescriptionAddress | undefined,  prescriptionPharmacies: PrescriptionPharmacyNew | undefined) => {
        const content = 
            prescriptionAddresses ? 
                prescriptionAddresses?.regist_name + '<br/>' + 
                t('PrescriptionReceiveNew.label-postal-code') + prescriptionAddresses?.postal_code + '<br/>' + 
                prescriptionAddresses?.prefectures_name + 
                prescriptionAddresses?.city_name + 
                prescriptionAddresses?.address + '<br/>' + 
                prescriptionAddresses?.send_name + t('PrescriptionReceiveNew.label-send-name')
            :
                prescriptionPharmacies?.pharmacy_name + '<br/>' + 
                t('PrescriptionReceiveNew.label-postal-code') + prescriptionPharmacies?.postal_code + '<br/>' + 
                prescriptionPharmacies?.prefectures_name + 
                prescriptionPharmacies?.city_name + 
                prescriptionPharmacies?.address + '<br/>' + 
                t('PrescriptionReceiveNew.label-pharmacy');
        return (
            <span dangerouslySetInnerHTML={replaceWithBr(content)}></span>
        )
    }

    return (
        <>
            <Col span={24} className='prescriptionInfo'>
                <Row gutter={[{ xs: 8, sm: 8, md: 8 }, { xs: 8, sm: 8, md: 8 }]}>
                    <Col className='prescriptionInfoTitle text-bold-styles'>
                        {title}
                    </Col>
                    <Col className='prescriptionInfoButton'>
                        <Button className='button text-bold-styles' 
                                icon={<img src={Plus} alt=''/>}
                                onClick={() => handleClick()}
                                >
                            {button}
                        </Button>
                    </Col>
                </Row>
                {
                    prescriptionAddresses &&
                    <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20, md: 20 }]} style={{marginTop: 24}}>
                        {
                            prescriptionAddresses.map((data, index) => {
                                return (
                                    <Col xs={24} sm={24} md={8} key={index}>
                                        <Card title={renderHeaderTitle(data, undefined)} extra={renderHeaderExtra(data, undefined)}>
                                            {renderBodyContent(data, undefined)}
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
                {
                    prescriptionPharmacies &&
                    <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20, md: 20 }]} style={{marginTop: 24}}>
                        {
                            prescriptionPharmacies.map((data, index) => {
                                return (
                                    <Col xs={24} sm={24} md={8} key={index}>
                                        <Card title={renderHeaderTitle(undefined, data)} extra={renderHeaderExtra(undefined, data)}>
                                            {renderBodyContent(undefined, data)}
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
            </Col>
            {isLoading && <Loading />}
            {
                showModalWarning &&
                <ModalWarning
                    message={t('message.MESS_CMN_CNF_DELETE.text').replace('{0}', t('PrescriptionReceiveNew.modal-message'))}
                    title={t('message.MESS_CMN_CNF_DELETE.title')}
                    typeModal={MODAL_WARNING}
                    typeButton={BUTTON_CHOOSE}
                    changeVisibleModal={() => setShowModalWarning(false)}
                    changeConfirm={() => handleAcceptDeleteCardInfo()}
                />
            }
            {
                showModalFailWarning &&
                <ModalWarning
                    message={t("message.MESS_ERR_CANNOT_REGIS")}
                    title={t('message.TITLE_FAIL')}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => setShowModalFailWarning(false)}
                />
            }
            {
                isModalOpenEditAddress &&
                <Modal
                    maskClosable={false}
                    title={t("ModalNewAddress.title")}
                    centered
                    open={true}
                    onCancel={() => setIsModalOpenEditAddress(false)}
                    footer={null}
                    className='modalNewAddress'
                >
                    <ModalNewAddress
                        cityList={props.cityList}
                        prefList={props.prefList}
                        typeModal
                        closeModal={() => setIsModalOpenEditAddress(false)}
                        prescriptionAddress={prescriptionAddressSelected}
                        />
                </Modal>
            }
            {
                isModalOpenEditPharmacy &&
                <Modal
                    maskClosable={false}
                    title={t("ModalNewAddress.title")}
                    centered
                    open={true}
                    onCancel={() => setIsModalOpenEditPharmacy(false)}
                    footer={null}
                    className='modalNewAddress'
                >
                    <RegisterAddress
                        prefList={props.prefList}
                        cityList={props.cityList}
                        typeModal
                        setIsModalOpenNewPharmacy={setIsModalOpenEditPharmacy}
                        setIsModalOpenEditPharmacy={setIsModalOpenEditPharmacy}
                        prescriptionPharmacy={prescriptionPharmacySelected}
                        setVisibleSuccess={setVisibleSuccess}
                        methodReceive={methodReceive}
                        tabIndex={3}
                        setCityList={props.setCityList}
                    />
                </Modal>
            }
            {
            visibleSuccess &&
                <ModalWarning
                    message={t("message.MESS_INF_ADD_ADDRESS_SUCCESS.text")}
                    typeModal={MODAL_SUCCESS}
                    typeButton={BUTTON_CLOSE}
                    changeVisibleModal={() => {
                        setVisibleSuccess(false)
                    }}
                />
            }
        </>
    )
}

export default PrescriptionReceiveNew;
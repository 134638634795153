import TabbarSearch from "src/Component/TabbarSearch";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Checkbox, Col, Dropdown, MenuProps, Row, Tooltip } from "antd";
import './styles.css';
import { useTranslation } from "react-i18next";
import active_menu from '../../../assets/admin/active-menu.svg';
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { BUTTON_ACCEPT, MODAL_SUCCESS, checkStatus, formatYYYYMMDDNoSlash, getAge, timeFrame } from "src/constant";
import { useCountPatientsBookingToDayQuery, useCountPatientsCompletedExaminationToDayQuery, useReservationInfoListQuery } from 'src/Network/Home/DoctorApi';
import { replaceWithBr } from "src/Utils/textUtils";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import active_item from '../../../assets/admin/active_item.svg';
import download_icon from '../../../assets/admin/download_icon.svg';
import './styles.css';
import CustomPagination from "src/Component/CustomPagination";
import { ReservationInfoList } from "src/Network/Model/ReservationInfoList";
import { Link } from "react-router-dom";
import DowloadCSVAndPrint from "../DowloadCSVAndPrint";
import { getClinicIdFromLocalStorage } from "src/common/Libs";
import Loading from "src/Component/Modal/Loading";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useAppDispatch } from "src/Redux/hooks";
import { changeShowModalCancel } from "./slice";
import CancelSchedule from "../CancelSchedule";
import ModalWarning from "src/Component/Modal/ModalWarning";

const Reservation = () => {
    const { t } = useTranslation();
    const [clinicId] = useState(getClinicIdFromLocalStorage());
    const [valueCheckboxSearch, setValueCheckboxSearch] = useState<CheckboxValueType[]>([1, 2, 3]);
    const [rsvStatus, setRsvStatus] = useState(0);
    const [receptionStatus, setReceptionStatus] = useState(1);
    const [notDiagnosisStatus, setNotDiagnosisStatus] = useState(1);
    const [diagnosisStatus, setDiagnosisStatus] = useState(1);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateNow = year.toString() + (month < 10 ? "0" + month : month) + (day < 10 ? "0" + day : day);
    const [rsvYmd, setRsvYmd] = useState(dateNow);
    const [clinicUserId, setClinicUserId] = useState(0);
    const [treatmentMenuId, setTreatmentMenuId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [countItems, setCountItems] = useState(0);
    const [isDownloadOrPrint, setIsDownloadOrPrint] = useState(0);
    const [listItem, setListItem] = useState<ReservationInfoList[]>([]);
    const pageSize = 100;
    const [sortColor, setSortColor] = useState("");
    const dispatch = useAppDispatch();
    const [dataReservationInfo, setDataReservationInfo] = useState<ReservationInfoList>({});
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const handleSearch = (date:Dayjs = dayjs(), doctor:number = 0, clinic:number = 0) => {
        setRsvYmd(date.format(formatYYYYMMDDNoSlash));
        setClinicUserId(doctor);
        setTreatmentMenuId(clinic);
    }
    const countPatient = useCountPatientsCompletedExaminationToDayQuery(
        {
            rsv_ymd: rsvYmd,
            clinic_user_id: clinicUserId,
            treatment_menu_id: treatmentMenuId,
            clinic_id: clinicId
        }
    );
    const countBooking = useCountPatientsBookingToDayQuery(
        {
            rsv_ymd: rsvYmd,
            clinic_user_id: clinicUserId,
            treatment_menu_id: treatmentMenuId,
            clinic_id: clinicId
        }
    );
    const onChangeCheckboxSearch = (checkedValues: CheckboxValueType[]) => {
        setReceptionStatus(checkedValues.includes(1) ? 1 : 0);
        setDiagnosisStatus(checkedValues.includes(2) ? 1 : 0);
        setNotDiagnosisStatus(checkedValues.includes(3) ? 1 : 0);
        setRsvStatus(checkedValues.includes(4) ? 1 : 0);
        setValueCheckboxSearch(checkedValues);
    };
    const reservationInfoList = useReservationInfoListQuery(
        {
            rsv_ymd: rsvYmd,
            clinic_user_id: clinicUserId,
            treatment_menu_id: treatmentMenuId,
            clinic_id: clinicId,
            rsv_status: rsvStatus,
            reception_status: receptionStatus,
            diagnosis_status: diagnosisStatus,
            not_diagnosis_status: notDiagnosisStatus,
            page: currentPage,
            limit: pageSize,
            order: sort ? "1" : "0"
        }
    );
    const {isFetching} = reservationInfoList;
    const dataList = reservationInfoList?.data?.data;
    useEffect(() => {
        countPatient?.refetch();
        countBooking?.refetch();
    }, [rsvYmd, clinicUserId, treatmentMenuId]);
    const onChangeSort = () => {
        setSort(!sort);
        setSortColor(sort ? "1" : "0");
    }
    const showChexbox = (key: number) => {
        setShowCheckbox(true);
        setIsDownloadOrPrint(key);
    }
    useEffect(() => {
        reservationInfoList?.refetch();
    }, [rsvYmd, clinicUserId, treatmentMenuId, rsvStatus, receptionStatus, diagnosisStatus,  currentPage, sort])
    const options = [
        { label: t("reservation-list.header.waiting"), value: 1 },
        { label: t("reservation-list.header.waiting-for-payment"), value: 2 },
        { label: t("reservation-list.header.paid"), value: 3 },
        { label: t("reservation-list.header.cancel"), value: 4 }
    ];
    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ReservationInfoList[]) => {
            setCountItems(selectedRows.length);
            setListItem(selectedRows)
        },
    };
    const hiddenDownloadAndPrint = () => {
        setShowCheckbox(false);
        setCountItems(0);
    };
    const handleShowModalCancel = (data: ReservationInfoList) => {
        dispatch(changeShowModalCancel(true));
        setDataReservationInfo(data);
    }
    const data: ReservationInfoList[] = dataList?.map((item: ReservationInfoList, index: number) => {
        const timeFrames = timeFrame(item?.rsv_time, item?.exm_time);
        const status = checkStatus(t, item?.rsv_status, item?.reception_status, item?.diagnosis_status);
        let yearOldCustom = '';
        if (item?.user) {
            const yearOld = getAge(item?.user?.birth_year, item?.user?.birth_month, item?.user?.birth_day);
            yearOldCustom = '(' + yearOld + t('reservation-list.wrapper.yearold') + ')';
        }
        return {
            key: (currentPage - 1) * pageSize + (index + 1),
            id: item?.id,
            time: timeFrames,
            status: status,
            rsv_status: item.rsv_status,
            rsv_div: item.rsv_div,
            reception_status: item.reception_status,
            user_name: item?.user_name + yearOldCustom,
            sex: item?.user ? (item?.user?.sex === 1 ? t('register.woman') : t('register.male')) : '',
            treatment_menu_name: item?.treatment_menu_name,
            clinic_user_name: item?.clinic_user_name,
            user_id: item?.user_id,
            karute_id: item?.karute_id,
            meeting_room_id: item?.meeting_room_id,
            postal_code: item?.postal_code,
            prefectures_name: item?.prefectures_name,
            city_name: item?.city_name,
            address: item?.address,
            questions: item?.questions,
            rsv_ymd: item?.rsv_ymd,
            // reservation_user_name: item?.reservation_user_name
        }
    });
    const columns: ColumnsType<ReservationInfoList> = [
        {
            dataIndex: 'key',
            key: 'key',
            // width: '5%',
            align: 'center',
            className: 'stt'
        },
        {
            title: () => {
                return <>
                    <span>{t("reservation-list.wrapper.time")}</span>
                    <div className="sort" onClick={data?.length > 0 ? onChangeSort : undefined}>
                        <div style={{color: `${sortColor === "1" ? 'rgb(48, 138, 230)' : ''}`}}><UpOutlined /></div>
                        <div style={{color: `${sortColor === "0" ? 'rgb(48, 138, 230)' : ''}`}}><DownOutlined /></div>
                    </div>
                </>
            },
            dataIndex: 'time',
            key: 'time',
            // width: '10%',
            className: 'time'
        },
        {
            title: <span>{t("reservation-list.wrapper.status")}</span>,
            dataIndex: 'status',
            key: 'status',
            // width: '8%',
            align: 'center',
            className: 'status'
        },
        {
            title: <span>{t("reservation-list.wrapper.user_name")}</span>,
            dataIndex: 'user_name',
            key: 'user_name',
            // width: '15%',
            align: 'center',
            className: 'user_name',
            render: (data) => (
                <span dangerouslySetInnerHTML={replaceWithBr(data)}></span>
            )
        },
        {
            title: <span>{t("reservation-list.wrapper.sex")}</span>,
            dataIndex: 'sex',
            key: 'sex',
            // width: '7%',
            align: 'center',
            className: 'sex'
        },
        {
            title: <span>{t("reservation-list.wrapper.treatment_menu_name")}</span>,
            dataIndex: 'treatment_menu_name',
            key: 'treatment_menu_name',
            // width: '15%',
            align: 'center',
            className: 'treatment_menu_name'
        },
        {
            title: <span>{t("reservation-list.wrapper.medical_record_storage")}</span>,
            dataIndex: 'medical_record_storage',
            key: 'medical_record_storage',
            // width: '10%',
            align: 'center',
            className: 'medical_record_storage',
            render: (_text, data) => {
                let displayIconKarute = false;
                if ((data.user_id === null && data.karute_id === null) || (data.user_id && data.karute_id)) {
                    displayIconKarute = true;
                }
                if (displayIconKarute) {
                    return (
                        <Link to={process.env.REACT_APP_CHART_DETAIL + '?karute_id=' + data?.karute_id || ''} target="_blank">
                            <Tooltip title={t('admin-schedule.reservation-frame-detail.already-register-karute')}>
                                <img src={download_icon} />
                            </Tooltip>
                        </Link>
                    );
                } else {
                    return '';
                }
            }
        },
        {
            title: <span>{t("reservation-list.wrapper.question")}</span>,
            dataIndex: 'question',
            key: 'question',
            // width: '9%',
            align: 'center',
            className: 'question',
            render: (_text, data) => {
                let displayIconQuestion = false;
                if (data?.questions !== null) {
                    displayIconQuestion = true;
                }
                if (displayIconQuestion) {
                    return (
                        <Link to={process.env.REACT_APP_PATIENT_INFO || ''} target="_blank">
                            <Tooltip title={t('admin-schedule.reservation-frame-detail.already-answer-questions')}>
                                <img src={download_icon} />
                            </Tooltip>
                        </Link>
                    )
                } else {
                    return '';
                }
            }
        },
        {
            title: <span>{t("reservation-list.wrapper.clinic_user_name")}</span>,
            dataIndex: 'clinic_user_name',
            key: 'clinic_user_name',
            // width: '14%',
            align: 'center',
            className: 'clinic_user_name',
            render: (data) => (
                <span dangerouslySetInnerHTML={replaceWithBr(data)}></span>
            )
        },
        {
            dataIndex: 'icon',
            key: 'icon',
            // width: '5%',
            align: 'center',
            className: 'icon',
            render: (_text, data) => {
                const rawData: MenuProps['items'] = [
                    {
                      label: <Link to={process.env.REACT_APP_MEETING_ROOM + '?room=' + data.meeting_room_id || ''} target="_blank">{t('reservation-list.to_medical_room')}</Link>,
                      key: 0,
                    },
                    {
                      label: <span onClick={() => handleShowModalCancel(data)}>{t('reservation-list.header.cancel')}</span>,
                      key: 1,
                    }
                ];
                const items = data.rsv_status === 1 && data.rsv_div === 0 ? rawData.filter(i => i?.key === 0) : data.rsv_status === 1 && data.reception_status === 2 ? rawData.filter(i => i?.key === 1) : [] as MenuProps['items'];
                if((data.rsv_status === 1 && data.rsv_div === 0) || (data.rsv_status === 1 && data.reception_status === 2)) {
                    return <Dropdown menu={{ items }} trigger={['click']}>
                        <img className="active_row" src={active_item} />
                    </Dropdown>
                } else {
                    return '';
                }
            }
        }
    ];
    const totalItems = reservationInfoList?.data?.paginate?.total;
    const items: MenuProps['items'] = [
        {
          label: <div onClick={() => showChexbox(0)}><span>{t('reservation-list.download_csv')}</span></div>,
          key: 0,
        },
        {
          label: <div onClick={() => showChexbox(1)}><span>{t('reservation-list.print')}</span></div>,
          key: 1,
        }
    ];
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    useEffect(() => {
        if (currentPage) {
          const reservations = document.querySelectorAll('.reservations')[0];
          const listItems = reservations?.querySelectorAll('.ant-pagination-item');
          const itemActive = reservations?.querySelectorAll('.ant-pagination-item-active');
          const titleActive = itemActive[0]?.getAttribute('title');
          let titleLastItem = '0';
          
          if (listItems.length > 3) {
            const classLastItemname = listItems[listItems.length - 1].className;
            const hasItemLast = classLastItemname.includes('item-last');
            if (!hasItemLast) {
              listItems[listItems.length - 1].className = classLastItemname + ' item-last';
            }
            titleLastItem = listItems[listItems.length - 1].getAttribute('title') as string;
          }
    
          if (Number(titleActive) >= 3) {
            const classFirstItemname = listItems[0].className;
            const hasItemNone = classFirstItemname.includes('item-first');
            if (!hasItemNone) {
              listItems[0].className = classFirstItemname + ' item-first';
            }
          } else {
            const firstItem = reservations?.querySelectorAll('.item-first');
            let firstClassItem = firstItem[0]?.className;
            if (firstClassItem) {
              const classArray = firstClassItem.split(' ');
              const filteredClassArray = classArray.filter(className => className !== 'item-first');
              firstClassItem = filteredClassArray.join(' ');
              firstItem[0].className = firstClassItem;
            }
          }
    
          if (Number(titleActive) >= Number(titleLastItem) -1) {
            const lastItem = reservations?.querySelectorAll('.item-last');
            let lastClassItem = lastItem[0]?.className;
            if (lastClassItem) {
              const classArray = lastClassItem.split(' ');
              const filteredClassArray = classArray.filter(className => className !== 'item-last');
              lastClassItem = filteredClassArray.join(' ');
              lastItem[0].className = lastClassItem;
            }
          }
        }
    }, [currentPage, data]);
    return (
        <>
            <div className="header-reservation">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <TabbarSearch handleSearch={(date:Dayjs = dayjs(), doctor:number = 0, clinic:number = 0) => {handleSearch(date, doctor, clinic)}} isNextDay/>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <div className="reservation_checkbox">
                            <Checkbox.Group options={options} defaultValue={valueCheckboxSearch} onChange={onChangeCheckboxSearch} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <div className="header-right">
                            <div>
                                <span>{t("reservation-list.header.today-medical")}</span>
                            </div>
                            <div>
                                <span>{typeof countPatient?.data === 'number' ? countPatient.data : 0}/{typeof countBooking?.data === 'number' ? countBooking.data : 0}</span>
                            </div>
                            <div className="active_menu">
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <img src={active_menu} />
                                </Dropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="reservations">
                <Table
                    rowSelection={showCheckbox ? {
                        type: selectionType,
                        ...rowSelection,
                    } : undefined}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    bordered
                    locale={{emptyText: t("reservation-info.empty_data")}}
                    className="reservationsTbl"
                />
                {totalItems > pageSize &&
                    <CustomPagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={pageSize}
                        setCurrentPage={setCurrentPage}
                    />
                }
                {isFetching && <Loading />}
            </div>
            {
                showCheckbox && <DowloadCSVAndPrint
                    countItems={countItems}
                    isDownloadOrPrint={isDownloadOrPrint}
                    hiddenDownloadAndPrint={hiddenDownloadAndPrint}
                    listItem={listItem}
                />
            }
            <CancelSchedule
                dataReservationInfo={dataReservationInfo}
                setVisibleSuccess={setVisibleSuccess}
            />
            {
                visibleSuccess &&
                <ModalWarning
                    message={t("cancelRsv.cancelSuccess")}
                    typeModal={MODAL_SUCCESS}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {
                        setVisibleSuccess(false)
                    }}
                />
            }
        </>
    )
}

export default Reservation;
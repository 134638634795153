import { Button, Pagination } from 'antd'
import './styles.css'
// import chevronsLeftIcon from '../../assets/image/chevrons-left.svg'
// import chevronsRightIcon from '../../assets/image/chevrons-right.svg'
import { ReactComponent as ChevronsLeftIcon } from '../../assets/image/chevrons-left.svg'
import { ReactComponent as ChevronsRightIcon } from '../../assets/image/chevrons-right.svg'

type Props = {
    current: number,
    total: number,
    pageSize: number,
    setCurrentPage: (a:number) => void
}

const CustomPagination:React.FC<Props> = ({ current, total, pageSize, setCurrentPage }) => {

    const totalPages = Math.ceil(total / pageSize);

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
    }

    const toFirstPage = () => {
        setCurrentPage(1);
    }

    const toLastPage = () => {
        setCurrentPage(totalPages);
    }

    return (
        <div className="footer-pagination">
            <Button
                icon={<ChevronsLeftIcon/>}
                style={{marginRight: 8}}
                onClick={toFirstPage}
                className={`page-icon ${current === 1 ? '': "active"}`}
            />
            <Pagination
                current={current}
                onChange={handleChangePage}
                total={total}
                pageSize={pageSize}
                showSizeChanger={false}
                showLessItems={true}
            />
            <Button
                icon={<ChevronsRightIcon />}
                style={{marginLeft: 8}}
                onClick={toLastPage}
                className={`page-icon ${current === totalPages ? '': "active"}`}
            />
        </div>
    )
}
export default CustomPagination
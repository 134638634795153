import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  changeDto,
  changeExamMethod,
  changeOpenConfirmModal,
  selectEditMedicalMenu,
  selectIsCreate
} from '../../store/slice';
import { FormData, MedicalMenuFormSchema } from '../constants';
import CommonForm from './CommonForm';
import ScheduleDirectForm from './ScheduleDirectForm';
import ScheduleOnlineForm from './ScheduleOnlineForm';

const MedicalFormContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const editMedicalMenu = useAppSelector(selectEditMedicalMenu);
  const isCreate = useAppSelector(selectIsCreate);

  const defaultValues = {
    treatment_div: 1,
    public_status: 1,
    reservation_frame_public: 1,

    f_unapproved_devices_drugs: 0,
    f_rsv_acceptance_pediod_type: 1,

    first_exm_flg: 0,
    unapproved_devices_drugs: 0,
    rsv_acceptance_pediod_type: 1
  };

  const formMethods = useForm<FormData>({
    resolver: yupResolver(MedicalMenuFormSchema),
    defaultValues
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    clearErrors
  } = formMethods;

  const examMethod = watch('exm_method');

  const onFinish = (values: FormData) => {
    dispatch(changeOpenConfirmModal(true));
    dispatch(changeDto({ ...values }));
  };

  useEffect(() => {
    if (!isCreate && editMedicalMenu) {
      reset(editMedicalMenu);
      if (editMedicalMenu.consent_form_id === null) {
        setValue('consent_form_id', 0);
      }
    } else {
      reset(defaultValues);
    }
    dispatch(changeDto(undefined));
  }, [isCreate, editMedicalMenu]);

  useEffect(() => {
    if (examMethod || examMethod === 0) {
      dispatch(changeExamMethod(examMethod));
    } else {
      dispatch(changeExamMethod(1));
    }
    clearErrors();
  }, [examMethod]);

  const f_consul_time_first_exm = watch('f_consul_time_first_exm');
  const f_consul_time_re_exm = watch('f_consul_time_re_exm');
  const f_rsv_fee = watch('f_rsv_fee');
  const f_consul_hours_pt = watch('f_consul_hours_pt');
  const consul_time_first_exm = watch('consul_time_first_exm');
  const consul_time_re_exm = watch('consul_time_re_exm');
  const rsv_fee = watch('rsv_fee');
  const consul_hours_pt = watch('consul_hours_pt');

  useEffect(() => {
    if (f_consul_time_first_exm && f_consul_time_first_exm % 1 !== 0) {
      setValue('f_consul_time_first_exm', 1);
    }
    if (f_consul_time_re_exm && f_consul_time_re_exm % 1 !== 0) {
      setValue('f_consul_time_re_exm', 1);
    }
    if (consul_time_first_exm && consul_time_first_exm % 1 !== 0) {
      setValue('consul_time_first_exm', 1);
    }
    if (consul_time_re_exm && consul_time_re_exm % 1 !== 0) {
      setValue('consul_time_re_exm', 1);
    }
    if (f_rsv_fee && f_rsv_fee % 1 !== 0) {
      setValue('f_rsv_fee', 1);
    }
    if (f_consul_hours_pt && f_consul_hours_pt % 1 !== 0) {
      setValue('f_consul_hours_pt', 1);
    }
    if (rsv_fee && rsv_fee % 1 !== 0) {
      setValue('rsv_fee', 1);
    }
    if (consul_hours_pt && consul_hours_pt % 1 !== 0) {
      setValue('consul_hours_pt', 1);
    }
  }, [
    f_consul_time_first_exm,
    f_consul_time_re_exm,
    consul_time_first_exm,
    consul_time_re_exm,
    f_rsv_fee,
    f_consul_hours_pt,
    rsv_fee,
    consul_hours_pt
  ]);

  return (
    <FormProvider {...formMethods}>
      <Form name="medicalMenuForm" onFinish={handleSubmit(onFinish)}>
        <CommonForm errors={errors} watch={watch} />
        {[0, 1, 2].includes(examMethod) && (
          <>
            <ScheduleDirectForm
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
            <ScheduleOnlineForm
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          </>
        )}
        <div className="medical-menu-form_submit-button_wrapper">
          <button className="medical-menu-form_submit-button" type="submit">
            {isCreate
              ? t('reExamCode.createMedicalMenu')
              : t('reExamCode.editMedicalMenu')}
          </button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default MedicalFormContent;

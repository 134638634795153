import { Col, Input, Modal, Radio, Row } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { useState } from 'react';
import FormInputTextArea from 'src/Component/common/form/FormInputTextArea';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { changeShowModalCancel, selectShowModalCancel } from '../Reservation/slice';
import { RadioChangeEvent } from 'antd/lib';
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import { ReservationInfoList } from 'src/Network/Model/ReservationInfoList';
import dayjs from 'dayjs';
import { BUTTON_ACCEPT, MODAL_FAIL, birthdayJanpan } from 'src/constant';
import { useCancelReservationInfoMutation } from 'src/Network/Home/DoctorApi';
import Loading from 'src/Component/Modal/Loading';
import ModalWarning from "src/Component/Modal/ModalWarning";

type Props = {
    dataReservationInfo: ReservationInfoList,
    setVisibleSuccess: Function
}
const CancelSchedule: React.FC<Props> = ({dataReservationInfo, setVisibleSuccess}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showModalCancel = useAppSelector(selectShowModalCancel);
    const [valueRadio, setValueRadio] = useState(t("cancelRsv.patientNotCome"));
    const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelReservationInfo] = useCancelReservationInfoMutation();
    const [visibleWarning, setVisibleWarning] = useState(false);
    const hideModal = () => {
        dispatch(changeShowModalCancel(false));
        reset();
        setValueRadio(t("cancelRsv.patientNotCome"));
    }
    const onChange = (e: RadioChangeEvent) => {
        setValueRadio(e.target.value);
    };
    const defaultValues = {
        text_send_to_patient: "",
    };
    const schema = yup.object().shape({
        text_send_to_patient: yup
        .string()
        .test(
            'lengthFullAndHalfWidth',
            t('register.label-maxlength')
                .replace('{0}', t("cancelRsv.msgToPatient"))
                .replace('{1}', '2000'),
            (value) => {
                return lengthFullAndHalfWidth(value) <= 2000;
            },
        )
    });
    type FormData = yup.InferType<typeof schema>;
    const methods = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(schema),
        mode: "all",

    });
    const { reset, watch } = methods;
    const onSubmit = () => {
        setOpenConfirmCancelModal(true);
    }
    const text_send_to_patient = watch('text_send_to_patient');
    const rsv_ymd = dayjs(dataReservationInfo?.rsv_ymd)
    const formatRSVYMD = birthdayJanpan(rsv_ymd.year(), rsv_ymd.month() + 1, rsv_ymd.date());
    const handleCancelRsv = () => {
        const param = {
            id: dataReservationInfo.id,
            rsv_cancel_reason: valueRadio,
            text_send_to_patient: text_send_to_patient,
            rsv_status: 0
        };
        setIsLoading(true);
        cancelReservationInfo(param).then((res: any) => {
            if (!res?.data?.error) {
                setOpenConfirmCancelModal(false);
                setVisibleSuccess(true);
                dispatch(changeShowModalCancel(false));
                reset();
                setValueRadio(t("cancelRsv.patientNotCome"));
            } else {
                setVisibleWarning(true);
            }
            setIsLoading(false);
        });
    }

    return (
        <div>
            <Modal
                title={t("cancelRsv.cancelReason")}
                centered
                onOk={hideModal}
                onCancel={hideModal}
                open={showModalCancel}
                footer={null}
                className="wrapper-cancel"
                maskClosable={false}
                style={{ top: 0, paddingTop: 20, maxHeight: 'calc(75vh - 50px)' }}
                // closeIcon={null}
                zIndex={998}
            >
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className="cancel-container">
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Radio.Group name="rsv_cancel_reason" value={valueRadio} className="searchRadio" onChange={onChange}>
                                            <Radio value={t("cancelRsv.patientNotCome")} checked>{t("cancelRsv.patientNotCome")}</Radio>
                                            <Radio value={t("cancelRsv.patientCancel")}>{t("cancelRsv.patientCancel")}</Radio>
                                            <Radio value={t("cancelRsv.doctorNotCome")}>{t("cancelRsv.doctorNotCome")}</Radio>
                                            <Radio value={t("cancelRsv.other")}>{t("cancelRsv.other")}</Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <span>{t("cancelRsv.msgToPatient")}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <FormInputTextArea
                                            rows={5}
                                            name="text_send_to_patient"
                                            placeholder={t("cancelRsv.plsEnterReason")}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Input
                                            type="submit"
                                            className="footer_cancel text-bold-styles"
                                            value={t("cancelRsv.cancelBtn")}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </FormProvider>
            </Modal>
            <Modal className={"modalConfirm"} title="" open={openConfirmCancelModal} destroyOnClose={true}
                closeIcon={true} onCancel={() => setOpenConfirmCancelModal(false)}
                onOk={handleCancelRsv}
                centered
                okText={t("cancelRsv.confirm")} cancelText={t("cancelRsv.cancel")}
            >
                <p style={{fontWeight: "bold"}}> {t("cancelRsv.confirm-cancel")}</p>
                <p>{formatRSVYMD}{dataReservationInfo?.time}</p>
                <p>{dataReservationInfo?.user_name}</p>
                <p>{dataReservationInfo?.treatment_menu_name}</p>
                {isLoading && <Loading />}
            </Modal>
            {
                visibleWarning &&
                <ModalWarning
                    message={t("reservation-info.MESS_RSV_CANCEL_FAIL")}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {setVisibleWarning(false)}}
                />
            }
        </div>
    )
}

export default CancelSchedule;
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker, Form } from "antd";
import dayjs from "dayjs";

const FormInputCalendarDays = ({ inputProps, ...props }: any) => {
  const { control, register } = useFormContext();
  return (
    <Controller
      name={props.name}
      {...props}
      control={control}
      defaultValue={props.defaultValue}
      render={({
        field: { value, onChange },
        fieldState: { error },
      }) => 
      {
        value=dayjs(value);
        return (
          <Form.Item
            className={props.className}
            validateStatus={error ? 'error' : 'validating'}
            help={error?.message}
          >
            <DatePicker
              disabled={props.disabled}
              ref={register(props?.name).ref}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              {...{ value, onChange }}
              format={props.format}
              disabledDate={props.disabledDate}
              {...inputProps}
              allowClear={false}
            />
          </Form.Item>
        );}
      }
    />
  );
};

export default FormInputCalendarDays;

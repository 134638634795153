import { CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { TreatmentMenuType } from 'src/Network/Model/DoctorSetting';
import { useAppDispatch } from 'src/Redux/hooks';
import { RsvAcceptPeriodListType, rsvAcceptPeriodList } from 'src/constant';
import { ReactComponent as EditIcon } from '../../../../../assets/image/edit_icon.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/image/info.svg';
import {
  changeEditMedicalMenu,
  changeIsCreate,
  changeIsListMode
} from '../store/slice';

type Props = {
  dataItem: TreatmentMenuType;
};

const MedicalMenuRowItem: React.FC<Props> = ({ dataItem }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const rowspanAll = dataItem.exm_method == 2 ? 4 : 2;
  const handleEditItem = (item: TreatmentMenuType) => {
    dispatch(changeIsListMode(false));
    dispatch(changeIsCreate(false));
    dispatch(changeEditMedicalMenu(item));
  };

  const handleRsvAcceptPeriod = (
    type: keyof TreatmentMenuType,
    fromKey: keyof TreatmentMenuType,
    toKey: keyof TreatmentMenuType
  ) => {
    if (dataItem[type] === 1) {
      return t('reExamCode.setupMedicalBasicInfo');
    } else {
      const periodFrom =
        rsvAcceptPeriodList[dataItem[fromKey] as keyof RsvAcceptPeriodListType];
      const periodTo =
        rsvAcceptPeriodList[dataItem[toKey] as keyof RsvAcceptPeriodListType];
      return `${periodFrom} ~ ${periodTo}`;
    }
  };

  return (
    <>
      <tr>
        <td rowSpan={rowspanAll}>
          <div className="treatment-menu-name_container">
            <span>{dataItem.treatment_menu_name}</span>
            <div
              style={{
                backgroundColor:
                  dataItem.public_status === 1 ? '#308AE6' : '#ACB4BC',
                  whiteSpace: 'nowrap'
              }}
            >
              {dataItem.public_status === 1
                ? t('reExamCode.public')
                : t('reExamCode.private')}
            </div>
          </div>
        </td>
        <td rowSpan={rowspanAll}>
          {dataItem.treatment_div === 1
            ? t('reExamCode.examWithInsurance')
            : t('reExamCode.freeExam')}
        </td>
        <td rowSpan={rowspanAll}>
          <div className="reservation-frame-public_item">
            <span>{t('reExamCode.hospistal')}</span>
            <CheckOutlined style={{ fontSize: 20, color: '#06AD53' }} />
          </div>
          {dataItem.reservation_frame_public === 1 && (
            <div className="reservation-frame-public_item">
              <span>{t('reExamCode.patient')}</span>
              <CheckOutlined style={{ fontSize: 20, color: '#06AD53' }} />
            </div>
          )}
        </td>
        <td rowSpan={2}>
          {dataItem.exm_method === 0
            ? t('reExamCode.online')
            : t('reExamCode.inPerson')}
        </td>
        <td rowSpan={1}>{t('reExamCode.firstExam')}</td>
        <td rowSpan={1}>
          {t('reExamCode.consultTimeValue', {
            value:
              dataItem.exm_method === 0
                ? dataItem.consul_time_first_exm
                : dataItem.f_consul_time_first_exm
          })}
        </td>
        <td rowSpan={1}>
          {dataItem.exm_method === 0
            ? handleRsvAcceptPeriod(
                'rsv_acceptance_pediod_type',
                'rsv_acceptance_pediod_first_exm_from',
                'rsv_acceptance_pediod_first_exm_to'
              )
            : handleRsvAcceptPeriod(
                'f_rsv_acceptance_pediod_type',
                'f_rsv_acceptance_pediod_first_exm_from',
                'f_rsv_acceptance_pediod_first_exm_to'
              )}
        </td>
        <td rowSpan={2}>
          {t('reExamCode.rsvFeeValue', {
            value:
              dataItem.exm_method === 0 ? dataItem.rsv_fee : dataItem.f_rsv_fee
          })}
        </td>
        <td rowSpan={2}>
          {dataItem.exm_method === 0
            ? dataItem.first_exm_flg === 1
              ? t('reExamCode.need')
              : t('reExamCode.notNeed')
            : '-'}
        </td>
        <td rowSpan={rowspanAll}>
          <div className="medical-menu_consent-form_container">
            {dataItem.consent_form_id ? (
              <>
                <span>{t('reExamCode.able')}</span>
                <Tooltip title={dataItem?.consent_form?.title}>
                  <InfoIcon className="medical-menu_info-icon" />
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </div>
        </td>
        <td rowSpan={rowspanAll}>
          <div className="medical-menu_edit_container">
            <button
              onClick={() => handleEditItem(dataItem)}
              className="medical-menu_edit_button"
            >
              <EditIcon />
              <span>{t('reExamCode.edit')}</span>
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td rowSpan={1}>{t('reExamCode.reExam')}</td>
        <td rowSpan={1}>
          {t('reExamCode.consultTimeValue', {
            value:
              dataItem.exm_method === 0
                ? dataItem.consul_time_re_exm
                : dataItem.f_consul_time_re_exm
          })}
        </td>
        <td rowSpan={1}>
          {dataItem.exm_method === 0
            ? handleRsvAcceptPeriod(
                'rsv_acceptance_pediod_type',
                'rsv_acceptance_pediod_re_exm_from',
                'rsv_acceptance_pediod_re_exm_to'
              )
            : handleRsvAcceptPeriod(
                'f_rsv_acceptance_pediod_type',
                'f_rsv_acceptance_pediod_re_exm_from',
                'f_rsv_acceptance_pediod_re_exm_to'
              )}
        </td>
      </tr>
      {dataItem.exm_method === 2 && (
        <>
          <tr>
            <td rowSpan={2}>{t('reExamCode.online')}</td>
            <td rowSpan={1}>{t('reExamCode.firstExam')}</td>
            <td rowSpan={1}>
              {t('reExamCode.consultTimeValue', {
                value: dataItem.consul_time_first_exm
              })}
            </td>
            <td rowSpan={1}>
              {handleRsvAcceptPeriod(
                'rsv_acceptance_pediod_type',
                'rsv_acceptance_pediod_first_exm_from',
                'rsv_acceptance_pediod_first_exm_to'
              )}
            </td>

            <td rowSpan={2}>
              {t('reExamCode.rsvFeeValue', {
                value: dataItem.rsv_fee
              })}
            </td>
            <td rowSpan={2}>
              {dataItem.first_exm_flg === 1
                ? t('reExamCode.need')
                : t('reExamCode.notNeed')}
            </td>
          </tr>
          <tr>
            <td rowSpan={1}>{t('reExamCode.reExam')}</td>
            <td rowSpan={1}>
              {t('reExamCode.consultTimeValue', {
                value: dataItem.consul_time_re_exm
              })}
            </td>
            <td rowSpan={1}>
              {handleRsvAcceptPeriod(
                'rsv_acceptance_pediod_type',
                'rsv_acceptance_pediod_re_exm_from',
                'rsv_acceptance_pediod_re_exm_to'
              )}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default MedicalMenuRowItem;

import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import BackToTop from "../Component/BackToTop";
import './styles.css';

type Props = {
    children: React.ReactNode
}

const Layout:React.FC<Props> = ({children}) => {
    return <div className="layout">
     <Header />
     <div style={{ height: 80 }}></div>
     {children}
     <BackToTop />
     <Footer />
    </div>
}

export default Layout;
import { TreatmentMenuType } from 'src/Network/Model/DoctorSetting';
import MedicalMenuRowItem from './MedicalMenuRowItem';
import { useTranslation } from 'react-i18next';

type Props = {
  data: TreatmentMenuType[];
};

const MedicalMenuTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const colList = [
    {
      id: 0,
      label: t('reExamCode.medicalTreatmentMenu'),
      colspan: 1,
      className: 'treatment-menu-name-th'
    },
    { id: 1, label: t('reExamCode.treatmentCategory'), colspan: 1 },
    { id: 2, label: t('reExamCode.rsvFrameStatus'), colspan: 1 },
    { id: 3, label: t('reExamCode.examMethod'), colspan: 2 },
    { id: 4, label: t('reExamCode.rsvTime'), colspan: 1 },
    { id: 6, label: t('reExamCode.reservationAcceptTime'), colspan: 1 },
    { id: 7, label: t('reExamCode.rsvFee'), colspan: 1 },
    {
      id: 5,
      label: t('reExamCode.enterReExamCode'),
      colspan: 1
    },
    { id: 8, label: t('reExamCode.consentForm'), colspan: 1 },
    { id: 9, label: '', colspan: 1 }
  ];

  return (
    <div className="medical-menu_table_container tabsetting-scrollbar-style">
      <table className="medical-menu_table">
        <thead>
          <tr>
            {colList.map((item, index) => {
              return (
                <th
                  className={item?.className}
                  key={index}
                  colSpan={item.colspan}
                >
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="medical-menu_table-body">
          {data.map((item, index) => (
            <MedicalMenuRowItem dataItem={item} key={index} />
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={11}>
                <div className="medical-menu_table_no-data">
                  <span>{t('reExamCode.medicalMenuTableNoDataMess')}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MedicalMenuTable;

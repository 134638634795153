import './styles.css';
import {Button, Select} from "antd";
import ModalWarning from 'src/Component/Modal/ModalWarning';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  BUTTON_CLOSE,
  HALF_AN_HOURS,
  HOURS_IN_DAY,
  MODAL_CONFIRM,
  MODAL_NEGATIVE,
  QUARTER_AN_HOURS
} from "../../../constant";
import {CloseOutlined} from "@ant-design/icons";
import {useAddCloseTimeMutation} from "../../../Network/Home/DoctorApi";
import {getClinicIdFromLocalStorage} from "../../../common/Libs";
import dayjs from "dayjs";

const APP = ({
               onClose,
               treatmentMenuIds,
               selectedDate,
               isApplyAllTreatmentMenu,
               treatmentMenuName, 
               onAddFinish,
               exmTime,
               initialTime
             }: { onClose: (event: React.MouseEvent<HTMLInputElement>) => void, treatmentMenuIds: number[], selectedDate: string, isApplyAllTreatmentMenu: boolean, treatmentMenuName: String, onAddFinish: (event: CustomEvent) => void, exmTime: number, initialTime: string}) => {

  const {t} = useTranslation();
  const [optionTime, setOptionTime] = useState<any[]>([]);
  const [selectedStartTime, setSelectedStartTime] = useState<string>();
  const [selectedEndTime, setSelectedEndTime] = useState<string>();
  const [addCloseTime] = useAddCloseTimeMutation();
  const [modalMsg, setModalMsg] = useState<string>();
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalType, setModalType] = useState<number>(1);
  const [modalBtnType, setModalBtnType] = useState<number>(1);
  const [errorTime, setErrorTime] = useState(false);
  
  useEffect(() => {
    setSelectedStartTime(initialTime)
    setSelectedEndTime(dayjs(initialTime,"HHmm").add(exmTime, "minute").format("HHmm"))
    },
    [initialTime])
  const handleAddCloseTime = () => {
    if (checkErrorTime())
      return;
    const param = {
      clinic_id: getClinicIdFromLocalStorage(),
      treatment_menu_ids: treatmentMenuIds,
      closed_start_time: selectedStartTime,
      closed_end_time: selectedEndTime,
      closed_ymd: selectedDate.replaceAll("-", "")
    };
    addCloseTime({params: param}).then((res: any) => {
      if (res?.data?.code && res?.data?.code === 200) {
        setModalMsg(t('MESS_INF_REGIS_CLOSED_FRAME_SUCCESS'));
        setModalType(MODAL_CONFIRM);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_SUCCESS"));
      } else {
        setModalMsg(t('MESS_ERR_CANNOT_REGIS_CLOSED_FRAME'));
        setModalType(MODAL_NEGATIVE);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_FAIL"));
      }
    });
  }

  const checkErrorTime = () => {
    const start = dayjs(selectedStartTime, "HHmm")
    const end = dayjs(selectedEndTime, "HHmm")
    if (end.isSame(start) || end.isBefore(start)) {
      setErrorTime(true);
      return true;

    }
  }

  const generateTimeOptions = () => {
    const results: any = [];

    let minuteArr: string[];
    if (exmTime == 15) {
      minuteArr = QUARTER_AN_HOURS;
    } else {
      minuteArr = HALF_AN_HOURS;
    }

    for (const hour of HOURS_IN_DAY) {
      minuteArr.forEach((minus) => {
        results.push({
          value: hour + minus,
          label: hour + ':' + minus
        });
      })
    }

    return results;
  }

  useEffect(() => {
    setOptionTime(generateTimeOptions());
  }, [])

  return <div>
    <div className={"dt044"}>
      <div>
        <div className={"title"}>
          <span> {t("addCloseTime.title")}</span>
          <span>
        <Button type='text' icon={<CloseOutlined/>} onClick={onClose} style={{color: '#878E9B'}}/>
      </span></div>
        <div style={{
          justifyContent: "space-between",
          display: "flex",
          background: "#F8F8F8",
          padding: "10px",
          margin: "20px 0 20px 0"
        }}>
          <Select
            value={selectedStartTime}
            options={optionTime}
            onChange={(e) => {
              setSelectedStartTime(e);
              setErrorTime(false);
            }} className={"selectTime"}> </Select>
          <span style={{fontSize: "16px", color: "#6C747B", marginLeft: "10px", marginRight: "10px"}}>~</span>


          <Select
            value={selectedEndTime}
            options={optionTime}
            onChange={(e) => {
              setSelectedEndTime(e)
              setErrorTime(false);
            }} className={"selectTime"}> </Select>
        </div>
        {
          errorTime &&
          <div className="errcode">{t("message.MESS_ERR_END_TIME_LESS_THAN_START_TIME.text")}</div>
        }
        <div className={"treatmentMenu"}>
          {isApplyAllTreatmentMenu ? t("addCloseTime.allTreatmentMenus") : treatmentMenuName} {isApplyAllTreatmentMenu}
        </div>
      </div>

      <Button type="text" className={"addBtn"} onClick={handleAddCloseTime}>{t("addCloseTime.settingBtn")}</Button>
    </div>
    {modalMsg && modalMsg !== '' && (
      <ModalWarning
        message={modalMsg}
        typeModal={modalType}
        typeButton={modalBtnType}
        title={modalTitle}
        changeVisibleModal={() => {
          if(modalType === MODAL_CONFIRM){
            onAddFinish(new CustomEvent(""))
          } else {
            setModalMsg('');
          }
        }}
      />
    )}
  </div>
}
export default APP;
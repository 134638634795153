import { Col, Row, Table, ConfigProvider, Button } from "antd";
import './styles.css';
import Dot from 'src/assets/image/dot.svg';
import Full from 'src/assets/image/full.svg';
import Minus from 'src/assets/image/minus.svg';
import Setting from 'src/assets/image/setting2.svg';
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useGetWeekReservationFrameQuery } from "src/Network/Home/DoctorApi";
import { useEffect, useState } from "react";
import { getClinicIdFromLocalStorage } from "src/common/Libs";
import { HALF_AN_HOURS, HOURS_IN_DAY, QUARTER_AN_HOURS } from "src/constant";
import { DataTypeTable } from "src/Network/Model/WeekReservationFrame";
import { renderColumnHour } from "src/Screen/Doctor/Schedule/ReservationSetting/splitComponent";
import Loading from "../../Loading";

const { Column, ColumnGroup } = Table;

interface DayOfWeek {
  exam: number,
  reExam: number
}
interface DataType {
  key: React.Key;
  time: string,
  halfTime: string,
  dayOfWeek_1: DayOfWeek,
  dayOfWeek_2: DayOfWeek,
  dayOfWeek_3: DayOfWeek,
  dayOfWeek_4: DayOfWeek,
  dayOfWeek_5: DayOfWeek,
  dayOfWeek_6: DayOfWeek,
  dayOfWeek_7: DayOfWeek,
}

type Props = {
    searchDate: string,
    doctorId?: number,
    treatmentMenuId?: number,
    radioValue: number,
    exmTime: number,
}

const APP: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    let weekDay = ["日", "月", "火", "水", "木", "金", "土"];
    const [clinicId] = useState(getClinicIdFromLocalStorage());
    const weekReservationFrame = useGetWeekReservationFrameQuery({
        date: props.searchDate,
        clinic_id: clinicId,
        treatment_menu_id: props.treatmentMenuId,
        clinic_user_id: props.doctorId,
        exm_method: props.radioValue
    });
    const [dataAPI, setDataAPI] = useState<DataTypeTable>();
    const [data, setData] = useState<DataType[]>();
    const {isFetching} =  weekReservationFrame;

    useEffect(() => {
        setDataAPI(weekReservationFrame.data);
    }, [weekReservationFrame.data]);

    useEffect(() => {
        weekReservationFrame.refetch();
    }, [props.searchDate, props.doctorId, props.treatmentMenuId, props.radioValue]);

    useEffect(() => {
      if (dataAPI) {
        setData(genDataSource());
      }
    }, [dataAPI]);

    const genExam = (time: string, day = '') => {
        let exam = 0;
        let reExam = 0;
        dataAPI?.data_for_frame?.forEach(element => {
            if (element.date !== day) return;
            element.first_exm.forEach(e => {
                if (e.rsv_time === time) {
                    exam = e.disable_frame;
                    return;
                }
            })
            element.re_exm.forEach(e => {
                if (e.rsv_time === time) {
                    reExam = e.disable_frame;
                    return;
                }
            })
        })
        
        return {
            exam,
            reExam
        }
    }

    const genDataSource = () => {
        let datas: DataType[] = [];
        let i = 0;
        let minuteArr: string[];
        if (props.exmTime === 15) {
            minuteArr = QUARTER_AN_HOURS;
        } else {
            minuteArr = HALF_AN_HOURS;
        }
        for (const hour of HOURS_IN_DAY) {
            minuteArr.forEach((halfTime) => {
                const data: DataType = {
                    key: i.toString(),
                    time: hour,
                    halfTime: hour + ':' + halfTime,
                    dayOfWeek_1: genExam(hour + halfTime, dataAPI?.day_of_week[0]),
                    dayOfWeek_2: genExam(hour + halfTime, dataAPI?.day_of_week[1]),
                    dayOfWeek_3: genExam(hour + halfTime, dataAPI?.day_of_week[2]),
                    dayOfWeek_4: genExam(hour + halfTime, dataAPI?.day_of_week[3]),
                    dayOfWeek_5: genExam(hour + halfTime, dataAPI?.day_of_week[4]),
                    dayOfWeek_6: genExam(hour + halfTime, dataAPI?.day_of_week[5]),
                    dayOfWeek_7: genExam(hour + halfTime, dataAPI?.day_of_week[6]),
                }
                datas.push(data);
                i++;
            })
        };
        let tmpTime = '00';
        let datasTmp = datas;
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].time !== tmpTime) {
                tmpTime = datas[i].time;
                datasTmp = datas.slice(i, datas.length);
            }
            if (!checkDayOfWeek(datas[i])) {
                break;
            }
            if (i === datas.length - 1) {
                datasTmp = [];
            }
        }
        
        datas = datasTmp;
        tmpTime = '23';
        for (let i = datas.length - 1; i >=0; i--) {
            if (datas[i].time !== tmpTime) {
                tmpTime = datas[i].time;
                datasTmp = datas.slice(0, i + 1);
            }
            if (!checkDayOfWeek(datas[i])) {
                break;
            }
        }
        datas = datasTmp;
        
        return datas;
    }

    const checkDayOfWeek = (data: DataType) => {
        if (data.dayOfWeek_1.exam !== 0 || data.dayOfWeek_1.reExam !== 0) {
            return false;
        }
        if (data.dayOfWeek_2.exam !== 0 || data.dayOfWeek_2.reExam !== 0) {
            return false;
        }
        if (data.dayOfWeek_3.exam !== 0 || data.dayOfWeek_3.reExam !== 0) {
            return false;
        }
        if (data.dayOfWeek_4.exam !== 0 || data.dayOfWeek_4.reExam !== 0) {
            return false;
        }
        if (data.dayOfWeek_5.exam !== 0 || data.dayOfWeek_5.reExam !== 0) {
            return false;
        }
        if (data.dayOfWeek_6.exam !== 0 || data.dayOfWeek_6.reExam !== 0) {
            return false;
        }
        if (data.dayOfWeek_7.exam !== 0 || data.dayOfWeek_7.reExam !== 0) {
            return false;
        }
        return true;
    }

    const renderTitleTable = (date: string, index: number) => {
        let className = '';
        if (date === '土') {
            className = 'saturday';
        } else if (date === '日') {
            className = 'sunday';
        }

        return (
            <Col style={{display: 'flex', flex: 1}}>
                <Row gutter={[{xs: 2, sm: 2, md: 2}, {}]} style={{flex: 1, alignItems: 'center'}}>
                    <Col xs={24} sm={24} md={24} style={{textAlign: 'center'}}>
                        <div className={className}>
                            {date}
                        </div>
                        <div>
                            {dayjs(dataAPI?.day_of_week[index]).format('MM/DD')}
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderDataTable = (dayOfWeek: DayOfWeek, isExam: boolean) => {
        const data = isExam ? dayOfWeek.exam : dayOfWeek.reExam;
        if (data === 1)
            return <img src={Dot} alt='' />
        else if (data === 2)
            return <img src={Full} alt='' />
        else
            return (
            <div className="minusData">
                <img src={Minus} alt='' />
            </div>
            )
    }

    const renderFooterTable = () => {
        return (
            <Row className="tableContent-footer" gutter={[{xs: 60, sm: 60, md: 60}, {}]}>
                <Col className="footerMark" style={{paddingLeft: 0}}>
                    <img src={Dot} alt="" />
                    {t("modal-confirm-setting.markdown.dot")}
                </Col>
                <Col className="footerMark">
                    <img src={Full} alt="" />
                    {t("modal-confirm-setting.markdown.full")}
                </Col>
                <Col className="footerMark">
                    <img src={Minus} alt="" />
                    {t("modal-confirm-setting.markdown.minus")}
                </Col>
            </Row>
        );
    }

    let locale = {
        emptyText: <span className="emptyText">{t("modal-confirm-setting.table-content-empty")}</span>,
    };

    return (
        <div className="tableContent">
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBorderRadius: 0
                        },
                    },
                }}
                >
                <Table 
                    dataSource={data} 
                    pagination={false} 
                    className="tableContent-body" 
                    locale={locale}
                    scroll={{ y: '40vh' }}
                    footer={renderFooterTable}
                >
                    <ColumnGroup>
                        {renderColumnHour(true, props.exmTime)}
                        <Column dataIndex="halfTime" key="halfTime"/>
                    </ColumnGroup>

                    {
                        weekDay.map((element, index) => {
                            return (
                                <ColumnGroup key={index} title={renderTitleTable(element, index)} fixed={true}>
                                    <Column title={t("modal-confirm-setting.table-content.exam")} dataIndex={`dayOfWeek_${index + 1}`} key="exam" render={(data: DayOfWeek) => renderDataTable(data, true)}/>
                                    <Column title={t("modal-confirm-setting.table-content.re-exam")} dataIndex={`dayOfWeek_${index + 1}`} key="reExam" render={(data: DayOfWeek) => renderDataTable(data, false)}/>
                                </ColumnGroup>
                            )
                        })   
                    }

                    <ColumnGroup>
                        {renderColumnHour(false, props.exmTime)}
                    </ColumnGroup>

                </Table>
                {isFetching && <Loading/>}
            </ConfigProvider>
        </div>
    )
}

export default APP;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetReservationQuery,
  useUpdateReservationMutation
} from 'src/Network/Home/DoctorSettingApi';
import { ReservationUpdateDto } from 'src/Network/Model/DoctorSetting';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import ReservationUrlItem from './ReservationUrlItem';
import ErrorModal from '../ErorrModal';
import { Switch } from 'antd';
import { useAppSelector } from 'src/Redux/hooks';
import { selectEndValue, selectHomepageUrl, selectReExamCode, selectStartValue } from '../../medicalMenu/store/slice';
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';

const ReservationURL: React.FC = () => {
  const { t } = useTranslation();
  const clinicId = getClinicIdFromLocalStorage();
  const [openErrorModal, setOpenErorrModal] = useState(false);
  const [erorrMessage, setErorrMessage] = useState('');
  const [updateReservation, { isLoading }] = useUpdateReservationMutation();
  const { data: reservationData } = useGetReservationQuery(clinicId, {
    skip: clinicId < 0
  });
  const reExamCode = useAppSelector(selectReExamCode);
  const homepageUrl = useAppSelector(selectHomepageUrl);
  const startValue = useAppSelector(selectStartValue);
  const endValue = useAppSelector(selectEndValue);
  const codeLength = lengthFullAndHalfWidth(reExamCode);

  const isPublic =
    !reservationData || reservationData?.url_public_status === 1 ? true : false;
  const siteHost = process.env.REACT_APP_APP_URL;
  const reservationUrlOptionList = [
    {
      id: 3,
      title: t('reExamCode.onlineDisplay'),
      link: `${siteHost}/?clinic_id=${clinicId}&method=online`
    }
  ];

  const updateUrl = (dto: ReservationUpdateDto) => {
    updateReservation(dto).then((res: any) => {
      if (!res?.data?.data) {
        setErorrMessage(res?.data?.message);
        setOpenErorrModal(true);
      }
    });
  };

  const handleChangePublicStatus = () => {
    if (reservationData) {
      const dto: ReservationUpdateDto = {
        clinic_id: reservationData.clinic_id,
        rsv_acceptance_pediod_from: reservationData.rsv_acceptance_pediod_from,
        rsv_acceptance_pediod_to: reservationData.rsv_acceptance_pediod_to,
        url_public_status: isPublic ? 0 : 1,
        home_page_url: reservationData.home_page_url,
        re_exm_code: reservationData.re_exm_code
      };
      updateUrl(dto);
    }

    if (codeLength === 8 && !reservationData) {
      const dto: ReservationUpdateDto = {
        clinic_id: clinicId,
        re_exm_code: reExamCode,
        rsv_acceptance_pediod_from: startValue,
        rsv_acceptance_pediod_to: endValue,
        home_page_url: homepageUrl,
        url_public_status: 0
      };
      updateUrl(dto);
    }

    if (codeLength !== 8 && !reservationData) {
      const triggerReExamCode = document.getElementById('triggerReExamCode');
      if (triggerReExamCode) {
        triggerReExamCode.click();
      }
    }
  };

  return (
    <div className="reservation-url_container">
      <div className="reservation-url_title">
        <h3 className="reservation-url_title_text">
          {t('reExamCode.revervationUrlTitle')}
        </h3>
      </div>
      <Switch
        loading={isLoading}
        checkedChildren={t('reExamCode.public')}
        unCheckedChildren={t('reExamCode.private')}
        checked={isPublic}
        onChange={handleChangePublicStatus}
        className="public-status-button"
      />
      <div className="reservation-url_option_container">
        {reservationUrlOptionList.map((item, index) => {
          return (
            <ReservationUrlItem
              link={item.link}
              title={item.title}
              key={index}
            />
          );
        })}
      </div>
      <ErrorModal
        message={erorrMessage}
        open={openErrorModal}
        setClose={() => setOpenErorrModal(false)}
      />
    </div>
  );
};

export default ReservationURL;

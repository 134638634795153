import { createSlice } from '@reduxjs/toolkit';
import { User } from 'src/Network/Model/User';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
    selectionRegist: {} as any,
    selectionRegistGuestUserName: '',
    clinicChoose: 0,
    userChoose: 0,
    rsvFrameIdSelectect: [] as number[],
 }

const slice = createSlice({
  name: "rsvRegistElementSideBar",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeSelectionRegistList : (state, action) => {
        state.selectionRegist = action.payload
    },
    changeSelectionGuestUserName : (state, action) => {
        state.selectionRegistGuestUserName = action.payload
    },
    changeClinicChoose : (state, action) => {
        state.clinicChoose = action.payload
    },
    changeUserChoose : (state, action) => {
        state.userChoose = action.payload
    },
    changeRsvFrameIdSelectect : (state, action) => {
        state.rsvFrameIdSelectect = action.payload
    },
  },
});

export const selectSelectionRegistList = (state:RootState) => state.common[slice.name].selectionRegist
export const selectSelectionRegistGuestUserName = (state:RootState) => state.common[slice.name].selectionRegistGuestUserName
export const selectClinicChoose = (state:RootState) => state.common[slice.name].clinicChoose
export const selectUserChoose = (state:RootState) => state.common[slice.name].userChoose
export const selectRsvFrameIdSelectect = (state:RootState) => state.common[slice.name].rsvFrameIdSelectect

export const {
    changeSelectionRegistList,
    changeSelectionGuestUserName,
    changeClinicChoose,
    changeUserChoose,
    changeRsvFrameIdSelectect,
} = slice.actions;

export const RsvRegistElementSideBarReducer = slice.reducer;
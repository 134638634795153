import { createApi } from '@reduxjs/toolkit/query/react'
import { Clinic as api, BASE_URL } from "../Api";
import axiosOutsideBaseQuery from '../axiosOutsideBaseQuery';
import { PostCodeRequest } from '../Model/PostCodeRequest';

export const zipcodeApi = createApi({
    reducerPath: "zipcodeApi",
    baseQuery: axiosOutsideBaseQuery({
        baseUrl: BASE_URL,
    }),
    endpoints: (builder) => ({
        postCode: builder.mutation({
            query: (param: PostCodeRequest) => ({
                url: api.getPostCode(),
                method: 'GET',
                data: null,
                params: param
            }),
        }),
    }),
});

export const {
    usePostCodeMutation
} = zipcodeApi;

export default zipcodeApi;
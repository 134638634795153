import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { formatYYYYMMDD, placeholderyyyymmdd } from "src/constant";
import { DatePicker, Select, Button, Tooltip, Col, Row } from "antd";
import './styles.css';
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomClinicUserResponse } from 'src/Network/Model/User';
import { useLazyClinicUsersByClinicIdQuery, useLazyTreatmentMenusByClinicIdQuery } from 'src/Network/Home/DoctorApi';
import { CustomTreatmentMenuByDateResponse } from 'src/Network/Model/TreatmentMenu';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import { useDispatch } from 'react-redux';
import { changeClinicChoose, changeUserChoose } from 'src/Screen/Doctor/DT-09/slice';

type Props = {
    handleSearch: Function,
    isNextDay: Boolean,
    searchDate?: string,
    isModal?: boolean,
    doctorId?: number,
    treatmentMenuId?: number,
    getDefaultValueClinicUser?: Function,
    getDefaultValueTreatmentMenu?: Function
}

interface typeOption {
    label: string;
    value: string;
}

const APP: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [clinicId] = useState(getClinicIdFromLocalStorage());
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [clinicUser, setClinicUser] = useState<number | null>();
    const [treatmentMenu, setTreatmentMenu] = useState<number | null>();
    
    const [clinicUserArr, setClinicUserArr] = useState<CustomClinicUserResponse[]>([]);
    const [getClinicUserArr] = useLazyClinicUsersByClinicIdQuery();
    const [treatmentMenuArr, setTreatmentMenuArr] = useState<CustomTreatmentMenuByDateResponse[]>([]);
    const [getTreatmentMenuArr] = useLazyTreatmentMenusByClinicIdQuery();
    const [optionClinicUser, setOptionClinicUser] = useState<typeOption[]>();
    const [optionTreatmentMenu, setOptionTreatmentMenu] = useState<typeOption[]>();
    const dispatch = useDispatch();

    const param = { clinic_id: clinicId };

    useEffect(() => {
        getClinicUserArr(param).then((res: any) => {
            if (res?.data) {
              setClinicUserArr(res.data.data || []);
              if (res.data.data.length > 0 && props.getDefaultValueClinicUser) {
                props.getDefaultValueClinicUser(Number(res.data.data[0].user_id));
              }
            }
        });

        getTreatmentMenuArr(param).then((res: any) => {
            if (res?.data) {
                setTreatmentMenuArr(res.data.data || []);
                if (res.data.data && props.getDefaultValueTreatmentMenu) {
                    props.getDefaultValueTreatmentMenu(Number(res.data.data[0].id));
                }
            }
        });
        if (props.searchDate) {
            setCurrentDate(dayjs(props.searchDate));
        }
    }, []);

    const handleChangeDate = (nextDate=true) => {
        const newDate = currentDate.add(nextDate ? 1 : -1, props.isNextDay ? 'day' : 'week');
        setCurrentDate(newDate);
        props.handleSearch(newDate, clinicUser, treatmentMenu);
    }

    const handleChangeDateSelected = (newDate:Dayjs|null) => {
        if (newDate === null) return;
        setCurrentDate(dayjs(newDate))
        props.handleSearch(newDate, clinicUser, treatmentMenu);
    }

    const handleResetDate = () => {
        setCurrentDate(dayjs());
        props.handleSearch(dayjs(), clinicUser, treatmentMenu);
    }

    const handleChangeClinicUser = (clinicUser: number) => {
        setClinicUser(clinicUser);
        dispatch(changeUserChoose(clinicUser));
        props.handleSearch(currentDate, clinicUser, treatmentMenu);
    }

    const handleChangeTreatmentMenu = (clinic: number) => {
        setTreatmentMenu(clinic);
        dispatch(changeClinicChoose(clinic));
        props.handleSearch(currentDate, clinicUser, clinic);
    }

    const getClinicUserOptions = () => {
        let result = [];
        if (!props.isModal) {
            result.push({label: "", value: ""});
        }
        return result.concat(clinicUserArr.map((user) => ({
            value: user.user_id,
            label: user.user_name
        })));
    }

    useEffect(() => {
        if (clinicUserArr) {
            setOptionClinicUser(getClinicUserOptions());
            if (props.isModal) {
                if (props.doctorId === -1 && clinicUserArr.length > 0) {
                    setClinicUser(Number(clinicUserArr[0].user_id));
                    dispatch(changeUserChoose(Number(clinicUserArr[0].user_id)));
                    props.handleSearch(currentDate, Number(clinicUserArr[0].user_id), treatmentMenu);
                }
                if (props.doctorId !== -1) {
                    setClinicUser(props.doctorId);
                    dispatch(changeUserChoose(props.doctorId));
                }
            }
        }
    }, [clinicUserArr])

    useEffect(() => {
        if (treatmentMenuArr) {
            setOptionTreatmentMenu(getTreatmentMenuOptions());
            if (props.isModal) {
                if (props.treatmentMenuId === -1 && treatmentMenuArr.length > 0) {
                    setTreatmentMenu(Number(treatmentMenuArr[0].id));
                    dispatch(changeClinicChoose(Number(treatmentMenuArr[0].id)));
                    props.handleSearch(currentDate, clinicUser, Number(treatmentMenuArr[0].id));
                }
                if (props.treatmentMenuId !== -1) {
                    setTreatmentMenu(props.treatmentMenuId);
                    dispatch(changeClinicChoose(props.treatmentMenuId));
                }
            }
        }
    }, [treatmentMenuArr])

    const getTreatmentMenuOptions = () => {
        let result = [];
        if (!props.isModal) {
            result.push({label: "", value: ""});
        }
        return result.concat(treatmentMenuArr.map((tm) => ({
            value: tm.id,
            label: tm.treatment_menu_name
        })));
    }

    return (
        <Row className="tabbarSearch">
            <Col className="searchDate">
                <Tooltip title={props.isNextDay ? t('admin-schedule.yesterday') : t('admin-schedule.last-week')}>
                    <Button className="btnDirection" onClick={() => handleChangeDate(false)}>
                        <DoubleLeftOutlined style={{ color: '#6C747B' }} />
                    </Button>
                </Tooltip>
                <DatePicker 
                    placeholder={placeholderyyyymmdd} 
                    value={currentDate}
                    format={formatYYYYMMDD}
                    onChange={handleChangeDateSelected}
                />
                <Tooltip title={props.isNextDay ? t('admin-schedule.tomorrow') : t('admin-schedule.next-week')}>
                    <Button className="btnDirection" onClick={() => handleChangeDate()}>
                        <DoubleRightOutlined style={{ color: '#6C747B' }} />
                    </Button>
                </Tooltip>
                <Button className="btnCurrent" type='text' onClick={handleResetDate} style={{fontWeight: 700}}>
                    {
                        props.isNextDay ?
                        t("admin-schedule.today") : t('admin-schedule.this-week')
                    }
                </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={0}></Col>
            <Col className="searchSelect">
                <Select 
                    allowClear={props.isModal ? false : true}
                    placeholder={t("time-booking-info-prof.doctor")}
                    optionFilterProp="children"
                    value={clinicUser ? clinicUser : null}
                    className={clinicUser ? '' : 'placeHolderDoctor'}
                    style={{width: 200}}
                    onChange={handleChangeClinicUser}
                    options={optionClinicUser}
                />
                <Select 
                    allowClear={props.isModal ? false : true}
                    placeholder={t("booking-info-hospital.treatment_menu_name")}
                    optionFilterProp="children"
                    value={treatmentMenu ? treatmentMenu : null}
                    className={treatmentMenu ? '' : 'placeHolderTreatmentMenu'}
                    style={{width: 200}}
                    onChange={handleChangeTreatmentMenu}
                    options={optionTreatmentMenu}
                />
            </Col>
        </Row>
    )
}

export default APP;
export const BASE_URL =
  process.env.REACT_APP_API_URL ?? 'http://localhost:9000/v1';
export const Clinic = {
  getHomeClinic: (id?: Number) => {
    return '/clinic/' + id ?? '';
  },
  getClinicReview: '/clinic-review',
  getReservationFrame: (id?: String) => {
    return '/reservation-frame/' + id ?? '';
  },
  getTreatmentMenuDetail: (id?: String) => {
    return '/treatment-menu/' + id ?? '';
  },
  getPostCode: () => {
    return 'https://zipcloud.ibsnet.co.jp/api/search';
  },
  getAllMPrefs: () => {
    return '/m-prefs';
  },
  getAllMCitys: () => {
    return '/m-citys';
  },
  getListMCitysById: (id?: Number) => {
    return '/m-citys/' + id;
  },
  userRegister: () => {
    return '/user';
  },
  user: '/user',
  userLogin: () => {
    return '/user/login';
  },
  userLogout: () => {
    return '/user/logout';
  },
  resetPassword: '/user/reset',
  scanCode: '/clinic/rsv-code',
  validate: '/user/validate',
  reservationFrameData: '/doctor/reservation-frame-consumption/week',
  treatmentMenusByClinicId: '/doctor/treatment-menu/find-by-clinic-id',
  clinicUsersByClinicId: '/doctor/clinic-user/find-by-clinic-id',
  exmTimeByClinicId: '/doctor/clinic/exm-time',
  getListPrescriptionAddressByUserId: '/prescription-address/find-by-userid',
  prescriptionAddress: '/prescription-address',
  prescriptionPharmacy: '/prescription-pharmacy',
  getPrescriptionPharmacyByUserId: '/prescription-pharmacy/find-by-userid',
  getListPrescriptionPharmacyByIdUser: () => {
    return '/prescription-pharmacy/';
  },
  getPharmacyInfoWithPrescriptionsByUserId: () => {
    return '/pharmacy-info/pharmacy-detail';
  },
  doctorLogin: '/doctor/auth/login',
  getReservationInfo: () => {
    return '/reservation-info';
  },
  doctor: '/doctor/auth',
  reservationInfosByDateIdAndClinicId:
    '/doctor/reservation-info/find-by-date-and-clinic',
  countPatientsCompletedExaminationToDay:
    '/doctor/reservation-info/count-patient-completed',
  countPatientsBookingToDay: '/doctor/reservation-info/count-patient-booking',
  getReservationInfoDetail: (id: number) => {
    return '/doctor/reservation-info/' + id;
  },
  getReservationInfoDetailPatient: (id: number) => {
    return '/reservation-info/' + id;
  },
  wpPatientInfo: '/clinic-admin/patient-info/',
  wpKaruteInfo: '/clinic-admin/chart-detail/?karute_id=',
  reservationInfo: '/doctor/reservation-info',
  reservationInfoList: '/doctor/reservation-info/reservation-info-list',
  searchMCity: '/m-citys/find-by-name',
  searchDepartment: '/clinical-department/find-by-name',
  getSearchIshachokuUser: () => {
    return '/user/search';
  },
  searchClinic: '/clinic/search-clinic',
  getFindTreatmentMenu: '/doctor/reservation-frame-repeat/find-treatment-menu',
  reservationFrameRepeat: '/doctor/reservation-frame-repeat',
  closedTime: '/doctor/closed-time',
  getClosedTimeByDateAndClinic: '/doctor/closed-time/get-by-clinic-id-and-date',
  reservationFrameRepeatDelete: (id: number) => {
    return '/doctor/reservation-frame-repeat/' + id;
  },
  weekReservationFrame: '/doctor/reservation-frame/rsv-frame',
  validateTokenSession: '/validate-token-session',
  getRsvInfoByDatetime: '/doctor/reservation-info/get-by-date-time',
  getCountOnClosedTime: '/doctor/closed-time/get-frame-on-closed-time',
  getVitalSignal: 'https://st.ishachoku.com/api/vitalSignal',
  getListPrefecture: '/pharmacy-info/prefectures',
  getListCityByPrefecture: '/pharmacy-info/citys',
  pharmacyInfo: '/pharmacy-info',
  getFamilyMemberInfomation: (user_id: number) => {
    return '/user/' + user_id;
  },
  checkCreditCard: (user_id: number) => {
    return '/user/checkCreditCard/' + user_id;
  },
  validateInnsurance: '/karute/validate-insurance',
  createQuestions: '/questions',
  registerCard: '/user/regisCreditCard',
  getCardInfo: '/user/getInfoCreditCard',
  editCardInfo: '/user/updateCreditCard',
  sendOTP: '/user/send-mail-otp-edit-card',
  verifyOTPEditCard: '/user/verify-otp-edit-card',
  createKaruteInsurance: '/karute/create-insurance',
};

export const DoctorSettingApi = {
  treatmentMenu: {
    getList: (clinic_id: number) =>
      `/doctor/treatment-menu/find-by-clinic-id?clinic_id=${clinic_id}`,
    updateItem: '/doctor/treatment-menu/',
    addItem: '/doctor/treatment-menu/'
  },

  consentForm: {
    getListConsent: (clinic_id: number) =>
      `/doctor/consent-form/find-by-clinic-id/${clinic_id}`,
    updateItem: '/doctor/consent-form/',
    addItem: '/doctor/consent-form/',
    deleteItem: '/doctor/consent-form/'
  },

  reservationManagement: {
    getReservation: (clinic_id: number) =>
      `/doctor/reservation-management/clinicId/${clinic_id}`,
    updateReservation: '/doctor/reservation-management',
    getClinicAddress: (clinic_id: number) =>
      `/doctor/clinic/address/${clinic_id}`,
    generateReExamCode: '/doctor/reservation-management/gen-re-exam-code'
  }
};

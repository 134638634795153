import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
  showModalCancel: false
}

const slice = createSlice({
  name: "showModalCancelSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeShowModalCancel : (state, action) => {
        state.showModalCancel = action.payload
    }
  },
});

export const selectShowModalCancel = (state:RootState) => state.common.showModalCancelSlice.showModalCancel;

export const {
    changeShowModalCancel
} = slice.actions;

export const ShowModalCancelReducer = slice.reducer;
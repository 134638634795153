import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DOCTOR_ACCESS_TOKEN, KEY_DOCTOR_USER } from 'src/common/Constant';
import { changeIsLogin } from './Header/slice';
import { ClinicUser } from 'src/Network/Model/ClinicUser';
import { linkToLoginDoctorWP } from 'src/common/Libs';
import { useSearchParams } from 'react-router-dom';
import { useDoctorLoginMutation, useLazyDoctorInfoQuery } from 'src/Network/Home/DoctorApi';

const ProtectedRoute = ({ children }: any) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState<ClinicUser>();
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id') ?? null;
  const clinic_user_id = searchParams.get('clinic_user_id' ?? null);
  const [doctorLogin] = useDoctorLoginMutation();
  const [doctorInfo] = useLazyDoctorInfoQuery();

  useEffect(() => {
    if (session_id && clinic_user_id) {
      doctorLogin({ session_id, clinic_user_id }).then((data: any) => {
        const _user: ClinicUser = data?.data?.data?.user;
        if (_user && _user?.status === 1) {
          setUser(_user);
          localStorage.setItem(KEY_DOCTOR_USER, JSON.stringify(_user));
          if (data?.data?.data?.access_token) {
            localStorage.setItem(
              DOCTOR_ACCESS_TOKEN,
              data?.data?.data?.access_token,
            );
          }
          dispatch(changeIsLogin(true));
        } else {
          localStorage.removeItem(KEY_DOCTOR_USER);
          window.location.href = linkToLoginDoctorWP();
        }
      });
    } else {
      doctorInfo({}).then(response => {
        // console.log(response?.data?.data);
        if (response?.data?.data) {
          localStorage.setItem(KEY_DOCTOR_USER, JSON.stringify(response?.data?.data));
          setUser(response?.data?.data);
          dispatch(changeIsLogin(true));
        } else {
          console.log(linkToLoginDoctorWP());
          window.location.href = linkToLoginDoctorWP();
        }
      });

      // const json = localStorage.getItem(KEY_DOCTOR_USER);
      // if (json) {
      //   console.log(json);
      //   const _user = JSON.parse(json);
      //   setUser(_user);
      //   dispatch(changeIsLogin(true));
      // } else {
      //   console.log(linkToLoginDoctorWP());
      //   window.location.href = linkToLoginDoctorWP();
      // }
    }
  }, []);
  return user ? children : <></>;
};

export default ProtectedRoute;

import { Button, Checkbox, Col, Row, Table } from 'antd';
import arrow_left from '../../assets/image/arrow-left.svg';
import './styles.css';
import BookingInfoHospital from '../BookingInfoHospital';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import Time from '../../Screen/Booking/Time';
import ReceptionInfo from '../ReceptionInfo';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { changeDataQuestion, selectDataQuestion } from '../../Screen/Booking/store/slice';
import { changeDataAddress, selectDataAddress } from 'src/Screen/Booking/store/slice';
import BookingInfoPatient from '../BookingInfoPatient';
import {
  useCreateQuestionsMutation,
  useListPharmacyInfoWithPrescriptionsByUserIdQuery,
  useMPrefsQuery,
  usePrescriptionPharmacyByIdQuery, useLazyRsvInfoDetailByIdPatientQuery,
  useTreatmentMenuDetailQuery,
  useCheckCreditCardQuery,
  useValidateInnsuranceQuery
} from 'src/Network/Home/HomeClinicApi';
import { useEffect } from 'react';
import { changeCheckStepFooter, changeCurrentStep, selectCheckStepFooter, selectCurrentStep, selectReservationInfoId, setReservationInfoId } from '../ArrowStepper/Slice/Slice';
import { useTranslation } from 'react-i18next';
import { KEY_USER } from 'src/common/Constant';
import RequestLogin from '../Modal/RequestLogin';
import { changeIsRequestLoginUrl, changeModalLogin, changeRequestLogin, selectIsRequestLoginUrl } from '../Header/slice';
import { selectDoctorSelectionList, selectFilteredData, selectRadioIndex } from '../TimeBooking/slice';
import { BUTTON_ACCEPT, BUTTON_WARNING_DUPLICATE, MODAL_FAIL, MODAL_SUCCESS, MODAL_WARNING, STEP_CHOOSE_INFORMATION, STEP_CHOOSE_PRINT, STEP_CONFIRM_INFORMATION, baseUrlHome, getPrefList } from 'src/constant';
import { changeBiologicalData, selectBiologicalData } from 'src/Component/PT-05_A/slice';
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import BiologicalData from '../PT-05_A';
import PrescriptionReceiveNew from 'src/Component/PrescriptionReceive/PrescriptionReceiveNew';
import BookingPaymentInfo from '../BookingPaymentInfo';
import printerIcon from 'src/assets/image/printer.svg';
import CardPayment from 'src/Component/CardPayment';
import { PrescriptionPharmacy } from 'src/Network/Model/PrescriptionPharmacy';
import { PharmacyInfo } from 'src/Network/Model/PharmacyInfo';
import PrescriptionReceiveConfirm from '../PrescriptionReceive/PrescriptionReceiveConfirm';
import { linkToMypage, linkToTermOfUser, linkToWP } from 'src/common/Libs';
import {ReservationInfoDetailResponse} from "../../Network/Model/ReservationInfo";
import dayjs from "dayjs";
import ModalWarning from "src/Component/Modal/ModalWarning";
import { selectTab } from '../BookingContent/slice';
import { ConsentFormNonInsuranceAmount } from 'src/Network/Model/ConsentFormNonInsuranceAmount';
import Loading from '../Modal/Loading';
import { formatTimeRange } from 'src/Utils/CalculateHourResevationUtils';
import FormInputCheckbox from '../common/form/FormInputCheckbox';
import Login from 'src/Screen/PT-04/Login';

const ArrowStepper = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinic_id') || '';
  const treatmentMenuId = searchParams.get('treatmentmenu_id') || '';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentStep = useAppSelector(selectCurrentStep);
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<string>();
  const [titleNext, setTitleNext] = useState<string>();
  const [titleprev, setTitlePrev] = useState<string>();
  const [detailInfo, setDetailInfo] = useState<any>();
  const [familyUserId, setFamilyUserId] = useState<number>(0);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleDuplicateRSV, setVisibleDuplicateRSV] = useState(false);
  const [visibleWarning, setVisibleWarning] = useState(false);
  const [messRegistErr, setMessRegistErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasJustRegisteredCard, setHasJustRegisterCard] = useState(false);
  const dataDetail = useTreatmentMenuDetailQuery({
    clinic_id: clinicId,
    treatment_menu_id: treatmentMenuId
  });
  const json = localStorage.getItem(KEY_USER)??"{}";
  const user_name = JSON.parse(json).user_name;
  const [familyName, setFamilyName] = useState<string>(user_name);
  const [karuteId, setKaruteId] = useState<string>('');
  const [createQuestions] = useCreateQuestionsMutation();
  const user_id = JSON.parse(json).user_id;
  const checkCreditCard = useCheckCreditCardQuery({user_id: user_id})?.data;
  const validateInnsurance = useValidateInnsuranceQuery({user_id: user_id, family_user_id: familyUserId});
  const isRequestLoginUrl = useAppSelector(selectIsRequestLoginUrl);
  //init screen
  useEffect(() => {
    if (searchParams.get('reservation_info_id') ){
      // clear data form reception information input
      dispatch(changeDataQuestion({}));
      dispatch(changeDataAddress({}));
      dispatch(changeBiologicalData({}));
      dispatch(changeCurrentStep(1));
      setContent(t('booking-online.step1.content-step1'));
      setTitle(t('booking-online.step1.title-step1'));
      setTitleNext(t('booking-online.step1.title-next1'));
      setTitlePrev(t('booking-online.step1.title-prev1'));
      // if (!localStorage.getItem(KEY_USER)){
      //   dispatch(changeRequestLogin(true));
      // }
      return;
    }
    setContent(t('booking-online.step0.content-step0'));
    setTitle(t('booking-online.step0.title-step0'));
    setTitleNext(t('booking-online.step0.title-next0'));
    setTitlePrev(t('booking-online.step0.title-prev0'));
  }, []);
  useEffect(() => {
    if(currentStep === 0){
      document.title = t('title_tabs_patient.pt_02');
    } else if(currentStep === 1){
      document.title = t('title_tabs_patient.pt_05');
    } else if(currentStep === 2){
      document.title = t('title_tabs_patient.pt_06');
    } else if(currentStep === 3){
      document.title = t('title_tabs_patient.pt_07');
    }
  }, [currentStep]);
  useEffect(() => {
    if (dataDetail) setDetailInfo(dataDetail.data);
  }, [dataDetail]);
  const rsv_fee = detailInfo?.rsv_fee;
  const consent_form_id = detailInfo?.consent_form_id;
  const consentFormNonInsuranceAmounts = detailInfo?.consent_form?.consent_form_non_insurance_amounts;
  const treatment_menu_name = detailInfo?.treatment_menu_name;
  const prevStep = () => {
    if (currentStep === 0) {
      navigate('/' + baseUrlHome(searchParams));
      localStorage.removeItem('qrCode');
    } else {
      dispatch(changeCurrentStep(currentStep - 1));
    }
    setStepInfo(currentStep - 1);
  };
  function setStepInfo(currentStep: any) {
    if (currentStep === 0) {
      setTitle(t('booking-online.step0.title-step0'));
      setContent(t('booking-online.step0.content-step0'));
      setTitleNext(t('booking-online.step0.title-next0'));
      setTitlePrev(t('booking-online.step0.title-prev0'));
    } else if (currentStep === 1) {
      setTitle(t('booking-online.step1.title-step1'));
      setContent('');
      setTitleNext(t('booking-online.step1.title-next1'));
      setTitlePrev(t('booking-online.step1.title-prev1'));
    } else if (currentStep === 2) {
      setTitle(t('booking-online.step2.title-step2'));
      setContent('');
      setTitleNext(t('booking-online.step2.title-next2'));
      setTitlePrev(t('booking-online.step2.title-prev2'));
    }
  }
  const mPrefsAll = useMPrefsQuery({})?.data;
  const [prefList, setPrefList] = useState<Array<{ value: number; label: string }>>([]);
  useEffect(() => {
    if (mPrefsAll) {
      const newPrefList = getPrefList(mPrefsAll, t('register.text-default-pref'));
      setPrefList(newPrefList);
    }
  }, [mPrefsAll]);
  const [cityList, setCityList] = useState<Array<{ value: number; label: string }>>([{ value: 0, label: t('register.text-default-city') }]);

  const defaultValues = {
    symptoms: '',
    period: '',
    family_clinic: '',
    request: '',
    tempe: 35,
    tempe_decimal: 0,
    prescription_receive: '',
  };
  const validationSchema = [
    //validation for step1
    yup.object({}),
    //validation for step2
    yup.object({
      symptoms: yup
        .string()
        .required(
          t('register.input-required').replace(
            '{0}',
            t('receptioninfo.prefix-symptoms')
          )
        )
        .test(
          'lengthFullAndHalfWidth',
          t('register.label-maxlength')
            .replace('{0}', t('receptioninfo.prefix-symptoms'))
            .replace('{1}', '100'),
          (value) => {
            return lengthFullAndHalfWidth(value) <= 100;
          }
        ),
      period: yup
        .string()
        .required(
          t('register.input-required').replace(
            '{0}',
            t('receptioninfo.prefix-pediod')
          )
        )
        .test(
          'lengthFullAndHalfWidth',
          t('register.label-maxlength')
            .replace('{0}', t('receptioninfo.prefix-pediod'))
            .replace('{1}', '100'),
          (value) => {
            return lengthFullAndHalfWidth(value) <= 100;
          }
        ),
      family_clinic: yup
        .string()
        .required(
          t('register.input-required').replace(
            '{0}',
            t('receptioninfo.prefix-family-clinic')
          )
        )
        .test(
          'lengthFullAndHalfWidth',
          t('register.label-maxlength')
            .replace('{0}', t('receptioninfo.prefix-family-clinic'))
            .replace('{1}', '100'),
          (value) => {
            return lengthFullAndHalfWidth(value) <= 100;
          }
        ),
      request: yup
        .string()
        .test(
          'lengthFullAndHalfWidth',
          t('register.label-maxlength')
            .replace('{0}', t('receptioninfo.prefix-request'))
            .replace('{1}', '300'),
          (value) => {
            return lengthFullAndHalfWidth(value) <= 300;
          }
        ),
      tempe: yup.number(),
      tempe_decimal: yup.number(),
      prescription_receive_validate: yup.string().required(''),
      prescription_receive_data: yup.object(),
      agreeAmount: !consent_form_id ? yup.boolean() : yup
        .boolean()
        .oneOf([true], t('receptioninfo.confirm-required'))
        .required(t('receptioninfo.confirm-required')),
      agreePolicy: yup
        .boolean()
        .oneOf([true], t('message.MESS_ERR_MISS_CHECK_TERM_OF_SERVICE'))
        .required(t('message.MESS_ERR_MISS_CHECK_TERM_OF_SERVICE'))
    }),
    //validation for step3
    yup.object({})
  ];
  const schema = validationSchema[currentStep];
  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'all'
  });
  const {
    trigger,
    setValue,
    getValues,
    formState: { errors },
    setFocus,
  } = methods;
  // Get data from store
  const dataQuestions = useAppSelector(selectDataQuestion);
  const reservation_info_id = searchParams.get('reservation_info_id');
  const [disablePrev, setDisablePrev] = useState<boolean>(false);
  const [getRsvInfoById] = useLazyRsvInfoDetailByIdPatientQuery();
  const [checkKaruteFlg, setCheckKaruteFlg] = useState(false);
  useEffect(() => {
    if (currentStep === 1 && reservation_info_id) {
      setDisablePrev(true);
      if (reservation_info_id) {
        getRsvInfoById({id: Number(reservation_info_id)}).then((res) => {
          if (!res?.data?.error) {
            if(res?.data?.data?.rsvInfo?.rsv_status === 3) {
              setCheckKaruteFlg(true);
              setKaruteId(res?.data?.data?.rsvInfo?.karute_id??'');
            } else {
              setVisibleDuplicateRSV(true);
            }
          }
        })
      }
    } else {
      setDisablePrev(false);
    }
  }, [currentStep])
  const addressStore = useAppSelector(selectDataAddress);
  const exm_method = useAppSelector(selectTab);
  const doctorSelectionList = useAppSelector(selectDoctorSelectionList);
  const filteredData = useAppSelector(selectFilteredData);
  const radioIndex = useAppSelector(selectRadioIndex);
  const frameTimeSelecttion = filteredData[radioIndex];
  const doctorSelection= doctorSelectionList.find((i:any) => i.id === radioIndex);
  const bioData = useAppSelector(selectBiologicalData);
  const nextStep = async () => {
    let isStepValid = true;
    isStepValid = await trigger();
    if (currentStep === 1 && (!validateInnsurance?.data?.has_registered_insurrance || (!checkCreditCard?.has_registered_card && !hasJustRegisteredCard))) {
      isStepValid = false;
    }
    if (currentStep === 1 && !localStorage.getItem(KEY_USER)) {
      dispatch(changeRequestLogin(true));
      return;
    }
    if (isStepValid) {
      if (currentStep === 0) {
        // clear data form reception information input
        dispatch(changeDataQuestion({}));
        dispatch(changeDataAddress({}));
        dispatch(changeBiologicalData({}));
        methods.reset();
        const json = localStorage.getItem(KEY_USER);
        if (json === null || json === undefined) {
          setStepInfo(currentStep);
          dispatch(changeRequestLogin(true));
          return;
        }
        dispatch(changeCurrentStep(currentStep + 1));
        setStepInfo(currentStep + 1);
      } else if (currentStep === 1) {
        if (!methods.getValues('prescription_receive_validate'))
          return;
        // Save data to store
        const tempe = getValues('tempe')??defaultValues.tempe;
        const tempe_decimal = getValues('tempe_decimal')??defaultValues.tempe_decimal;
        const questions = {
          symptoms: getValues('symptoms'),
          tempe: tempe,
          tempe_decimal: tempe_decimal,
          temperature: tempe + '.' + tempe_decimal,
          period: getValues('period'),
          family_clinic: getValues('family_clinic'),
          request: getValues('request')??'',
          checkedAmount: true,
          checkedPolicy: true,
        }
        dispatch(changeDataQuestion({
            ...questions
          })
        );
        const addressStore = methods.getValues('prescription_receive_data');
        dispatch(changeDataAddress({
          ...addressStore
        }));
        dispatch(changeCurrentStep(currentStep + 1));
        setStepInfo(currentStep + 1);
      } else if (currentStep === 2) {
        const reception_id = searchParams.get('reception_id'); // reception_id get from URL in mail register from DT-09
        const json = localStorage.getItem(KEY_USER)??"{}";
        const user_id = JSON.parse(json).user_id;
        const user_name = JSON.parse(json).user_name;
        const mailaddress = JSON.parse(json).mailaddress;
        const consent_form = detailInfo?.consent_form;
        const amounts = [] as Array<{ non_insurance_amount_id: number; amount_description: string; amount: number }>;
        consent_form?.consent_form_non_insurance_amounts?.map((item: ConsentFormNonInsuranceAmount) => {
          amounts.push({
            non_insurance_amount_id: item?.id??0,
            amount_description: item?.non_insurance_amount?.amount_description??'',
            amount: item?.non_insurance_amount?.amount??0
          })
        })
        let consent_form_content = amounts.length > 0 ? {
          id: consent_form?.id,
          title: consent_form?.title,
          amounts: amounts
        } : null;
        let reservation_info: any = {
          clinic_id: clinicId,
          karute_id: karuteId,
          user_name: familyName,
          mailaddress: mailaddress,
          consent_form_content: consent_form_content
        }
        const data = {
          user_id: user_id,
          user_name: user_name,
          family_user_id: familyUserId,
          treatment_menu_name: treatment_menu_name,
          questions: {
            ...dataQuestions,
            // If 郵送で受け取る is checked, then (if methodReceive === 1) prescription_method = 4
            // If 薬局で受け取る is checked, then prescription_method = 3
            prescription_method: addressStore.methodReceive === 1 ? 4 : 3,
            prescription_method_id: addressStore.prescriptionPharmacy.pharmacy_id
          },
          reservation_info: reservation_info
        };
        let vitalSignal = null;
        if (bioData?.heartbeat) {
          vitalSignal = {
            bpm: bioData?.heartbeat,
            bpv1: bioData?.bloodPressure1,
            bpv0: bioData?.bloodPressure2,
            S2: bioData?.acidSaturatio,
            LTv: bioData?.acidConcentration
          }
        }
        let dto = {};
        const rsv_div = searchParams.get('rsv_div'); // rsv_div get from URL in mail register from DT-09
        const rsv_type = searchParams.get('rsv_type'); // rsv_type get from URL in mail register from DT-09
        if (reception_id) { // bác sĩ đặt lịch từ màn DT-09 cho bệnh nhân đã có tài khoản
          const rsv_ymd = searchParams.get('rsv_ymd'); // rsv_ymd get from URL in mail register from DT-09
          const rsv_time = searchParams.get('rsv_time'); // rsv_time get from URL in mail register from DT-09
          const clinic_user_id = searchParams.get('doctor_id'); // doctor_id get from URL in mail register from DT-09
          dto = {
            vitalSignal: vitalSignal,
            rsv_ymd: rsv_ymd,
            rsv_time: rsv_time,
            reception_id: reception_id,
            rsv_div: rsv_div,
            rsv_type: rsv_type,
            ...data,
            reservation_info: {
              ...data.reservation_info,
              clinic_user_id: clinic_user_id
            }
          }
        } else {
          const reservation_info_id = searchParams.get('reservation_info_id');
          const company_id = JSON.parse(json).company_id;
          const city_id = JSON.parse(json).city_id;
          const reception = {
            company_id: company_id,
            city_id: city_id
          }
          // bác sĩ đặt lịch từ màn DT-09 cho bệnh nhân chưa có tài khoản
          if (reservation_info_id) {
            const rsv_ymd = searchParams.get('rsv_ymd'); // rsv_ymd get from URL in mail register from DT-09
            const rsv_time = searchParams.get('rsv_time'); // rsv_time get from URL in mail register from DT-09
            const clinic_user_id = searchParams.get('doctor_id'); // doctor_id get from URL in mail register from DT-09
            dto = {
              vitalSignal: vitalSignal,
              reservation_info_id: reservation_info_id,
              rsv_ymd: rsv_ymd,
              rsv_time: rsv_time,
              rsv_div: rsv_div,
              rsv_type: rsv_type,
              ...data,
              reception: reception,
              reservation_info: {
                ...data.reservation_info,
                treatment_menu_id: treatmentMenuId,
                clinic_user_id: clinic_user_id
              }
            }
          } else { // bệnh nhân đặt lịch từ màn PT-01->PT-02->PT-05->PT-06
            const re_exm_code = localStorage.getItem('qrCode');
            dto = {
              vitalSignal: vitalSignal,
              re_exm_code: re_exm_code,
              exm_method: exm_method,
              rsv_ymd: frameTimeSelecttion?.rsv_ymd,
              rsv_time: frameTimeSelecttion?.rsv_time,
              ...data,
              reception: reception,
              reservation_info: {
                ...data.reservation_info,
                date_id: frameTimeSelecttion?.date_id,
                treatment_menu_id: treatmentMenuId,
                clinic_user_id: doctorSelection?.selection?.user_id,
                reservation_frame_id: frameTimeSelecttion?.id
              }
            }
          }
        }
        setIsLoading(true);
        // call api create questions
        createQuestions(dto).then((res: any) => {
          if (!res?.data?.error) {
            dispatch(setReservationInfoId(res?.data?.data));
            setVisibleSuccess(true);
            dispatch(changeCurrentStep(currentStep + 1));
            setStepInfo(currentStep + 1);
          } else {
            setVisibleWarning(true);
            if (res?.data.code === 400) {
              setMessRegistErr(t("dt09.MESS_RSV_REGIST_FAIL"));
            } else {
              setMessRegistErr(t("message.MESS_ERR_RSV_REGIST_INVALID"));
            }
          }
          setIsLoading(false);
        })
      }
    }
  };
  const PrintScreen = () => {
    window.print();
  };
  const handleToMyPage = () => {
    window.location.href = linkToMypage() + '';
  }
  const handleCloseDiffUser = () => {
    dispatch(changeIsRequestLoginUrl(false));
    dispatch(changeModalLogin({
      show: true
    }));
  }
  const handleToTopPage = () => {
    window.location.href = linkToWP() + '';
  }
  const handleCloseWarningDuplicate = () => {
    setVisibleDuplicateRSV(false)
    navigate('/' + baseUrlHome(searchParams))
  }
  useEffect(() => {
     const firstError = Object.keys(errors).reduce((field, a) => {
       return errors[field] ? field : a;
     }, '');

     if (firstError && firstError !== '') {
       setFocus(firstError);
     }
  }, [errors, setFocus]);

  return (
    <div className="step-by-step">
      <p className="text-bold-styles text">{title}</p>
      <div className="frame-arrows">
        <div className="arrow-steps">
          <div className={`step${currentStep}`}></div>
        </div>
      </div>
      {currentStep === 0 && (
        <div>
          <p className="text-bold-styles text-2">{content}</p>
        </div>
      )}
      <div>
        <FormProvider {...methods}>
          <Row className="form-step">
            <Col xs={24} sm={24} md={24} lg={24} xl={15} className="main-step">
              {currentStep === 0 && (
                <Step1 />
              )}
              {currentStep === 1 && (
                <Step2
                  currentStep={currentStep}
                  prefList={prefList}
                  cityList={cityList}
                  setCityList={setCityList}
                  consent_form_id={consent_form_id}
                  consentFormNonInsuranceAmounts={consentFormNonInsuranceAmounts}
                  dataQuestions={dataQuestions}
                  setValue={setValue}
                  methods={methods}
                  addressStore={addressStore}
                />
              )}
              {currentStep === 2 && (
                <Step3
                  currentStep={currentStep}
                  prefList={prefList}
                  cityList={cityList}
                />
              )}
              {currentStep === 3 && 
                <Step4 
                  currentStep={currentStep}
                  dataQuestions={dataQuestions}
                  setValue={setValue}
                />
              }
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} className="info-step">
              <div className="container-info">
                <div>
                  {currentStep === 0 ? (
                    <BookingInfoHospital detail_info={detailInfo} />
                  ) : (
                    <>
                      <BookingInfoPatient
                        currentStep={currentStep}
                        setFamilyUserId={setFamilyUserId}
                        setFamilyName={setFamilyName}
                        karuteId={karuteId}
                        setKaruteId={setKaruteId}
                        checkKaruteFlg={checkKaruteFlg}
                        setCheckKaruteFlg={setCheckKaruteFlg}
                      />
                      {
                        (currentStep === STEP_CHOOSE_INFORMATION || currentStep === STEP_CONFIRM_INFORMATION) && 
                        <>
                          <div className="payment">
                            <span>{t('receptioninfo.title-payment')}</span>
                          </div>
                          <BookingPaymentInfo rsv_fee={rsv_fee}/>
                        </>
                      }
                      {
                        currentStep === STEP_CHOOSE_INFORMATION &&
                        <CardPayment familyUserId={familyUserId} checkCreditCard={checkCreditCard} hasJustRegisteredCard={hasJustRegisteredCard} setHasJustRegisterCard={setHasJustRegisterCard} user_id={user_id}/>
                      }
                    </>
                  )}
                </div>
                {currentStep === 0 && (
                  <div className="title-calendar-step">
                    <p className="text-bold-styles text-3">{content}</p>
                  </div>
                )}
              </div>
              {currentStep === 3 ? (
                <ScheduInfo t={t} PrintScreen={PrintScreen} handleToTopPage={handleToTopPage} handleToMyPage={handleToMyPage} rsv_fee={rsv_fee}/>
              ) : (
                <ConfirmFrame
                  currentStep={currentStep}
                  nextStep={nextStep}
                  titleNext={titleNext}
                  prevStep={prevStep}
                  titleprev={titleprev}
                  disablePrev={disablePrev}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} className="info-step2">
              {currentStep === 3 ? (
                <ScheduInfo t={t} PrintScreen={PrintScreen} handleToTopPage={handleToTopPage} handleToMyPage={handleToMyPage} rsv_fee={rsv_fee}/>
              ) : (
                <ConfirmFrame
                  currentStep={currentStep}
                  nextStep={nextStep}
                  titleNext={titleNext}
                  prevStep={prevStep}
                  titleprev={titleprev}
                  disablePrev={disablePrev}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} className="info-step3">
              {currentStep === 3 ? (
                <ScheduBtn t={t} PrintScreen={PrintScreen} handleToTopPage={handleToTopPage} handleToMyPage={handleToMyPage}/>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </FormProvider>
      </div>
      {
        visibleSuccess &&
        <ModalWarning
          message={t("dt09.MESS_RSV_REGIST_SUCCESS")}
          typeModal={MODAL_SUCCESS}
          typeButton={BUTTON_ACCEPT}
          changeVisibleModal={() => {setVisibleSuccess(false)}}
        />
      }
      {
        visibleWarning &&
        <ModalWarning
          message={messRegistErr}
          typeModal={MODAL_FAIL}
          typeButton={BUTTON_ACCEPT}
          changeVisibleModal={() => {setVisibleWarning(false)}}
        />
      }
      {
        visibleDuplicateRSV &&
        <ModalWarning
          message={t("message.MESS_WRN_CANNOT_REGIST_DUPLICATE_RSV")}
          typeModal={MODAL_WARNING}
          typeButton={BUTTON_WARNING_DUPLICATE}
          handleWarning={handleCloseWarningDuplicate}
          changeVisibleModal={handleCloseWarningDuplicate}
        />
      }
      {
        isRequestLoginUrl &&
        <ModalWarning
          message={t("message.MESS_WRN_DIFFIRENCE_USER.text")}
          title={t("message.MESS_WRN_DIFFIRENCE_USER.title")}
          typeModal={MODAL_WARNING}
          typeButton={BUTTON_ACCEPT}
          handleWarning={handleCloseDiffUser}
          changeVisibleModal={handleCloseDiffUser}
        />
      }
      {isLoading && <Loading />}
    </div>
  );
};
function Step1() {
  const dispatch = useAppDispatch();
  dispatch(changeCheckStepFooter(true));
  useAppSelector(selectCheckStepFooter);
  return (
    <div>
      <Time />
    </div>
  );
}
function Step2(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  dispatch(changeCheckStepFooter(true));
  const currentStep = props.currentStep;
  const json = localStorage.getItem(KEY_USER) ?? '{}';
  const user_id = JSON.parse(json).user_id;
  const dataPrescriptionPharmacy = usePrescriptionPharmacyByIdQuery({
    user_id: user_id
  })?.data;
  const dataPharmacyInfo = useListPharmacyInfoWithPrescriptionsByUserIdQuery({
    user_id: user_id
  })?.data;
  const dataPrescription = [] as Array<PrescriptionPharmacy>;
  dataPharmacyInfo?.map((item: PharmacyInfo) => {
    item.prescriptions?.map((i: PrescriptionPharmacy) => {
      dataPrescription.push(i);
    });
  });
  const data = [] as Array<PrescriptionPharmacy>;
  dataPrescriptionPharmacy?.map((item: PrescriptionPharmacy) => {
    data.push(item);
  });
  dataPrescription?.map((item: PrescriptionPharmacy) => {
    data.push(item);
  });


  return (
    <div>
      <ReceptionInfo
        currentStep={currentStep}
        onChangeConfirmAmount={props.onChangeConfirmAmount}
        consent_form_id={props?.consent_form_id}
        consentFormNonInsuranceAmounts={props?.consentFormNonInsuranceAmounts}
        dataQuestions={props?.dataQuestions}
        setValue={props.setValue}
      />
      <BiologicalData currentStep={currentStep} />
      <PrescriptionReceiveNew
        prefList={props.prefList}
        cityList={props.cityList}
        dataPharmacyInfo={props.dataPharmacyInfo}
        methods={props.methods}
        addressStore={props?.addressStore}
        setCityList={props.setCityList}
      />
      <div className="custom-div">
        <div className="checkbox-div">
          <FormInputCheckbox
            name='agreePolicy'
            defaultChecked={props?.dataQuestions.checkedPolicy}
          >
            <Link
              to={linkToTermOfUser() + ''}
              style={{ color: '#308AE6' }}
              target="_blank"
            >
              {t('ReceptionInfo.link1')}
            </Link>
            {t('ReceptionInfo.link2')}
          </FormInputCheckbox>
        </div>
        <div className="text-div">
          <p>※ {t('ReceptionInfo.text1')}</p>
          <p>※ {t('ReceptionInfo.text2')}</p>
        </div>
      </div>
    </div>
  );
}
function Step3(props: any) {
  const dispatch = useAppDispatch();
  dispatch(changeCheckStepFooter(true));
  const currentStep = props.currentStep;
  return (
    <div>
      <ReceptionInfo currentStep={currentStep} />
      <BiologicalData currentStep={currentStep} />
      <PrescriptionReceiveConfirm />
    </div>
  );
}
function Step4(props: any) {
  const dispatch = useAppDispatch();
  dispatch(changeCheckStepFooter(false));
  const currentStep = props.currentStep;
  return (
    <div>
      <ReceptionInfo 
        currentStep={currentStep}
        dataQuestions={props?.dataQuestions}
        setValue={props.setValue}
      />
      <BiologicalData currentStep={currentStep} />
      <PrescriptionReceiveConfirm />
    </div>
  );
}
function ScheduInfo(props: any) {
  const dispatch = useAppDispatch();
  const [getRsvInfoById] = useLazyRsvInfoDetailByIdPatientQuery();
  const [rsvInfo, setRsvInfo] = useState<ReservationInfoDetailResponse>();
  const reservationInfoId = useAppSelector(selectReservationInfoId);
  dispatch(changeCheckStepFooter(false));
  const { t } = props;
  const columns = [
    {
      title: '',
      dataIndex: 'name'
    },
    {
      title: '',
      dataIndex: 'value'
    }
  ];
  useEffect(()=>{
    getDataRsv();
  }, [])
  const getDataRsv = () => {
    getRsvInfoById({id: reservationInfoId}).then((res:any) => {
      if (!res?.data?.error) {
        setRsvInfo(res?.data?.data);
      }
    })
  }
  const getRsvDivTypeStr = (rsvDiv: number|undefined, rsvType: number|undefined) => {
    let str = '';
    if (rsvType === 0) {
      str += t('modal-confirm-setting.table-content.exam') + '・';
    } else if (rsvType === 1) {
      str += t('modal-confirm-setting.table-content.re-exam') + '・';
    }
    if (rsvDiv === 0) {
      str += t('admin-schedule.reservation-frame-detail.rsvDivTextFull0');
    } else if (rsvDiv === 1) {
      str += t('admin-schedule.reservation-frame-detail.rsvDivTextFull1');
    }
    return str + t('admin-schedule.reservation-frame-detail.examine');
  };
  const titleTimeLabel = formatTimeRange(
    rsvInfo?.rsvInfo?.rsv_time || '', rsvInfo?.basic_time ?? 0
  );
  const dateString = rsvInfo?.rsvInfo?.rsv_ymd ? dayjs(rsvInfo?.rsvInfo?.rsv_ymd).format('YYYY-MM-DD') : '';
  const [year, month, day] = dateString.split("-");
  const formattedDate = rsvInfo?.rsvInfo?.rsv_ymd  ? `${year}年${month}月${day}日` : '';
  const data = [
    {
      name: (
        <span className="text-normal-styles">
          {t("booking-info-hospital.clinic_name")}
        </span>
      ),
      value: <span className="text-normal-styles">{rsvInfo?.rsvInfo?.clinic_name}</span>
    },
    {
      name: (
        <span className="text-normal-styles">
          {t('booking-online.info-clinic.title-date')}
        </span>
      ),
      value: <span className="text-normal-styles">{formattedDate}</span>
    },
    {
      name: (
        <span className="text-normal-styles">
          {t('booking-online.info-clinic.title-time')}
        </span>
      ),
      value: <span className="text-normal-styles">{rsvInfo?.rsvInfo?.rsv_time ? titleTimeLabel : ''}</span>
    },
    {
      name: (
        <span className="text-normal-styles">
          {t("booking-info-hospital.doctor_label")}
        </span>
      ),
      value: <span className="text-normal-styles">{rsvInfo?.rsvInfo?.doctor_name}</span>
    },
    {
      name: (
        <span className="text-normal-styles">
          {t('booking-online.info-clinic.title-type')}
        </span>
      ),
      value: <span className="text-normal-styles">{getRsvDivTypeStr(rsvInfo?.rsvInfo?.rsv_div, rsvInfo?.rsvInfo?.rsv_type)}</span>
    },
    {
      name: (
        <span className="text-normal-styles">
          {t('booking-online.info-clinic.title-menu')}
        </span>
      ),
      value: <span className="text-normal-styles">{rsvInfo?.rsvInfo?.treatment_menu_name}</span>
    }
  ];

  return (
    <div className="container-schedu-info">
      <div className="schedu-info">
        <div className="schedu-info-table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}
          />
        </div>
      </div>
      <div className="payment">
        <span>{t('receptioninfo.title-payment')}</span>
      </div>
      <BookingPaymentInfo rsv_fee={props.rsv_fee}/>
      <div className="schedu-regis">
        <Button className="text-bold-styles" onClick={props.handleToMyPage}>
          <span>{t('booking-online.info-clinic.to-my-page')}</span>
        </Button>
        <Button className="text-bold-styles" onClick={props.handleToTopPage}>
          <span>{t('booking-online.info-clinic.top-page')}</span>
        </Button>
      </div>
      <Button
        className="text-bold-styles print-button-step"
        onClick={props.PrintScreen}
      >
        <img src={printerIcon} />
        <span>{t('booking-online.info-clinic.print-page')}</span>
      </Button>
    </div>
  );
}

function ScheduBtn(props: any) {
  const { t } = props;
  return (
    <div className="container-schedu-info-btn">
      <div className="schedu-regis">
        <Button className="text-bold-styles" onClick={props.handleToMyPage}>
          <span>{t('booking-online.info-clinic.to-my-page')}</span>
        </Button>
        <Button className="text-bold-styles" onClick={props.handleToTopPage}>
          <span>{t('booking-online.info-clinic.top-page')}</span>
        </Button>
      </div>
      <Button
        className="text-bold-styles print-button-step"
        onClick={props.PrintScreen}
      >
        <img src={printerIcon} />
        <span>{t('booking-online.info-clinic.print-page')}</span>
      </Button>
    </div>
  );
}

function ConfirmFrame(props: any) {
  const dispatch = useAppDispatch();
  dispatch(changeCheckStepFooter(true));
  const { t } = useTranslation();
  const {nextStep, titleNext, prevStep, titleprev, currentStep, disablePrev} = props;
  const doctorSelectionList = useAppSelector(selectDoctorSelectionList);
  const radioIndex = useAppSelector(selectRadioIndex);
  const doctorSelection = doctorSelectionList.find((i: any) => i.id === radioIndex);
  const conditionDoctorValue = doctorSelection?.selection && doctorSelection?.selection?.user_name !== t('time-booking-info-prof.doctor-default');
  return (
    <div
      className={
        (currentStep === 0 || currentStep === 1 || currentStep === 2 )
          ? 'container-step container-step-calendar'
          : 'container-step container-step-normal'
      }
    >
      <Row className="row-step">
        <Col xs={24} sm={24} md={12} lg={24}>
          <Button
            id={`currentStep${currentStep}`}
            onClick={nextStep}
            disabled={currentStep === 0 && !conditionDoctorValue}
            className={
              (currentStep === 0 && !conditionDoctorValue)
                ? 'button-disabled next-button-step'
                : 'next-button-step'
            }
          >
            <span className="text-bold-styles">{titleNext}</span>
          </Button>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24}>
          <Button onClick={prevStep} className="prev-button-step" disabled={disablePrev}>
            <img src={arrow_left} />
            <span className="text-bold-styles">{titleprev}</span>
          </Button>
        </Col>
      </Row>
      <RequestLogin />
      <Login />
    </div>
  );
}

export default ArrowStepper;

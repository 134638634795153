import './styles/reset.css';
import './App.css';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';
import Home from './Screen/Doctor/Home/Home';
import Login from './Screen/Login/Login';
import ProtectedRoute from './Component/ProtectedRoute';
import Booking from './Screen/Booking/Booking';
import PT_10 from './Screen/PT-10';
import './styles/styles.css';
import Layout from './Layout';
import './i18n/i18n';
import ArrowStepper from './Component/ArrowStepper';
import History from './Screen/History/History';
import { useEffect } from 'react';
import SettingPassword from './Component/SettingPassword';
import ErrorPage from './Screen/ErrorPage';
import { linkToWP } from './common/Libs';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Layout>
          <div className="wrapper-content">
            <Routes>
              <Route path="/" element={<StateParamRoute />}>
                <Route path="/" element={<Booking />} />
              </Route>
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/reservation" element={<ArrowStepper />} />
              <Route path="/pt-10" element={<PT_10 />} />
              <Route path="/history" element={<History />} />
              <Route
                path="/setting-password"
                element={<SettingPassword newUser={true} />}
              />
              <Route
                path="/reset-password"
                element={<SettingPassword newUser={false} />}
              />
              <Route path="/error" element={<ErrorPage />} />
            </Routes>
          </div>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

const RedirectPage = () => {
  useEffect(() => {
    window.location.replace(linkToWP());
  }, []);
  return <></>;
};

const StateParamRoute = () => {
  const [searchParams] = useSearchParams();

  const clinic_id = searchParams.get('clinic_id');

  return clinic_id ? <Outlet /> : <RedirectPage />;
};

export default App;

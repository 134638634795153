import './styles.css';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Radio } from "antd";
import { useTranslation } from "react-i18next";
import TabbarSearch from "src/Component/TabbarSearch";
import TableContent from "./TableContent";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  closeModal: Function,
  searchDate: string,
  doctorId?: number,
  treatmentMenuId?: number,
  exmTime: number,
}

const APP: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [radioValue, setRadioValue] = useState(1);
    const [searchDate, setSearchDate] = useState('');
    const [doctorId, setDoctorId] = useState<number>();
    const [treatmentMenuId, setTreatmentMenuId] = useState<number>();

    const handleCancel = () => {
      props.closeModal();
    };

    const handleClose = () => {
      props.closeModal();
    };

    const handleSearch = (date:Dayjs = dayjs(), doctor:number = 0, clinic:number = 0) => {
      setSearchDate(date.format('YYYY-MM-DD'));
      setDoctorId(doctor);
      setTreatmentMenuId(clinic);
    }

    const handleRadio = (formal:number = 0) => {
      setRadioValue(formal);
    }

    useEffect(() => {
      setSearchDate(props.searchDate);
    }, [props.searchDate])

    useEffect(() => {
      setDoctorId(props.doctorId);
    }, [props.doctorId])

    useEffect(() => {
      setTreatmentMenuId(props.treatmentMenuId);
    }, [props.treatmentMenuId])

    return (
        <Modal maskClosable={false} title closeIcon={null} centered open={true} footer={null} className='modalDoctorConfirmPreOrder'>
          <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
            <Col span={24}>
              <div className='headerModal'>
                <span className='headerModal-title text-bold-styles'>{t("modal-confirm-setting.title")}</span>
                <span>
                  <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
                </span>
              </div>
            </Col>
            <Col span={24}>
              <Row className="tabbarFilter" gutter={[{xs: 20, sm: 20, md: 20}, { }]}>
                <Col className='fieldSearchDate'>
                  <TabbarSearch
                    isModal={true}
                    searchDate={props.searchDate}
                    doctorId={props.doctorId}
                    treatmentMenuId={props.treatmentMenuId}
                    handleSearch={(date:Dayjs = dayjs(), doctor:number = 0, clinic:number = 0) => {handleSearch(date, doctor, clinic)}} isNextDay={false}/>
                </Col>
                <Col>
                  <Radio.Group onChange={(e) => {handleRadio(e.target.value)}} defaultValue={radioValue} className="searchRadio">
                    <Radio value={0}>{t("modal-confirm-setting.formal.offline")}</Radio>
                    <Radio value={1}>{t("modal-confirm-setting.formal.online")}</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={24} className='bodyModal text-normal-styles'>
                <TableContent
                  searchDate={searchDate}
                  doctorId={doctorId}
                  treatmentMenuId={treatmentMenuId}
                  radioValue={radioValue}
                  exmTime={props.exmTime}
                />
            </Col>
            <Col span={24} className='footerModal' style={{textAlign: 'center'}}>
                <Button type='text' onClick={handleCancel} className='buttonDt04_05 text-bold-styles'>
                    {t("button-close.text")}
                </Button>
            </Col>
          </Row>
        </Modal>
    )
}

export default APP;
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Input } from 'antd';

const FormInput = ({ inputProps, ...props }: any) => {
  const { control, register } = useFormContext();
  return (
    <Controller
      name={props?.name}
      {...props}
      control={control}
      defaultValue={props?.defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error }
      }) => (
        <Form.Item
          className={props?.className}
          validateStatus={error ? 'error' : 'validating'}
          help={error?.message}
        >
          <Input
            disabled={props?.disabled}
            ref={register(props?.name).ref}
            placeholder={props?.placeholder}
            onKeyDown={props?.onKeyDown}
            {...{ value, onBlur }}
            {...inputProps}
            onChange={(e) => {
              onChange(e);
              if (inputProps?.onChange) {
                inputProps?.onChange(e);
              }
            }}
            onBlur={props?.onBlur}
          />
        </Form.Item>
      )}
    />
  );
};

export default FormInput;
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../../assets/image/plus2.svg';
import AgreementManagementTable from './AgreementManagementTable';
import AgreementManagementModal from './agreementManagementModal';
import { useAppDispatch } from 'src/Redux/hooks';
import {
  changeIsCreate,
  changeIsModalOpen,
  changeSelectedUpdateRecord
} from './store/slice';
import DeleteModal from './DeleteModal';
import SuccessModal from './SuccessModal';
import DeleteSuccessModal from './DeleteSuccessModal';
import {
  useAddConsentFormMutation,
  useDeleteConsentFormMutation,
  useGetListConsentFormQuery,
  useUpdateConsentFormMutation
} from 'src/Network/Home/DoctorSettingApi';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import Loading from 'src/Component/Modal/Loading';

const AgreementManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clinicId = getClinicIdFromLocalStorage();
  const { isLoading, isFetching } = useGetListConsentFormQuery(clinicId, {
    skip: clinicId < 0
  });
  const [_deleteItem, { isLoading: isDeleting }] =
    useDeleteConsentFormMutation();
  const [_addConsentForm, { isLoading: isAdding }] =
    useAddConsentFormMutation();
  const [_updateConsentForm, { isLoading: isUpdating }] =
    useUpdateConsentFormMutation();

  const loading =
    isLoading || isDeleting || isUpdating || isFetching || isAdding;

  const handleOpenAddModal = () => {
    dispatch(changeIsCreate(true));
    dispatch(changeIsModalOpen(true));
    dispatch(changeSelectedUpdateRecord(undefined));
  };

  return (
    <div className="agreement-management_container">
      <div className="add-agreement-form_container">
        <button
          onClick={handleOpenAddModal}
          className="add-agreement-form_button"
        >
          <PlusIcon />
          <span>{t('reExamCode.addAgreementForm')}</span>
        </button>
      </div>
      <AgreementManagementTable />
      <AgreementManagementModal />
      <DeleteModal />
      <SuccessModal />
      <DeleteSuccessModal />
      {loading && <Loading />}
    </div>
  );
};

export default AgreementManagement;

import { MPrefs } from './Network/Model/MPrefs';
import { MCitys } from './Network/Model/MCitys';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TreatMentMenuItem } from './Network/Model/TreatMentMenuItem';
import { TreatmentMenu } from './Network/Model/TreatmentMenu';
import { ReservationFrameRepeat } from './Network/Model/ReservationFrameRepeat';

export const MOBILE_SIZE = 768;
export const formatYYYYMMDD = 'YYYY/MM/DD';
export const placeholderyyyymmdd = 'yyyy/mm/dd';
export const RegExp = /^[\w+.-]+@([\w-]+\.)+[\w-]{2,4}$/;
export const halfWidthCheck = /^[!-~]*$/;
export const DATA_AREA = ['???', '????', '???', '???', '???'];

export const DATA_SUBJECT = ['??', '???', '???', '????', '??'];
export const formatYYYYMMD = 'YYYYMMD';
export const formatYYYYMMDDNoSlash = 'YYYYMMDD';
export const birthdayJanpan = (year: number, month: number, day: number) => {
  if (year && month && day) {
    return (
      year +
      '年' +
      (month < 10 ? '0' + month : month) +
      '月' +
      (day < 10 ? '0' + day : day) +
      '日'
    );
  } else {
    return '';
  }
};
export const baseUrlHome = (searchParams: any) => {
  const clinic_id = searchParams.get('clinic_id');
  return '?clinic_id=' + clinic_id;
};
export const getPrefList = (mPrefsAll: [MPrefs], labelDefault: string) => {
  const newPrefList = [] as Array<{ value: number; label: string }>;
  mPrefsAll.forEach((item) => {
    newPrefList.push({
      value: item.id,
      label: item.prefectures_name
    });
  });
  return [{ value: 0, label: labelDefault }, ...newPrefList];
};
export const getCityList = (
  mCitysAll: [MCitys],
  labelDefault: string,
  status: number
) => {
  const newCityList = [] as Array<{ value: number; label: string }>;
  mCitysAll.forEach((item) => {
    newCityList.push({
      value: item.id,
      label: item.city_name
    });
  });
  if (status === 0) {
    return [{ value: 0, label: labelDefault }, ...newCityList];
  } else {
    return newCityList;
  }
};

export const MODAL_WARNING = 1;
export const MODAL_NEGATIVE = 2;
export const MODAL_CONFIRM = 3;
export const MODAL_SUCCESS = 4;
export const MODAL_FAIL = 5;

export const BUTTON_CLOSE = 1;
export const BUTTON_ACCEPT = 2;
export const BUTTON_CHOOSE = 3;
export const BUTTON_WARNING = 4;
export const BUTTON_CONFIRM = 5;
export const BUTTON_WARNING_DUPLICATE = 6;

export const RECEIVE_ADDRESS_PHARMACY = 1;  //receive medicine both at pharmacy and address
export const RECEIVE_PHARMACY = 2;  //receive medicine only at pharmacy

export const STEP_CHOOSE_INFORMATION = 1;
export const STEP_CONFIRM_INFORMATION = 2;
export const STEP_CHOOSE_PRINT = 3;

export const HOURS_IN_DAY = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];
export const HALF_AN_HOURS = ['00', '30'];
export const QUARTER_AN_HOURS = ['00', '15', '30', '45'];
export const EXM_TIME = {
  HALF_AN_HOURS: 30,
  QUARTER_AN_HOURS: 15
};

export const timeFrame = (rsv_time?: string, exm_time?: number) => {
  const rsv_time_hh = Number(rsv_time?.substring(0, 2));
  const rsv_time_mm = Number(rsv_time?.substring(2, 4));
  const rsv_time_mm_last = rsv_time_mm + (exm_time??0);
  const rsv_time_mm_last_60 = rsv_time_mm_last - 60;
  let rsv_time_last = rsv_time?.substring(0, 2) + ':' + rsv_time?.substring(2, 4) + ' ~ ' + (rsv_time_hh < 10 ? '0' + rsv_time_hh : rsv_time_hh) + ':' + (rsv_time_mm_last < 10 ? '0' + rsv_time_mm_last : rsv_time_mm_last);
  if (rsv_time_mm_last_60 >= 0) {
    rsv_time_last = rsv_time?.substring(0, 2) + ':' + rsv_time?.substring(2, 4) + ' ~ ' + ((rsv_time_hh + 1) < 10 ? '0' + (rsv_time_hh + 1) : (rsv_time_hh + 1)) + ':' + (rsv_time_mm_last_60 < 10 ? '0' + rsv_time_mm_last_60 : rsv_time_mm_last_60);
  }
  return rsv_time_last;
};

export const checkStatus = ( t: Function, rsv_status?: number, reception_status?: number, diagnosis_status?: number) => {
  if (rsv_status === 0) {
    return <span style={{ color: 'grey' }}>{t("reservation-list.header.cancel")}</span>;
  } else if (rsv_status === 1) {
    if (reception_status === 1) {
      return <span style={{ color: 'orange' }}>{t("reservation-list.header.wait_room")}</span>;
    } else if (reception_status === 2) {
      return <span style={{ color: '#023468' }}>{t("reservation-list.header.waiting")}</span>;
    } else if (reception_status === 9) {
      return <span style={{ color: 'grey' }}>{t("cancelRsv.cancel")}</span>;
    } else if (reception_status === 7) {
      return <span style={{ color: 'grey' }}>{t("reservation-list.header.wait_room_timeout")}</span>;
    } else if (reception_status === 8) {
      return <span style={{ color: 'grey' }}>{t("reservation-list.header.cancel_from_doctor")}</span>;
    } else if (reception_status === 3) {
      if (diagnosis_status === 0 || diagnosis_status === null) {
        return <span style={{ color: 'red' }}>{t("reservation-list.header.waiting-for-payment")}</span>;
      } else if (diagnosis_status === 1 || diagnosis_status === 2) {
        return <span style={{ color: '#16db65' }}>{t("reservation-list.header.paid")}</span>;
      }
    }
  } else if (rsv_status === 3) {
    return <span style={{ color: 'grey' }}>{t("reservation-list.header.tentative_rsv")}</span>;
  }
  return '';
};

export const isFutureDate = (dateStr: string) => {
  return (
    dayjs(dateStr).isAfter(dayjs()) || dayjs(dateStr).isSame(dayjs(), 'day')
  );
};

export const getAge = (year?: number, month?: number, day?: number) => {
  const today = new Date();
  let age = today.getFullYear() - (year??0);
  const m = today.getMonth() + 1 - (month??0);
  if (m < 0 || (m === 0 && today.getDate() < (day??0))) {
    age--;
  }
  return age;
};

export const DAY_EXPIRED = '21001231';

export const renderTreatmentValue =(uniqueTreatmentList:any, id:number, treatmentList?:TreatMentMenuItem[], treatMentMenuList?: TreatmentMenu[]) => {
  const { t } = useTranslation();
  const treatmentItem = treatMentMenuList?.find(((i:TreatmentMenu) => i.id === Number(id)))
  const treatmentListById = treatmentList?.filter((i:TreatMentMenuItem) => i.id === id)
  let treatmentOffline;
  let treatmentOnline = "";
  treatmentListById?.forEach((item) => {
    if (item.value === '1' || item.value === '3') {
      treatmentOffline = <>{t("schedule.reservation-setting.tooltip.line-3.content-1")}<span><br/></span></>;
    }
    if (item.value === '2' || item.value === '4') {
      treatmentOnline = t("schedule.reservation-setting.tooltip.line-3.content-0");
    }
  })
  return <div>
    {uniqueTreatmentList?.length !== 1 && treatmentItem?.treatment_menu_name}
    {uniqueTreatmentList?.length !== 1 && <span><br/></span>}
    {treatmentOffline}{treatmentOnline}
  </div>
}

export const contentRepeat = (data: ReservationFrameRepeat, repeatOnDayOfWeekOptions?:Array<{value:number, label:string}>) => {
  const { t } = useTranslation();
  let content = data?.rsv_start_time?.substring(0, 2) + ':' + data?.rsv_start_time?.substring(2, 4) + ' ~ ' + data?.rsv_end_time?.substring(0, 2) + ':' + data?.rsv_end_time?.substring(2, 4);
  if (data?.repeat_type === 0) { // no repeats
      content += '<br/>' + t("schedule.reservation-setting.treatmentmenu.repeat-type.type-0");
  } else if (data?.repeat_type === 1 || data?.repeat_type === 2) { // weekly
      const repeatOnDay = repeatOnDayOfWeekOptions?.find((item) => item.value === data?.repeat_on_day_of_week);
      let publicOnHoliday = "";
      if (data?.is_public_on_holiday) { // also publish holidays
          publicOnHoliday = t("schedule.reservation-setting.treatmentmenu.is-public-on-holiday");
      } else {
          publicOnHoliday = t("schedule.reservation-setting.treatmentmenu.un-public-on-holiday");
      }
      let type = '';
      if (data?.repeat_type === 1) {
        type = t("schedule.reservation-setting.treatmentmenu.repeat-type.type-1");
      } else {
        type = t("schedule.reservation-setting.treatmentmenu.repeat-type.type-2");
      }
      content += '<br/>' + type + repeatOnDay?.label + publicOnHoliday;
  }
  const rsv_start_ymd = dayjs(data?.rsv_start_ymd);
  const repeat_type = data?.repeat_type;
  const formatStartYMD = birthdayJanpan(rsv_start_ymd.year(), rsv_start_ymd.month() + 1, rsv_start_ymd.date());
  if (repeat_type === 0) { // no repeat
    content += '<br/>' + formatStartYMD + ' ~ ' + formatStartYMD;
  } else {
    if (data?.period) { // indefinite period
        content += '<br/>' + formatStartYMD + ' ~ ' + t("schedule.sidebar-reservation.treatment-menu.period");
    } else {
        const rsv_end_ymd = dayjs(data?.rsv_end_ymd);
        content += '<br/>' + formatStartYMD + ' ~ ' + birthdayJanpan(rsv_end_ymd.year(), rsv_end_ymd.month() + 1, rsv_end_ymd.date());
    }
  }
  content += '<br/>' + t("schedule.reservation-setting.treatmentmenu.reservation-frame-repeat") + data?.upper_limit_frame;
  return content;
}
export const renderTreatmentValueEdit =(treatmentList?:TreatMentMenuItem[]) => {
  const { t } = useTranslation();
  let treatmentOffline;
  let treatmentOnline = '';
  const arrayTreatMent:Array<string> = [];
  treatmentList?.forEach((item) => {
    arrayTreatMent.push(item.value??'');
  })
  if (arrayTreatMent.includes('1') && arrayTreatMent.includes('3')) {
    treatmentOffline = <>{t("schedule.reservation-setting.treatmentmenu.edit_treatment_menu.type_1")}<span><br/></span></>;
  } else if (arrayTreatMent.includes('1') && !arrayTreatMent.includes('3')) {
    treatmentOffline = <>{t("schedule.reservation-setting.treatmentmenu.edit_treatment_menu.type_2")}<span><br/></span></>;
  } else if (!arrayTreatMent.includes('1') && arrayTreatMent.includes('3')) {
    treatmentOffline = <>{t("schedule.reservation-setting.treatmentmenu.edit_treatment_menu.type_3")}<span><br/></span></>;
  }
  if (arrayTreatMent.includes('2') && arrayTreatMent.includes('4')) {
    treatmentOnline = t("schedule.reservation-setting.treatmentmenu.edit_treatment_menu.type_4");
  } else if (arrayTreatMent.includes('2') && !arrayTreatMent.includes('4')) {
    treatmentOnline = t("schedule.reservation-setting.treatmentmenu.edit_treatment_menu.type_5");
  } else if (!arrayTreatMent.includes('2') && arrayTreatMent.includes('4')) {
    treatmentOnline = t("schedule.reservation-setting.treatmentmenu.edit_treatment_menu.type_6");
  }
  return <div>
    {treatmentOffline}{treatmentOnline}
  </div>
}

export interface RsvAcceptPeriodListType  {
  129600: string;
  86400: string;
  43200: string;
  20160: string;
  10080: string;
  4320: string;
  2880: string;
  1440: string;
  360: string;
  180: string;
  60: string;
  30: string;
  15: string;
};

export const rsvAcceptPeriodList: RsvAcceptPeriodListType = {
  129600: '3ヶ月(90日)前',
  86400: '2ヶ月(60日)前',
  43200: '1ヶ月(30日)前',
  20160: '2週間前',
  10080: '1週間前',
  4320: '3日前',
  2880: '2日前',
  1440: '前日',
  360: '当日6時間前',
  180: '当日3時間前',
  60: '当日1時間前',
  30: '当日30分前',
  15: '当日15分前'
};

export const currencyFormat = (num: number) => {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const IMAGES_TYPE_ALLOW = ["jpg", "jpeg", "gif", "png", "tif", "pdf", "heic"];
import './styles.css';
import dayjs, { Dayjs } from 'dayjs';
import CalendarBooking from '../../Component/CalendarBooking';
import TimeBooking from '../../Component/TimeBooking';
import { useState, useEffect } from 'react';

const Time = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [dataHour, setDataHour] = useState<any>();

  useEffect(() => {
    if (!selectedDate) {
      setIsDateSelected(false);
    }
  }, [!isDateSelected]);

  const handleSelectDate = (date: Dayjs) => {
    setSelectedDate(date);
    setIsDateSelected(true);
    setDataHour(dataHour);
  };

  return (
    <div className="container-calendar">
      <div className="frame-content">
        <div className="content-calendar">
          <div className="container-time">
            <CalendarBooking
              setSelectedDate={handleSelectDate}
              setIsSelected={setIsDateSelected}
              setDataHourInfo={setDataHour}
            />
            {isDateSelected && (
              <TimeBooking
                key={selectedDate.toString()}
                timeSelected={selectedDate}
                dataHourReservationFrame={dataHour}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Time;

import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs, {Dayjs} from 'dayjs';
import { CustomTreatmentMenuByDateResponse } from '../../../../Network/Model/TreatmentMenu';
import { ReservationInfoResponse } from '../../../../Network/Model/ReservationInfo';
import {
  useLazyReservationInfoListByDateTimeQuery,
  useLazyReservationInfosByDateIdAndClinicIdQuery
} from '../../../../Network/Home/DoctorApi';
import './styles.css';
import CloseBtn from 'src/assets/image/closebtn.svg';
import DT_01_2 from 'src/Screen/Doctor/Schedule/DT-01-2/';
import { useTranslation } from 'react-i18next';
import ModalWarning from '../../../../Component/Modal/ModalWarning';
import { BUTTON_ACCEPT, MODAL_WARNING } from '../../../../constant';
import { getClinicIdFromLocalStorage } from '../../../../common/Libs';

const APP = ({
  exmTime,
  dateId,
  isShow,
  onClose,
  moveToSettingTab,
  rsvDate,
  rsvTime,
  reloadRsvFrame,
  selectedClinicUserId,
  selectedTreatmentMenuId
}: {
  exmTime: number;
  dateId: number;
  isShow: boolean;
  onClose: (event: React.MouseEvent<HTMLInputElement>) => void;
  moveToSettingTab: (event: React.MouseEvent<HTMLInputElement>) => void;
  rsvDate: string;
  rsvTime: string;
  reloadRsvFrame: (event: CustomEvent) => void;
  selectedClinicUserId: number;
  selectedTreatmentMenuId: number;
}) => {
  const { t } = useTranslation();
  const [openDt012, setOpenDt012] = useState<boolean>(false);
  const [selectedRsvInfoId, setSelectedRsvInfoId] = useState<number>(-1);
  const [dateTimeStr, setDateTimeStr] = useState<string>('');
  const [clinicId] = useState(getClinicIdFromLocalStorage());
  const [getRsvInfosByDateTime] = useLazyReservationInfoListByDateTimeQuery();
  const [treatmentMenuTableData, setTreatmentMenuTableData] = useState<
      CustomTreatmentMenuByDateResponse[]
      >([]);
  const [dateTimeDetail, setDateTimeDetail] = useState<any>(null);
  const [rsvInfoTableData, setRsvInfoTableData] = useState<
      ReservationInfoResponse[]
      >([]);
  const [getReservationInfoByDateIdAndClinicId] =
      useLazyReservationInfosByDateIdAndClinicIdQuery();
  const [errMsg, setErrMsg] = useState<string>();
  const [apiData, setApiData] = useState<any>()

  useEffect(() => {
    if (!apiData) return;
    setDateTimeDetail(apiData.date_time_detail)

    try {
      filterData();
    } catch (e){
      setRsvInfoTableData([])
      setTreatmentMenuTableData([])
    }

  }, [apiData, selectedClinicUserId, selectedTreatmentMenuId])

  const filterData = () => {
    const isFilterByTreatmentMenu = selectedTreatmentMenuId && selectedTreatmentMenuId != -1;
    const isFilterByClinicUser = selectedClinicUserId && selectedClinicUserId != -1;

    if (!isFilterByTreatmentMenu && !isFilterByClinicUser) {
      setRsvInfoTableData(apiData.rsv_infos || [])
      setTreatmentMenuTableData(apiData.tm_menus || [])
      return;
    }

    let tmMenus: CustomTreatmentMenuByDateResponse[] = [];
    for (const tm of apiData.tm_menus || []) {
      if (isFilterByTreatmentMenu && tm.id != selectedTreatmentMenuId) {
        continue;
      }

      if (!isFilterByClinicUser) {
        tmMenus.push(tm);
      }

      const clinicUsers = JSON.parse(tm.clinic_users || '{}')
      for (const cu of clinicUsers) {
        if (cu.clinic_user_id === selectedClinicUserId) {
          tmMenus.push(tm);
          break;
        }
      }
    }

    let rsvInfos : ReservationInfoResponse[] = [];
    for (const info of apiData.rsv_infos || []) {
      if ((!isFilterByTreatmentMenu|| info.treatment_menu_id === selectedTreatmentMenuId) && (!isFilterByClinicUser || info.clinic_user_id === selectedClinicUserId)){
        rsvInfos.push(info);
      }
    }
    setRsvInfoTableData(rsvInfos)
    setTreatmentMenuTableData(tmMenus)
  }

  const handleGetRsvInfosByDateTime = () => {
    const param = {
      rsvDate: rsvDate,
      rsvTime: rsvTime,
      clinicId: getClinicIdFromLocalStorage()
    }
    getRsvInfosByDateTime(param).then((res) => {
      setApiData(res.data?.data);
    })
  }

  useEffect(() => {
    if (selectedRsvInfoId !== -1) {
      setOpenDt012(true);
    }
  }, [dateId]);
  useEffect(() => {
    if (dateId == -1) {
      handleGetRsvInfosByDateTime();
      setOpenDt012(false);
      setSelectedRsvInfoId(-1);
    }
  }, [rsvDate,rsvTime, isShow ]);
  const columns1 = [
    {
      title: t('admin-schedule.reservation-frame-detail.reservation-info-id'),
      dataIndex: 'id',
      key: 'id',
      render: (_: any, record: ReservationInfoResponse) => (
        <div style={{  justifyContent: "center", width: "100%", display: "flex"
        }}>
          <Button style={{border: "none", width: "fit-content", height: "fit-content", padding: 0, background: "transparent"}} tabIndex={0}
            className={'rsvId'}
            onClick={() => {
              setSelectedRsvInfoId(record.id);
              setOpenDt012(true);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                setSelectedRsvInfoId(record.id);
                setOpenDt012(true);
              }
            }}
          >
            {record.id}
          </Button>
        </div>
      )
    },
    {
      title: t('admin-schedule.treatment_menu'),
      dataIndex: 'treatment_menu_name',
      key: 'name'
    },
    {
      title: t('admin-schedule.reservation-frame-detail.doctor-in-charge-name'),
      dataIndex: 'doctor_name',
      key: 'doctor_name'
    },
    {
      title: t('admin-schedule.reservation-frame-detail.reservation-div'),
      key: 'rsv_div',
      render: (_: any, record: ReservationInfoResponse) => (
        <div>
          {record.rsv_div === 1
            ? t('admin-schedule.reservation-frame-detail.rsvDiv1')
            : record.rsv_div === 0
            ? t('admin-schedule.reservation-frame-detail.rsvDiv0')
            : ''}
        </div>
      )
    },
    {
      title: t('admin-schedule.reservation-frame-detail.patient-name'),
      dataIndex: 'patient_name',
      key: 'patient_name'
    }
  ];
  const columns2 = [
    {
      title: t('admin-schedule.treatment_menu'),
      dataIndex: 'treatment_menu_name',
      key: 'tm_name'
    },
    {
      title: t('update-dt-01.doctors'),
      key: 'clinic_users',
      render: (_: any, record: CustomTreatmentMenuByDateResponse) => (
          <div>
            {displayDoctorName(record.clinic_users || '{}')}
          </div>
      )
    },
    {
      title: t('admin-schedule.reservation-frame-detail.reservation-div'),
      key: 'rsv_type_text',
      render: (_: any, record: CustomTreatmentMenuByDateResponse) => (
          <div>
            {displayRsvType(
                record.online_first_exm,
                record.online_re_exm,
                record.face_to_face_first_exm,
                record.face_to_face_re_exm
            )}
          </div>
      )
    },
    {
      title: t('update-dt-01.upper-limit'),
      dataIndex: 'upper_limit_frame',
      key: 'upper_limit_frame'
    }
  ];

  const displayDoctorName = (jsonClinicUsers: string) => {
    const clinicUsers = JSON.parse(jsonClinicUsers);
    return (
        <div style={{lineHeight: "30px"}}>
          {clinicUsers?.map((cu: any, i: number) => (
              <div key={i}>
                {cu?.clinic_user_name}
              </div>
          ))}
        </div>
    );
  }

  const handleFrameDetailAPI = (dateId: number) => {
    const param = {
      clinic_id: clinicId,
      date_id: dateId
    };
    getReservationInfoByDateIdAndClinicId(param).then((res: any) => {
      if (res?.data?.error) {
        setErrMsg(res?.data?.message);
      } else {
        setApiData(res.data?.data);
      }
    });
  };

  useEffect(() => {
    if (isShow && dateId !== -1) {
      handleFrameDetailAPI(dateId);
      setOpenDt012(false);
      setSelectedRsvInfoId(-1);
    }
  }, [isShow, dateId]);

  useEffect(() => {
    if (isShow && dateId !== -1) {
      handleFrameDetailAPI(dateId);
      setOpenDt012(false);
      setSelectedRsvInfoId(-1);
    }
  }, []);

  useEffect(() => {
    if (!openDt012) {
      setSelectedRsvInfoId(-1);
    }
  }, [openDt012, selectedRsvInfoId]);

  useEffect(() => {
    if (!dateTimeDetail) return;
    const str = `${dateTimeDetail.year}年${dateTimeDetail.month}月${
      dateTimeDetail.day
    }日（${dateTimeDetail.day_suffix_jp}）${
      dateTimeDetail.time
    }〜${calculateRsvFinishTime(dateTimeDetail.date_full, exmTime)}`;
    setDateTimeStr(str);
  }, [dateTimeDetail]);

  const displayRsvType = (
    online_first_exm: number,
    online_re_exm: number,
    face_to_face_first_exm: number,
    face_to_face_re_exm: number
  ) => {
    const isOnline = online_re_exm === 1 || online_first_exm === 1;
    const isOffline = face_to_face_first_exm === 1 || face_to_face_re_exm === 1;

    if (isOnline && isOffline) {
      return (
        t('admin-schedule.reservation-frame-detail.rsvDiv1') +
        '・' +
        t('admin-schedule.reservation-frame-detail.rsvDiv0')
      );
    } else if (isOnline) {
      return (
        t('admin-schedule.reservation-frame-detail.rsvDiv0')
      );
    } else if (isOffline) {
      return (
        t('admin-schedule.reservation-frame-detail.rsvDiv1')
      );
    }
  };

  return (
    <div>
      <div
        style={{ width: '480px' }}
        className={!openDt012 && isShow ? 'dt011' : 'dt011 hidden'}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p className={'title'}>
              {t('admin-schedule.reservation-frame-detail.title')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right'
            }}
          >
            <Button onClick={onClose} className={'closeBtn'}>
              <img src={CloseBtn} alt="" />
            </Button>
          </div>
        </div>
        <div className={'thick'}>
          {t('admin-schedule.reservation-frame-detail.reservation-time')}
        </div>
        <RsvFrameDateTime dateTimeStr={dateTimeStr} exmTime={exmTime} />
        <div className={'thick'}>
          {t('admin-schedule.reservation-frame-detail.reservation-info-list')}
        </div>
        <Table
            className={'table'}
            dataSource={rsvInfoTableData}
            columns={columns1}
            pagination={false}
            locale={{emptyText: t("MESS_HAS_NO_DATA")}}
        />
        {dateId !== -1 &&
        <Table
          className={'table'}
          dataSource={treatmentMenuTableData}
          columns={columns2}
          pagination={false}
          locale={{emptyText: t("MESS_HAS_NO_DATA")}}
        />
        }

      </div>
      <DT_01_2
        rsvInfoId={selectedRsvInfoId}
        isShow={openDt012 && isShow}
        dateTimeStr={dateTimeStr}
        onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
          setOpenDt012(false);
          if (dateId != -1){
            handleFrameDetailAPI(dateId);
          } else {
            handleGetRsvInfosByDateTime();
          }
        }}
        reloadRsvFrame={() => {reloadRsvFrame(new CustomEvent(""))}}
      />
      {errMsg && errMsg !== '' && (
        <ModalWarning
          message={errMsg}
          typeModal={MODAL_WARNING}
          typeButton={BUTTON_ACCEPT}
          changeVisibleModal={() => {
            setErrMsg('');
          }}
        />
      )}
    </div>
  );
};

const ClinicUsers = (props: any) => {
  return (
    <div style={{ display: 'flex', flexWrap: "wrap" }}>
      {props.clinic_users.map((cu: any, i: number) => (
        <div className={'clinic_user'} key={i}>
          {cu?.user_name}
        </div>
      ))}
    </div>
  );
};

const calculateRsvFinishTime = (dateFull: string, exmTime: number) => {
  return dayjs(dateFull)?.add(exmTime, 'minute').format('HH:mm');
};

const RsvFrameDateTime = (props: any) => {
  if (props.dateTimeStr === null) return <div />;
  return <div className={'dateTime'}>{props.dateTimeStr}</div>;
};

export default APP;

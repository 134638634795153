import React from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import 'videojs-record';

import 'webrtc-adapter';

interface PlayerCustom extends Player {
  record?: () => any;
}

const VideoJSComponent = (props: any) => {
    const videoRef = React.useRef<HTMLDivElement | null>(null);
    const playerRef: React.MutableRefObject<Player | null> = React.useRef(null);
    const {options, onReady, turnOnCamera} = props;

    React.useEffect(() => {
        if (videoRef.current && !playerRef.current) {
            const videoElement = document.createElement('video-js');
            videoElement.className = 'video-js vjs-default-skin';
            videoRef.current.appendChild(videoElement);
            const player = playerRef.current = videojs(videoElement, options) as PlayerCustom;
            if (player && player.record) {
              onReady(player);
            }
        }
    },[options, videoRef]);

    React.useEffect(() => {
        const player = playerRef.current;
    
        return () => {
          if (player && !player.isDisposed()) {
            player.dispose();
            playerRef.current = null;
          }
        };
      }, [playerRef]);

    return (
    <div data-vjs-player className={turnOnCamera ? "camera" : "camera-none" } >
      <div ref={videoRef}></div>
    </div>
    );
};

export default VideoJSComponent;
import './styles.css';
import {Button, Modal, Upload, message} from "antd";
import { useEffect, useState } from "react";
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { LoadingOutlined } from '@ant-design/icons';
import idFront from 'src/assets/image/idFront.png';
import idBack from 'src/assets/image/idBack.png';
import {useTranslation} from "react-i18next";
import { IMAGES_TYPE_ALLOW } from 'src/constant';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

const UploadCardImage = (props: any) => {
	const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState<string>();
  const typeImage = props.isFront ? 'insurance_img1' : 'insurance_img2';

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [listPreviewImage, setListPreviewImage] = useState<UploadFile[]>(
    () => 
    {
      return !props.previewImageProp ? [] : 
      [
        {
          uid: '-1',
          name: t('register-insurance.img-card.front'),
          status: 'done',
          url: props.previewImageProp,
        }
      ]
    }
  );

  useEffect(() => {
    if (imageUrl) {
      props.methods.clearErrors(typeImage);
    } else {
      props.methods.setValue(typeImage, undefined);
      props.methods.setError(typeImage, true);
    }
  }, [imageUrl]);

	const getBase64 = (img: RcFile, callback: (url: string) => void) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result as string));
		reader.readAsDataURL(img);
  };
	  
	const beforeUpload = (file: RcFile) => {
		const isJpgOrPng = IMAGES_TYPE_ALLOW.includes(file.type.split('/')[1]);
		if (!isJpgOrPng) {
		  message.error(t('message.MESS_CMN_ERR_UPLOAD_FILE_WRONG_FORMAT').replace('{0}', props.isFront ? t('register-insurance.img-card.front') : t('register-insurance.img-card.back')).replace('{1}', t('register-insurance.img-card.invalid-type')));
		}
		const isLt2M = file.size / 1024 / 1024 < 20;
		if (!isLt2M) {
		  message.error(t('message.MESS_CMN_ERR_OVER_DOSE').replace('{0}', props.isFront ? t('register-insurance.img-card.front') : t('register-insurance.img-card.back')).replace('{1}', t('register-insurance.img-card.max-size')));
		}
		return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        props.methods.setValue(typeImage, info.file.originFileObj);
        setImageUrl(url);
      });
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64Preview = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Preview(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const dummyRequest = ( options:RcCustomRequestOptions ) => {
    if (options.onSuccess)
      options.onSuccess('ok');
  };
  
  const yetUpload = (
    <div className='yetUpload'>
      <img src={props.isFront ? idFront : idBack} alt={''}/>
      <p className="text-bold-styles titleAvt">{t('register-insurance.select-file')}</p>
      <p>{t('register-insurance.no-file')}</p>
    </div>
  );

  const beforeUploadDisplay = (
      <div>
          {loading ? <LoadingOutlined /> : yetUpload}
      </div>
  );

  const afterUploadDisplay = (
    <img src={imageUrl} alt="avatar" />
  );

  const previewCard = (
    <div className="wrapper-previewImage">
      <div onClick={() => handlePreview(listPreviewImage[0])} className='previewImage'>
        <Upload
          listType="picture-card"
          fileList={listPreviewImage}
          onPreview={handlePreview}
          customRequest={dummyRequest}
        >
        </Upload>
      </div>
      <Button type='text' onClick={() => setListPreviewImage([])} className='btnClear text-bold-styles'>{t('register-insurance.btn-clear')}</Button>
    </div>
  );

  const uploadedCard = (
    <Upload
      name="avatar"
      listType="picture-card"
      className="uploadedImage"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      onChange={handleChange}
      >
      {imageUrl ? afterUploadDisplay : beforeUploadDisplay}
    </Upload>
  )

    return (
      <div className='uploadCardImage'>
        <div className="img-card">
          <div className='textLabel'>
            {
              props.isFront ? t('register-insurance.img-card.text-front') : t('register-insurance.img-card.text-back')
            }
          </div>
          {
            listPreviewImage.length ? previewCard : uploadedCard
          }
        </div>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        {
          (props.isFront && props.methods.formState.errors.insurance_img1) &&
          <div className='message-error'>
            {props.methods.formState.errors.insurance_img1.message}
          </div>
        }
        {
          (!props.isFront && props.methods.formState.errors.insurance_img2) &&
          <div className='message-error'>
            {props.methods.formState.errors.insurance_img2.message}
          </div>
        }
      </div>
    )
}

export default UploadCardImage;
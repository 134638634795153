import { Button, ConfigProvider, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table';
import CustomPagination from '../CustomPagination';
import { useEffect, useState } from 'react';
import './styles.css'
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from 'src/Utils/textUtils';

interface DataTypeResult {
    key: string;
    nameClinic: string;
    address: string;
    doctor: string;
    clinic_id: string;
}

const ListClinicsOnline = (props: any) => {
    const dataResult: DataTypeResult[] = props?.dataSearch;
    const { t } = useTranslation();
    let locale = {
      emptyText: <span className="emptyText" dangerouslySetInnerHTML={replaceWithBr(t("reservation.list.emptyText"))}></span>,
    };
    let localeInit = {
      emptyText: <span></span>,
    };
    const columnsResult: ColumnsType<DataTypeResult> = [
      {
        title: t("reservation.list.nameClinic"),
        dataIndex: 'nameClinic',
        key: 'nameClinic',
        width: '415px',
        render: (_, record) => <a target='_blank' rel="noreferrer" className="name-clinic" href={process.env.REACT_APP_WP_URL + `/clinicdetail/?clinic_id=${record.clinic_id}`}>{record.nameClinic}</a>
      },
      {
        title: t("reservation.list.address"),
        dataIndex: 'address',
        key: 'address',
        width: '415px',
      },
      {
        title: t("reservation.list.doctor"),
        dataIndex: 'doctor',
        key: 'doctor',
        width: '415px',
      },
      {
        title: t("reservation.list.register"),
        dataIndex: 'register',
        key: 'register',
        align: 'center',
        width: '160px',
        render: (_, record) => (
          <Button 
              type="primary"
              ghost 
              className="btn-register"
              href={`${process.env.REACT_APP_APP_URL}?clinic_id=${record.clinic_id}`}
              target='_blank'
          >
              {t("reservation.list.button-search")}
          </Button>
        ),
      },
    ];
    useEffect(() => {
      if (props.currentPage) {
        const boxResult = document.querySelectorAll('.box-result')[0];
        const listItems = boxResult?.querySelectorAll('.ant-pagination-item');
        const itemActive = boxResult?.querySelectorAll('.ant-pagination-item-active');
        const titleActive = itemActive[0]?.getAttribute('title');
        let titleLastItem = '0';
        
        if (listItems.length > 3) {
          const classLastItemname = listItems[listItems.length - 1].className;
          const hasItemLast = classLastItemname.includes('item-last');
          if (!hasItemLast) {
            listItems[listItems.length - 1].className = classLastItemname + ' item-last';
          }
          titleLastItem = listItems[listItems.length - 1].getAttribute('title') as string;
        }
        console.log(titleLastItem);
        

        if (Number(titleActive) >= 3) {
          const classFirstItemname = listItems[0].className;
          const hasItemNone = classFirstItemname.includes('item-first');
          if (!hasItemNone) {
            listItems[0].className = classFirstItemname + ' item-first';
          }
        } else {
          const firstItem = boxResult?.querySelectorAll('.item-first');
          let firstClassItem = firstItem[0]?.className;
          if (firstClassItem) {
            const classArray = firstClassItem.split(' ');
            const filteredClassArray = classArray.filter(className => className !== 'item-first');
            firstClassItem = filteredClassArray.join(' ');
            firstItem[0].className = firstClassItem;
          }
        }

        if (Number(titleActive) >= Number(titleLastItem) -1) {
          const lastItem = boxResult?.querySelectorAll('.item-last');
          let lastClassItem = lastItem[0]?.className;
          if (lastClassItem) {
            const classArray = lastClassItem.split(' ');
            const filteredClassArray = classArray.filter(className => className !== 'item-last');
            lastClassItem = filteredClassArray.join(' ');
            lastItem[0].className = lastClassItem;
          }
        }
      }

    }, [props.currentPage, dataResult]);


    return (
        <div className="box-result">
            <div className="box-result-header text-bold-styles">
                {t("reservation.list.totalResult").replace('{1}',props.totalResult)}
            </div>
            <div className="box-result-body">
                <ConfigProvider
                    theme={{
                        components: {
                            Table: {
                                headerBorderRadius: 0,
                                headerBg: '#ffffff',
                                rowHoverBg: '#ffffff',
                                footerBg: '#ffffff'
                            }
                        }
                    }}
                >
                    <Table
                        dataSource={dataResult}
                        columns={columnsResult}
                        bordered
                        pagination={false}
                        locale={dataResult && props.isClickSearch ? locale : localeInit}
                    />
                    {props.totalResult > props.pageSize && (
                      <CustomPagination
                        current={props.currentPage}
                        total={props.totalResult}
                        pageSize={props.pageSize}
                        setCurrentPage={props.setCurrentPage}
                      />
                    )}
                </ConfigProvider>
            </div>
        </div>
    )
}

export default ListClinicsOnline
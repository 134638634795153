import { Col, Row } from 'antd';
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CheckCircleIcon } from '../../../../../../assets/image/check_circle.svg';
import { ReactComponent as InforOutlineIcon } from '../../../../../../assets/image/info_outline.svg';
import {
  FormData,
  MAX_CONSULT_TIME,
  MAX_RSV_FEE,
  MIN_CONSULT_TIME,
  MIN_RSV_FEE,
  rsvAcceptancePediodType,
  unapprovedDevicesDrugs
} from '../constants';
import InputNumberForm from './formItem/InputNumberForm';
import RadioGroupForm from './formItem/RadioGroupForm';
import SelectAcceptTimeForm from './formItem/SelectAcceptTimeForm';
import TextAreaForm from './formItem/TextAreaForm';

type Props = {
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
};

const ScheduleDirectForm: React.FC<Props> = ({ errors, watch, setValue }) => {
  const { t } = useTranslation();
  const exam_method = watch('exm_method');
  const f_rsv_acceptance_pediod_type = watch('f_rsv_acceptance_pediod_type');
  const reservation_frame_public = watch('reservation_frame_public');
  const f_unapproved_devices_drugs = watch('f_unapproved_devices_drugs');

  const consultTimeOption = [
    {
      name: 'f_consul_time_first_exm',
      titleBefore: t('reExamCode.firstExam'),
      unitName: t('reExamCode.minute'),
      placeholder: t('reExamCode.firstExam'),
      inputProps: {
        max: MAX_CONSULT_TIME,
        min: MIN_CONSULT_TIME,
        onKeyDown: (event: any) => {
          const regex = /^[0-9.]+$/;
          if (event.key === 'Backspace') return;
          if (event.key === 'Tab') return;
          if (event.key === 'Delete') return;
          if (!regex.test(event.key)) {
            return setValue('f_consul_time_first_exm', 1);
          }
        }
      }
    },
    {
      name: 'f_consul_time_re_exm',
      titleBefore: t('reExamCode.reExam'),
      unitName: t('reExamCode.minute'),
      placeholder: t('reExamCode.reExam'),
      inputProps: {
        max: MAX_CONSULT_TIME,
        min: MIN_CONSULT_TIME,
        onKeyDown: (event: any) => {
          const regex = /^[0-9.]+$/;
          if (event.key === 'Backspace') return;
          if (event.key === 'Tab') return;
          if (event.key === 'Delete') return;
          if (!regex.test(event.key)) {
            return setValue('f_consul_time_re_exm', 1);
          }
        }
      }
    }
  ];

  return (
    <div className="medical-menu-form_separate-container">
      <div className="medical-menu-form_separate-header">
        <p>{t('reExamCode.setupScheduleTreatmentInperson')}</p>
        <div className="medical-menu-form_separate_public-status_wrapper">
          <div className="medical-menu-form_separate_public-status-item mr-32">
            <CheckCircleIcon />
            <span>{t('reExamCode.hospistal')}</span>
          </div>
          {reservation_frame_public === 1 && (
            <div className="medical-menu-form_separate_public-status-item">
              <CheckCircleIcon />
              <span>{t('reExamCode.patient')}</span>
            </div>
          )}
        </div>
      </div>
      <div className="medical-menu-form_separate-content_wrapper">
        {[1, 2].includes(exam_method) ? (
          <>
            <Row>
              <Col xl={11} sm={15}>
                <InputNumberForm
                  options={consultTimeOption}
                  titleInput={t('reExamCode.rsvTime')}
                />
              </Col>
              <Col span={2} />
              <Col xl={11} sm={7}>
                <InputNumberForm
                  options={[
                    {
                      name: 'f_rsv_fee',
                      unitName: t('reExamCode.yenUnit'),
                      inputProps: {
                        max: MAX_RSV_FEE,
                        min: MIN_RSV_FEE,
                        onKeyDown: (event: any) => {
                          const regex = /^[0-9.]+$/;
                          if (event.key === 'Backspace') return;
                          if (event.key === 'Tab') return;
                          if (event.key === 'Delete') return;
                          if (!regex.test(event.key)) {
                            return setValue('f_rsv_fee', 1);
                          }
                        }
                      }
                    }
                  ]}
                  titleInput={t('reExamCode.rsvFee')}
                  inputTitleNote={t('reExamCode.includeTax')}
                />
              </Col>
            </Row>
            <TextAreaForm
              name="f_introduction"
              errors={errors}
              placeholder={t('reExamCode.introduction')}
              titleInput={t('reExamCode.introduction')}
              watch={watch}
            />
            <div className="divider" />
            <InputNumberForm
              titleInput={t('reExamCode.rsvTime')}
              options={[
                {
                  name: 'f_consul_hours_pt',
                  unitName: t('reExamCode.aboutMinute'),
                  placeholder: t('reExamCode.consultTimePlaceholder'),
                  inputProps: {
                    max: MAX_CONSULT_TIME,
                    min: MIN_CONSULT_TIME,
                    onKeyDown: (event: any) => {
                      const regex = /^[0-9.]+$/;
                      if (event.key === 'Backspace') return;
                      if (event.key === 'Tab') return;
                      if (event.key === 'Delete') return;
                      if (!regex.test(event.key)) {
                        return setValue('f_consul_hours_pt', 1);
                      }
                    }
                  }
                }
              ]}
              inputTitleNote={t('reExamCode.toDisplayPatient')}
            />
            <TextAreaForm
              name="f_risk_side_effects"
              errors={errors}
              placeholder={t('reExamCode.riskSideEffectsPlaceholder')}
              titleInput={t('reExamCode.riskSideEffects')}
              watch={watch}
            />
            <RadioGroupForm
              inputTitle={t('reExamCode.unapprovedDevicesDrugs')}
              name="f_unapproved_devices_drugs"
              options={unapprovedDevicesDrugs}
              inputNote={t('reExamCode.unapprovedDevicesDrugsNote')}
            />
            {f_unapproved_devices_drugs === 1 && (
              <p className="not-public-medical-menu_note">
                <Trans
                  i18nKey={'reExamCode.ableTouseNote'}
                  components={{
                    infoIcon: <InforOutlineIcon />,
                    div: <div className="div-in-able-touse-note" />,
                    br: <br />,
                    span: <span />
                  }}
                />
              </p>
            )}
            <TextAreaForm
              name="f_note"
              errors={errors}
              titleInput={t('reExamCode.noteTitle')}
              watch={watch}
            />
            <RadioGroupForm
              inputTitle={t('reExamCode.reservationAcceptTime')}
              name="f_rsv_acceptance_pediod_type"
              options={rsvAcceptancePediodType}
            />
            {f_rsv_acceptance_pediod_type === 0 && (
              <SelectAcceptTimeForm
                inputNames={{
                  firstFrom: 'f_rsv_acceptance_pediod_first_exm_from',
                  firstTo: 'f_rsv_acceptance_pediod_first_exm_to',
                  reFrom: 'f_rsv_acceptance_pediod_re_exm_from',
                  reTo: 'f_rsv_acceptance_pediod_re_exm_to'
                }}
              />
            )}
          </>
        ) : (
          <p>{t('reExamCode.hideMedicalMenuFormNote')}</p>
        )}
      </div>
    </div>
  );
};

export default ScheduleDirectForm;

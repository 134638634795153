import './styles.css';
import doctor from '../../assets/image/doctor.svg';
import warning from '../../assets/image/warning.svg';
import edit from '../../assets/image/edit.svg';
import { Col, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import FormSelect from '../common/form/FormSelect';
import FormInput from '../common/form/FormInput';
import { replaceWithBr } from 'src/Utils/textUtils';
import FormInputTextArea from '../common/form/FormInputTextArea';
import { ConsentFormNonInsuranceAmount } from 'src/Network/Model/ConsentFormNonInsuranceAmount';
import { currencyFormat } from 'src/constant';
import { useEffect, useState } from 'react';
import FormInputCheckbox from '../common/form/FormInputCheckbox';

const ReceptionInfo = (props: any) => {
  const { currentStep, consent_form_id, consentFormNonInsuranceAmounts, dataQuestions, setValue } = props;
  const { t } = useTranslation();
  const [tempInfo, setTempInfo] = useState('');
  const [tempInfo2, setTempInfo2] = useState('');
  const [tempInfo3, setTempInfo3] = useState('');
  const [tempInfo4, setTempInfo4] = useState('');
  const [tempInfo5, setTempInfo5] = useState('');
  const temperature = [];
  for (let i = 35; i < 41; i++) {
    temperature.push({ value: i, label: i });
  }
  const tempeDecimal = [];
  for (let i = 0; i < 10; i++) {
    tempeDecimal.push({ value: i, label: i });
  }
  useEffect(() => {
    if (dataQuestions) {
      setValue('symptoms', dataQuestions.symptoms);
      setValue('tempe', dataQuestions.tempe);
      setValue('tempe_decimal', dataQuestions.tempe_decimal);
      setValue('period', dataQuestions.period);
      setValue('family_clinic', dataQuestions.family_clinic);
      setValue('request', dataQuestions.request);
      if (dataQuestions.checkedAmount && props.onChangeConfirmAmount)
        props.onChangeConfirmAmount(dataQuestions.checkedAmount);
      setTempInfo(dataQuestions.tempe?.toString() + '.' +  dataQuestions.tempe_decimal?.toString() + t('receptioninfo.tempe-decimal'));
      setTempInfo2(dataQuestions.symptoms?.toString());
      setTempInfo3(dataQuestions.period?.toString());
      setTempInfo4(dataQuestions.family_clinic?.toString());
      setTempInfo5(dataQuestions.request?.toString());
    }
  }, [dataQuestions]);

  return (
    <div className="receptioninfo">
      <div className="warning">
        <img src={warning} />
        <span className="text-normal-styles">{t('receptioninfo.title')}</span>
      </div>
      <div className="infomation">
        <div className='questionRow'>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='img-icon'>
              <img src={doctor} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
              <div className='questionTemplate'>
                  {t('receptioninfo.temperature')}
              </div>
            </Col>
          </Row>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col className='img-icon' xs={3} sm={3} md={3} lg={3} xl={3}>
              <img src={edit} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21} className='degree-select-form'>
              <FormSelect
                name="tempe"
                // defaultValue="35"
                options={temperature}
                disabled={currentStep === 2 || currentStep === 3}
              />
              <span className="text-normal-styles temperature">.</span>
              <FormSelect
                name="tempe_decimal"
                // defaultValue="5"
                options={tempeDecimal}
                disabled={currentStep === 2 || currentStep === 3}
              />
              <span className="text-normal-styles temperature">
                {t('receptioninfo.tempe-decimal')}
              </span>
            </Col>
            {currentStep === 3 && 
              <Col xs={21} sm={21} md={21} lg={21} xl={21} className="temperature-print">
              {tempInfo}
              </Col>
            }
          </Row>
        </div>
        <div className='questionRow'>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='img-icon'>
              <img src={doctor} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
              <div className='questionTemplate'>
                  {t('receptioninfo.title-symptoms')}
                  <Tag className="text-bold-styles need">{t('register.tag')}</Tag>
              </div>
            </Col>
          </Row>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col className='img-icon' xs={3} sm={3} md={3} lg={3} xl={3}>
              <img className="styles-validator" src={edit} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21} className='degree-select-form'>
              <FormInputTextArea
                name="symptoms"
                disabled={currentStep === 2 || currentStep === 3}
                className="text-normal-styles"
                rows={4}
                placeholder={t('receptioninfo.place-symptoms')}
              />
            </Col>
            {currentStep === 3 && 
              <Col xs={21} sm={21} md={21} lg={21} xl={21} className="form-print">
                {tempInfo2}
              </Col>
            }
          </Row>
        </div>
        <div className='questionRow'>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='img-icon'>
              <img src={doctor} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
              <div className='questionTemplate'>
                  {t('receptioninfo.title-period')}
                  <Tag className="text-bold-styles need">{t('register.tag')}</Tag>
              </div>
            </Col>
          </Row>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col className='img-icon' xs={3} sm={3} md={3} lg={3} xl={3}>
              <img className="styles-validator" src={edit} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21} className='degree-select-form'>
              <FormInput
                name="period"
                className="text-normal-styles"
                placeholder={t('receptioninfo.place-period')}
                disabled={currentStep === 2 || currentStep === 3}
              />
            </Col>
            {currentStep === 3 && 
              <Col xs={21} sm={21} md={21} lg={21} xl={21} className="form-print">
                {tempInfo3}
              </Col>
              }
          </Row>
        </div>
        <div className='questionRow'>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='img-icon'>
              <img src={doctor} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
              <div className='questionTemplate'>
                  {t('receptioninfo.family-clinic')}
                  <Tag className="text-bold-styles need">{t('register.tag')}</Tag>
              </div>
            </Col>
          </Row>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col className='img-icon' xs={3} sm={3} md={3} lg={3} xl={3}>
              <img className="styles-validator" src={edit} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21} className='degree-select-form'>
              <FormInput
                name="family_clinic"
                className="text-normal-styles"
                placeholder={t('receptioninfo.place-family-clinic')}
                disabled={currentStep === 2 || currentStep === 3}
              />
            </Col>
            {currentStep === 3 && 
              <Col xs={21} sm={21} md={21} lg={21} xl={21} className="form-print">
                {tempInfo4}
              </Col>
              }
          </Row>
        </div>
        <div className='questionRow'>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='img-icon'>
              <img src={doctor} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
              <div className='questionTemplate'>
                  {t('receptioninfo.request')}
              </div>
            </Col>
          </Row>
          <Row gutter={[{ xs: 32, sm: 32, md: 32 }, { }]}>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='img-icon'>
              <img className="styles-validator" src={edit} />
            </Col>
            <Col xs={21} sm={21} md={21} lg={21} xl={21} className='degree-select-form'>
              <FormInputTextArea
                name="request"
                disabled={currentStep === 2 || currentStep === 3}
                className="text-normal-styles"
                rows={4}
                placeholder={t('receptioninfo.place-request')}
              />
            </Col>
            {currentStep === 3 && 
              <Col xs={21} sm={21} md={21} lg={21} xl={21} className="form-print">
                {tempInfo5}
              </Col>
            }
          </Row>
        </div>
        {consent_form_id && (
          <div className="insurance">
            <div>
              <span className="text-bold-styles">
                {t('receptioninfo.agreement')}
              </span>
            </div>
            <div>
              <span
                className="text-normal-styles"
                dangerouslySetInnerHTML={replaceWithBr(t('receptioninfo.info'))}
              ></span>
              {consentFormNonInsuranceAmounts?.map(
                (item: ConsentFormNonInsuranceAmount, index: number) => {
                  return (
                    <div key={index} className="non_insurance_amount">
                      <span className="text-normal-styles">
                        {item?.non_insurance_amount?.amount_description}
                      </span>
                      <span className="text-normal-styles">
                        {currencyFormat(
                          Number(item?.non_insurance_amount?.amount)
                        )}
                        {t(
                          'admin-schedule.reservation-frame-detail.consent-form-amount-suffix'
                        )}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
            <div className="checked_confirm">
              <Row gutter={[{ xs: 32, sm: 32, md: 32 }, {}]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormInputCheckbox
                    name='agreeAmount'
                    defaultChecked={dataQuestions.checkedAmount}
                  >
                    {t('receptioninfo.agree')}
                  </FormInputCheckbox>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceptionInfo;

import { useTranslation } from "react-i18next";
import "./styles.css";
import { Col, Row, Button } from 'antd';
import { useAppDispatch } from "src/Redux/hooks";
import { changeModalLogin, changeModalRegister, toggleHeaderDropdown } from "src/Component/Header/slice";
import { changeIsResetRegister } from "../ConfirmRegister/slice";

const LoggedOut = () => {
    const dispatch = useAppDispatch();
    const showFormRegister = () => {
        dispatch(toggleHeaderDropdown(false));
        dispatch(changeModalRegister(true));
        dispatch(changeIsResetRegister(true));
    };
    const showFormLogin = () => {
        dispatch(toggleHeaderDropdown(false));
        dispatch(changeModalLogin({
            show:true
        }));
    };
    const { t } = useTranslation();
    return (
        <div className="cardHeaderMenu">
            <Row gutter={[{ xs: 20, sm: 20 }, { xs: 16, sm: 16 }]}>
                <Col xs={24} sm={24} lg={8}>
                    <Button
                        shape="round"
                        className="login text-bold-styles"
                        onClick={showFormLogin}
                    >
                        {t("header.login")}
                    </Button>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Button
                        shape="round"
                        className="register text-bold-styles"
                        onClick={showFormRegister}
                    >
                        {t("header.regist")}
                    </Button>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Button 
                    shape="round" 
                    className="login-medical text-bold-styles"
                    onClick={()=>{
                        window.location.href = process.env.REACT_APP_WP_URL + "/c-login"
                    }}
                    >
                        {t("header.login-medical")}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default LoggedOut;

import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import i18n from 'src/i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

export const treatmentCategoryOptions = [
  { value: 1, label: t('reExamCode.examWithInsurance') },
  { value: 0, label: t('reExamCode.freeExam') }
];

export const publicStatus = [
  { value: 1, label: t('reExamCode.public') },
  { value: 0, label: t('reExamCode.private') }
];

export const examMethod = [
  { value: 1, label: t('reExamCode.onlyInPerson') },
  { value: 0, label: t('reExamCode.onlyTreatmentOnline') },
  { value: 2, label: t('reExamCode.onlineAndInPerson') }
];

export const rsvFrameStatus = [
  { value: 0, label: t('reExamCode.onlyHospital') },
  { value: 1, label: t('reExamCode.hospistalAndPatient') }
];

export const unapprovedDevicesDrugs = [
  { value: 1, label: t('reExamCode.ableTouse') },
  { value: 0, label: t('reExamCode.NotUse') }
];

export const rsvAcceptancePediodType = [
  { value: 1, label: t('reExamCode.useSettingRsvSetting') },
  { value: 0, label: t('reExamCode.bookingSeparately') }
];

export const enterReExamCode = [
  { value: 1, label: t('reExamCode.need') },
  { value: 0, label: t('reExamCode.notNeed') }
];

export const periodFromOption = [
  { value: 129600, label: '3ヶ月(90日)前' },
  { value: 86400, label: '2ヶ月(60日)前' },
  { value: 43200, label: '1ヶ月(30日)前' },
  { value: 20160, label: '2週間前' },
  { value: 10080, label: '1週間前' },
  { value: 4320, label: '3日前' },
  { value: 2880, label: '2日前' },
  { value: 1440, label: '前日' },
  { value: 360, label: '当日6時間前' },
  { value: 180, label: '当日3時間前' },
  { value: 60, label: '当日1時間前' }
];

export const DEFAULT_PERIOD_FROM_OPTION = 129600;
export const DEFAULT_PERIOD_TO_OPTION = 30;
export const DEFAULT_NOT_SETTING_FORM = '設定無し';

export const periodToOption = [
  { value: 10080, label: '1週間前' },
  { value: 4320, label: '3日前' },
  { value: 2880, label: '2日前' },
  { value: 1440, label: '前日' },
  { value: 360, label: '当日6時間前' },
  { value: 180, label: '当日3時間前' },
  { value: 60, label: '当日1時間前' },
  { value: 30, label: '当日30分前' },
  { value: 15, label: '当日15分前' }
];

export const requiredTextForm = (name: string) => {
  return t('input-required', { inputName: name });
};

export const inpersonKey = [
  'f_consul_time_first_exm',
  'f_consul_time_re_exm',
  'f_rsv_fee',
  'f_introduction',
  'f_consul_hours_pt',
  'f_risk_side_effects',
  'f_unapproved_devices_drugs',
  'f_note',
  'f_rsv_acceptance_pediod_type',
  'f_rsv_acceptance_pediod_first_exm_from',
  'f_rsv_acceptance_pediod_first_exm_to',
  'f_rsv_acceptance_pediod_re_exm_from',
  'f_rsv_acceptance_pediod_re_exm_to'
];

export const onlineKey = [
  'first_exm_flg',
  'consul_time_first_exm',
  'consul_time_re_exm',
  'rsv_fee',
  'introduction',
  'consul_hours_pt',
  'risk_side_effects',
  'unapproved_devices_drugs',
  'note',
  'rsv_acceptance_pediod_type',
  'rsv_acceptance_pediod_first_exm_from',
  'rsv_acceptance_pediod_first_exm_to',
  'rsv_acceptance_pediod_re_exm_from',
  'rsv_acceptance_pediod_re_exm_to',
  'consent_form_id'
];

export const commonKey = [
  'treatment_menu_name',
  'treatment_div',
  'public_status',
  'exm_method',
  'reservation_frame_public'
];

export const MedicalMenuFormSchema = yup.object().shape({
  treatment_menu_name: yup
    .string()
    .required(
      t('input-required', { inputName: t('reExamCode.medicalMenuName') })
    )
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 30) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.medicalMenuName'),
              value: 30
            })
          });
        }
        if (lengthFullAndHalfWidth(value) < 1) {
          return ctx.createError({
            message: t('inputErrorMinLength', {
              inputName: i18n.t('reExamCode.medicalMenuName'),
              value: 1
            })
          });
        }
        return true;
      }
    }),
  treatment_div: yup.number(),
  public_status: yup.number(),
  exm_method: yup.number().required(t('reExamCode.requiredExamMethod')),
  reservation_frame_public: yup.number(),
  f_consul_time_first_exm: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.firstExam') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 1,
      then: (schema) =>
        schema
          .required(
            t('input-required', { inputName: t('reExamCode.firstExam') })
          )
          .max(
            120,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.firstExam'),
              value: 120
            })
          )
          .min(
            1,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.firstExam'),
              value: 1
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),

  f_consul_time_re_exm: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.reExam') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 1,
      then: (schema) =>
        schema
          .required(t('input-required', { inputName: t('reExamCode.reExam') }))
          .max(
            120,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.reExam'),
              value: 120
            })
          )
          .min(
            1,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.reExam'),
              value: 1
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  f_rsv_fee: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.rsvFee') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 1,
      then: (schema) =>
        schema
          .required(t('input-required', { inputName: t('reExamCode.rsvFee') }))
          .max(
            500000,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.rsvFee'),
              value: 500000
            })
          )
          .min(
            50,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.rsvFee'),
              value: 50
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  f_introduction: yup
    .string()
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 1000) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.introduction'),
              value: 1000
            })
          });
        }
        return true;
      }
    })
    .nullable(),
  f_consul_hours_pt: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.rsvTime') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 1,
      then: (schema) =>
        schema
          .required(t('input-required', { inputName: t('reExamCode.rsvTime') }))
          .max(
            120,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.rsvTime'),
              value: 120
            })
          )
          .min(
            1,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.rsvTime'),
              value: 1
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  f_risk_side_effects: yup
    .string()
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 1000) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.riskSideEffects'),
              value: 1000
            })
          });
        }
        return true;
      }
    })
    .nullable(),
  f_unapproved_devices_drugs: yup.number(),
  f_note: yup
    .string()
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 1000) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.noteTitle'),
              value: 1000
            })
          });
        }
        return true;
      }
    })
    .nullable(),
  f_rsv_acceptance_pediod_type: yup.number().when('exm_method', {
    is: (val: number) => val === 2 || val === 1,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable()
  }),
  f_rsv_acceptance_pediod_first_exm_from: yup
    .number()
    .when('f_rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.firstExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  f_rsv_acceptance_pediod_first_exm_to: yup
    .number()
    .when('f_rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
    }),
  f_rsv_acceptance_pediod_re_exm_from: yup
    .number()
    .when('f_rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.firstExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  f_rsv_acceptance_pediod_re_exm_to: yup
    .number()
    .when('f_rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.reExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  first_exm_flg: yup.number(),
  consul_time_first_exm: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.firstExam') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 0,
      then: (schema) =>
        schema
          .required(
            t('input-required', { inputName: t('reExamCode.firstExam') })
          )
          .max(
            120,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.firstExam'),
              value: 120
            })
          )
          .min(
            1,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.firstExam'),
              value: 1
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  consul_time_re_exm: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.reExam') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 0,
      then: (schema) =>
        schema
          .required(t('input-required', { inputName: t('reExamCode.reExam') }))
          .max(
            120,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.reExam'),
              value: 120
            })
          )
          .min(
            1,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.reExam'),
              value: 1
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  rsv_fee: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.rsvFee') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 0,
      then: (schema) =>
        schema
          .required(t('input-required', { inputName: t('reExamCode.rsvFee') }))
          .max(
            500000,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.rsvFee'),
              value: 500000
            })
          )
          .min(
            50,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.rsvFee'),
              value: 50
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  introduction: yup
    .string()
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 1000) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.introduction'),
              value: 1000
            })
          });
        }
        return true;
      }
    })
    .nullable(),
  consul_hours_pt: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return null;
      } else return value;
    })
    .typeError(t('input-required', { inputName: t('reExamCode.rsvTime') }))
    .when('exm_method', {
      is: (val: number) => val === 2 || val === 0,
      then: (schema) =>
        schema
          .required(t('input-required', { inputName: t('reExamCode.rsvTime') }))
          .max(
            120,
            t('inputErrorMaxValue', {
              inputName: i18n.t('reExamCode.rsvTime'),
              value: 120
            })
          )
          .min(
            1,
            t('inputErrorMinValue', {
              inputName: i18n.t('reExamCode.rsvTime'),
              value: 1
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  risk_side_effects: yup
    .string()
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 1000) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.riskSideEffects'),
              value: 1000
            })
          });
        }
        return true;
      }
    })
    .nullable(),
  unapproved_devices_drugs: yup.number(),
  note: yup
    .string()
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 1000) {
          return ctx.createError({
            message: t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.noteTitle'),
              value: 1000
            })
          });
        }
        return true;
      }
    })
    .nullable(),
  rsv_acceptance_pediod_type: yup.number().when('exm_method', {
    is: (val: number) => val === 2 || val === 0,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable()
  }),
  rsv_acceptance_pediod_first_exm_from: yup
    .number()
    .when('rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.firstExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  rsv_acceptance_pediod_first_exm_to: yup
    .number()
    .when('rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.firstExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  rsv_acceptance_pediod_re_exm_from: yup
    .number()
    .when('rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.firstExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  rsv_acceptance_pediod_re_exm_to: yup
    .number()
    .when('rsv_acceptance_pediod_type', {
      is: 0,
      then: (schema) =>
        schema.required(
          t('input-required', { inputName: t('reExamCode.firstExam') })
        ),
      otherwise: (schema) => schema.nullable()
    }),
  consent_form_id: yup.mixed().nullable()
});
export type FormData = yup.InferType<typeof MedicalMenuFormSchema>;

export const MAX_RSV_FEE = 500000;
export const MIN_RSV_FEE = 50;
export const MAX_CONSULT_TIME = 120;
export const MIN_CONSULT_TIME = 1;

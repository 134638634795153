import { createSlice } from '@reduxjs/toolkit';
import { User } from 'src/Network/Model/User';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
    showUserModal: false,
    userList: [] as User[],
    userIndex: -1,
    radioIndex: -1,
    titleTimeLabel: '',
    doctorSelections: [] as any[],
    filteredData: [] as any[],
 }

const slice = createSlice({
  name: "timeBookingSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeShowUserModal : (state, action) => {
        state.showUserModal = action.payload
    },
    changeUserList : (state, action) => {
        state.userList = action.payload
    },
    changeUserIndex : (state, action) => {
        state.userIndex = action.payload
    },
    changeRadioIndex : (state, action) => {
        state.radioIndex = action.payload
    },
    changeTitleTimeLabel : (state, action) => {
        state.titleTimeLabel = action.payload
    },
    changeDoctorSelectionList : (state, action) => {
        state.doctorSelections = action.payload
    },
    changeFilteredData : (state, action) => {
        state.filteredData = action.payload
    }
  },
});

export const selectShowUserModal = (state:RootState) => state.common[slice.name].showUserModal
export const selectUserList = (state:RootState) => state.common[slice.name].userList
export const selectUserIndex = (state:RootState) => state.common[slice.name].userIndex
export const selectRadioIndex = (state:RootState) => state.common[slice.name].radioIndex
export const selectTitleTimeLabel = (state:RootState) => state.common[slice.name].titleTimeLabel
export const selectDoctorSelectionList = (state:RootState) => state.common[slice.name].doctorSelections
export const selectFilteredData = (state:RootState) => state.common[slice.name].filteredData

export const {
    changeShowUserModal,
    changeUserList,
    changeUserIndex,
    changeRadioIndex,
    changeTitleTimeLabel,
    changeDoctorSelectionList,
    changeFilteredData,
} = slice.actions;

export const TimeBookingReducer = slice.reducer;
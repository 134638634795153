import { createApi } from '@reduxjs/toolkit/query/react'
import { Clinic as api, BASE_URL } from "../Api";
import axiosVitalSignalBaseQuery from '../axiosVitalSignalBaseQuery';
import { cipher } from 'src/Utils/GenerateEncrypt';
import { ApiResponse } from '../Model/ApiResponse';

export const vitalSignalApi = createApi({
    reducerPath: "vitalSignalApi",
    baseQuery: axiosVitalSignalBaseQuery({
        baseUrl: BASE_URL,
    }),
    endpoints: (builder) => ({
        vitalSignal: builder.mutation({
            query: (formData) => {
                const headers = {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': cipher.toString()
                };

                return {
                  url: api.getVitalSignal,
                  method: 'POST',
                  data: formData,
                  params: null,
                  headers,
                };
            },
            transformResponse: (response: ApiResponse<any>) => response?.data,
        }),
    }),
});

export const {
    useVitalSignalMutation,
} = vitalSignalApi;

export default vitalSignalApi;
import { useEffect, useState } from 'react';
import backtotop from 'src/assets/image/backtotop.svg';
import './styles.css';

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", () => setIsVisible(window.scrollY > 200));
    }, []);

    return (
        <div className="scroll-to-top">
            {isVisible && (
                <div onClick={scrollToTop}>
                    <img src={backtotop} alt=''/>
                </div>
            )}
        </div>
    )
}
  
export default BackToTop;
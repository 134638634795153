import { Col, Row, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import BookingOnline from '../BookingOnline';
import BookingOffice from '../BookingOffice';
import "./styles.css";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../Redux/hooks";
import { changeTabs } from "./slice";
import { Clinic } from "../../Network/Model/Clinic";
import { useTranslation } from 'react-i18next';

type Props = {
  data: Clinic | undefined;
};

const BookingContent: React.FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const treatment_menus = data?.treatment_menus;
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("0");
  const checkKey0 = treatment_menus?.every(
    (item: any) => item.exm_method === 1
  );
  const checkKey1 = treatment_menus?.every(
    (item: any) => item.exm_method === 0
  );

  const items: TabsProps["items"] = [
    {
      key: '0',
      label: <span>{t('booking-tab.booking-online')}</span>,
      children: <BookingOnline data={data} />,
      disabled: checkKey0,
    },
    {
      key: '1',
      label: <span>{t('booking-tab.booking-office')}</span>,
      children: <BookingOffice />,
      disabled: checkKey1,
    },
  ];
  const onChangeTab = (key: string) => {
    setActiveKey(key);
    dispatch(changeTabs(key));
  };

  useEffect(() => {
    if (checkKey0) {
      setActiveKey("1");
    }
    if (checkKey1) {
      setActiveKey("0");
    }

    dispatch(changeTabs(activeKey));
  }, [checkKey0, checkKey1]);

  return (
    <div className="content">
      <Row>
        <Col xs={24} sm={24} md={24} className='bookingTab text-bold-styles'>
          {treatment_menus && (
            <Tabs type="card" activeKey={activeKey} items={items} onChange={onChangeTab} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BookingContent;
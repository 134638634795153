import { createApi } from '@reduxjs/toolkit/query/react';
import { ClinicRequest } from '../Model/ClinicRequest';
import { ApiResponse } from '../Model/ApiResponse';
import { Clinic } from '../Model/Clinic';
import { Clinic as api, BASE_URL } from '../Api';
import axiosBaseQuery from '../axiosBaseQuery';
import { ReservationFrame } from '../Model/ReservationFrame';
import { ReservationFrameRequest } from '../Model/ReservationFrameRequest';
import { TreatmentMenuRequest } from '../Model/TreatmentMenuRequest';
import { TreatmentMenu } from '../Model/TreatmentMenu';
import { MPrefs } from '../Model/MPrefs';
import { MCitys } from '../Model/MCitys';
import { MCitysRequest } from '../Model/MCitysRequest';
import { User } from '../Model/User';
import { UserLogin } from '../Model/UserLogin';
import { ValidateTokenRequest } from '../Model/ValidateTokenRequest';
import { PrescriptionAddress } from 'src/Network/Model/PrescriptionAddress';
import { PrescriptionPharmacy } from '../Model/PrescriptionPharmacy';
import { PrescriptionPharmacyNew } from '../Model/PrescriptionPharmacyNew';
import { PrescriptionPharmacyRequest } from '../Model/PrescriptionPharmacyRequest';
import { PharmacyInfo } from '../Model/PharmacyInfo';
import {ReservationInfoDetailResponse, ReservationInfoRequest} from "../Model/ReservationInfo";

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Patient', 'Pharmacy'],
  endpoints: (builder) => ({
    // getClinic: builder.mutation<ApiResponse<Clinic[]>, ClinicRequest>({
    //     query: (request?: ClinicRequest) => ({
    //         url: api.getHomeClinic(request?.id),
    //         method: "GET",
    //     }),
    // }),
    clinics: builder.query({
      query: (data: ClinicRequest) => ({
        url: api.getHomeClinic(data?.id),
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response: ApiResponse<Clinic>) => response?.data,
    }),
    clinicReviews: builder.query({
      query: (data: ClinicRequest) => ({
        url: api.getClinicReview,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response: ApiResponse<Clinic>) =>
        response?.data || response,
    }),
    ReservationFrame: builder.query({
      query: (data: ReservationFrameRequest) => ({
        url: api.getReservationFrame(data?.clinic_id),
        method: 'GET',
        data: null,
        params: {
          treatment_menu_id: data.treatment_menu_id,
          rsv_ymd: data.rsv_ymd,
        },
      }),
      transformResponse: (response: ApiResponse<ReservationFrame>) =>
        response?.data,
    }),
    TreatmentMenuDetail: builder.query({
      query: (data: TreatmentMenuRequest) => ({
        url: api.getTreatmentMenuDetail(data?.clinic_id),
        method: 'GET',
        data: null,
        params: { treatment_menu_id: data.treatment_menu_id },
      }),
      transformResponse: (response: ApiResponse<TreatmentMenu>) =>
        response?.data || response,
    }),
    mPrefs: builder.query({
      query: () => ({
        url: api.getAllMPrefs(),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[MPrefs]>) => response?.data,
    }),
    mCitys: builder.query({
      query: () => ({
        url: api.getAllMCitys(),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[MCitys]>) => response?.data,
    }),
    mCitysById: builder.mutation({
      query: (param: MCitysRequest) => ({
        url: api.getListMCitysById(param.id),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[MCitys]>) => response?.data,
    }),
    mCitysByIdPref: builder.query({
      query: (param: MCitysRequest) => ({
        url: api.getListMCitysById(param.id),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[MCitys]>) => response?.data,
    }),
    registerUser: builder.mutation({
      query: (param: User) => ({
        url: api.userRegister(),
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[User]>) =>
        response?.data || response,
    }),
    resetPassword: builder.mutation({
      query: (param: {
        clinic_id?: string | null | undefined;
        mailaddress: string | null | undefined;
        redirect_url: string | null | undefined;
      }) => ({
        url: api.resetPassword,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) =>
        response?.data || response,
    }),
    loginUser: builder.mutation({
      query: (param: UserLogin) => ({
        url: api.userLogin(),
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[User]>) =>
        response?.data || response,
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: api.userLogout(),
        method: 'POST',
        data: null,
        params: null,
      }),
    }),
    scanCode: builder.query({
      query: (re_exm_code?: string) => ({
        url: api.scanCode,
        method: 'GET',
        data: null,
        params: { re_exm_code },
      }),
      transformResponse: (response: ApiResponse<Clinic>) =>
        response?.data || response,
    }),
    validateToken: builder.mutation({
      query: (params: ValidateTokenRequest) => ({
        url: api.validate,
        method: 'POST',
        data: params,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
    prescriptionPharmacyById: builder.query({
      query: (param: PrescriptionPharmacyRequest) => ({
        url: api.getListPrescriptionPharmacyByIdUser(),
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<[PrescriptionPharmacy]>) =>
        response?.data,
    }),
    listPharmacyInfoWithPrescriptionsByUserId: builder.query({
      query: (param: PrescriptionPharmacyRequest) => ({
        url: api.getPharmacyInfoWithPrescriptionsByUserId(),
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<[PharmacyInfo]>) =>
        response?.data,
    }),
    createPrescriptionPharmacy: builder.mutation({
      query: (param: PrescriptionPharmacy) => ({
        url: api.prescriptionPharmacy,
        method: 'POST',
        data: param,
        params: null,
      }),
      invalidatesTags: ['Pharmacy'],
      transformResponse: (response: ApiResponse<[PrescriptionPharmacy]>) =>
        response?.data || response,
    }),
    listReservationInfo: builder.query({
      query: (params) => ({
        url: api.getReservationInfo(),
        method: 'GET',
        data: null,
        params: params
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data
    }),
    searchMCityName: builder.mutation({
      query: (params) => ({
        url: api.searchMCity,
        method: 'GET',
        data: null,
        params: params
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data
    }),
    searchDepartment: builder.mutation({
      query: (params) => ({
        url: api.searchDepartment,
        method: 'GET',
        data: null,
        params: params
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data
    }),
    searchClinic: builder.mutation({
      query: (params) => ({
        url: api.searchClinic,
        method: 'GET',
        data: null,
        params: params
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data
    }),
    validateTokenSession: builder.query({
      query: (params) => ({
        url: api.validateTokenSession,
        method: 'GET',
        data: null,
        params: params
      })
    }),
    userProfile: builder.query({
      query: () => ({
        url: api.user,
        method: 'GET',
        data: null,
        params: null
      })
    }),
    prescriptionAddressByUserId: builder.query({
      query: (param) => ({
        url: api.getListPrescriptionAddressByUserId,
        method: 'GET',
        data: null,
        params: param,
      }),
      providesTags: ['Patient'],
      transformResponse: (response: ApiResponse<[PrescriptionAddress]>) => response?.data
    }),
    createPrescriptionAddress: builder.mutation({
      query: (param: PrescriptionAddress) => ({
        url: api.prescriptionAddress,
        method: 'POST',
        data: {params: param},
        params: null,
      }),
      invalidatesTags: ['Patient'],
      transformResponse: (response: ApiResponse<[PrescriptionAddress]>) => response?.data || response,
    }),
    updatePrescriptionAddress: builder.mutation({
      query: (param: PrescriptionAddress) => ({
        url: api.prescriptionAddress,
        method: 'PUT',
        data: {params: param},
        params: null,
      }),
      invalidatesTags: ['Patient'],
      transformResponse: (response: ApiResponse<[PrescriptionAddress]>) => response?.data || response,
    }),
    deletePrescriptionAddress: builder.mutation({
      query: (param: any) => ({
        url: api.prescriptionAddress,
        method: 'DELETE',
        data: null,
        params: param,
      }),
      invalidatesTags: ['Patient'],
      transformResponse: (response: ApiResponse<[String]>) => response,
    }),
    deletePrescriptionPharmacy: builder.mutation({
      query: (param: any) => ({
        url: api.prescriptionPharmacy,
        method: 'DELETE',
        data: null,
        params: param,
      }),
      invalidatesTags: ['Pharmacy'],
      transformResponse: (response: ApiResponse<[String]>) => response,
    }),
    prescriptionPharmacyByUserId: builder.query({
      query: (param) => ({
        url: api.getPrescriptionPharmacyByUserId,
        method: 'GET',
        data: null,
        params: param,
      }),
      providesTags: ['Pharmacy'],
      transformResponse: (response: ApiResponse<[PrescriptionPharmacyNew]>) => response?.data
    }),
    getListPrefecture: builder.mutation({
      query: () => ({
        url: api.getListPrefecture,
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<[PharmacyInfo]>) => response?.data,
    }),
    getListCityByPrefecture: builder.mutation({
      query: (param) => ({
        url: api.getListCityByPrefecture,
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<[PharmacyInfo]>) => response,
    }),
    listPharmacyInfo: builder.mutation({
      query: (param) => ({
        url: api.pharmacyInfo,
        method: 'GET',
        data: null,
        params: param,
      }),
      transformResponse: (response: ApiResponse<[PharmacyInfo]>) => response,
    }),
    updatePrescriptionPharmacy: builder.mutation({
      query: (param: PrescriptionPharmacy) => ({
        url: api.prescriptionPharmacy,
        method: 'PUT',
        data: param,
        params: null,
      }),
      invalidatesTags: ['Pharmacy'],
      transformResponse: (response: ApiResponse<[PrescriptionPharmacy]>) =>
        response?.data || response,
    }),
    getFamilyMemberInfomation: builder.query({
      query: (param) => ({
        url: api.getFamilyMemberInfomation(param.user_id),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<User>) => response?.data
    }),
    checkCreditCard: builder.query({
      query: (param) => ({
        url: api.checkCreditCard(param.user_id),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data
    }),
    validateInnsurance: builder.query({
      query: (param) => ({
        url: api.validateInnsurance,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response?.data
    }),
    rsvInfoDetailByIdPatient: builder.query({
      query: (data: ReservationInfoRequest) => ({
        url: api.getReservationInfoDetailPatient(data.id),
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (
          response: ApiResponse<ReservationInfoDetailResponse>,
      ) => response,
    }),
    createQuestions: builder.mutation({
      query: (param) => ({
        url: api.createQuestions,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response
    }),
    registerCard: builder.mutation({
      query: (param: any) => ({
        url: api.registerCard,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response
    }),
    getCardInfo: builder.query({
      query: () => ({
        url: api.getCardInfo,
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response
    }),
    sendOTP: builder.mutation({
      query: (param: any) => ({
        url: api.sendOTP,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response
    }),
    verifyOTPEditCard: builder.mutation({
      query: (param: any) => ({
        url: api.verifyOTPEditCard,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response
    }),
    editCard: builder.mutation({
      query: (param: any) => ({
        url: api.editCardInfo,
        method: 'PUT',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response
    }),
    createKaruteInsurance: builder.mutation({
      query: (param) => ({
        url: api.createKaruteInsurance,
        method: 'POST',
        data: param,
        params: null,
      }),
      transformResponse: (response: ApiResponse<any>) => response,
    }),
  }),
});

export const {
  useClinicsQuery,
  useClinicReviewsQuery,
  useReservationFrameQuery,
  useTreatmentMenuDetailQuery,
  useMPrefsQuery,
  useMCitysQuery,
  useMCitysByIdMutation,
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useResetPasswordMutation,
  useScanCodeQuery,
  useLazyScanCodeQuery,
  useValidateTokenMutation,
  usePrescriptionPharmacyByIdQuery,
  useListPharmacyInfoWithPrescriptionsByUserIdQuery,
  useCreatePrescriptionPharmacyMutation,
  useListReservationInfoQuery,
  useSearchMCityNameMutation,
  useSearchDepartmentMutation,
  useSearchClinicMutation,
  useLazyValidateTokenSessionQuery,
  useLazyUserProfileQuery,
  usePrescriptionAddressByUserIdQuery,
  useCreatePrescriptionAddressMutation,
  useUpdatePrescriptionAddressMutation,
  useDeletePrescriptionAddressMutation,
  usePrescriptionPharmacyByUserIdQuery,
  useDeletePrescriptionPharmacyMutation,
  useGetListPrefectureMutation,
  useGetListCityByPrefectureMutation,
  useListPharmacyInfoMutation,
  useUpdatePrescriptionPharmacyMutation,
  useGetFamilyMemberInfomationQuery,
  useCheckCreditCardQuery,
  useValidateInnsuranceQuery,
  useLazyRsvInfoDetailByIdPatientQuery,
  useRsvInfoDetailByIdPatientQuery,
  useCreateQuestionsMutation,
  useRegisterCardMutation,
  useLazyGetCardInfoQuery,
  useSendOTPMutation,
  useEditCardMutation,
  useVerifyOTPEditCardMutation,
  useMCitysByIdPrefQuery,
  useCreateKaruteInsuranceMutation
} = homeApi;

export default homeApi;

import './styles.css';
import { Button, Col, Row, Tag } from "antd";
import FormInput from "../common/form/FormInput";
import FormInputSelect from "../common/form/FormInputSelect";
import { useTranslation } from "react-i18next";
import search_address from "../../assets/image/search_address.svg";
import { useEffect, useState } from "react";
import { useMCitysByIdMutation } from "src/Network/Home/HomeClinicApi";
import { getCityList } from "src/constant";
import { useAppDispatch } from "src/Redux/hooks";
import { changeCityName, changePrefName } from "../Register/slice";
import Loading from "../Modal/Loading";
import { usePostCodeMutation } from 'src/Network/Home/ZipcodeApi';

const PostalCode = (props:any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [code, setCode] = useState("");
    const [postCode] = usePostCodeMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [mCitysById] = useMCitysByIdMutation();
    useEffect(() => {
        if (props.cityList) {
            props.setCityList(props.cityList);
        }
    }, [props.cityList]);
    const onGetPostCode = () => {
        if (code.length <= 8) {
          setIsLoading(true);
          postCode({ zipcode: code }).then((res: any) => {
            const dataCode = res.data;
            if (dataCode && dataCode.results) {
              const dataPostCode = dataCode.results?.[0];
              const prefcode = dataPostCode.prefcode;
              const address2 = dataPostCode.address2;
              if (prefcode) {
                const prefItem: any = props.prefList.find(
                  (item:any) => item.value === Number(prefcode)
                );
                props.setValue("pref_id", prefItem?.value, { shouldValidate: true }); 
                mCitysById({ id: prefcode }).then((res: any) => {
                  const dataCityById = res.data;
                  if (dataCityById) {
                    const newCityList = getCityList(dataCityById, t("register.text-default-city"), 1);
                    props.setCityList(newCityList);
                    const cityItem:any = newCityList.find(
                      (item) => item.label === address2
                    )
                    props.setValue("city_id", cityItem?.value);
                    props.clearErrors('city_id');
                    dispatch(changeCityName(cityItem?.label));
                  }
                });
                dispatch(changePrefName(prefItem?.label));
              }
              const address3 = dataPostCode.address3;
              if (address3 !== "") {
                props.setValue("address", address3);
                props.clearErrors('address');
              }
            } else {
              props.setErrCode(t("register.poscode-invalid"));
            }
            setIsLoading(false);
          });
        }
      };
      const handleChangePrefId = (newPrefId:number) => {
        if (newPrefId !== 0) {
            const prefItem: any = props.prefList.find((item:any) => item.value === newPrefId);
            dispatch(changePrefName(prefItem.label));
            mCitysById({ id: newPrefId }).then((res: any) => {
                const dataCityById = res.data;
                if (dataCityById) {
                    const newCityList = getCityList(dataCityById, t("register.text-default-city"), 1);
                    props.setCityList(newCityList);
                    if (newCityList) {
                        props.setValue("city_id", newCityList?.[0]?.value);
                        props.clearErrors('city_id');
                        dispatch(changeCityName(newCityList?.[0]?.label));
                    }
                }
            })
        } else {
            props.setCityList([{ value: 0, label: t('register.text-default-city') }]);
            props.setValue("city_id", 0);
        }
    }
    const handleChangeCityId = (e:number) => {
        const cityItem: any = props.cityList.find((item:any) => item.value === e);
        dispatch(changeCityName(cityItem?.label));
    }
    const postal_code = props.watch("postal_code");
    useEffect(() => {
        if (postal_code) {
            setCode(postal_code);
            props.setValue("postal_code", postal_code);
            const regex = /^\d{3}-\d{4}$/;
            const regexNumber = /^\d{7}/;
            const checkPostCode =  regex.test(postal_code) || (regexNumber.test(postal_code) && postal_code.length === 7);
            if (!checkPostCode) {
                props.setErrCode(t("register.poscode-invalid"));
            } else {
                props.setErrCode("");
            }
        } else {
            props.setErrCode("");
        }
    }, [postal_code]);

    return (
        <div className='search-postcode'>
            <div>
                <div className="info-register">
                    <span className="text-normal-styles">
                        {t("register.postal_code")}
                    </span>
                    <Tag className="text-bold-styles need">
                        {t("register.tag")}
                    </Tag>
                </div>
                <Row className="postal-code-row" gutter={[{ xs: 20, sm: 20, md: 20 }, {}]}>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7} className="postal-code">
                        <FormInput
                        className={props.errCode ? "err-color-code" : ""}
                        name="postal_code"
                        placeholder={t("register.postal_code")}
                        onKeyDown={(event: any) => {
                            const regex = /^[0-9-]+$/;
                            if (event.key === "Backspace") return;
                            if (event.key === "Delete") return;
                            if (!regex.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                        />
                        {props.errCode !== "" && (
                        <span className="errcode">{props.errCode}</span>
                        )}
                    </Col>
                    <Col className="col-search" xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Button className="btn-search" onClick={onGetPostCode}>
                        <img src={search_address} />
                        <span className="text-bold-styles">
                            {t("register.text-search")}
                        </span>
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="select-address">
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="col-pref">
                        <div className="info-register">
                            <span className="text-normal-styles">
                                {t("ModalNewAddress.label3")}
                            </span>
                            <Tag className="text-bold-styles need">
                                {t("register.tag")}
                            </Tag>
                        </div>
                        <FormInputSelect
                            name="pref_id"
                            options={props.prefList}
                            inputProps = {{
                                onChange: (e:number) => handleChangePrefId(e)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="col-city" >
                        <div className="info-register">
                            <span className="text-normal-styles">
                                {t("ModalNewAddress.label4")}
                            </span>
                            <Tag className="text-bold-styles need">
                                {t("register.tag")}
                            </Tag>
                        </div>
                        <FormInputSelect
                            name="city_id"
                            options={props.cityList}
                            inputProps = {{
                                onChange: (e:number) => handleChangeCityId(e)
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <div className="info-register">
                        <span className="text-normal-styles">
                            {t("ModalNewAddress.label5")}
                        </span>
                        <Tag className="text-bold-styles need">
                            {t("register.tag")}
                        </Tag>
                    </div>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FormInput
                        name="address"
                        placeholder={t("ModalNewAddress.placeholder5")}
                        />
                    </Col>
                </Row>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}

export default PostalCode;
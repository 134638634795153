import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import i18n from 'src/i18n/i18n';
import * as yup from 'yup';

export const startOption = [
  { value: 129600, label: '3ヶ月(90日)前' },
  { value: 86400, label: '2ヶ月(60日)前' },
  { value: 43200, label: '1ヶ月(30日)前' },
  { value: 20160, label: '2週間前' },
  { value: 10080, label: '1週間前' },
  { value: 4320, label: '3日前' },
  { value: 2880, label: '2日前' },
  { value: 1440, label: '前日' },
  { value: 360, label: '当日6時間前' },
  { value: 180, label: '当日3時間前' },
  { value: 60, label: '当日1時間前' }
];

export const DEFAULT_START_OPTION = 129600;
export const DEFAULT_END_OPTION = 30;

export const endOption = [
  { value: 10080, label: '1週間前' },
  { value: 4320, label: '3日前' },
  { value: 2880, label: '2日前' },
  { value: 1440, label: '前日' },
  { value: 360, label: '当日6時間前' },
  { value: 180, label: '当日3時間前' },
  { value: 60, label: '当日1時間前' },
  { value: 30, label: '当日30分前' },
  { value: 15, label: '当日15分前' }
];

export const CreateReExamCodeSchema = yup.object().shape({
  re_exm_code: yup
    .string()
    .required(
      i18n.t('input-required', {
        inputName: i18n.t('reExamCode.reExaminationCode')
      })
    )
    .matches(
      /^[!-~]*$/,
      i18n.t('inputHalfwidthInvalid', {
        inputName: i18n.t('reExamCode.reExaminationCode')
      })
    )
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 8) {
          return ctx.createError({
            message: i18n.t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.reExaminationCode'),
              value: 8
            })
          });
        }
        if (lengthFullAndHalfWidth(value) < 8) {
          return ctx.createError({
            message: i18n.t('inputErrorMinLength', {
              inputName: i18n.t('reExamCode.reExaminationCode'),
              value: 8
            })
          });
        }
        return true;
      }
    })
});

export type FormData = yup.InferType<typeof CreateReExamCodeSchema>;

export const HomePageUrlSchema = yup.object().shape({
  home_page_url: yup
    .string()
    .matches(
      /^[!-~]*$/,
      i18n.t('inputHalfwidthInvalid', {
        inputName: i18n.t('reExamCode.homepageUrl')
      })
    )
    .test({
      name: 'check-length',
      skipAbsent: true,
      test(value, ctx) {
        if (lengthFullAndHalfWidth(value) > 255) {
          return ctx.createError({
            message: i18n.t('inputErrorMaxLength', {
              inputName: i18n.t('reExamCode.homepageUrl'),
              value: 255
            })
          });
        }
        return true;
      }
    })
});

export type HomePageFormData = yup.InferType<typeof HomePageUrlSchema>;

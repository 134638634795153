import { Select, Table } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { KEY_USER } from 'src/common/Constant';
import { useGetFamilyMemberInfomationQuery } from 'src/Network/Home/HomeClinicApi';
import magnifying_glass from 'src/assets/image/magnifying_glass.svg';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  currentStep?: number,
  setFamilyUserId?: Function,
  setFamilyName?: Function,
  karuteId?: string,
  setKaruteId?: Function,
  checkKaruteFlg?: boolean,
  setCheckKaruteFlg?: Function
}

const BookingInfoPatient:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {currentStep, setFamilyUserId, setFamilyName, karuteId, setKaruteId, checkKaruteFlg, setCheckKaruteFlg} = props;
    const json = localStorage.getItem(KEY_USER)??"{}";
    const user_id = JSON.parse(json).user_id;
    const user_name = JSON.parse(json).user_name;
    const tel = JSON.parse(json).tel;
    const mailaddress = JSON.parse(json).mailaddress;
    const dataFamilyMember = useGetFamilyMemberInfomationQuery({user_id: user_id})?.data;
    const userName = user_name? user_name  + t("bookinginfopatient.person_himself") : "";
    const [value, setValue] = useState<string>();
    const [searchParams] = useSearchParams();
    const reception_id = searchParams.get('reception_id');
    const [checkHandleChange, setCheckHandleChange] = useState(false);
    useEffect(() => {
      if (dataFamilyMember && !reception_id) {
        const userMember = dataFamilyMember?.karute?.find((item:any) => item.family_user_id === 0);
        if (setKaruteId) {
          if (userMember?.karute_id) {
            setKaruteId(userMember?.karute_id);
          } else {
            setKaruteId(null);
          }
        }
      }
    }, [dataFamilyMember])
    const handleChange = (value: string)=> {
        const selectedOption = karute?.find(item => item?.value === Number(value));
        setValue(selectedOption?.label);
        if (setFamilyUserId) {
          setFamilyUserId(Number(value));
        }
        if (setFamilyName) {
          setFamilyName(selectedOption?.label?.replace(t("bookinginfopatient.person_himself"), ''));
        }
        const familyMember = dataFamilyMember?.karute?.find((item:any) => item.family_user_id === Number(value));
        if (setKaruteId) {
          if (familyMember?.karute_id) {
            setKaruteId(familyMember?.karute_id);
          } else {
            setKaruteId(null);
          }
        }
        setCheckHandleChange(true);
    };
    const karute = [] as Array<{ value: number; label: string }>;
    dataFamilyMember?.karute?.forEach((item) => {
      karute.push({
        value: Number(item?.family_user_id),
        label: item?.family_user_id === 0 ? userName : (item?.user_name ? item?.user_name : '')
      });
    })
    const checkUserLogin = karute.some(item => item.value === 0);
    if (!checkUserLogin) {
      karute.unshift({value: 0, label: userName});
    }
    useEffect(() => {
      if (dataFamilyMember && reception_id && checkKaruteFlg && !checkHandleChange) {
        const member = dataFamilyMember?.karute?.find((item:any) => item.karute_id === karuteId);
        if (!member?.family_user_id) {
          setValue(userName);
        } else {
          setValue(member?.user_name??'');
        }
        if (setKaruteId) {
          if (member?.karute_id) {
            setKaruteId(member?.karute_id);
          } else {
            setKaruteId(null);
          }
        }
        if (setCheckKaruteFlg) {
          setCheckKaruteFlg(false);
        }
      }
    }, [dataFamilyMember, checkKaruteFlg])
    useEffect(() => {
      if (!reception_id && !value) {
        setValue(userName);
      }
    }, [])
    const columns = [
      {
        title: '',
        dataIndex: 'name'
      },
      {
        title: '',
        dataIndex: 'value'
      }
    ];
    const data = [
      {
        'name': <span className="text-normal-styles">{t("bookinginfopatient.fullname")}</span>,
        'value': (
          currentStep === 1 ?
            <div className='search-box'>
              <Select
                showSearch
                style={{ width: 250 }}
                options={karute}
                suffixIcon={<img src={magnifying_glass} alt='magnifying glass' />}
                value={value}
                onSelect={handleChange}
                optionFilterProp='label'
                defaultValue={userName}
              />
            </div>
          : <span className="text-normal-styles">{value}</span>
          )
      },
      {
        'name': <span className="text-normal-styles">{t("bookinginfopatient.telephone")}</span>,
        'value': <span className="text-normal-styles">{tel}</span>
      },
      {
        'name': <span className="text-normal-styles">{t("bookinginfopatient.emailaddress")}</span>,
        'value': <span className="text-normal-styles">{mailaddress}</span>
      }
    ];

    return (
      <div className='infoHos infoHosCard'>
        <Table title={() => t("bookinginfopatient.patient_infomation")} columns={columns} dataSource={data} pagination={false} showHeader={false} />
      </div>
    )
}

export default BookingInfoPatient;

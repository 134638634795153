import warning from '../../assets/image/warning.svg';
import "./styles.css";
import { useEffect, useState } from "react";
import { Clinic } from "../../Network/Model/Clinic";
import { replaceWithBr } from 'src/Utils/textUtils';

type Props = {
  dataInfo: Clinic | undefined;
};

const Message: React.FC<Props> = ({ dataInfo }) => {
  const [dataInfomation, setDataInfomation] = useState<any>();
  useEffect(() => {
    if (dataInfo) setDataInfomation(dataInfo);
  }, [dataInfo]);
  const hoursRemarks = dataInfomation ? dataInfomation?.hours_remarks : '';
  const hoursOther = dataInfomation ? dataInfomation?.hours_other : '';
  return (
    <div className="message">
      <span><img src={warning} alt="" /></span>
      <div className="text-normal-styles pre-line">
        <p dangerouslySetInnerHTML={replaceWithBr(hoursRemarks)}></p>
        <p dangerouslySetInnerHTML={replaceWithBr(hoursOther)}></p>
      </div>
    </div>
  );
};

export default Message;
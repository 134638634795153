import './styles.css';
import { Button, Col, Input, InputRef, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/Redux/hooks';
import { changeMedicalDetailRadioSelected, selectMedicalDetailRadioSelected } from 'src/Component/MedicalDetailReceive/slice';
import arrow_left from 'src/assets/image/arrow-left-white.svg';
import japan_mapIcon from 'src/assets/image/japan-map.svg';
import magnifying_glass from 'src/assets/image/magnifying_glass.svg';
import { useGetListPrefectureMutation, useGetListCityByPrefectureMutation } from "src/Network/Home/HomeClinicApi";
import NewDetailPharmacyInfo from './NewDetailPharmacyInfo';
import { PharmacyInfo } from 'src/Network/Model/PharmacyInfo';

type Props = {
    setIsModalOpenNewPharmacy: Function,
}

const NewDetailPharmacy:React.FC<Props> = (props) => {
    const {setIsModalOpenNewPharmacy} = props;
    const [radioValue, setRadioValue] = useState(1);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const radioValueSelected = useAppSelector(selectMedicalDetailRadioSelected);
    const [isBodyModalVisible, setIsBodyModalVisible] = useState(true);
    const [isDivVisiblePref, setDivVisiblePref] = useState(false);

    const inputRefPref = useRef<InputRef>(null);
    const inputRefCity = useRef<InputRef>(null);
    const inputRefPharmacy = useRef<InputRef>(null);
    const [detailPharmacy, setDetailPharmacy] = useState<PharmacyInfo>();
    const [prefName, setPrefName] = useState<string>('');
    const [cityName, setCityName] = useState<string>('');
    const [GetListPrefecture] = useGetListPrefectureMutation();
    const [GetListCityByPrefecture] = useGetListCityByPrefectureMutation();
    const [dataPref, setDataPref] = useState<Array<{ pref: string, name: string }>>([]);
    const [dataCity, setDataCity] = useState<Array<{ name: string }>>([]);

    const [isDivVisibleCity, setDivVisibleCity] = useState(false);
    const [inputValuePref, setInputValuePref] = useState('');
    const [inputValueCity, setInputValueCity] = useState('');

    const handleInputClickPref = () => {
        const prefectures = [] as Array<{ pref: string, name: string }>;
        // data call api get list prefecture
        GetListPrefecture({}).then((res: any) => {
            res.data?.map((item: any) => {
                prefectures.push({
                    pref: item.prefecture,
                    name: item.prefecture + '(' + item?.count + ')'
                });
            })
            setDataPref(prefectures);
        });
        setDivVisiblePref(true);
        setDivVisibleCity(false);
    }

    const handleInputClickCity = () => {
        // data call api get list city
        if (prefName !== '') {
            const citys = [] as Array<{ name: string }>;
            GetListCityByPrefecture({ pref: prefName }).then((res: any) => {
                res?.data?.map((item: any) => {
                    citys.push({
                        name: item.city
                    });
                })
            }).then(() => {
                setDataCity(citys);
            });
        }
        setDivVisibleCity(true);
        setDivVisiblePref(false);
    }

    useEffect(() => {
        setDataPref([]);
        setDataCity([]);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])
    const handleClickOutside = (event: MouseEvent) => {
        if ((inputRefPref.current && !inputRefPref.current.input?.contains(event.target as Node))
            || (inputRefCity.current && !inputRefCity.current.input?.contains(event.target as Node))
            || (inputRefPharmacy.current && !inputRefPharmacy.current.input?.contains(event.target as Node))
        ) {
            const element = event.target as Element;
            const className = element.classList.value;
            if (className === 'modal-keyword-item modal-keyword-pref') {
                setPrefName(element.querySelector('.pref_name')?.getAttribute('value') as string);
                setInputValuePref(element.querySelector('.pref_name')?.getAttribute('value') as string);
            }
            if (className === 'modal-keyword-item modal-keyword-city') {
                setCityName(element.textContent as string);
                setInputValueCity(element.textContent as string);
                const title = element.querySelector('.title')?.getAttribute('value');
                    const tel = element.querySelector('.tel')?.getAttribute('value');
                    const fax = element.querySelector('.fax')?.getAttribute('value');
                    const zip = element.querySelector('.zip')?.getAttribute('value');
                    const address = element.querySelector('.address')?.getAttribute('value');
                    const open = element.querySelector('.open')?.getAttribute('value');
                    const holiday = element.querySelector('.holiday')?.getAttribute('value');
                    const line_url = element.querySelector('.line_url')?.getAttribute('value');
                    setDetailPharmacy({
                        title: title ?? "",
                        tel: tel ?? "",
                        fax: fax ?? "",
                        zip: zip ?? "",
                        address: address ?? "",
                        open: open ?? "",
                        holiday: holiday ?? "",
                        line_url: line_url ?? "",
                        prescriptions: []
                    });
            }
            setDivVisiblePref(false);
            setDivVisibleCity(false);
        }
    };

    const filteredDataCity = dataCity.filter(item => {
        return item.name.toLowerCase().includes(inputValueCity.toLowerCase());
    });

    useEffect(() => {
        if (radioValueSelected) setRadioValue(radioValueSelected);
    }, []);

    const handleSetValue = (value: number) => {
        setRadioValue(value);
        dispatch(changeMedicalDetailRadioSelected(value));
    };

    const handleCancelClick = () => {
        setIsBodyModalVisible(!isBodyModalVisible);
    };

    const handleBackClick = () => {
        setIsModalOpenNewPharmacy(false);
    };

    const filteredDataPref = dataPref.filter(item => {
        return item.name.toLowerCase().includes(inputValuePref.toLowerCase());
    });

    const handleFocusOutPref = () => {
        setDivVisiblePref(false);
    }

    const handleInputChangePref = (event: any) => {
        setInputValuePref(event.target.value);
    }

    const handleFocusOutCity = () => {
        setDivVisibleCity(false);
    }

    const handleInputChangeCity = (event: any) => {
        setInputValueCity(event.target.value);
    }
    return (
        <>
            <div className='new-address'>
                <Row className="new-prescription-info-row inline-row relativeRow" gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20, md: 20 }]}>
                    <Col xs={24} sm={24} md={12} lg={12} className="new-prescription-info-label new-prescription-info-label-first">
                        <div className="prescription-info-row inline-row search_pharmacy">
                            <div className="prescription-info-label">
                                <span>{t("LetterPrescription.label1")}</span>
                                <span className="text-normal-styles">{t("LetterPrescription.label1")}</span>
                                <Input
                                    prefix={<img src={japan_mapIcon} />}
                                    placeholder={t("LetterPrescription.placeholder1")}
                                    className="prescription-info-input"
                                    onClick={handleInputClickPref}
                                    onBlur={handleFocusOutPref}
                                    onChange={handleInputChangePref}
                                    value={inputValuePref}
                                    ref={inputRefPref}
                                />
                            </div>
                            {isDivVisiblePref && (
                                <div id="modal-area" className={filteredDataPref.length != 0 ? "search_pharmacy_regis result_pref" : "search-modal search-modal-none"}>
                                    <div className="modal-container">
                                        <div className="modal-content">
                                            <h5 className="modal-heading">{t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.search_pref")}</h5>
                                            <ul className="modal-keyword-list">
                                                {filteredDataPref.map((item, index) => (
                                                    <li className="modal-keyword-item modal-keyword-pref" key={index}>
                                                        {item.name}
                                                        <input type='hidden' className="pref_name" value={item?.pref}></input>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} className="new-prescription-info-label">
                        <div className="prescription-info-label">
                            <span className="text-normal-styles">{t("LetterPrescription.label2")}</span>
                            <Input
                                prefix={<img src={magnifying_glass} />}
                                placeholder={t("LetterPrescription.placeholder2")}
                                className="prescription-info-input"
                                onClick={handleInputClickCity}
                                onBlur={handleFocusOutCity}
                                onChange={handleInputChangeCity}
                                value={inputValueCity}
                                ref={inputRefCity}
                            />
                        </div>
                        {isDivVisibleCity && (
                            <div id="modal-subject" className="search_pharmacy_regis result_city">
                                <div className="modal-container"></div>
                                <h4 className="modal-title">{t("ReceptionInfo.other_pharmacy.search_pharmacy_guidance.search_city")}</h4>
                                <div className="modal-content modal-subject">
                                    <ul className="modal-keyword-list">
                                        {filteredDataCity.map((item, index) => (
                                            <li className="modal-keyword-item modal-keyword-city" key={index}>
                                                {item.name}
                                                <input type='hidden' className="city_name" value={item?.name}></input>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>

                <Row className='ant-row-title-and-btn'>
                    <Col >
                        <span className="text-bold-styles">{isBodyModalVisible ? t("ReceptionInfo.register_adress_new.select_pharmacy") : t("ReceptionInfo.register_adress_new.cancel_select_pharmacy")}</span>
                    </Col>
                    <Col className='new-prescription-btn-cancel'>
                        {isBodyModalVisible && (
                            <Button type='text' onClick={() => handleCancelClick()} className="cancel-button-step">
                                {t("ReceptionInfo.register_adress_new.cancel")}
                            </Button>
                        )}
                    </Col>
                </Row>

                {isBodyModalVisible ? (
                    <NewDetailPharmacyInfo detailPharmacy={detailPharmacy}/>
                ) :
                    <>
                        <Row className="prescription-row">
                            <Col span={24}>
                                <div className="radio-group">
                                    <Radio.Group className='medical-detail-receive-radio' value={radioValue} onChange={(e) => handleSetValue(e.target.value)}>
                                        <Radio value={1} className='radio text-normal-styles'>{t("ReceptionInfo.register_adress_new.radio_cancel1")}</Radio>
                                        <Radio value={2} className='radio text-normal-styles'>{t("ReceptionInfo.register_adress_new.radio_cancel2")}</Radio>
                                        <Radio value={3} className='radio text-normal-styles'>{t("ReceptionInfo.register_adress_new.radio_cancel3")}</Radio>
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
                <Row className="prescription-info-button" gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 16, sm: 16, md: 16 }]}>
                    <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                        <Button icon={<img src={arrow_left} alt=''/>} className='buttonCancel text-bold-styles' onClick={() => handleBackClick()}>
                            {t('ReceptionInfo.register_adress_new.back')}
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                        <Button className='buttonAccept text-bold-styles' onClick={() => { }}>
                            {t('ReceptionInfo.register_adress_new.register')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default NewDetailPharmacy;
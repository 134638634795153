import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import './styles.css';
import React, {useState} from "react";
import visa from "../../../assets/image/visaCard.png";
import masterCard from "../../../assets/image/masterCard.png";
import jcbCard from "../../../assets/image/jcbCard.png";
import americanCard from "../../../assets/image/americanExpressCard.png";
import dinerCard from "../../../assets/image/dinersClubCard.png";
import infoIcon from "../../../assets/image/info2.svg";
import {CardData} from "../../../Network/Model/Card";
import {useEditCardMutation} from "../../../Network/Home/HomeClinicApi";
import {KEY_USER} from "../../../common/Constant";
import {BUTTON_CLOSE, MODAL_CONFIRM, MODAL_NEGATIVE} from "../../../constant";
import ModalWarning from "../../Modal/ModalWarning";
import Loading from "../../Modal/Loading";

const APP = ({
							 isOpen,
							 onClose,
							 onEditFinish,
							 currentCard,
							 newCardData
						 }: {
	isOpen: boolean,
	onClose: Function,
	onEditFinish: (event: CustomEvent) => void,
	currentCard: CardData | undefined,
	newCardData: CardData
}) => {
	const {t} = useTranslation();
	const handleClose = () => {
		onClose();
	};
	const [updateCard] = useEditCardMutation();
	const [modalMsg, setModalMsg] = useState<string>('');
	const [modalType, setModalType] = useState<number>(1);
	const [modalBtnType, setModalBtnType] = useState<number>(1);
	const [modalTitle, setModalTitle] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const handleCallAPIEditCard = () => {
		setIsLoading(true);
		const param = {
			user_id: JSON.parse(localStorage.getItem(KEY_USER) ?? '{}').user_id,
			card_number: newCardData.cardNumbers,
			limit_month: newCardData.limitMonth,
			limit_year: newCardData.limitYear,
			cc_csc: newCardData.cvvNumbers
		}
		updateCard(param).then((res: any) => {
			if (res?.data?.code && res?.data?.code === 200) {
				setModalMsg(t("MESS_INFO_REGIST_CREDIT_CARD_SUCCESS"));
				setModalType(MODAL_CONFIRM);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_SUCCESS"));
				setIsLoading(false);
			} else {
				setModalMsg(res?.data?.message || t("MESS_ERR_CANNOT_REGIST_CREDIT_CARD"));
				setModalType(MODAL_NEGATIVE);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_FAIL"));
				setIsLoading(false);
			}
		})
	}
	return<div> <Modal className={'confirmEditCard'}
								centered
								open={isOpen}
								title={t("card_payment.register-title")}
								closeIcon={true}
								footer={<div style={{display: "flex", justifyContent: "center"}}>
									<Button type={"text"}
													className={'confirmBtn'}
													onClick={handleCallAPIEditCard}>{t("card_payment.register-btn")}</Button></div>}
								onCancel={handleClose}>
		<div className={'cardList'}>
			<img src={visa} alt={''}/>
			<img src={masterCard} alt={''}/>
			<img src={jcbCard} alt={''}/>
			<img src={americanCard} alt={''}/>
			<img src={dinerCard} alt={''}/>
		</div>
		<table className={"currentCardTable"}>
			<tr>
				<td>{t('card_payment.registered-card-number')}</td>
				<td>{currentCard?.cardNumber}</td>
			</tr>
			<tr>
				<td>{t('card_payment.limit-date')}</td>
				<td>{currentCard?.cardExpire}</td>
			</tr>
		</table>
		<table style={{width: "100%"}} className={"newCardTable"}>
			<tr>
				<td>{t("card_payment.card-numbers")}</td>
				<td>{newCardData?.cardNumbers}</td>
			</tr>
			<tr>
				<td>
					{t("card_payment.limit-date")}
				</td>
				<td>
					{newCardData?.limitMonth}{t('card_payment.month')}・{newCardData?.limitYear}{t('card_payment.year')}
				</td>
			</tr>
			<tr>
				<td style={{width: "50%"}}>
					{t("card_payment.cvv-numbers")}
				</td>
				<td>
					{newCardData?.cvvNumbers}
				</td>
			</tr>
		</table>
		<p style={{marginTop: 24}}>
			<img src={infoIcon} alt={''}/><span className={'info1'}>{t('card_payment.info1')}</span>
			<br/>
			<span className={'info2'}>{t('card_payment.info2')}</span>
		</p>
		{isLoading &&
    <Loading/>
		}
	</Modal>
	{modalMsg && modalMsg !== '' && (
		<ModalWarning
			message={modalMsg}
			typeModal={modalType}
			typeButton={modalBtnType}
			title={modalTitle}
			changeVisibleModal={() => {
				setModalMsg('');
				if (modalType === MODAL_CONFIRM) {
					onEditFinish(new CustomEvent(''));
				} }
			}
		/>
	)}
</div>
}

export default APP;
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfirmCircle } from '../../../../../assets/image/confirm-circle.svg';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  changeIsListMode,
  changeOpenSuccessModal,
  selectOpenSuccessModal
} from '../store/slice';
import ModalHeader from '../../agreementManagement/ModalHeader';

const SuccessModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openModal = useAppSelector(selectOpenSuccessModal);

  const handleClosModal = () => {
    dispatch(changeOpenSuccessModal(false));
    dispatch(changeIsListMode(true));
  };

  const renderFooter = () => {
    return (
      <div className="agreement-management_modal-footer_container justify-center h-60px">
        <button
          onClick={handleClosModal}
          className="menu-treatment-single-button"
        >
          {t('reExamCode.close')}
        </button>
      </div>
    );
  };

  return (
    <Modal
      open={openModal}
      closeIcon={null}
      centered
      styles={{
        body: {
          marginTop: 24
        }
      }}
      title={
        <ModalHeader
          handleCloseModal={handleClosModal}
          title={t('reExamCode.info')}
        />
      }
      footer={renderFooter()}
      destroyOnClose
    >
      <div className="delete-agreement-modal_icon">
        <ConfirmCircle />
      </div>
      <div className="agreement-management_modal-footer_container justify-center">
        <h2>{t('reExamCode.successMessage')}</h2>
      </div>
    </Modal>
  );
};

export default SuccessModal;

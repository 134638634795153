import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoOutlineIcon } from '../../../../../assets/image/info_outline.svg';
import { ReactComponent as Plus2Icon } from '../../../../../assets/image/plus2.svg';
import MedicalMenuTable from './MedicalMenuTable';
import { useAppDispatch } from 'src/Redux/hooks';
import {
  changeIsCreate,
  changeIsListMode,
  changeSelectedUpdateId
} from '../store/slice';
import { useGetTreatmentMenuListByClinicIdQuery } from 'src/Network/Home/DoctorSettingApi';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';

const MedicalMenuListTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clinicId = getClinicIdFromLocalStorage();
  const { data } = useGetTreatmentMenuListByClinicIdQuery(clinicId, {
    skip: clinicId < 0
  });
  const handleAddTreatment = () => {
    dispatch(changeSelectedUpdateId(undefined));
    dispatch(changeIsListMode(false));
    dispatch(changeIsCreate(true));
  };

  return (
    <>
      <div className="medical-menu_header">
        <div className="medical-menu_note_container">
          <InfoOutlineIcon />
          <span className="medical-menu_note">
            {t('reExamCode.medicalMenuNote')}
          </span>
        </div>
        <button
          onClick={handleAddTreatment}
          className="add-treatment-menu_button"
        >
          <Plus2Icon className="add-treatment-menu_icon" />
          {t('reExamCode.addTreatmentMenu')}
        </button>
      </div>
      <MedicalMenuTable data={data || []} />
    </>
  );
};

export default MedicalMenuListTable;

import './styles.css';
import { ReservationInfoList } from "src/Network/Model/ReservationInfoList";

const ComponentToPrint = (props: any, listItem:ReservationInfoList[]) => {
    
    return (
        <div ref={props} className='cards'>
            {listItem.map((item, index) => {
                return (
                    <div key={index} className='card'>
                        <div><span>〒{item.postal_code}</span></div>
                        <div><span>{item.prefectures_name}{item.city_name}</span></div>
                        <div><span>{item.address}</span></div>
                        <div><span>{item.user_name}</span></div>
                    </div>
                )
            })}
        </div>
    )
}

export default ComponentToPrint;
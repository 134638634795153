import { Col, Row, Table } from 'antd';
import dot from '../../assets/image/dot.svg';
import './styles.css';
import Message from '../Message';
import generateTableData from '../../Utils/generateTableDataUtils';

type Props = {
    dataInfomation: any,
}

const generateTableColumns = (weekDay: string[]) => {
    const columns = weekDay.map((item, index) => {
        let className = '';
        if (item === '土') {
            className = ' saturday';
        } else if (item === '日') {
            className = ' sunday';
        }
        const col = {
            title: <span className={`text-bold-styles${className}`}>{item}</span>,
            dataIndex: item,
            key: index + 1,
            render: (data: any) => {
                return (
                    <div key={index}>{data ? '' : <img src={dot} alt='' />}</div>
                )
            }
        }
        return col
    });
    return [
        { title: <span className="text-bold-styles">診療時間</span>, dataIndex: 'blank', key: 0 },
        ...columns
    ];
};

const BookingTime: React.FC<Props> = ({ dataInfomation }) => {
    let weekDay = ["日", "月", "火", "水", "木", "金", "土", "祝"];

    const result = generateTableData(dataInfomation);
    const columns = generateTableColumns(weekDay);

    const dataTable = result.map((item, i) => ({
        'key': i,
        'blank': <span className="text-normal-styles">{item[8]}〜{item[9]}</span>,
        '日': item[6],
        '月': item[0],
        '火': item[1],
        '水': item[2],
        '木': item[3],
        '金': item[4],
        '土': item[5],
        '祝': item[7]
    }));

    return (
        <div className="table-time">
            <Row gutter={[{}, { xs: 24, sm: 24, md: 12 }]}>
                <Col xs={24} sm={24} md={24}>
                    <Table
                        columns={columns}
                        dataSource={dataTable}
                        pagination={false}
                    />
                </Col>
                <Col xs={24} sm={24} md={24}>
                    <Message dataInfo={dataInfomation} />
                </Col>
            </Row>
        </div>
    );
}

export default BookingTime;
import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { changeOpenErrorModal, selectOpenErrorModal } from '../store/slice';
import { useTranslation } from 'react-i18next';
import ModalHeader from '../../agreementManagement/ModalHeader';
import { ReactComponent as XCircleFillIcon } from '../../../../../assets/image/x_circle_fill_icon.svg';

const ErrorModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openErrorModal = useAppSelector(selectOpenErrorModal);
  const handleCloseErrorModal = () => {
    dispatch(changeOpenErrorModal(false));
  };

  const renderFooter = () => {
    return (
      <div className="agreement-management_modal-footer_container justify-center h-60px">
        <button
          onClick={handleCloseErrorModal}
          className="agreement-management_modal-footer_create-button"
        >
          {t('reExamCode.cancel')}
        </button>
      </div>
    );
  };

  return (
    <Modal
      open={openErrorModal}
      closeIcon={null}
      centered
      styles={{
        body: {
          marginTop: 24
        }
      }}
      title={
        <ModalHeader
          handleCloseModal={handleCloseErrorModal}
          title={t('reExamCode.error')}
        />
      }
      footer={renderFooter()}
      destroyOnClose
    >
      <div className="delete-agreement-modal_icon">
        <XCircleFillIcon />
      </div>
      <div className="agreement-management_modal-footer_container ">
        <h2>{t('reExamCode.errorMessage')}</h2>
      </div>
    </Modal>
  );
};

export default ErrorModal;

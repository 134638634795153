import './styles.css';
import CloseBtn from '../../../../assets/image/closebtn.svg';
import {Button, Modal, Radio} from 'antd';
import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps, Tooltip } from 'antd/lib';
import DOC_ICON from 'src/assets/image/docs.svg';
import { useTranslation } from 'react-i18next';
import {NonInsuranceAmount, ReservationInfoDetailResponse} from '../../../../Network/Model/ReservationInfo';
import {
  useLazyReservationInfoDetailByIdQuery,
  useUpdateReservationInfoMutation
} from '../../../../Network/Home/DoctorApi';
import { ConsentForm } from '../../../../Network/Model/ConsentForm';
import { Clinic as api } from 'src/Network/Api';
import ModalWarning from '../../../../Component/Modal/ModalWarning';
import {
  BUTTON_CLOSE,
  isFutureDate,
  MODAL_CONFIRM,
  MODAL_NEGATIVE,
  MODAL_WARNING
} from '../../../../constant';
import dayjs from 'dayjs';
import Loading from "../../../../Component/Modal/Loading";

const APP = ({
  rsvInfoId,
  isShow,
  onClose,
  dateTimeStr,
  reloadRsvFrame
}: {
  rsvInfoId: number;
  isShow: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dateTimeStr: string;
  reloadRsvFrame: (event: CustomEvent) => void
}) => {
  const { t } = useTranslation();
  const [rsvInfoDetail, setRsvInfoDetail] =
    useState<ReservationInfoDetailResponse>();
  const [getRsvInfoById] = useLazyReservationInfoDetailByIdQuery();
  const [isModalConsentFormOpen, setIsModalConsentFormOpen] = useState(false);
  const [consentForm, setConsentForm] = useState<ConsentForm>();
  const [modalMsg, setModalMsg] = useState<string>();
  const [modalTitle, setModalTitle] = useState<string>();
  const [dayMemo, setDayMemo] = useState<string>();
  const [patientMemo, setPatientMemo] = useState<string>();
  const [updateReservationInfo] = useUpdateReservationInfoMutation();
  const [modalType, setModalType] = useState<number>(1);
  const [modalBtnType, setModalBtnType] = useState<number>(1);
  const [dayNoteValidateMsg, setDayNoteValidateMsg] = useState<string>('');
  const [patientNoteValidateMsg, setPatientNoteValidateMsg] =
    useState<string>('');
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState(t("cancelRsv.patientNotCome"));
  const [msgToPatient, setMsgToPatient] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const handleCancelRsv = (e: React.MouseEvent<HTMLButtonElement> ) => {
    const param = {
      id: rsvInfoId,
      rsv_cancel_reason: cancelReason.trim(),
      text_send_to_patient: msgToPatient.trim(),
      rsv_status: 0
    };
    updateReservationInfo(param).then((res: any) => {
      if (res?.data?.code && res?.data?.code === 200) {
        setOpenCancelModal(false);
        setOpenConfirmCancelModal(false);
        onClose(e);
        setModalMsg(t("cancelRsv.cancelSuccess"));
        setModalType(MODAL_CONFIRM);
        setModalBtnType(BUTTON_CLOSE);
        setIsLoading(false);
        setModalTitle(t("message.TITLE_SUCCESS"));
        reloadRsvFrame(new CustomEvent(""));
      } else {
        setModalMsg(t('errorMsg'));
        setModalType(MODAL_NEGATIVE);
        setModalBtnType(BUTTON_CLOSE);
        setIsLoading(false);
        setModalTitle(t("message.TITLE_FAIL"));
      }
    });
  }

  const showConsentFormModal = () => {
    setIsModalConsentFormOpen(true);
  };

  const handleClose = () => {
    setIsModalConsentFormOpen(false);
  };

  const handleRsvInfoDetailAPI = () => {
    const param = {
      id: rsvInfoId
    };
    getRsvInfoById(param).then((res: any) => {
      if (res?.data?.error) {
        setModalMsg(t('errorMsg'));
        setModalType(MODAL_WARNING);
        setModalTitle(t("message.TITLE_FAIL"));
      } else {
        setRsvInfoDetail(res?.data?.data);
        setDayMemo(res?.data?.data?.day_note || '');
        setPatientMemo(res?.data?.data?.patient_note || '');
      }
    });
  };

  useEffect(() => {
    if (rsvInfoId !== -1) {
      handleRsvInfoDetailAPI();
      setPatientNoteValidateMsg('');
      setDayNoteValidateMsg('');
      setCancelReason(t("cancelRsv.patientNotCome"));
      setMsgToPatient('');
    }
  }, [rsvInfoId]);

  useEffect(() => {
    setDayMemo(rsvInfoDetail?.rsvInfo.day_note || '');
    setPatientMemo(rsvInfoDetail?.rsvInfo.patient_note || '');
      try {
        setConsentForm(JSON.parse(rsvInfoDetail?.rsvInfo.consent_form_content || ''));
      } catch (e) {
        setConsentForm(undefined);
      }
  }, [rsvInfoDetail]);

  const handleUpdateDayMemo = () => {
    if (dayMemo && dayMemo.trim().length > 2000) {
      setDayNoteValidateMsg(
        t('admin-schedule.reservation-frame-detail.day-note-exceed-max-length')
      );
      return;
    }
    const param = {
      id: rsvInfoId,
      day_note: dayMemo?.trim()
    };
    updateReservationInfo(param).then((res: any) => {
      if (res?.data?.code && res?.data?.code === 200) {
        setModalMsg(t('admin-schedule.update-day-memo-success'));
        setModalType(MODAL_CONFIRM);
        setModalBtnType(BUTTON_CLOSE);
        handleRsvInfoDetailAPI();
        setModalTitle(t("message.TITLE_SUCCESS"));
      } else {
        setModalMsg(t('errorMsg'));
        setModalType(MODAL_NEGATIVE);
        setModalBtnType(BUTTON_CLOSE);
        setModalTitle(t("message.TITLE_FAIL"));
      }
    });
  };

  const handleUpdatePatientMemo = () => {
    if (patientMemo && patientMemo.trim().length > 2000) {
      setPatientNoteValidateMsg(
        t(
          'admin-schedule.reservation-frame-detail.patient-note-exceed-max-length'
        )
      );
      return;
    }
    const param = {
      id: rsvInfoId,
      patient_note: patientMemo?.trim()
    };
    updateReservationInfo(param).then((res: any) => {
      if (res?.data?.code && res?.data?.code === 200) {
        setModalType(MODAL_CONFIRM);
        setModalBtnType(BUTTON_CLOSE);
        setModalMsg(t('admin-schedule.update-patient-memo-success'));
        setModalTitle(t("message.TITLE_SUCCESS"));
        handleRsvInfoDetailAPI();
      } else {
        setModalType(MODAL_NEGATIVE);
        setModalBtnType(BUTTON_CLOSE);
        setModalMsg(t('errorMsg'));
        setModalTitle(t("message.TITLE_FAIL"));
      }
    });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('admin-schedule.reservation-frame-detail.memo-that-day'),
      children: (
        <div>
          <p>
            <span style={{ fontWeight: 'normal' }}>診察日：</span>
            {dayjs(rsvInfoDetail?.rsvInfo.rsv_ymd).format('YYYY年MM月DD日')}
          </p>
          <textarea
            name={'day_note'}
            className={'textArea'}
            value={dayMemo}
            onChange={(e) => {
              setDayMemo(e.target.value);
              setDayNoteValidateMsg('');
            }}
            placeholder={t(
              'admin-schedule.reservation-frame-detail.day-note-placeholder'
            )}
            disabled={
              !isFutureDate(dayjs(rsvInfoDetail?.rsvInfo.rsv_ymd).format('YYYY-MM-DD'))
            }
            maxLength={2000}
          />
          {dayNoteValidateMsg !== '' && (
            <span className="messErr text-nornal-styles">
              {dayNoteValidateMsg}
            </span>
          )}
          <p style={{ fontWeight: 'normal' }}>{dayMemo?.trim().length} / 2000文字</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isFutureDate(
              dayjs(rsvInfoDetail?.rsvInfo.rsv_ymd).format('YYYY-MM-DD')
            ) && (
              <Button type="text" className={'submitBtn'} onClick={handleUpdateDayMemo}>
                {t('admin-schedule.save')}
              </Button>
            )}
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: t('admin-schedule.reservation-frame-detail.patient-memo'),
      children: (
        <div>
          <p>
            <span style={{ fontWeight: 'normal' }}>更新日：</span>
            {dayjs(rsvInfoDetail?.rsvInfo.updated_datetime).format('YYYY年MM月DD日')}
          </p>
          <textarea
            className={'textArea'}
            value={patientMemo}
            onChange={(e) => {
              setPatientMemo(e.target.value);
              setPatientNoteValidateMsg('');
            }}
            placeholder={t(
              'admin-schedule.reservation-frame-detail.patient-note-placeholder'
            )}
            disabled={
              !isFutureDate(dayjs(rsvInfoDetail?.rsvInfo.rsv_ymd).format('YYYY-MM-DD'))
            }
          />
          {patientNoteValidateMsg !== '' && (
            <span className="messErr text-nornal-styles">
              {patientNoteValidateMsg}
            </span>
          )}
          <p style={{ fontWeight: 'normal' }}>
            {patientMemo?.trim().length} / 2000文字
          </p>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {isFutureDate(
              dayjs(rsvInfoDetail?.rsvInfo.rsv_ymd).format('YYYY-MM-DD')
            ) && (
              <Button type="text" className={'submitBtn'} onClick={handleUpdatePatientMemo}>
                {t('admin-schedule.save')}
              </Button>
            )}
          </div>
        </div>
      )
    }
  ];

  const getRsvDivStr = (rsvDiv: number) => {
    if (rsvDiv === 0) {
      return (
        t('admin-schedule.reservation-frame-detail.rsvDivTextFull0') +
        t('admin-schedule.reservation-frame-detail.examine')
      );
    } else if (rsvDiv === 1) {
      return (
        t('admin-schedule.reservation-frame-detail.rsvDivTextFull1') +
        t('admin-schedule.reservation-frame-detail.examine')
      );
    }
    return '';
  };
  return (
    <div className={isShow ? 'dt012' : 'dt012 hidden'}>
      <div className={'title'}>
        {t('admin-schedule.reservation-frame-detail.reservation-info-content')}
        <Button onClick={onClose} className={'closeBtn'}>
          <img src={CloseBtn} alt="" />
        </Button>
      </div>
      <div className={'wrap-table'}>
        <table>
          <tr>
            <td>
              {t('admin-schedule.reservation-frame-detail.reservation-time')}
            </td>
            <td>{dateTimeStr}</td>
          </tr>
          <tr>
            <td>
              {t('admin-schedule.reservation-frame-detail.reservation-info-id')}
            </td>
            <td>{rsvInfoDetail?.rsvInfo.id}</td>
          </tr>
          <tr>
            <td>{t('admin-schedule.reservation-frame-detail.patient-name')}</td>
            <td>{rsvInfoDetail?.rsvInfo.patient_name}</td>
          </tr>
          <tr>
            <td>{t('admin-schedule.treatment_menu')}</td>
            <td>{rsvInfoDetail?.rsvInfo.treatment_menu_name}</td>
          </tr>
          <tr>
            <td>
              {t('admin-schedule.reservation-frame-detail.reservation-div')}
            </td>
            <td>
              {getRsvDivStr(
                rsvInfoDetail?.rsvInfo.rsv_div === undefined
                  ? -1
                  : rsvInfoDetail?.rsvInfo.rsv_div
              )}
            </td>
          </tr>
          <tr>
            <td>
              {t('admin-schedule.reservation-frame-detail.doctor-in-charge')}
            </td>
            <td>{rsvInfoDetail?.rsvInfo.doctor_name}</td>
          </tr>
          <tr>
            <td>{t('admin-schedule.question')}</td>
            <td>
              {rsvInfoDetail?.rsvInfo.reception_id && (
                <div>
                  <form
                    name={'patient' + rsvInfoDetail?.rsvInfo.reception_id}
                    action={`${process.env.REACT_APP_WP_URL}${api.wpPatientInfo}`}
                    method="post"
                    target="_blank"
                  >
                    <input
                      type="hidden"
                      name="reception_id"
                      value={rsvInfoDetail?.rsvInfo.reception_id}
                    />
                    <input type="hidden" name="dsp_ptn" value="1" />
                  </form>
                  <Tooltip
                    title={t(
                      'admin-schedule.reservation-frame-detail.already-answer-questions'
                    )}
                  >
                    <a
                      href={`javascript:patient${rsvInfoDetail?.rsvInfo.reception_id}.submit()`}
                    >
                      <Button className="docsBtn">
                      <img src={DOC_ICON} alt="" />
                      </Button>
                    </a>
                  </Tooltip>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>{t('admin-schedule.consent-form')}</td>
            <td>
              {rsvInfoDetail?.rsvInfo.consent_completed_flg === 1 && consentForm && (
                <Tooltip
                  title={t(
                    'admin-schedule.reservation-frame-detail.already-agree'
                  )}
                >
                  <Button className="docsBtn">
                  <img src={DOC_ICON} alt="" onClick={showConsentFormModal} />
                  </Button>
                </Tooltip>
              )}
            </td>
          </tr>
          <tr>
            <td>{t('admin-schedule.karute')}</td>
            <td>
              {rsvInfoDetail?.rsvInfo.karute_id && (
                <Tooltip
                  title={t(
                    'admin-schedule.reservation-frame-detail.already-register-karute'
                  )}
                >
                  <a
                    href={`${process.env.REACT_APP_WP_URL}/clinic-admin/chart-detail/?karute_id=${rsvInfoDetail?.rsvInfo.karute_id}`}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <Button className="docsBtn">
                    <img src={DOC_ICON} alt="" />
                    </Button>
                  </a>
                </Tooltip>
              )}
            </td>
          </tr>
        </table>
      </div>
      <Tabs className={'dt012-tabs'} type="card" items={items} />
      {isFutureDate(dayjs(rsvInfoDetail?.rsvInfo.rsv_ymd).format('YYYY-MM-DD')) && (
        <div style={{ textAlign: 'center' }}>
          <button className={'cancelBtn'} onClick={() => setOpenCancelModal(true)}>{t("cancelRsv.cancelBtn")}</button>
        </div>
      )}
      <Modal
        title={consentForm ? consentForm.title : ''}
        open={isModalConsentFormOpen}
        onCancel={handleClose}
        className={'consentFormModal'}
        footer={[
          <Button key="submit" type="primary" onClick={handleClose}>
            {t('admin-schedule.close')}
          </Button>
        ]}
        destroyOnClose={true}
      >
        <p>{t("admin-schedule.reservation-frame-detail.consent-form-description-1")}</p>
        <p>{t("admin-schedule.reservation-frame-detail.consent-form-description-2")}</p><br/>
        <p>{t("admin-schedule.reservation-frame-detail.consent-form-description-3")}</p>
        {consentForm?.amounts?.map((nia: NonInsuranceAmount, i: number) => (
          <div>
          <p>{nia?.amount_description || ''} </p>
          <p>{nia?.amount || ''}<span>{t("admin-schedule.reservation-frame-detail.consent-form-amount-suffix")}</span></p>
          </div>
        ))}
      </Modal>
      {modalMsg && modalMsg !== '' && (
        <ModalWarning
          message={modalMsg}
          typeModal={modalType}
          typeButton={modalBtnType}
          title={modalTitle}
          changeVisibleModal={() => {
            setModalMsg('');
          }}
        />
      )}
      <Modal className={"modalCancel"} title={t("cancelRsv.cancelReason")} destroyOnClose={true} open={openCancelModal}
             closeIcon={true} onCancel={() => setOpenCancelModal(false)} centered
             okText={t("cancelRsv.cancelBtn")} footer={[
        <Button
          type="primary"
          onClick={() => setOpenConfirmCancelModal(true)}
        >
          <span>
          {t("cancelRsv.cancelBtn")}
            </span>
        </Button>,
      ]}>
          <Radio.Group onChange={(e) => {
            setCancelReason(e.target.value)
          }} className="searchRadio" value={cancelReason}>
              <Radio value={t("cancelRsv.patientNotCome")} checked>{t("cancelRsv.patientNotCome")}</Radio>
              <Radio value={t("cancelRsv.patientCancel")}>{t("cancelRsv.patientCancel")}</Radio>
              <Radio value={t("cancelRsv.doctorNotCome")}>{t("cancelRsv.doctorNotCome")}</Radio>
              <Radio value={t("cancelRsv.other")}>{t("cancelRsv.other")}</Radio>
          </Radio.Group>
          <p>{t("cancelRsv.msgToPatient")}</p>

          <textarea value={msgToPatient} maxLength={2000} onChange={(e) => {
            setMsgToPatient(e.target.value)
          }} placeholder={t("cancelRsv.plsEnterReason")}/>
        <p style={{ fontWeight: 'normal' }}>
          {`${msgToPatient?.trim().length} / 2000${t("cancelRsv.character")}`}
          </p>
      </Modal>
      <Modal className={"modalConfirmCancel"} title={t("message.MESS_CMN_CNF_DELETE.title")} open={openConfirmCancelModal} destroyOnClose={true}
             closeIcon={true}
             centered
             onCancel={() => setOpenConfirmCancelModal(false)}
             footer={[
        <div style={{display: "flex"}}>
          <Button key="submit" type="text" onClick={(e :React.MouseEvent<HTMLButtonElement>) => {
            setIsLoading(true);
            handleCancelRsv(e);
            setOpenCancelModal(false);
            setOpenConfirmCancelModal(false);
          }}>
            {t("cancelRsv.confirm")}
          </Button>
          <Button key="submit" type="text" onClick={() => setOpenConfirmCancelModal(false)}>
            {t("cancelRsv.cancel")}
          </Button>
        </div>
      ]} >
        <p style={{fontWeight: "bold"}}> {t("cancelRsv.confirm-cancel")}</p>
        <p> {dateTimeStr}</p>
        <p> {rsvInfoDetail?.rsvInfo.patient_name}</p>
        <p> {rsvInfoDetail?.rsvInfo.treatment_menu_name}</p>
      </Modal>
      {isLoading && <Loading/>}
    </div>
  );
}

export default APP;

import BannerImage from 'src/assets/image/banner.svg';
import './styles.css';

type Props = {
  backgroundImg: string | undefined;
  backgroundImgAlt: string | undefined;
};

const Banner: React.FC<Props> = ({ backgroundImg, backgroundImgAlt }) => {
  let background_image = null;
  if (backgroundImg) {
    background_image = process.env.REACT_APP_WP_IMAGES + backgroundImg;
  }
  return (
    <div className="banner">
      <img src={background_image ?? BannerImage} alt={backgroundImgAlt} title={backgroundImgAlt}/>
    </div>
  );
};
  
export default Banner;
import './styles.css';
import { Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PlainCreditCard from 'src/assets/image/plain-credit-card.svg';
import NoteBook from 'src/assets/image/notebook.svg';
import { useTranslation } from 'react-i18next';
import { KEY_USER } from 'src/common/Constant';
import {
	useCheckCreditCardQuery,
	useLazyGetCardInfoQuery, useSendOTPMutation,
	useValidateInnsuranceQuery
} from 'src/Network/Home/HomeClinicApi';
import React, { useEffect, useState } from 'react';
import RegisterCard from './RegisterCard';
import CardInfo from './CardInfo';
import ConfirmBeforeEditCard from './ConfirmBeforeEditCard';
import EditCard from './EditCard';
import RegisterInsurance from './RegisterInsurance';
import {CardData} from "../../Network/Model/Card";
import Loading from "../Modal/Loading";
import ModalWarning from "../Modal/ModalWarning";
import {BUTTON_CLOSE, MODAL_NEGATIVE} from "../../constant";

type Props = {
    familyUserId: number,
    checkCreditCard: any,
    hasJustRegisteredCard: boolean,
    setHasJustRegisterCard: (a: boolean) => void,
    user_id: any,
}

const CardPayment:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {familyUserId} = props;
    const json = localStorage.getItem(KEY_USER)??"{}";
    const [openModalRegisterCard, setOpenModalRegisterCard] = useState(false);
    const [openModalRegisterInsurance, setOpenModalRegisterInsurance] = useState(false);
    const [openModalShowCardInfo, setOpenModalShowCardInfo] = useState(false);
    const [openModalConfirmBeforeEditCard, setOpenModalConfirmBeforeEditCard] = useState(false);
    const [openModalEditCard, setOpenModalEditCard] = useState(false);
    const validateInnsurance = useValidateInnsuranceQuery({user_id: props.user_id, family_user_id: familyUserId});
    useEffect(() => {
        validateInnsurance?.refetch();
    }, [familyUserId])
		const [cardInfo, setCardInfo] = useState<CardData>();
		const [isLoading, setIsLoading] = useState(false);
		const [getCardInfo] = useLazyGetCardInfoQuery();
		const [modalMsg, setModalMsg] = useState<string>('');
		const [modalType, setModalType] = useState<number>(1);
		const [modalBtnType, setModalBtnType] = useState<number>(1);
		const [modalTitle, setModalTitle] = useState<string>();
		const [sendMailOTPToEdit] = useSendOTPMutation();

	const handleClickViewCardInfo = () => {
		setIsLoading(true);
		getCardInfo({}).then((res) => {
			if (res?.data?.code && res?.data?.code === 200) {
				setCardInfo(res?.data?.data);
				setIsLoading(false);
				setOpenModalShowCardInfo(true);
			} else {
				setModalMsg(t('errorMsg'));
				setModalType(MODAL_NEGATIVE);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_FAIL"));
				setIsLoading(false);
			}
		})
	}

	const handleSendMailOTPToEdit = () => {
		setIsLoading(true);
		sendMailOTPToEdit({}).then((res: any) => {
			if (res?.data?.code && res?.data?.code === 200) {
				setOpenModalConfirmBeforeEditCard(true);
				setIsLoading(false);
			} else {
				setModalMsg(t('errorMsg'));
				setModalType(MODAL_NEGATIVE);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_FAIL"));
				setIsLoading(false);
			}
		})
	}

    return (
        <div style={{marginTop: 40}} className='cardPayment'>
            <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20 }]} style={{ marginBottom: 16 }}>
                {/*If user already registered card, show modal card info*/}
                {(props.checkCreditCard?.has_registered_card || props.hasJustRegisteredCard)&&
                <Col xs={24} sm={12} md={12}>
                    <div className='cardItem' onClick={handleClickViewCardInfo}>
                        <img src={PlainCreditCard} alt=''/>
                        <span className='text-bold-styles'>{t('receptioninfo.card-payment.credit')}</span>
                    </div>
                </Col>
                }
                {/*If user hasnt registered card, show modal card registration*/}
                {!props.checkCreditCard?.has_registered_card && !props.hasJustRegisteredCard &&
                <Col xs={24} sm={12} md={12}>
                    <div className='cardItem' onClick={() => setOpenModalRegisterCard(true)}>
                        <img src={PlainCreditCard} alt=''/>
                        <span className='text-bold-styles'>{t('receptioninfo.card-payment.credit')}</span>
                    </div>
                </Col>
                }
                {!validateInnsurance?.data?.has_registered_insurrance &&
                <Col xs={24} sm={12} md={12}>
                    <div className='cardItem' onClick={() => setOpenModalRegisterInsurance(true)}>
                        <img src={NoteBook} alt='' />
                        <span className='text-bold-styles'>{t('receptioninfo.card-payment.book')}</span>
                    </div>
                </Col>
                }
            </Row>
            <Row gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 20, sm: 20 }]} style={{ marginBottom: 16 }}>
                <span className='errcode'>
                    {!props.checkCreditCard?.has_registered_card && !props.hasJustRegisteredCard &&
                        t('message.MESS_ERR_MISS_REGIST_CREDIT_CARD')
                    }
                    {!validateInnsurance?.data?.has_registered_insurrance &&
                        t('message.MESS_ERR_MISS_REGIST_INSURANCE_CARD')
                    }
                </span>
            </Row>
            {openModalRegisterCard &&
            <RegisterCard isOpen={openModalRegisterCard}
                          onClose={() => {setOpenModalRegisterCard(false)}}
                          onRegisterFinish={() => {
                          setOpenModalRegisterCard(false);
                          props.setHasJustRegisterCard(true)}}/>}
            {openModalShowCardInfo &&
            <CardInfo isOpen={openModalShowCardInfo} onClose={() => setOpenModalShowCardInfo(false)}
                      moveToConfirmBeforeEdit={() => {
                          setOpenModalShowCardInfo(false);
                          handleSendMailOTPToEdit();
                      }}
                      currentCard={cardInfo}/>}
            {openModalConfirmBeforeEditCard &&
            <ConfirmBeforeEditCard isOpen={openModalConfirmBeforeEditCard}
                                   onClose={() => setOpenModalConfirmBeforeEditCard(false)}
                                   onVerifyOTPDone={() => {
                                       setOpenModalConfirmBeforeEditCard(false);
                                       setOpenModalEditCard(true)
                                   }
                                   }/>
            }
            {openModalEditCard &&
            <EditCard isOpen={openModalEditCard}
                      onClose={() => setOpenModalEditCard(false)}
                      onEditFinish={() => {
                      	setOpenModalEditCard(false);}}
											currentCard={cardInfo}
						/>}
            {openModalRegisterInsurance &&
            <RegisterInsurance isOpen={openModalRegisterInsurance}
                               onClose={() => setOpenModalRegisterInsurance(false)}/>}

						{isLoading && <Loading/>}
					{modalMsg && modalMsg !== '' && (
						<ModalWarning
							message={modalMsg}
							typeModal={modalType}
							typeButton={modalBtnType}
							title={modalTitle}
							changeVisibleModal={() => {
								setModalMsg('');
							}
							}
						/>
					)}
        </div>
    )
}

export default CardPayment;
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as XCircleFillIcon } from '../../../../assets/image/x_circle_fill_icon.svg';
import ModalHeader from '../agreementManagement/ModalHeader';

type Props = {
  open: boolean;
  message: string;
  setClose: () => void;
};

const ErrorModal: React.FC<Props> = ({ message, open, setClose }) => {
  const { t } = useTranslation();
  const renderFooter = () => {
    return (
      <div className="agreement-management_modal-footer_container justify-center h-60px">
        <button
          onClick={setClose}
          className="agreement-management_modal-footer_create-button"
        >
          {t('reExamCode.cancel')}
        </button>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      closeIcon={null}
      centered
      styles={{
        body: {
          marginTop: 24
        }
      }}
      title={
        <ModalHeader
          handleCloseModal={setClose}
          title={t('reExamCode.error')}
        />
      }
      footer={renderFooter()}
      destroyOnClose
    >
      <div className="delete-agreement-modal_icon">
        <XCircleFillIcon />
      </div>
      <div className="agreement-management_modal-footer_container ">
        <h2>{message}</h2>
      </div>
    </Modal>
  );
};

export default ErrorModal;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Redux/store';
import { revertAll } from 'src/Redux/hooks';

const initialState = {
    dataFormQuestion: {} as any,
    dataFormAddress: {} as any,
}

const slice = createSlice({
  name: "questionSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeDataQuestion : (state, action) => {
        state.dataFormQuestion = action.payload
    },
    changeDataAddress : (state, action) => {
      state.dataFormAddress = action.payload
  },
  },
});

export const selectDataQuestion = (state:RootState) => state.common.questionSlice.dataFormQuestion
export const selectDataAddress = (state:RootState) => state.common.questionSlice.dataFormAddress

export const {
  changeDataQuestion,
  changeDataAddress,
} = slice.actions;

export const QuestionReducer = slice.reducer;
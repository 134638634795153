import React, { useState } from "react";
import { Calendar, Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { DoubleRightOutlined } from "@ant-design/icons";
// import updateLocale from "dayjs/plugin/updateLocale";
import "./styles.css";
import { useReservationFrameQuery } from "src/Network/Home/HomeClinicApi";
import { useEffect } from "react";
import { availabilityData } from "src/Network/Model/availabilityData";
import empty from "../../assets/image/dot.svg";
import full from "../../assets/image/full-event.svg";
import { useSearchParams } from "react-router-dom";
import { formatYYYYMMD } from "src/constant";
import { formatYYYYMMDDNoSlash } from "src/constant";
import { useAppDispatch, useAppSelector } from "src/Redux/hooks";
import { changeDataHourInfo, changeDate, selectIsSelectedDate, selectSelectedDate, selectdataHourInfo } from "./slice";
import { changeDoctorSelectionList, changeFilteredData, changeRadioIndex, changeUserIndex } from "../TimeBooking/slice";

interface calendarBookingProps {
    setSelectedDate: (a: Dayjs) => void;
    setIsSelected: (b: boolean) => void;
    setDataHourInfo: (c: any) => void;
}


const CalendarBooking: React.FC<calendarBookingProps> = ({ setSelectedDate, setIsSelected, setDataHourInfo }) => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams();
    const clinicId = searchParams.get('clinic_id') || '';
    const id = searchParams.get('treatmentmenu_id') || '';
    const [selectedBox, setSelectedBox] = useState<Dayjs | null>(null);
    const [dateInfo, setDateInfo] = useState<any>();
    const nowDate = dayjs();
    const timeStore = dayjs(useAppSelector(selectSelectedDate));
    const dataHourGetter = useAppSelector(selectdataHourInfo);
    const isSelectedDate = useAppSelector(selectIsSelectedDate);
    // dayjs.extend(updateLocale);
    // dayjs.updateLocale("en", {
    //     weekStart: 1,
    // });
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const { data } = useReservationFrameQuery({ clinic_id: clinicId, treatment_menu_id: id, rsv_ymd: currentMonth.format('YYYYMM') });
    useEffect(() => {
        if (data) setDateInfo(data);
    }, [data]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (isSelectedDate) {
            if (dataHourGetter !== null ) {
                setSelectedBox(timeStore);
                setSelectedDate(timeStore);
                setCurrentMonth(timeStore);
            } else {
                setIsSelected(false);
                setSelectedBox(null);
            }
        }
    }, []);

    const dateCellRender = (date: Dayjs) => {
        const isCurrentMonth = date.isSame(currentMonth, "month");
        const isBefore = date.isBefore(nowDate, "day")
        const hasEvent = isDateAvailable(date, dateInfo?.availabilityData) || false;
        const isInArray = isDateInAvailabilityArray(date, dateInfo?.availabilityData);
        const isSelected = selectedBox && date.isSame(selectedBox, "day");
        return (
            <div className={`${isCurrentMonth && hasEvent && !isBefore ? "event-day" : "disabled-day"}`} style={{
                background: isSelected ? "#E8F4FF" : "transparent",
                width: isSelected ? "-webkit-fill-available" : "inherit",
                height: isSelected ? "-webkit-fill-available" : "inherit",
                display: isSelected ? "flex" : "inherit", alignItems: isSelected ? "center" : "inherit", justifyContent: isSelected ? "center" : "inherit"
            }}>
                <span>
                    <div className="day-number">{date.format("D")}</div>
                    {generateIcon(isCurrentMonth, isInArray, hasEvent)}
                </span>
            </div>
        );
    };

    const disabledDate = (current: Dayjs) => {
        const isBefore = current.isBefore(nowDate, "day")
        const hasEvent = isDateAvailable(current, dateInfo?.availabilityData) || false;
        return !hasEvent || isBefore;
    };

    const isDateInAvailabilityArray = (date: Dayjs, availabilityData: availabilityData) => {
        const fullDays = availabilityData?.fullDays;
        const notFullDays = availabilityData?.notFullDays;
        if ((fullDays && fullDays.includes(date.format(formatYYYYMMDDNoSlash))) || (notFullDays && notFullDays.includes(date.format(formatYYYYMMDDNoSlash)))) {
            return true;
        } else {
            return false;
        }
    };

    const generateIcon = (CheckDisableDays: boolean, isInArray: boolean, checkFullDays: boolean) => {
        if (CheckDisableDays && isInArray) {
            return <div className="div-icon">{!checkFullDays ? <img src={full} className="icon-full"/> : <img src={empty} className="icon-empty"/>}</div>
        }
    }

    const isDateAvailable = (date: Dayjs, filteredArray: availabilityData) => {
        const fullDays = filteredArray?.fullDays || [];
        const notFullDays = filteredArray?.notFullDays || [];
        if (fullDays.some((item: string) => dayjs(item, formatYYYYMMD).isSame(date, 'day'))) {
            return false;
        } else if (notFullDays.some((item: string) => dayjs(item, formatYYYYMMD).isSame(date, 'day'))) {
            return true;
        }
    };
    const prevBtnRender = (date: Dayjs) => {
        const isPastMonth = date.isSame(nowDate, "month") || date.isBefore(nowDate, "month");
        const disabled = isPastMonth;

        return (
            <Button type="primary" icon={<DoubleLeftOutlined />} className="button-prev text-bold-styles" disabled={disabled} onClick={handlePrevMonth} >前月</Button>
        );
    };

    const handlePrevMonth = () => {
        setCurrentMonth(currentMonth.subtract(1, "month"));
        setIsSelected(false);
        setSelectedBox(null);
    };

    const handleNextMonth = () => {
        setCurrentMonth(currentMonth.add(1, "month"));
        setIsSelected(false);
        setSelectedBox(null);
    };

    const handleSelectDate = (date: Dayjs) => {
        setSelectedDate(date);
        setSelectedBox(date);
        setCurrentMonth(date);
        setDataHourInfo({ clinic_id: clinicId, treatment_menu_id: id, rsv_ymd: date.format('YYYYMMDD') });
        if (!date.isSame(selectedBox, "day")) {
            dispatch(changeUserIndex(-1));
            dispatch(changeRadioIndex(-1));
            dispatch(changeDoctorSelectionList([]));
            dispatch(changeFilteredData([]));
            dispatch(changeDate(date));
            dispatch(changeDataHourInfo({ clinic_id: clinicId, treatment_menu_id: id, rsv_ymd: date.format('YYYYMMDD') }));
        }
    }

    return (
        <div className="full-calendar">
            <div className="header-calendar">
                {prevBtnRender(currentMonth)}
                <div>
                    <div className="label-year text-bold-styles">{currentMonth.format("YYYY")}年</div>
                    <div className="label-month text-bold-styles">{currentMonth.format("M")}月</div>
                </div>
                <Button type="primary" className="button-next text-bold-styles" onClick={handleNextMonth}>次月{<DoubleRightOutlined />}</Button>
            </div>
            <div>
                <Calendar
                    value={currentMonth}
                    onSelect={handleSelectDate}
                    cellRender={dateCellRender}
                    disabledDate={disabledDate}
                />
            </div>
        </div>
    );
};

export default CalendarBooking;
import { Controller } from 'react-hook-form';
import { Select } from 'antd';

const FormSelect = ({ ...props }) => {
  return (
    <Controller
      name={props.name}
      render={({ field }) => <Select 
        {...field}
        defaultValue={props.defaultValue}
        options={props.options}
        disabled={props.disabled}
      />}
    />
  )
}

export default FormSelect
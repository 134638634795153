import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import line from 'src/assets/image/line-icon.svg'
import { PharmacyInfo } from 'src/Network/Model/PharmacyInfo';
import { replaceWithBr } from 'src/Utils/textUtils';
import PharmacyGuide from 'src/Component/Modal/PharmacyGuide';

type Props = {
    detailPharmacy?: PharmacyInfo
  }
  
const NewDetailPharmacyInfo:React.FC<Props> = (props) => {
  const {detailPharmacy} = props;
  const { t } = useTranslation();
  const [showModalPharmacy, setShowModalPharmacy] = useState(false);
  const line_url = 'https://vnexpress.net/';
  return (
    <>
    <Row className='detailPharmacy' gutter={[{ xs: 16, sm: 16, md: 16 }, { xs: 16, sm: 16, md: 16 }]}>
      <Col xs={24} sm={24} md={12} className='detailPharmacy-left'>
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.pharmacy-name"), content: detailPharmacy?.title }} />
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.tel"), content: detailPharmacy?.tel }} />
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.fax"), content: detailPharmacy?.fax }} />
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.postal-code"), content: detailPharmacy?.address }} />
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.location"), content: detailPharmacy?.address }} />
      </Col>
      <Col xs={24} sm={24} md={12} className='detailPharmacy-right'>
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.open"), content: detailPharmacy?.open }} />
        <ItemInfo data={{ title: t("receive-at-pharmacy.modal-detail-pharmacy.holiday"), content: detailPharmacy?.holiday }} />
        <div className="QR-line-content">
          <div className='qrcode'>
            {/* {detailPharmacy?.line_url?.length ? ( */}
              <QRCode
                // value={detailPharmacy?.line_url}
                value={line_url}
                renderAs="svg"
                bgColor="black"
                fgColor="white"
                level="H"
                size={100}
                fill='#565C67'
              />
            {/* ) : null} */}
          </div>
          <div>
            <span className="text-normal-styles">{t("ReceptionInfo.register_adress_new.qr_quote")}</span>
            <span className="text-normal-styles">{t("ReceptionInfo.register_adress_new.line_quote")}</span>
          </div>
        </div>
        <div className="line-content-mobile">
          <Button
            onClick={() => { }}
            className={'line-button'}
          >
            <img src={line} alt="Line" />
            <span className="text-bold-styles">{t("ReceptionInfo.register_adress_new.line_button_mobile")}</span>
          </Button>
          <span className="text-normal-styles line-content-mobile-text">{t("ReceptionInfo.register_adress_new.line_button_mobile_quote")}</span>
        </div>
        <span>
          {t("ReceptionInfo.register_adress_new.line_link_quote")}
          <span onClick={() => setShowModalPharmacy(true)} style={{ color: '#308AE6', cursor: 'pointer' }}>
              {t("ReceptionInfo.register_adress_new.line_link")}
          </span>
        </span>
      </Col>
    </Row>
    {
      showModalPharmacy &&
      <PharmacyGuide changeVisibleModal={() => setShowModalPharmacy(false)} />
    }
    </>
  );
};

const ItemInfo = ({ data }: any) => {
    return (
        <div>
            <div className='itemTitle text-bold-styles'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.title)}></span>
            </div>
            <div className='itemContent'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.content)}></span>
            </div>
        </div>
    )
}
export default NewDetailPharmacyInfo;
import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';

const initialState = {
    confirmRegister: false,
    isResetRegister: true
}

const slice = createSlice({
  name: "confirmRegisterSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeConfirmRegister: (state, action) => {
      state.confirmRegister = action.payload;
    },
    changeIsResetRegister: (state, action) => {
      state.isResetRegister = action.payload;
    }
  },
});

export const selectConfirmRegister = (state:RootState) => state.common.confirmRegisterSlice.confirmRegister;
export const selectIsResetRegister = (state:RootState) => state.common.confirmRegisterSlice.isResetRegister;

export const {
  changeConfirmRegister
} = slice.actions;
export const {
  changeIsResetRegister
} = slice.actions;

export const ConfirmRegisterReducer = slice.reducer;
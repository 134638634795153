import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Radio } from 'antd';
import './styles.css';
import doctor_icon from '../../assets/image/doctor-avatar.svg';
import { formatTimeRange } from '../../Utils/CalculateHourResevationUtils';
import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { Modal } from 'antd';
import { User } from 'src/Network/Model/User';
import {
  changeDoctorSelectionList,
  changeFilteredData,
  changeRadioIndex,
  changeShowUserModal,
  changeTitleTimeLabel,
  changeUserIndex,
  changeUserList,
  selectDoctorSelectionList,
  selectRadioIndex,
  selectShowUserModal,
  selectTitleTimeLabel,
  selectUserIndex,
  selectUserList,
} from './slice';
import { RadioChangeEvent } from 'antd/lib';
import { MOBILE_SIZE } from 'src/constant';
import { selectdataHourInfo } from '../CalendarBooking/slice';
import { useReservationFrameQuery } from 'src/Network/Home/HomeClinicApi';

interface TimeBookingInfoProps {
  timeSelected: Dayjs;
  dataHourReservationFrame: any;
}

const TimeBooking: React.FC<TimeBookingInfoProps> = ({
  timeSelected,
  dataHourReservationFrame,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const daysOfWeek = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'];
  const [isMobile, setIsMobile] = useState(false);
  const indexMobileRadio = useAppSelector(selectRadioIndex);
  const showUserModal = useAppSelector(selectShowUserModal);
  const TimeLabel = useAppSelector(selectTitleTimeLabel);
  const doctorSelectionList = useAppSelector(selectDoctorSelectionList);
  const userList = useAppSelector(selectUserList);
  const index = useAppSelector(selectUserIndex);
  const dataHourGetter = useAppSelector(selectdataHourInfo) || [];
  const selectdataHourReservationFrame = dataHourReservationFrame
    ? dataHourReservationFrame
    : dataHourGetter;
  const { data } = useReservationFrameQuery({clinic_id: selectdataHourReservationFrame?.clinic_id, treatment_menu_id: selectdataHourReservationFrame?.treatment_menu_id, rsv_ymd: selectdataHourReservationFrame?.rsv_ymd});
  const filteredData = data?.hoursFrameData;
  const [selectedDoctorId, setSelectedDoctorId] = useState(0);

  useEffect(() => {
    if (filteredData) {
        dispatch(changeFilteredData(filteredData));
        const timeBookingElement = document.getElementById('timeline-booking');
        if (timeBookingElement) {
            const yOffset = timeBookingElement.getBoundingClientRect().top + window.scrollY - 90;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    }
    const handleResize = () => {
        setIsMobile(window.innerWidth <= MOBILE_SIZE);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, [filteredData]);

  const handleOk = () => {
    const selectItem =
      selectedDoctorId !== 0
        ? userList.find((i: User) => i?.user_id === selectedDoctorId)
        : { user_id: 0, user_name: t('time-booking-info-prof.doctor-default') };
    const doctorSelection = { id: index, selection: selectItem };
    const indexDoctor = doctorSelectionList.findIndex(
      (x: any) => x.id === index,
    );
    const newDoctorSelectionList = [...doctorSelectionList];
    if (indexDoctor >= 0) {
      newDoctorSelectionList[indexDoctor] = doctorSelection;
    } else {
      newDoctorSelectionList.push(doctorSelection);
    }
    dispatch(changeDoctorSelectionList(newDoctorSelectionList));
    dispatch(changeRadioIndex(index));
    dispatch(changeShowUserModal(false));
  };

  const handleCancel = () => {
    dispatch(changeShowUserModal(false));
  };

  const handleChangeRadio = (e: any) => {
    const radioIndex = e.target.value;
    dispatch(changeRadioIndex(radioIndex));
    const dataUser = (filteredData as any[])[radioIndex].users;
    if (dataUser.length === 1) {
      const selectItem = { user_id: dataUser[0]?.user_id, user_name: dataUser[0]?.user_name };
      const doctorSelection = { id: radioIndex, selection: selectItem };
      const indexDoctor = doctorSelectionList.findIndex(
        (x: any) => x.id === radioIndex,
      );
      const newDoctorSelectionList = [...doctorSelectionList];
      if (indexDoctor >= 0) {
        newDoctorSelectionList[indexDoctor] = doctorSelection;
      } else {
        newDoctorSelectionList.push(doctorSelection);
      }
      dispatch(changeDoctorSelectionList(newDoctorSelectionList));
    }
  };

  const handleChangeSelect = (index: number, value: number, opt: any[]) => {
    const selectItem =
      value !== 0
        ? opt.find((i: User) => i?.user_id === value)
        : { user_id: 0, user_name: t('time-booking-info-prof.doctor-default') };
    const doctorSelection = { id: index, selection: selectItem };
    const indexDoctor = doctorSelectionList.findIndex(
      (x: any) => x.id === index,
    );
    dispatch(changeRadioIndex(index));

    const newDoctorSelectionList = [...doctorSelectionList];
    if (indexDoctor >= 0) {
      newDoctorSelectionList[indexDoctor] = doctorSelection;
    } else {
      newDoctorSelectionList.push(doctorSelection);
    }
    dispatch(changeDoctorSelectionList(newDoctorSelectionList));
  };

  type GenerateSelectProps = {
    options: User[];
    index: number;
  };

  const GenerateSelect: React.FC<GenerateSelectProps> = ({
    options,
    index,
  }) => {
    const doctorSelection = doctorSelectionList.find(
      (i: any) => i.id === index,
    );
    if (options.length === 1) {
      return (
        <Select
          className="dropdown-selection"
          placeholder={t('time-booking-info-prof.doctor')}
          onClick={() =>
            handleChangeSelect(index, options[0]?.user_id || 0, options)
          }
          value={options[0]?.user_id}
          suffixIcon={null}
          open={false}
        >
          <Select.Option
            className="select-doctor"
            key={options[0]?.user_id}
            value={options[0]?.user_id}
            style={{ color: 'black !important' }}
          >
            {options[0]?.user_name}
          </Select.Option>
        </Select>
      );
    } else {
      return (
        <Select
          className="dropdown-selection"
          placeholder={t('time-booking-info-prof.doctor')}
          onChange={(value) => handleChangeSelect(index, value, options)}
          value={doctorSelection ? doctorSelection?.selection?.user_id : 0}
          defaultValue={t('time-booking-info-prof.doctor-default')}
          suffixIcon={<DownOutlined />}
        >
          <Select.Option
            className="select-doctor"
            value={0}
            style={{ color: 'black !important' }}
          >
            {t('time-booking-info-prof.doctor-default')}
          </Select.Option>
          {options.length > 0 ? (
            options.map((option: User) => (
              <Select.Option
                className="select-doctor"
                key={option?.user_id}
                value={option?.user_id}
                style={{ color: 'black !important' }}
              >
                {option?.user_name}
              </Select.Option>
            ))
          ) : (
            <></>
          )}
        </Select>
      );
    }
  };

  useEffect(() => {
    const doctorSelection = doctorSelectionList.find(
      (i: any) => i.id === index,
    );

    if (doctorSelection) {
      setSelectedDoctorId(doctorSelection?.selection?.user_id || 0);
    } else {
      setSelectedDoctorId(0);
    }
  }, [showUserModal]);

  type RadioSelectProps = {
    setSelectedDoctorId: (id: number) => void;
    selectedDoctorId: number;
  };

  const GenerateRadioSelect: React.FC<RadioSelectProps> = ({
    setSelectedDoctorId,
    selectedDoctorId,
  }) => {
    const handleChangeDoctorRadio = ({
      target: { value },
    }: RadioChangeEvent) => {
      setSelectedDoctorId(value);
    };

    return (
      <>
        <div className="label-time-selected">
          <span className="text-bold-styles">
            {t('time-booking-info-prof.date-and-time-selected')}
          </span>
          <span className="text-normal-style">
            {timeSelected.format('YYYY')}年{timeSelected.format('M')}月
            {timeSelected.format('D')}日{daysOfWeek[timeSelected.day()]}
          </span>
          <span>{TimeLabel}</span>
        </div>
        <div className="text-normal-style title-choosing">
          {t('time-booking-info-prof.please-choosing-doctor')}
        </div>
        <div className="choosing-doctor-list">
          <Radio.Group
            onChange={handleChangeDoctorRadio}
            value={selectedDoctorId}
            className="choosing-user-radio"
          >
            <div className="border-select-time" style={{ display: 'flex' }}>
              <Radio
                className="select-doctor"
                value={0}
                style={{ color: 'black !important' }}
              >
                <div className="select-book-time">
                  <div className="select-book-time2">
                    <div className="icon-doc2">
                      <img src={doctor_icon} alt="Doctor Icon" />
                    </div>
                    <div className="name">
                      <div className="lower-name text-normal-styles">
                        {t('time-booking-info-prof.doctor-default')}
                      </div>
                    </div>
                  </div>
                </div>
              </Radio>
            </div>
            {userList.length > 0 ? (
              userList.map((option: User, index: number) => (
                <div key={index} className="border-select-time" style={{ display: 'flex' }}>
                  <Radio
                    className="select-doctor"
                    value={option?.user_id}
                    style={{ color: 'black !important' }}
                  >
                    <div className="select-book-time">
                      <div className="select-book-time2">
                        <div className="icon-doc2">
                          <img src={doctor_icon} alt="Doctor Icon" />
                        </div>
                        <div className="name">
                          <div className="lower-name text-normal-styles">
                            {option?.user_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Radio>
                </div>
              ))
            ) : (
              <></>
            )}
          </Radio.Group>
        </div>
      </>
    );
  };

  const renderBookingTimes = () => {
    const handleClickShowUser = (
      userList: User[],
      index: number,
      titleTime: string,
    ) => {
      dispatch(changeShowUserModal(true));
      dispatch(changeUserList(userList));
      dispatch(changeUserIndex(index));
      dispatch(changeTitleTimeLabel(titleTime));
    };

    return isMobile ? (
      <Radio.Group
        value={indexMobileRadio}
        onChange={handleChangeRadio}
        style={{ display: 'inherit' }}
      >
        {filteredData?.map((data: any, index: number) => {
          const upperLimitFrame = data?.upper_limit_frame ?? 0;
          const consumptionFrame =
            data?.consumption?.rsv_consumption_frame ?? 0;
          const disableFrame = data?.disable_frame;
          const isSelectable = (upperLimitFrame > consumptionFrame) && !disableFrame;
          const titleTimeLabel = formatTimeRange(
            data?.rsv_time,
            data?.clinics.exm_time,
          );
          const doctorSelection = doctorSelectionList.find(
            (i: any) => i.id === index,
          );
          const userToOption = userList.map((item: User) => {
            return { value: item.user_id, label: item.user_name };
          });
          const optionList = [
            { value: 0, label: t('time-booking-info-prof.doctor-default') },
            ...userToOption,
          ];
          return (
            <div className="border-booking-time" key={data?.id}>
              <Radio
                value={index}
                style={{ display: 'flex' }}
                disabled={!isSelectable}
              >
                <div className="booking-time1">
                  <div className="booking-time2-mobile">
                    {titleTimeLabel}
                    <div className="name-mobile">
                      {isSelectable ? (
                        <>
                          <Select
                            className="dropdown-selection"
                            placeholder={t('time-booking-info-prof.doctor')}
                            key={index}
                            options={optionList}
                            value={
                              data?.users.length !== 1
                                ? doctorSelection
                                  ? doctorSelection?.selection?.user_name
                                  : t('time-booking-info-prof.doctor-default')
                                : data?.users[0]?.user_name
                            }
                            suffixIcon={
                              data?.users.length !== 1 ? <DownOutlined /> : null
                            }
                            defaultValue={0}
                            onClick={() =>
                              data?.users.length !== 1
                                ? handleClickShowUser(
                                    data?.users,
                                    index,
                                    titleTimeLabel,
                                  )
                                : {}
                            }
                            open={false}
                          ></Select>
                        </>
                      ) : (
                        <Select
                          className="dropdown-selection"
                          placeholder={t('time-booking-info-prof.doctor')}
                          suffixIcon={<DownOutlined />}
                          disabled={!isSelectable}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Radio>
            </div>
          );
        })}
      </Radio.Group>
    ) : (
      <Radio.Group
        value={indexMobileRadio}
        onChange={handleChangeRadio}
        style={{ display: 'inherit' }}
      >
        {filteredData?.map((data: any, index: number) => {
          const upperLimitFrame = data?.upper_limit_frame ?? 0;
          const consumptionFrame =
            data?.consumption?.rsv_consumption_frame ?? 0;
          const disableFrame = data?.disable_frame;
          const isSelectable = (upperLimitFrame > consumptionFrame) && !disableFrame;
          const titleTimeLabel = formatTimeRange(
            data?.rsv_time,
            data?.clinics.exm_time,
          );

          return (
            <div
              className="border-booking-time"
              key={data?.id || index.toString()}
            >
              <Radio
                value={index}
                style={{ display: 'flex' }}
                disabled={!isSelectable}
              >
                <div className="booking-time1">
                  <div className="booking-time2" key={'test'}>
                    {titleTimeLabel || ''}
                  </div>
                </div>
              </Radio>
              <div className="booking-time3">
                <div className="icon-doc">
                  <img src={doctor_icon} alt="Doctor Icon" />
                </div>
                <div className="name-pc">
                  {isSelectable ? (
                    <GenerateSelect options={data?.users} index={index} />
                  ) : (
                    <Select
                      className="dropdown-selection"
                      placeholder={t('time-booking-info-prof.doctor')}
                      suffixIcon={<DownOutlined />}
                      disabled={!isSelectable}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Radio.Group>
    );
  };

  return (
    <div className="timeline-booking" id="timeline-booking">
      <div className="name-booking">
        <p className="name-context text-bold-styles">
          {timeSelected.format('M')}月{timeSelected.format('D')}日
        </p>
        <p className="day-of-week-context text-bold-styles">
          {daysOfWeek[timeSelected.day()]}
        </p>
      </div>
      <div style={{ width: '100%' }}>{renderBookingTimes()}</div>

      <Modal
        className="chooseDoctorModal"
        title={t('time-booking-info-prof.select-option')}
        open={showUserModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('time-booking-info-prof.ok-button') + ' '}
        cancelText={t('time-booking-info-prof.cancel-button')}
      >
        <GenerateRadioSelect
          selectedDoctorId={selectedDoctorId}
          setSelectedDoctorId={setSelectedDoctorId}
        />
      </Modal>
    </div>
  );
};

export default TimeBooking;

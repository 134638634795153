import './styles.css';
import UserIcon from 'src/assets/image/user.svg';
import Logout from 'src/assets/image/logout.svg';
import {
  useLogoutUserMutation,
  useLoginUserMutation,
} from 'src/Network/Home/HomeClinicApi';
import { useEffect, useState } from 'react';
import { User } from 'src/Network/Model/User';
import { changeIsLogin, selectIsLogin } from '../slice';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  ACCESS_TOKEN,
  DOCTOR_ACCESS_TOKEN,
  KEY_DOCTOR_USER,
  KEY_USER,
} from 'src/common/Constant';
import { revertAll, useAppSelector } from 'src/Redux/hooks';
import { isDoctorRoute, isHistoryRoute, linkToLoginDoctorWP, linkToLoginUserWP } from 'src/common/Libs';
import { useDoctorLoginMutation } from 'src/Network/Home/DoctorApi';
import { ClinicUser } from 'src/Network/Model/ClinicUser';

const LoggedIn = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const clinic_id = searchParams.get('clinic_id');
  const [userLogout] = useLogoutUserMutation();
  const session_id = searchParams.get('session_id') ?? null;
  const clinic_user_id = searchParams.get('clinic_user_id') ?? null;
  const [userLogin] = useLoginUserMutation();
  const isLogedIn = useAppSelector(selectIsLogin);
  const [doctorLogin] = useDoctorLoginMutation();
  const [user, setUser] = useState<User>();
  const [clinicUser, setClinicUser] = useState<ClinicUser>();
  const logOut = () => {
    userLogout({}).then(async () => {
      localStorage.clear();
      dispatch(revertAll());
      await logoutWP();
      if (isHistoryRoute()) {
        window.location.replace(linkToLoginUserWP());
        return;
      }
      if (isDoctorRoute()) {
        // await logoutWP();
        window.location.replace(linkToLoginDoctorWP(true));
      } else if (clinic_id) {
        // await logoutWP();
        window.location.replace(
          process.env.REACT_APP_APP_URL + '?clinic_id=' + clinic_id,
        );
      }
    });
  };
  useEffect(() => {
    if (isLogedIn) {
      updateUser();
    }
  }, [isLogedIn]);

  const updateUser = () => {
    if (isDoctorRoute()) {
      const json = localStorage.getItem(KEY_DOCTOR_USER);
      if (json) {
        setClinicUser(JSON.parse(json));
      }
    } else {
      const json = localStorage.getItem(KEY_USER);
      if (json) {
        setUser(JSON.parse(json));
      }
    }
  }

  const logoutWP = async () => {
    try {
      if (isDoctorRoute()) {
        let logoutURL = process.env.REACT_APP_WP_DT_LOGOUT_URL;
        if (logoutURL) {
          await fetch(logoutURL, {
            method: 'GET',
            credentials: 'include'
          });
        }
      } else {
        let logoutURL = process.env.REACT_APP_WP_LOGOUT_URL;
        if (logoutURL) {
          await fetch(logoutURL, {
            method: 'GET',
            credentials: 'include'
          });
        }
      }
    } catch (error) {
      //TODO: handle error
    }
  }
  useEffect(() => {
    // if (isDoctorRoute()) {
    //   const json = localStorage.getItem(KEY_DOCTOR_USER);
    //   if (json) {
    //     setClinicUser(JSON.parse(json));
    //   }
    // } else {
    //   const json = localStorage.getItem(KEY_USER);
    //   if (json) {
    //     setUser(JSON.parse(json));
    //   }
    // }
    updateUser();
  }, []);

  useEffect(() => {
    if (isDoctorRoute()) {
      if (session_id) {
        // doctorLogin({ session_id, clinic_user_id }).then((data: any) => {
        //   const user: ClinicUser = data?.data?.data?.user;
        //   if (user && user?.status === 1) {
        //     localStorage.setItem(
        //       KEY_DOCTOR_USER,
        //       JSON.stringify(data?.data?.data?.user),
        //     );
        //     localStorage.setItem(
        //       DOCTOR_ACCESS_TOKEN,
        //       data?.data?.data?.access_token,
        //     );
        //     dispatch(changeIsLogin(true));
        //   } else {
        //     console.log(linkToLoginDoctorWP());
        //     window.location.href = linkToLoginDoctorWP();
        //   }
        // });
      }
    } else {

      // if (session_id) {
      //   userLogin({ session_id }).then((data: any) => {
      //     const user: User = data?.data?.user;
      //     if (user && user?.status === 1) {
      //       localStorage.setItem('user', JSON.stringify(data?.data?.user));
      //       localStorage.setItem(ACCESS_TOKEN, data?.data?.access_token);
      //       dispatch(changeIsLogin(true));
      //     }
      //   });
      // }
    }
  }, []);

  return (
    <div className="userLoggedIn">
      <img src={UserIcon} alt="" style={{ paddingRight: 8 }} />
      <span className="text-normal-styles">
        &nbsp;{user?.user_name || clinicUser?.user_name}
      </span>
      <img onClick={logOut} className="logout" src={Logout} alt="Sign out" />
    </div>
  );
};

export default LoggedIn;

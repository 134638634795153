import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';
import { RootState } from 'src/Redux/store';
import { changeConfirmRegister, changeIsResetRegister } from 'src/Component/ConfirmRegister/slice';
const initialState = {
    showModalRegister: false,
    showModalLogin: {
      show: false,
      redirectUrl: null,
    },
    isLogin: false,
    showHeaderDropdown: false,
    isRequestLogin: false,
    isForgetPassword: false,
    isFirstExm: false,
    isRequestLoginUrl: false
}

const slice = createSlice({
  name: "showModalUserSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeModalRegister : (state, action) => {
        state.showModalRegister = action.payload
    },
    changeModalLogin : (state, action) => {
      state.showModalLogin = action.payload
    },
    changeIsLogin : (state, action) => {
      state.isLogin = action.payload
    },
    toggleHeaderDropdown : (state, action) => {
      state.showHeaderDropdown = action.payload ? !state.showHeaderDropdown : false  ;
    },
    changeRequestLogin : (state, action) => {
      state.isRequestLogin = action.payload
    },
    changeForgetPassword : (state, action) => {
      state.isForgetPassword = action.payload
    },
    changeFirstExm : (state, action) => {
      state.isFirstExm = action.payload
    },
    closeAllModals : (state) => {
      state.showHeaderDropdown = false;
      state.showModalRegister = false;
      // state.showModalLogin = {
      //   show: false,
      //   redirectUrl: null
      // };
      changeConfirmRegister(false);
      changeIsResetRegister(false);
    },
    changeIsRequestLoginUrl : (state, action) => {
      state.isRequestLoginUrl = action.payload
    },
  },
});

export const selectModalRegister = (state:RootState) => state.showModalUserSlice.showModalRegister
export const selectModalLogin = (state:RootState) => state.showModalUserSlice.showModalLogin
export const selectIsLogin = (state:RootState) => state.showModalUserSlice.isLogin
export const selectHeaderDropdown = (state:RootState) => state.showModalUserSlice.showHeaderDropdown
export const selectIsRequestLogin = (state:RootState) => state.showModalUserSlice.isRequestLogin
export const selectIsForgetPassword = (state:RootState) => state.showModalUserSlice.isForgetPassword
export const selectIsFirstExm = (state:RootState) => state.showModalUserSlice.isFirstExm
export const selectIsRequestLoginUrl = (state:RootState) => state.showModalUserSlice.isRequestLoginUrl

export const {
  changeModalRegister,
  changeModalLogin,
  changeIsLogin,
  toggleHeaderDropdown,
  changeRequestLogin,
  changeForgetPassword,
  changeFirstExm,
  closeAllModals,
  changeIsRequestLoginUrl
} = slice.actions;

export const ShowModalUserReducer = slice.reducer;
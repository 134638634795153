import { Col, Row } from "antd";
import './styles.css';
import React, { useEffect, useState, } from "react";
import { Carousel } from 'antd';
import { updateLink } from "../../Utils/linkMovUtils";
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from "src/Utils/textUtils";

type Props = {
    dataInfomation: any,
}

const ClinicDetail: React.FC<Props> = ({ dataInfomation }) => {
    const { t } = useTranslation();
    const [link, setLink] = useState<string | Array<any>>('');

    useEffect(() => {
        const updatedLink = updateLink(dataInfomation);
        setLink(updatedLink);
    }, [dataInfomation]);

    const renderMedia = () => {
        if (!Array.isArray(link)) {
            if (link.startsWith(t("url-video.youtube")) || link.startsWith(t("url-video.vimeo"))) {
                return <iframe loading="lazy" src={link} title={dataInfomation?.title1} className="videoFrame"></iframe>;
            } else {
                return <img className="image-type1" src={link} alt={dataInfomation?.title1} />;
            }
        } else {
            return <Carousel autoplay>
                {link.map((media, index) => (
                    <div key={index}>
                        <img className="image-type1" src={media} alt="" />
                    </div>
                ))}
            </Carousel>
        }
    };

    return (
        <div className="clinicDetail">
            <Row gutter={[{ xs: 40, sm: 40, md: 40 }, { xs: 24, sm: 24, md: 24 }]}>
                <Col xs={24} sm={24} md={10} lg={8} className="video">
                    {renderMedia()}
                </Col>
                <Col xs={24} sm={24} md={14} lg={16}>
                    <div className="text-normal-styles detail" dangerouslySetInnerHTML={replaceWithBr(dataInfomation?.text)}></div>
                </Col>
            </Row>
        </div>
    );
}

export default ClinicDetail;
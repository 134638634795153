import './styles.css';
import {Button, Card, Col, Modal, Radio, Row, Select, Tag, message} from "antd";
import {useTranslation} from "react-i18next";
import UploadCardImage from "./UploadCardImage";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCreateKaruteInsuranceMutation } from 'src/Network/Home/HomeClinicApi';
import { useState } from 'react';

const APP = ({ isOpen, onClose }: {
	isOpen: boolean,
	onClose: Function
}) => {
	const {t} = useTranslation();
	const [createKaruteInsurance] = useCreateKaruteInsuranceMutation();
	const [previewImage1, setPreviewImage1] = useState();
	const handleClose = () => {
		onClose();
	};

	const onSubmit = (data: any) => {
		const bodyFormData = new FormData();
		bodyFormData.append('insurance_img1', data.insurance_img1);
		bodyFormData.append('insurance_img2', data.insurance_img2);

		createKaruteInsurance(bodyFormData).then((res: any) => {
			if (res.data.error)
				message.error(t('message.MESS_ERR_CANNOT_REGIS'));
			else
				message.success(t('message.MESS_CMN_INF_REGIS_SUCCESS'));
		});
	}
	 
	const handleClick = () => {
		const btnElement = document.getElementById('submitButton');
		if (btnElement)
			btnElement.click();
	}

	const handleRenderFooter = () => {
		return (
			<div style={{display:'flex', justifyContent: 'center'}}>
				<Button  className='btnCf' onClick={handleClick}>
					{t("register-insurance.btn-register")}
				</Button>
			</div>
		)
	}

	const schema = yup.object({
        insurance_img1: yup
			.mixed()
			.required(t('message.MESS_CMN_ERR_MISS_SELECT_REQUIRED').replace('{0}', t('register-insurance.img-card.front'))),
		insurance_img2: yup
			.mixed()
			.required(t('message.MESS_CMN_ERR_MISS_SELECT_REQUIRED').replace('{0}', t('register-insurance.img-card.back'))),
		print_flag: yup
			.string()
			.required()
	});

	const methods = useForm<any>({
		defaultValues: null,
		resolver: yupResolver(schema),
		mode: 'all'
	});

	return (
		<Modal centered
								className={'registerInsurance'}
								open={isOpen}
								footer={handleRenderFooter()}
								title={t('register-insurance.title')}
								onCancel={handleClose}>
		<FormProvider {...methods}>
		<form onSubmit={methods.handleSubmit(onSubmit)}>
		{/* <div style={{marginTop: "20px"}}>{t('register-insurance.info1')}</div>
		{t('register-insurance.')}
		<Button type={"text"} className={"btnAdd"} style={{marginTop: "20px"}}>{t('register-insurance.btn-add')}</Button> */}
		<Card
			style={{marginTop: 16}}
			type="inner"
			title={
				<div>
					<div className={"title"}>
						{t('register-insurance.card1-title')}
						<Tag className="text-bold-styles required">
							{t('register-insurance.required')}
						</Tag>
					</div>
					<div className={"info"}>{t('register-insurance.card1-info')}</div>
				</div>}
		>
			<Row gutter={[{}, { xs: 20, sm: 20, md: 20 }]}>
				<Col span={24}>
					<Row style={{display: "flex", justifyContent: "space-evenly"}}>
						<Col xs={10} sm={10} md={10}>
							<UploadCardImage isFront methods={methods} previewImageProp={previewImage1}/>
						</Col>
						<Col xs={10} sm={10} md={10}>
							<UploadCardImage methods={methods}/>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<div className='insuranceLimitDateTitle'>    
						{t('register-insurance.limit-date-time')}
					</div>
				</Col>
				<Col span={24}>
					<div style={{padding: "0 40px", fontSize: "15px"}}>    
						{t('register-insurance.input-time-info')}
					</div>
					<div style={{padding: "0 40px", fontSize: "18px"}}>
						<Select/>
						{t('register-insurance.year')}
						<Select/>
						{t('register-insurance.month')}
						<Select/>
						{t('register-insurance.date')}
					</div>
				</Col>
				<Col span={24}>
					<div className={"approveCopy"}>
						<Row gutter={[{}, { xs: 20, sm: 20, md: 20 }]}>
							<Col span={24} style={{fontSize: "15px"}}>
								{t('register-insurance.approve-copy')}
							</Col>
							<Col span={24}>
								<Radio.Group style={{fontSize: "15px"}}>
									<Radio>{t('register-insurance.yes')}</Radio>
									<Radio>{t('register-insurance.no')}</Radio>
								</Radio.Group>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</Card>
		{/* <Card title={<div>
			<div className={"title"}>{t('register-insurance.card2-title')}</div>
			<div className={"info"}>{t('register-insurance.card2-info')}</div>
		</div>}>
			<div className={"cardContent"}>
				<Button type={"text"} className={"btnAdd"}>{t('register-insurance.card2-btn')}</Button>
			</div>
		</Card>
		<div style={{background: "#F9F9F9", padding: "40px", boxShadow: "0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20)"}}>
			<p style={{color: "#000000", textAlign: "center"}}>{t('register-insurance.card7-info1')}</p>
			<p style={{textAlign: "center"}}>
				<span style={{color: "#000000", fontWeight: 700}}>
				{t('register-insurance.card7-info2')}
			</span>
				{t('register-insurance.card7-info3')}
				<span style={{color: "#308AE6"}}>
					{t('register-insurance.card7-info4')}
				</span>
				{t('register-insurance.card7-info5')}
			</p>
		</div>
		<Card title={<div>
			<div className={"title"}>{t('register-insurance.card3-title')}</div>
			<div className={"info"}>{t('register-insurance.card3-info')}</div>
			<div className={"info"} style={{color: "#308AE6"}}>
				<Tag className="text-bold-styles help" style={{marginLeft: 0}}>
					{t('register-insurance.help')}
				</Tag>
				{t('register-insurance.card3-info2')}</div>
		</div>}>
			<div className={"cardContent"}>
				<Button type={"text"} className={"btnAdd"}>{t('register-insurance.card3-btn')}</Button>
			</div>
		</Card>
		<Card title={<div>
			<div className={"title"}>{t('register-insurance.card4-title')}
			</div>
			<div className={"info"}>{t('register-insurance.card4-info')}<Tag className="text-bold-styles help">
				{t('register-insurance.help')}
			</Tag></div>
		</div>}>
			<div className={"cardContent"}>
				<Button type={"text"} className={"btnAdd"}>{t('register-insurance.card4-btn')}</Button>
			</div>
		</Card>
		<Card title={<div>
			<div className={"title"}>{t('register-insurance.card5-title')}</div>
			<div className={"info"}>{t('register-insurance.card5-info')}<Tag className="text-bold-styles help">
				{t('register-insurance.help')}
			</Tag></div>
		</div>}>
			<div className={"cardContent"}>
				<Button type={"text"} className={"btnAdd"}>{t('register-insurance.card5-btn')}</Button>
			</div>
		</Card>
		<Card title={<div>
			<div className={"title"}>{t('register-insurance.card6-title')}</div>
			<div className={"info"}>{t('register-insurance.card6-info')}</div>
		</div>}>
			<div className={"cardContent"}>
				<Input style={{width: 400, height: 58    }} type={"text"}/>
			</div>
		</Card> */}
		<button type='submit' id='submitButton' style={{display: 'none'}} />
	</form>
	</FormProvider>
	</Modal>
	)
}

export default APP;
import { useEffect, useState } from 'react';
import ichashoku from 'src/assets/image/ichashoku.svg';
import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';
import './styles.css';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { changeIsLogin, selectIsLogin, selectHeaderDropdown, changeRequestLogin, changeIsRequestLoginUrl } from './slice';
import CardHeaderMenu from 'src/Component/CardHeaderMenu';
import { useLazyUserProfileQuery, useLoginUserMutation, useRsvInfoDetailByIdPatientQuery } from 'src/Network/Home/HomeClinicApi';
import { ACCESS_TOKEN, KEY_USER, WP_SESSION } from 'src/common/Constant';
import { User } from 'src/Network/Model/User';
import { isDoctorRoute, isHistoryRoute, linkToLoginUserWP, linkToWP } from 'src/common/Libs';
// import { useDoctorLoginMutation } from 'src/Network/Home/DoctorApi';

const Header = () => {
  const dispatch = useAppDispatch();
  const [queryParams] = useSearchParams();
  const isLogedIn = useAppSelector(selectIsLogin);
  const showHeaderDropdown = useAppSelector(selectHeaderDropdown);
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id') ?? null;
  const isLogin = queryParams.get('isLogin');
  const reservation_info_id = queryParams.get('reservation_info_id');
  const reception_id = queryParams.get('reception_id');
  const [isDoctor, setDoctor] = useState(false);
  // const clinic_user_id = searchParams.get('clinic_user_id' ?? null);
  const [userLogin] = useLoginUserMutation();
  // const [validateTokenSession] = useLazyValidateTokenSessionQuery();
  const [userProfile] = useLazyUserProfileQuery();
  const getRsvInfoById = useRsvInfoDetailByIdPatientQuery({id: Number(reservation_info_id)}, {skip: !reservation_info_id});
  // const [doctorLogin] = useDoctorLoginMutation();
  const user_id = getRsvInfoById?.data?.data?.rsvInfo?.user_id;
  const json = localStorage.getItem(KEY_USER)??"{}";
  // const isAdminRoute = () => {
  //   return window.location.pathname.includes('/admin');
  // }
  // const [user, setUser] = useState<User>();

  // const linkToWP = () => {
  //   return process.env.REACT_APP_WP_URL || window.location.origin + "/error";
  // }

  useEffect(() => {
    const _isDoctor = isDoctorRoute();
    setDoctor(_isDoctor);
    if (_isDoctor) {
      //removed logic
      //check doctor login
    } else {
      //check user login
      // let old_session = localStorage.getItem(WP_SESSION) ?? null;
      if (session_id) {
        userLogin({ session_id }).then((data: any) => {
          const _user: User = data?.data?.user;
          if (_user && _user?.status === 1) {
            // setUser(_user)
            localStorage.setItem(KEY_USER, JSON.stringify(_user));
            localStorage.setItem(WP_SESSION, session_id);
            if (data?.data?.access_token) {
              localStorage.setItem(ACCESS_TOKEN, data?.data?.access_token);
            }
            dispatch(changeIsLogin(true));
            checkHistoryRoute();
          } else {
            localStorage.removeItem(KEY_USER);
            dispatch(changeIsLogin(false));
            if (isHistoryRoute()) {
              window.location.replace(linkToLoginUserWP());
            }
          }
        });
      } else {
        // trường hợp đi từ mail DT-09 or đi từ PT-01
        if (
          (reception_id && user_id && user_id === JSON.parse(json).user_id) ||         // user_id trong mail = user_id đang login
          isLogin ||                                                                   // case setting password or resetpassword
          (reception_id === '' && user_id && user_id === JSON.parse(json).user_id) ||  // user_id trong mail = user_id đang login
          !reservation_info_id                                                         // đi từ PT-01
        ) {
          userProfile({}).then(data => {
            if (data?.data?.data) {
              localStorage.setItem(KEY_USER, JSON.stringify(data?.data?.data));
              dispatch(changeIsLogin(true));
              checkHistoryRoute();
            } else {
              localStorage.removeItem(KEY_USER);
              localStorage.removeItem(ACCESS_TOKEN);
              dispatch(changeIsLogin(false));
              if (isHistoryRoute()) {
                window.location.replace(linkToLoginUserWP());
              }
            }
          })
        }
        // const json = localStorage.getItem(KEY_USER);
        // if (json) {
        //   // const _user = JSON.parse(json);
        //   // setUser(_user);
        //   dispatch(changeIsLogin(true));
        // }
        // checkHistoryRoute();
      }
    }
  }, []);
  useEffect(() => {
    // yêu cầu login với user login khác với user đã login or trường hợp bệnh nhân chưa có tài khoản
    if (getRsvInfoById) {
      const diffUserId = reservation_info_id && user_id && user_id !== JSON.parse(json).user_id;
      const noAccount = reception_id === '' && !isLogin && user_id === null;
      if (diffUserId || noAccount) {
        if (localStorage.getItem(ACCESS_TOKEN)) {
          localStorage.removeItem(ACCESS_TOKEN);
        }
        localStorage.removeItem(KEY_USER);
        dispatch(changeIsLogin(false));
        if (noAccount) {
          dispatch(changeRequestLogin(true));
        }
        if (diffUserId) {
          dispatch(changeIsRequestLoginUrl(true));
        }
      } else {
        dispatch(changeIsLogin(true));
        dispatch(changeRequestLogin(false));
      }
    }
  }, [user_id])
  const checkHistoryRoute = () => {
    const userIdFromParam = queryParams.get('user_id');
    const itemUser = localStorage.getItem(KEY_USER) as string;
    const userIdFromStorage = JSON.parse(itemUser)?.user_id;
    if (isHistoryRoute() && userIdFromParam != userIdFromStorage) {
      window.location.replace(linkToLoginUserWP());
    }
  }

  const renderUser = () => {
    if (!isDoctor) {
      return (
        <div className="header-container">
          <div className="header">
            <div>
              <div className="ichashoku">
                <Link to={linkToWP()}>
                  <img src={ichashoku} alt="" />
                </Link>
              </div>
            </div>
            {isLogedIn ? <LoggedIn /> : <LoggedOut />}
          </div>
          {!isLogedIn && showHeaderDropdown && (
            <div className="dropDownMenu">
              <CardHeaderMenu />
            </div>
          )}
        </div>
      );
    }
    //return header doctor
    return (
      <div className="header-container">
        <div className="header">
          <div>
            <div className="ichashoku">
              <Link to={linkToWP()}>
                <img src={ichashoku} alt="" />
              </Link>
            </div>
          </div>
          {isLogedIn ? <LoggedIn /> : <></>}
        </div>
      </div>
    );
  };
  return renderUser();
};

export default Header;

import { InfoCircleOutlined } from '@ant-design/icons';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'src/constant';

type Props = {
    rsv_fee: string
}
const BookingPaymentInfo:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {rsv_fee} = props;
    return (
        <div className="payment-info-container">
            <div className="payment-info-head">
                <div>
                    <div>
                        {t("payment-info.reservation-fee")}<span>({t("payment-info.tax-included")})</span>
                    </div>
                    <div>
                        <span>{t("payment-info.symbol_money")}</span>{currencyFormat(Number(rsv_fee))}
                    </div>
                </div>
            </div>
            <div className="payment-info-note">
                <InfoCircleOutlined />
                <span>{t("payment-info.note")}</span>
            </div>
        </div>
    )
}

export default BookingPaymentInfo
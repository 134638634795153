import { Button, ConfigProvider, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './styles.css';
import { useListReservationInfoQuery } from 'src/Network/Home/HomeClinicApi';
import { ReservationInfo } from 'src/Network/Model/ReservationInfo';
import { useEffect, useState } from 'react';
import { formatDate } from 'src/Utils/textUtils';
import { useTranslation } from 'react-i18next';
import CustomPagination from '../CustomPagination';
import { useSearchParams } from 'react-router-dom';
import { KEY_USER } from 'src/common/Constant';
import { linkToLoginUserWP } from 'src/common/Libs';

interface DataType {
  key?: number;
  date: string;
  nameClinic: string;
  address: string;
  doctor: string;
  clinicId?: number;
}

const HistoryContent = () => {
  const { t } = useTranslation();
  const pageSize = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams] = useSearchParams();
  const userIdFromParam = queryParams.get('user_id');

  const listReservation = useListReservationInfoQuery({
    user_id: userIdFromParam,
    page: currentPage
  });
  const dataList = listReservation?.data?.data;

  const data: DataType[] = dataList?.map(
    (item: ReservationInfo, index: number) => {
      return {
        key: (currentPage - 1) * pageSize + (index + 1),
        date: formatDate(item.rsv_ymd),
        nameClinic: item.clinic.clinic_name as string,
        address: ((((item.clinic.prefecture?.prefectures_name as string) +
          item.clinic.city?.city_name) as string) +
          item.clinic.address) as string,
        doctor: item.clinic_user?.user_name as string,
        clinicId: item?.clinic_id
      };
    }
  );
  const totalItems = listReservation?.data?.paginate?.total;
  useEffect(() => {
    listReservation?.refetch();
  }, [currentPage]);
  let locale = {
    emptyText: <span className="emptyText">{t("reservation-info.emptyText")}</span>,
  };
  const columns: ColumnsType<DataType> = [
    {
      title: t('reservation-info.title.date'),
      dataIndex: 'date',
      key: 'date',
      width: '320px',
    },
    {
      title: t('reservation-info.title.nameClinic'),
      dataIndex: 'nameClinic',
      key: 'nameClinic',
      width: '320px',
      render: (_, record) => <a target='_blank' rel="noreferrer" className="name-clinic" href={process.env.REACT_APP_WP_URL + `/clinicdetail/?clinic_id=${record.clinicId}`}>{record.nameClinic}</a>
    },
    {
      title: t('reservation-info.title.address'),
      dataIndex: 'address',
      key: 'address',
      width: '320px',
    },
    {
      title: t('reservation-info.title.doctor'),
      dataIndex: 'doctor',
      key: 'doctor',
      width: '320px',
    },
    {
      title: t('reservation-info.title.register'),
      dataIndex: 'register',
      key: 'register',
      align: 'center',
      width: '160px',
      render: (_, record) => (
        <Button
          className="btn-reservation"
          type="primary"
          ghost
          href={`${process.env.REACT_APP_APP_URL}?clinic_id=${record.clinicId}`}
          target="_blank"
        >
          {t('reservation-info.button')}
        </Button>
      ),
    },
  ];
  useEffect(() => {
    if (currentPage) {
      const tableHistory = document.querySelectorAll('.table-history')[0];
      const listItems = tableHistory?.querySelectorAll('.ant-pagination-item');
      const itemActive = tableHistory?.querySelectorAll('.ant-pagination-item-active');
      const titleActive = itemActive[0]?.getAttribute('title');
      let titleLastItem = '0';
      
      if (listItems.length > 3) {
        const classLastItemname = listItems[listItems.length - 1].className;
        const hasItemLast = classLastItemname.includes('item-last');
        if (!hasItemLast) {
          listItems[listItems.length - 1].className = classLastItemname + ' item-last';
        }
        titleLastItem = listItems[listItems.length - 1].getAttribute('title') as string;
      }

      if (Number(titleActive) >= 3) {
        const classFirstItemname = listItems[0].className;
        const hasItemNone = classFirstItemname.includes('item-first');
        if (!hasItemNone) {
          listItems[0].className = classFirstItemname + ' item-first';
        }
      } else {
        const firstItem = tableHistory?.querySelectorAll('.item-first');
        let firstClassItem = firstItem[0]?.className;
        if (firstClassItem) {
          const classArray = firstClassItem.split(' ');
          const filteredClassArray = classArray.filter(className => className !== 'item-first');
          firstClassItem = filteredClassArray.join(' ');
          firstItem[0].className = firstClassItem;
        }
      }

      if (Number(titleActive) >= Number(titleLastItem) -1) {
        const lastItem = tableHistory?.querySelectorAll('.item-last');
        let lastClassItem = lastItem[0]?.className;
        if (lastClassItem) {
          const classArray = lastClassItem.split(' ');
          const filteredClassArray = classArray.filter(className => className !== 'item-last');
          lastClassItem = filteredClassArray.join(' ');
          lastItem[0].className = lastClassItem;
        }
      }
    }

  }, [currentPage, data]);
  return (
    <div className="table-history">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBorderRadius: 0,
              headerBg: '#ffffff',
              rowHoverBg: '#ffffff',
            },
          },
        }}
      >
        <Table
          locale={locale}
          dataSource={data}
          columns={columns}
          bordered
          pagination={false}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: !data
          }}
        />
        {totalItems > pageSize && (
          <CustomPagination
            current={currentPage}
            total={totalItems}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ConfigProvider>
    </div>
  );
};

export default HistoryContent;

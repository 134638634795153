import { ReactComponent as TimesIcon } from '../../../../assets/image/closebtn.svg';

type Props = {
  title: string;
  handleCloseModal: () => void;
};

const ModalHeader: React.FC<Props> = ({ title, handleCloseModal }) => {
  return (
    <div className="agreement-management_modal-header_container">
      <p className="agreement-management_modal-header_title">{title}</p>
      <button
        onClick={handleCloseModal}
        className="agreement-management_modal-header_close-button"
      >
        <TimesIcon width={14} height={14} />
      </button>
    </div>
  );
};

export default ModalHeader;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/store';
import { revertAll } from 'src/Redux/hooks';

const initialState = {
  radioMedicalPharmacySelected: 1,
}

const slice = createSlice({
  name: "medicalPharmacySlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changeMedicalPharmacyRadioSelected: (state, action) => {
      state.radioMedicalPharmacySelected = action.payload;
    },
  }
});

export const selectMedicalPharmacyRadioSelected = (state:RootState) => state.common[slice.name].radioMedicalPharmacySelected;

export const {
    changeMedicalPharmacyRadioSelected
} = slice.actions;

export const MedicalPharmacySliceReducer = slice.reducer;
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoOutlineIcon } from '../../../../../assets/image/info_outline.svg';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import {
  useGetReservationQuery,
  useUpdateReservationMutation
} from 'src/Network/Home/DoctorSettingApi';
import {
  DEFAULT_END_OPTION,
  DEFAULT_START_OPTION,
  endOption,
  startOption
} from '../constant';
import { ReservationUpdateDto } from 'src/Network/Model/DoctorSetting';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import ErrorModal from '../ErorrModal';
import {
  changeEndValue,
  changeStartValue,
  selectHomepageUrl,
  selectReExamCode
} from '../../medicalMenu/store/slice';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { lengthFullAndHalfWidth } from 'src/Utils/textUtils';

const ReservationAcceptTime: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [startValue, setStartValue] = useState<number>(0);
  const [endValue, setEndValue] = useState<number>(0);
  const [isChangeTime, setIsChangeTime] = useState(false);
  const [openErrorModal, setOpenErorrModal] = useState(false);
  const [erorrMessage, setErorrMessage] = useState('');
  const clinicId = getClinicIdFromLocalStorage();
  const [updateReservation] = useUpdateReservationMutation();
  const { data: reservationData } = useGetReservationQuery(clinicId, {
    skip: clinicId < 0
  });
  const reExamCode = useAppSelector(selectReExamCode);
  const homepageUrl = useAppSelector(selectHomepageUrl);
  const codeLength = lengthFullAndHalfWidth(reExamCode);

  const endableButton =
    !reservationData ||
    startValue !== reservationData?.rsv_acceptance_pediod_from ||
    endValue !== reservationData.rsv_acceptance_pediod_to;

  const updateUrl = (dto: ReservationUpdateDto) => {
    updateReservation(dto).then((res: any) => {
      if (!res?.data?.data) {
        setErorrMessage(res?.data?.message);
        setOpenErorrModal(true);
      }
    });
  };

  const handleUpdateTime = () => {
    if (reservationData) {
      setIsChangeTime(true);
      const dto: ReservationUpdateDto = {
        clinic_id: reservationData.clinic_id,
        home_page_url: reservationData.home_page_url,
        re_exm_code: reservationData.re_exm_code,
        url_public_status: reservationData.url_public_status,
        rsv_acceptance_pediod_from: startValue,
        rsv_acceptance_pediod_to: endValue
      };
      updateUrl(dto);
    }
    if (codeLength === 8 && !reservationData) {
      const dto: ReservationUpdateDto = {
        clinic_id: clinicId,
        re_exm_code: reExamCode,
        home_page_url: homepageUrl,
        rsv_acceptance_pediod_from: startValue,
        rsv_acceptance_pediod_to: endValue,
        url_public_status: 1
      };
      updateUrl(dto);
    }
    if (codeLength !== 8 && !reservationData) {
      const triggerReExamCode = document.getElementById('triggerReExamCode');
      if (triggerReExamCode) {
        triggerReExamCode.click();
      }
    }
  };

  useEffect(() => {
    if (
      reservationData?.rsv_acceptance_pediod_from &&
      (isChangeTime || !startValue)
    ) {
      setStartValue(reservationData.rsv_acceptance_pediod_from);
    }
    if (
      reservationData?.rsv_acceptance_pediod_to &&
      (isChangeTime || !endValue)
    ) {
      setEndValue(reservationData.rsv_acceptance_pediod_to);
    }
    setIsChangeTime(false);
    if (!reservationData) {
      setStartValue(DEFAULT_START_OPTION);
      setEndValue(DEFAULT_END_OPTION);
      setIsChangeTime(true);
    }
  }, [reservationData]);

  useEffect(() => {
    dispatch(changeStartValue(startValue));
    dispatch(changeEndValue(endValue));
  }, [startValue, endValue]);

  return (
    <div className="reservation-accept-time_container">
      <p className="reservation-accept-time_title">
        {t('reExamCode.reservationAcceptTime')}
      </p>
      <p className="reservation-accept-time_note">
        <InfoOutlineIcon />
        <span>{t('reExamCode.reservationAcceptTimeNote')}</span>
      </p>
      <div className="reservation-accept-time_select-time">
        <span>{t('reExamCode.reservationDate')}</span>
        <Select
          style={{ width: 240, margin: '0 20px', height: 58 }}
          options={startOption}
          value={startValue}
          onChange={(value) => setStartValue(value)}
        />
        <span>{t('reExamCode.from')}</span>
        <Select
          style={{ width: 240, margin: '0 20px', height: 58 }}
          options={endOption}
          value={endValue}
          onChange={(value) => setEndValue(value)}
        />
        <span>{t('reExamCode.to')}</span>

        <button
          disabled={!endableButton}
          onClick={handleUpdateTime}
          className={
            endableButton
              ? 'reservation-accept-time_button active-button'
              : 'reservation-accept-time_button'
          }
        >
          {t('reExamCode.reservationAcceptTimeButton')}
        </button>
      </div>
      <ErrorModal
        message={erorrMessage}
        open={openErrorModal}
        setClose={() => setOpenErorrModal(false)}
      />
    </div>
  );
};

export default ReservationAcceptTime;

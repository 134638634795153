import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from 'src/Redux/hooks';

const initialState = {
  QRCodeStore: ''
}

const qrSlice =  createSlice({
  name: 'qrSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    saveQRCode(state, action) {
      state.QRCodeStore = action.payload
    }
  }
})


export const {
  saveQRCode,
} = qrSlice.actions;


export const QRSliceReducer = qrSlice.reducer;
import { Button, Col, Input, Row, Tag } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import PostalCode from '../PostalCode';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { isFullWidth, lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from '../common/form/FormInput';
import { KEY_USER } from 'src/common/Constant';
import Loading from '../Modal/Loading';
import back_white from '../../assets/image/back_white.svg';
import { useCreatePrescriptionPharmacyMutation, useMCitysByIdPrefQuery, useUpdatePrescriptionPharmacyMutation } from 'src/Network/Home/HomeClinicApi';
import { BUTTON_ACCEPT, MODAL_FAIL, getCityList } from 'src/constant';
import ModalWarning from "src/Component/Modal/ModalWarning";

const Prescription = (props:any) => {
    const { t } = useTranslation();
    const {
        prefList,
        cityList,
        methodReceive,
        setVisibleSuccess,
        setIsModalOpenNewPharmacy,
        prescriptionPharmacy,
        setIsModalOpenEditPharmacy,
        setCityList
    } = props;
    const [errCode, setErrCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [visibleWarning, setVisibleWarning] = useState(false);
    const [createPrescriptionPharmacy] = useCreatePrescriptionPharmacyMutation();
    const [updatePrescriptionPharmacy] = useUpdatePrescriptionPharmacyMutation();
    let cityListNew = [] as Array<{ value: number; label: string }>;
    const [prefId, setPrefId] = useState<number>();
    const dataCitys = useMCitysByIdPrefQuery({id: Number(prefId)});
    useEffect(() => {
        if (prescriptionPharmacy && !props.typeModalNew) {
            const pref_id = prescriptionPharmacy?.pref_id;
            setPrefId(pref_id);
            dataCitys.refetch();
        } else {
            setCityList([{ value: 0, label: t("register.text-default-city") }]);
        }
    }, [prescriptionPharmacy])
    useEffect(() => {
        if (dataCitys?.data && dataCitys?.data.length > 0) {
            cityListNew = getCityList(dataCitys?.data, t("register.text-default-city"), 1);
            setCityList(cityListNew);
        } else {
            setCityList([{ value: 0, label: t("register.text-default-city") }]);
        }
    }, [dataCitys]);
    useEffect(() => {
        if (prescriptionPharmacy) {
            methods.setValue('pharmacy_name', prescriptionPharmacy.pharmacy_name ? prescriptionPharmacy.pharmacy_name.toString() : '');
            methods.setValue('fax', prescriptionPharmacy.fax ? prescriptionPharmacy.fax.toString() : '');
            methods.setValue('postal_code', prescriptionPharmacy.postal_code ? prescriptionPharmacy.postal_code.toString() : '');
            methods.setValue('pref_id', prescriptionPharmacy.pref_id ? prescriptionPharmacy.pref_id : 0);
            methods.setValue('city_id', prescriptionPharmacy.city_id ? prescriptionPharmacy.city_id : 0);
            methods.setValue('address', prescriptionPharmacy.address ? prescriptionPharmacy.address.toString() : '');
        }
    }, [prescriptionPharmacy])
    const defaultValues = {
        postal_code: '',
        pref_id: 0,
        city_id: 0,
    };
    const schema = yup.object({
        pharmacy_name: yup
        .string()
        .required(
            t("register.input-required").replace("{0}", t("receive-at-pharmacy.modal-detail-pharmacy.pharmacy-name"))
        )
        .test(
            "lengthFullAndHalfWidth",
            t("register.label-maxlength")
            .replace("{0}", t("register.address"))
            .replace("{1}", "100"),
            (value) => {
            return lengthFullAndHalfWidth(value) <= 100;
            }
        ),
        fax: yup
        .string()
        .required(
            t("register.input-required").replace("{0}", t("receive-at-pharmacy.modal-detail-pharmacy.fax"))
        )
        .matches(/^[\d-]+$/, 
        t("register.fullwidth-invalid")
          .replace("{0}", t("receive-at-pharmacy.modal-detail-pharmacy.fax"))
        )
        .test(
            "isFullWidth",
            t("register.fullwidth-invalid")
            .replace("{0}", t("receive-at-pharmacy.modal-detail-pharmacy.fax")),
            (value) => {
            return !isFullWidth(value);
            }
        )
        .max(20,
        t("register.label-maxlength")
        .replace("{0}", t("ModalDirectlyPharmacy.label2"))
        .replace("{1}", "20")
        ),
        postal_code: yup
        .string()
        .required(
            t("register.input-required").replace("{0}", t("register.postal_code"))
        )
        .test(
            "isFullWidth",
            t("register.fullwidth-invalid")
            .replace("{0}", t("register.postal_code")),
            (value) => {
            return !isFullWidth(value);
            }
        ),
        pref_id: yup
        .number()
        .required().oneOf(prefList.filter((item:any)=>item.value !== 0).map((item:any)=>item.value), t("register.select-required").replace(
            "{0}",
            t("register.text-default-pref")
        )),
        city_id: yup
        .number()
        .required().oneOf(cityList.filter((item:any)=>item.value !== 0).map((item:any)=>item.value), t("register.select-required").replace(
            "{0}",
            t("register.text-default-city")
        )),
        address: yup
        .string()
        .required(
            t("register.input-required").replace("{0}", t("register.address"))
        )
        .test(
            "lengthFullAndHalfWidth",
            t("register.label-maxlength")
            .replace("{0}", t("register.address"))
            .replace("{1}", "64"),
            (value) => {
            return lengthFullAndHalfWidth(value) <= 64;
            }
        ),
    })
    type FormData = yup.InferType<typeof schema>;       
    const methods = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(schema),
        mode: "all",
    });

    const handleClose = () => {
        setIsModalOpenNewPharmacy(false);
    }

    const { setValue, watch, clearErrors } = methods;
    const onSubmit = (data:any) => {
        const json = localStorage.getItem(KEY_USER)??"{}";
        const user_id = JSON.parse(json).user_id;
        if (prescriptionPharmacy) {
            const dto = {
                ...data,
                id: prescriptionPharmacy.pharmacy_id,
                user_id: user_id,
                receive_at: methodReceive
            }
            if (dto) {
                setIsLoading(true);
                updatePrescriptionPharmacy(dto).then((res: any) => {
                    if (!res?.data?.error) {
                        setIsModalOpenEditPharmacy(false);
                        setVisibleSuccess(true);
                    } else {
                        // TODO show error
                        setVisibleWarning(true);
                    }
                    setIsLoading(false);
                })
            }
        } else {
            const dto = {
                ...data,
                user_id: user_id,
                receive_at: methodReceive
            }
            if (dto) {
                setIsLoading(true);
                createPrescriptionPharmacy(dto).then((res: any) => {
                    if (!res?.data?.error) {
                        setIsModalOpenNewPharmacy(false);
                        setVisibleSuccess(true);
                    } else {
                        // TODO show error
                        setVisibleWarning(true);
                    }
                    setIsLoading(false);
                })
            }
        }
    }

    return (
        <div className='prescription'>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Row className="prescription-info-row">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="info-label pharmacy_name">
                            <span className='text-normal-styles'>{t("ModalDirectlyPharmacy.label1")}</span>
                            <Tag className="text-bold-styles need">{t("register.tag")}</Tag>
                            <FormInput name="pharmacy_name" className="prescription-info-input" placeholder={t("ModalDirectlyPharmacy.place-pharmacy-name")} />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="info-label fax">
                            <span className='text-normal-styles'>{t("ModalDirectlyPharmacy.label2")}</span>
                            <Tag className="text-bold-styles need">{t("register.tag")}</Tag>
                            <FormInput
                                name="fax" 
                                className="prescription-info-input" 
                                placeholder={t("ModalDirectlyPharmacy.place-fax")}
                                onKeyDown={(event: any) => {
                                    const regex = /^[0-9-]+$/;
                                    if (event.key === "Backspace") return;
                                    if (event.key === "Delete") return;
                                    if (!regex.test(event.key)) {
                                    event.preventDefault();
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <PostalCode
                        watch={watch}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        setErrCode={setErrCode}
                        errCode={errCode}
                        cityList={cityList}
                        prefList={prefList}
                        setCityList={setCityList}
                    />
                    <Row className="prescription-info-button" gutter={[{ xs: 20, sm: 20, md: 20 }, { xs: 16, sm: 16, md: 16 }]}>
                        <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                            <Button icon={<img src={back_white} />} className='buttonCancel text-bold-styles' onClick={handleClose}>{t("ReceptionInfo.register_adress_new.back")}</Button>
                        </Col>
                        <Col xs={24} sm={24} md={10} style={{width: '100%'}}>
                            <Input type='submit' className='buttonAccept text-bold-styles' value={t("ReceptionInfo.register_adress_new.register")} />
                        </Col>
                    </Row>
                </form>
            </FormProvider>
            {
                visibleWarning &&
                <ModalWarning
                    message={t("message.MESS_ERR_CANNOT_REGIS")}
                    title={t('message.TITLE_FAIL')}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {setVisibleWarning(false)}}
                />
            }
            {isLoading && <Loading />}
        </div>
    )
}

export default Prescription;

import "./styles.css";
import LockFill from "src/assets/image/lock.svg";
import MailOutlined from "src/assets/image/mail.svg";
import { Modal, Input, Col, Row } from "antd";
import { useAppDispatch, useAppSelector } from "src/Redux/hooks";
import {
  changeForgetPassword,
  changeIsLogin,
  changeModalLogin,
  selectModalLogin,
} from "src/Component/Header/slice";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "src/Component/common/form/FormInput";
import { useLoginUserMutation } from "src/Network/Home/HomeClinicApi";
import { useState } from "react";
import { RegExp } from "src/constant";
import { ACCESS_TOKEN, KEY_USER } from 'src/common/Constant';
import ForgetPassword from "src/Component/Modal/ForgetPassword";
import FormInPutPassWord from "src/Component/common/form/FormInPutPassWord";
import Loading from "src/Component/Modal/Loading";
import { useSearchParams } from "react-router-dom";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showModalLogin = useAppSelector(selectModalLogin);
  const [queryParams] = useSearchParams();
  const hideModal = () => {
    dispatch(changeModalLogin({
      show: false
    }));
    reset();
    if (loginFail !== '') {
      setLoginFail('');
    }
  };
  const [userLogin] = useLoginUserMutation();
  const [loginFail, setLoginFail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const reception_id = queryParams.get('reception_id');
  const onSubmit = async (data: any) => {
    const dto = {
      ...data,
    };
    setIsLoading(true);
    await logoutWPFirst();
    userLogin(dto).then(async (res: any) => {
      const data = res?.data?.user;
      if (data && data.status === 1) {
        localStorage.setItem(KEY_USER, JSON.stringify(data));
        if (res?.data?.access_token) {
          localStorage.setItem(ACCESS_TOKEN, res?.data?.access_token);
        }
        autoLogin(dto.mailaddress, dto.password);

        const redirectUrl = showModalLogin.redirectUrl;
        dispatch(changeModalLogin({
          show: false
        }));
        dispatch(changeIsLogin(true));
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          const currentUrl = new URL (window.location.href)
          if (currentUrl.href.includes("/reservation") && currentUrl.searchParams.get('reservation_info_id')){
            location.reload();
          }
        }
      } else {
        if (res?.data?.error && res?.data?.message) {
          setLoginFail(res?.data?.message);
        } else {
          setLoginFail(t("login.passerr"));
        }
      }
      setIsLoading(false);
    }).then(() => {
      const json = localStorage.getItem(KEY_USER);
      if (json) {
        document.getElementById('currentStep0')?.click();
      }
    });
  };

  const logoutWPFirst = async () => {
    let logoutURL = process.env.REACT_APP_WP_LOGOUT_URL;
    if (!logoutURL) return;
    await fetch(logoutURL, {
      method: 'GET',
      credentials: 'include'
    });
  }

  const autoLogin = async (mailaddress?: any, password?: string, redirectUrl?: any) => {
    if (!mailaddress || !password) return;
    const dataToSend = {
      email: mailaddress,
      password: password,
    };
    console.log(dataToSend);
    let authenURL = process.env.REACT_APP_WP_AUTHEN_URL;
    if (!authenURL) return;
    await fetch(authenURL, {
      body: new URLSearchParams(dataToSend),
      method: 'POST',
      credentials: 'include'
    });
  }

  const defaultValues = {
    mailaddress: "",
    password: "",
  };
  const schema = yup.object().shape({
    mailaddress: yup
      .string()
      .required(
        t("register.input-required").replace("{0}", t("register.mailaddress"))
      )
      .matches(RegExp, t("register.email-invalid")),
    password: yup
      .string()
      .required(
        t("register.input-required").replace("{0}", t("login.pla-password"))
      ),
  });
  type FormData = yup.InferType<typeof schema>;
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { reset } = methods;
  const onForgetPassword = () => {
    dispatch(changeModalLogin({
      show: false
    }
    ));
    dispatch(changeForgetPassword(true));
  }

  return (
    <div>
      <Modal
        title={t("header.login")}
        centered
        onOk={hideModal}
        onCancel={hideModal}
        open={showModalLogin.show}
        footer={null}
        className="wrapper-login"
        maskClosable={false}
        style={{ top: 0, paddingTop: 20, maxHeight: 'calc(75vh - 50px)' }}
        // closeIcon={null}
        zIndex={998}
      >
        <Row
          gutter={[
            { xs: 24, sm: 24, md: 32 },
            { xs: 24, sm: 24, md: 32 },
          ]}
        >
          <div className="login-container">
            <FormProvider {...methods}>
              <form className="form-login" onSubmit={methods.handleSubmit(onSubmit)}>
                <Col
                  span={24}
                  className="bodyModal"
                  style={{ paddingTop: 24, paddingBottom: 24 }}
                >
                  <FormInput
                    name="mailaddress"
                    inputProps={{
                      size: "large",
                      placeholder: t("register.mailaddress"),
                      prefix: <img src={MailOutlined} alt="" />,
                      style: { marginBottom: 12, height: 58 }
                    }}
                  />
                  <FormInPutPassWord
                    name="password"
                    inputProps={{
                      size: "large",
                      placeholder: t("login.pla-password"),
                      prefix: <img src={LockFill} alt="" />,
                      style: { marginBottom: 12, height: 58 },
                    }}
                  />
                  <div style={{ textAlign: "right" }}>
                    <span onClick={onForgetPassword} className="forget-password text-nornal-styles">
                      {t("login.forget-password")}
                    </span>
                  </div>
                  {loginFail !== "" && (
                    <span className="messErr text-nornal-styles">
                      {loginFail}
                    </span>
                  )}
                </Col>
                <Col span={24} className="footerModal">
                  <Row
                    gutter={[
                      { xs: 16, sm: 16, md: 20 },
                      { xs: 16, sm: 16, md: 20 },
                    ]}
                  >
                    <Col xs={24} sm={24} md={24}>
                      <Input
                        type="submit"
                        className="buttonPt04-login text-bold-styles"
                        value={t("login.text-login")}
                      />
                    </Col>
                  </Row>
                </Col>
              </form>
            </FormProvider>
          </div>
        </Row>
        {isLoading && <Loading />}
      </Modal>
      <ForgetPassword />
    </div>
  );
};

export default Login;

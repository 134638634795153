import avatar from '../../assets/image/avatar.svg';
import './styles.css';

type Props = {
  logoImg: string | undefined;
  logoImgAlt: string | undefined;
};

const Avatar: React.FC<Props> = ({ logoImg, logoImgAlt }) => {
  let logo_image = null;
  if (logoImg) {
    logo_image = process.env.REACT_APP_WP_IMAGES + logoImg;
  }
  return (
    <div className="booking-avatar">
      <img src={logo_image ?? avatar} alt={logoImgAlt} title={logoImgAlt} />
    </div>
  );
};

export default Avatar;
import { useTranslation } from 'react-i18next';
import FormInputTextArea from 'src/Component/common/form/FormInputTextArea';
import { FormData } from '../../constants';
import { FieldErrors, UseFormWatch } from 'react-hook-form';

type Props = {
  name: keyof FormData;
  titleInput: string;
  placeholder?: string;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
};

const TextAreaForm: React.FC<Props> = ({
  name,
  titleInput,
  placeholder,
  watch,
  errors
}) => {
  const { t } = useTranslation();
  const nameInputField = watch(name);

  return (
    <div>
      <div className="medical-menu-form_separate_title-form-item">
        <p>{titleInput}</p>
      </div>
      <div className="medical-menu-form_wrapper">
        <div className="medical-menu-form_input_wrapper w-full">
          <FormInputTextArea
            inputProps={{
              className: 'w-full',
              placeholder: placeholder,
              styles: { width: '100%' }
            }}
            rows={5}
            name={name}
          />
        </div>
      </div>
      <p
        style={{ marginTop: errors[name] ? 0 : -20 }}
        className="medical-menu-form_count-letter"
      >
        {t('reExamCode.medicalMenuCountInput', {
          number:
            typeof nameInputField === 'string' ? nameInputField?.length : 0,
          maxNumber: 1000
        })}
      </p>
    </div>
  );
};

export default TextAreaForm;

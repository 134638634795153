import "./styles.css";
import { Button, Modal, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from "src/Utils/textUtils";
import Warning from "src/assets/image/warning.svg";
import { ReservationFrameRepeat } from "src/Network/Model/ReservationFrameRepeat";
import React, { useState } from "react";
import { TreatmentMenu } from "src/Network/Model/TreatmentMenu";
import { BUTTON_ACCEPT, MODAL_FAIL, contentRepeat, formatYYYYMMDD, renderTreatmentValue } from "src/constant";
import { TreatMentMenuItem } from "src/Network/Model/TreatMentMenuItem";
import { useReservationFrameRepeatCreateMutation, useReservationFrameRepeatDeleteMutation } from "src/Network/Home/DoctorApi";
import ModalWarning from "src/Component/Modal/ModalWarning";
import Loading from "../Loading";
import dayjs from 'dayjs';

type Props = {
    isDeleteModal: boolean,
    nextToConfirm: Function,
    closeModal: Function,
    dataReservationFrameRepeat: ReservationFrameRepeat
    treatMentMenuList?: TreatmentMenu[],
    repeatOnDayOfWeekOptions:Array<{value: number, label: string}>
    // reset?: Function
    setVisibleSuccess?: Function,
    setVisibleDeleteSuccess?: Function,
    onClose?: Function,
    titleModal?: string
    titleBody?: string
    titteFooter?: string,
    treatMentMenu?: TreatMentMenuItem[]
    // setCheckboxValueTreatMent?: Function,
    // setCheckboxValueUserId?: Function
}

const APP: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [visibleWarning, setVisibleWarning] = useState(false);
    const [visibleDeleteWarning, setVisibleDeleteWarning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const data = props?.dataReservationFrameRepeat;
    const treatmentList = props.isDeleteModal ? (props.treatMentMenu ? props.treatMentMenu.map((i:TreatMentMenuItem) => Number(i.id)) : []) : (data?.exam ? data.exam.map((i:TreatMentMenuItem) => Number(i.id)) : []);
    const uniqueTreatmentList = [...new Set(treatmentList)];
    const treatmentNameList = uniqueTreatmentList.map((item) => {
        const treatmentItem = props.isDeleteModal ? props.treatMentMenu?.find((i:TreatMentMenuItem) => i.id === Number(item)) : props.treatMentMenuList?.find((i:TreatmentMenu) => i.id === Number(item))
        return treatmentItem?.treatment_menu_name
    })
    const [reservationFrameRepeatCreate] = useReservationFrameRepeatCreateMutation();
    const [messDeleteErr, setMessDeleteErr] = useState('');
    const handlConfirm = () => {
        const reservationFrameRepeatList = uniqueTreatmentList?.map((item) => {
            const treatMentMenuList = data?.exam?.filter((i:TreatMentMenuItem) =>  i.id === Number(item)) as Array<TreatMentMenuItem>
            const face_to_face_first_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '1') ? 1: 0;
            const online_first_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '2') ? 1: 0;
            const face_to_face_re_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '3') ? 1: 0;
            const online_re_exm = treatMentMenuList.some((i:TreatMentMenuItem) => i.value === '4') ? 1: 0;
            return {
                clinic_id: data?.clinic_id,
                treatment_menu_id: Number(item),
                rsv_start_ymd: dayjs(data?.rsv_start_ymd).format(formatYYYYMMDD).replaceAll('/', ''),
                rsv_end_ymd: dayjs(data?.repeat_type === 0 ? data?.rsv_start_ymd : data?.rsv_end_ymd).format(formatYYYYMMDD).replaceAll('/', ''),
                rsv_start_time: data?.rsv_start_time,
                rsv_end_time: data?.rsv_end_time,
                repeat_type: data?.repeat_type,
                is_public_on_holiday: data?.repeat_type === 0 ? 1 : (data?.is_public_on_holiday ? 1 : 0),
                upper_limit_frame: Number(data?.upper_limit_frame),
                repeat_on_day_of_week: data?.repeat_type === 0 ? null : data?.repeat_on_day_of_week,
                face_to_face_first_exm: face_to_face_first_exm,
                online_first_exm: online_first_exm,
                face_to_face_re_exm: face_to_face_re_exm,
                online_re_exm: online_re_exm,
                clinic_user_ids: data?.clinicUser
            }
        })
        setIsLoading(true);
        // call api register reservation_frame_repeat
        reservationFrameRepeatCreate(reservationFrameRepeatList).then((res: any) => {
            if (!res?.data?.error) {
                if (props.setVisibleSuccess) {
                    props.setVisibleSuccess(true);
                    props.closeModal();
                }
                // if (props.reset) {
                //     props.reset();
                // }
                if (props.onClose) {
                    props.onClose();
                }
                // if (props.setCheckboxValueTreatMent) {
                //     props.setCheckboxValueTreatMent({});
                // }
                // if (props.setCheckboxValueUserId) {
                //     props.setCheckboxValueUserId([]);
                // }
            } else {
                // TODO show error
                setVisibleWarning(true);
            }
            setIsLoading(false);
        })
    };
    const [reservationFrameRepeatDelete] = useReservationFrameRepeatDeleteMutation();
    const handleDelete = () => {
        setIsLoading(true);
        const reservationId = Number(data?.id);
        reservationFrameRepeatDelete(reservationId)
            .then((res: any) => {
                if (!res?.data.error) {
                    if (props.setVisibleDeleteSuccess) {
                        props.setVisibleDeleteSuccess(true);
                        props.closeModal();
                    }
                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    setVisibleDeleteWarning(true);
                    if (res?.data.code === 40001) {
                        setMessDeleteErr(t("message.MESS_ERR_RSV_FRAME_DELETE_FAIL"));
                    } else {
                        setMessDeleteErr(t("message.MESS_ERR_DELETE_RSV_FRAME_COMMON"));
                    }
                }
                setIsLoading(false);
            })
    };

    const handleCancel = () => {
        props.closeModal();
    };

    const handleClose = () => {
        props.closeModal();
    };

    const renderFooter = () => {
        if (props.isDeleteModal)
            return (
                <Row gutter={[{ xs: 20, sm: 20 }, { xs: 20, sm: 20 }]}>
                    <Col xs={12} sm={12}>
                        <Button type='text' onClick={handleDelete} className='buttonPt10-left text-bold-styles'>
                            {props.titteFooter}
                        </Button>
                    </Col>
                    <Col xs={12} sm={12}>
                        <Button type='text' onClick={handleCancel} className='buttonPt10-right text-bold-styles'>
                            {t("modal-delete-order.footer.button-cancel")}
                        </Button>
                    </Col>
                </Row>
            )
        else
            return (
                <Button type='text' onClick={handlConfirm} className='buttonPt10-left text-bold-styles'>
                    {props.titteFooter}
                </Button>
            )
    }

    return (
        <>
            <Modal
                maskClosable={false}
                title = {props?.titleModal}
                centered
                open={true}
                footer={null}
                onOk={handleClose}
                onCancel={handleClose}
                className='modalDeleteOrder'
            >
                <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
                    <Col span={24} className='bodyModal text-normal-styles'>
                        <Row gutter={[{}, { xs: 16, sm: 16, md: 24 }]}>
                            <Col xs={24} sm={24} md={24} className='title'>
                                {props?.titleBody}
                            </Col>
                            <Col xs={24} sm={24} md={24} className='orderList'>
                                <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-1.title"), content: treatmentNameList?.toString().replaceAll(',','<br/>') }} />
                                <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-2.title"), content: contentRepeat(data, props.repeatOnDayOfWeekOptions) }} />
                                <ItemInfo data={{ title: t("modal-delete-order.body.orderList.item-3.title"), content: null }} />
                                {uniqueTreatmentList.map((item:any, index:number) => {
                                    return <React.Fragment  key={index}>
                                        {props.isDeleteModal ?
                                            renderTreatmentValue(uniqueTreatmentList, item, props.treatMentMenu, props.treatMentMenuList)
                                        : renderTreatmentValue(uniqueTreatmentList, item, data?.exam, props.treatMentMenuList)
                                        }
                                    </React.Fragment>
                                })}
                            </Col>
                            {
                                props.isDeleteModal &&
                                <Col xs={24} sm={24} md={24} className="notice">
                                    <span><img src={Warning} alt='' /></span>
                                    <span>{t("modal-delete-order.body.notice")}</span>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col span={24} className='footerModal'>
                        {renderFooter()}
                    </Col>
                </Row>
                {isLoading && <Loading />}
            </Modal>
            {
                visibleWarning &&
                <ModalWarning
                    message={t("message.MESS_ERR_RSV_FRAME_UPDATE_FAIL")}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {setVisibleWarning(false)}}
                />
            }
            {
                visibleDeleteWarning &&
                <ModalWarning
                    message={messDeleteErr}
                    typeModal={MODAL_FAIL}
                    typeButton={BUTTON_ACCEPT}
                    changeVisibleModal={() => {setVisibleDeleteWarning(false)}}
                />
            }
        </>
    )
}

const ItemInfo = ({ data }: any) => {
    return (
        <div>
            <div className='itemTitle text-bold-styles'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.title)}></span>
            </div>
            {data?.content &&
            <div className='itemContent'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.content)}></span>
            </div>
            }
        </div>
    )
}

export default APP;
import { useState } from 'react';
import MedicalMenuListTable from './medicalMenuListTable';
import { useAppSelector } from 'src/Redux/hooks';
import { selectIsListMode } from './store/slice';
import MedicalMenuForm from './medicalMenuForm';
import {
  useAddTreatmentMenuMutation,
  useGetListConsentFormQuery,
  useGetTreatmentMenuListByClinicIdQuery,
  useUpdateTreatmentMenuMutation
} from 'src/Network/Home/DoctorSettingApi';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';
import Loading from 'src/Component/Modal/Loading';

const MedicalMenu: React.FC = () => {
  const isListMode = useAppSelector(selectIsListMode);
  const clinicId = getClinicIdFromLocalStorage();
  const { isLoading, isFetching } = useGetTreatmentMenuListByClinicIdQuery(
    clinicId,
    {
      skip: clinicId < 0
    }
  );
  const { isLoading: isLoadingForm, isFetching: isFetchingForm } =
    useGetListConsentFormQuery(clinicId, {
      skip: clinicId < 0
    });
  const [_addTreatmentMenu, { isLoading: isAdding }] =
    useAddTreatmentMenuMutation();
  const [_updateTreatmentMenu, { isLoading: isUpdating }] =
    useUpdateTreatmentMenuMutation();
  const loading =
    isLoadingForm ||
    isAdding ||
    isUpdating ||
    isFetching ||
    isFetchingForm ||
    isLoading;

  return (
    <div>
      {isListMode ? <MedicalMenuListTable /> : <MedicalMenuForm />}
      {loading && <Loading />}
    </div>
  );
};

export default MedicalMenu;

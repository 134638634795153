import { Carousel, Col, ConfigProvider, Modal, Row } from "antd"
import { useTranslation } from "react-i18next";
import qrCodeIcon from 'src/assets/image/pana.svg';
import lineIcon from 'src/assets/image/line.svg';
import locatingIcon from 'src/assets/image/locating.svg';
import formIcon from 'src/assets/image/form.svg';
import arrowRightIcon from 'src/assets/image/arrow-right.svg';
import warningIcon from 'src/assets/image/warning.svg';
import './styles.css'
import { Link } from 'react-router-dom';
import { linkToPsftHowToUse } from "src/common/Libs";

type Props = {
    changeVisibleModal: Function
}

const PharmacyGuide:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const onChange = (currentSlide: number) => {
      console.log(currentSlide);
    };
    const hideModal = () => {
        props.changeVisibleModal();
    }
    
    return (
        <div>
            <Modal
                maskClosable={false}
                title={t("pharmacy-guide.title")}
                centered footer={null}
                open={true}
                width={1640}
                className="modal-pharmacy-guide"
                onOk={hideModal}
                onCancel={hideModal}
            >
                <div className="pharmacy_guide_container">
                    <Row gutter={[{ xs: 24, sm: 24, md: 16 }, { xs: 24, sm: 24, md: 16 }]}>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div>{t("pharmacy-guide.guide.line1")}</div>
                            <div>{t("pharmacy-guide.guide.line2")}</div>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div className="topic">{t("pharmacy-guide.topic1")}</div>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div>{t("pharmacy-guide.guide.line3")}</div>
                            <div>{t("pharmacy-guide.guide.line4")}</div>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}} >
                            <Col xs={0} sm={0} md={24}>
                                <Row>
                                    <Col className="colCardItem">
                                        <div className='cardItem'>
                                            <img src={qrCodeIcon} alt='' />
                                            <span className='text-bold-styles'>{t('pharmacy-guide.slide1')}</span>
                                        </div>
                                    </Col>

                                    <Col xs={24} sm={12} md={1} className="splitCardItem"><img className="arrow-right" src={arrowRightIcon} /></Col>
                                    <Col className="colCardItem">
                                        <div className='cardItem'>
                                            <img src={lineIcon} alt='' />
                                            <span className='text-bold-styles'>{t('pharmacy-guide.slide2')}</span>
                                        </div>
                                    </Col>

                                    <Col xs={24} sm={12} md={1} className="splitCardItem"><img className="arrow-right" src={arrowRightIcon} /></Col>
                                    <Col className="colCardItem">
                                        <div className='cardItem'>
                                            <img src={locatingIcon} alt='' />
                                            <span className='text-bold-styles'>{t('pharmacy-guide.slide3')}</span>
                                        </div>
                                    </Col>

                                    <Col xs={24} sm={12} md={1} className="splitCardItem"><img className="arrow-right" src={arrowRightIcon} /></Col>
                                    <Col className="colCardItem">
                                        <div className='cardItem'>
                                            <img src={qrCodeIcon} alt='' />
                                            <span className='text-bold-styles'>{t('pharmacy-guide.slide4')}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={0}>
                                <ConfigProvider
                                    theme={{
                                    components: {
                                        Carousel: {
                                            dotActiveWidth: 14,
                                            dotHeight: 8,
                                            dotWidth: 8,
                                            colorBgContainer: '#ACB4BC'
                                        },
                                    },
                                    }}
                                >
                                    <Carousel afterChange={onChange} className="step-by-step mb">
                                        <div className="image-box-mb">
                                            <div>
                                                <img src={qrCodeIcon} />
                                                <span>{t("pharmacy-guide.slide1")}</span>
                                            </div>
                                        </div>
                                        <div className="image-box-mb">
                                            <div>
                                                <img src={lineIcon} />
                                                <span>{t("pharmacy-guide.slide2")}</span>
                                            </div>
                                        </div>
                                        <div className="image-box-mb">
                                            <div>
                                                <img src={locatingIcon} />
                                                <span>{t("pharmacy-guide.slide3")}</span>
                                            </div>
                                        </div>
                                        <div className="image-box-mb">
                                            <div>
                                                <img src={formIcon} />
                                                <span>{t("pharmacy-guide.slide4")}</span>
                                            </div>
                                        </div>
                                    </Carousel>
                                </ConfigProvider>
                            </Col>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div className="topic">{t("pharmacy-guide.topic2")}</div>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div>{t("pharmacy-guide.guide.line5")}</div>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div className="message-guide"><img src={warningIcon} />{t("pharmacy-guide.guide.line6")}</div>
                        </Col>
                        <Col span={24} className='bodyModal' style={{ paddingTop: 0, paddingBottom: 0}}>
                            <div>
                                {t("pharmacy-guide.guide.line7")}
                                <Link to={linkToPsftHowToUse() + ''} style={{ color: '#308AE6' }} target="_blank">
                                    {t("pharmacy-guide.guide.line7-link")}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default PharmacyGuide;
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import React from "react";
import './styles.css';
import {CardData} from "../../../Network/Model/Card";

const APP = ({
							 isOpen,
							 onClose,
							 moveToConfirmBeforeEdit,
	currentCard
						 }: {
	isOpen: boolean,
	onClose: Function,
	moveToConfirmBeforeEdit: (event: CustomEvent) => void,
	currentCard: CardData | undefined
}) => {
	const {t} = useTranslation();

	const handleClose = () => {
		onClose();
	};
	return <Modal destroyOnClose={true} centered
										 className={'cardInfo'}
										 open={isOpen}
										 title={t('card_payment.register-title')}
										 footer={<div style={{display: "flex", justifyContent: "center"}}>
											 <Button type={"text"} className={'btnEdit'}
															 onClick={() => moveToConfirmBeforeEdit(new CustomEvent(''))}>{t("card_payment.edit-title")}</Button>
										 </div>} onCancel={handleClose}>

		<table>
			<tr>
				<td>{t('card_payment.registered-card-number')}</td>
				<td>{currentCard?.cardNumber}</td>
			</tr>
			<tr>
				<td>{t('card_payment.limit-date')}</td>
				<td>{currentCard?.cardExpire}</td>
			</tr>
		</table>

	</Modal>
}

export default APP;
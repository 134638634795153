import { Col, Row, Table } from "antd";
import './styles.css';
import { useTranslation } from 'react-i18next';

type Props = {
  dataInfomation: any,
}

const drawItem = (title: string, value?: string, key?: string) => {
  if (!value || value === "") return null;
  return {
    key: key,
    name: <span className="text-normal-styles urlwebsite-title" style={{minWidth: 200}}>{title}</span>,
    value: <span className="text-normal-styles urlwebsite-value">{value}</span>,
  };
};

const UrlWebsite:React.FC<Props> = ({dataInfomation}) => {
  const { t } = useTranslation();
  const departmentString = dataInfomation?.departments.map((item: { department_name: string }) => item.department_name)?.join('・');
  const inspections = dataInfomation?.inspections.map((item: { inspection_name: string }) => item.inspection_name)?.join('・');
  const specialities = dataInfomation?.specialities.map((item: { department_name: string }) => item.department_name)?.join('・');
  const outpatients = dataInfomation?.outpatients.map((item: { department_name: string }) => item.department_name)?.join('・');


  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value'
    }
  ];

  const data = [
    drawItem(t('url-website.url'), dataInfomation?.url, 'url'),
    drawItem(t('url-website.responsible_person'), dataInfomation?.responsible_person, 'responsible_person'),
    drawItem(t('url-website.departmentString'), departmentString, 'departmentString'),
    drawItem(t('url-website.inspections'), inspections, 'inspections'),
    drawItem(t('url-website.specialities'), specialities, 'specialities'),
    drawItem(t('url-website.medical_treatment'), dataInfomation?.medical_treatment, 'medical_treatment'),
    drawItem(t('url-website.vaccination'), dataInfomation?.vaccination, 'vaccination'),
    drawItem(t('url-website.outpatients'), outpatients, 'outpatients'),
  ].filter(item => item !== null) as any[];

  return (
    <>
      {
        data.length ? 
        <div className="urlwebsite">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table columns={columns} dataSource={data} pagination={false} showHeader={false} />
            </Col>
          </Row>
        </div>
        :
        <></>
      }
    </>
  )
}
  
export default UrlWebsite;
import BookingCalendar from "../BookingCalendar";
import { Col, Row } from 'antd';
import './styles.css';
import BookingTime from "../BookingTime";
import ClinicDetail from "../ClinicDetail";
import UrlWebsite from "../UrlWebsite";
import { useTranslation } from "react-i18next";
import { Clinic } from "../../Network/Model/Clinic";

type Props = {
  data: Clinic | undefined;
};

const BookingOnline: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Row className="booking-online" gutter={[{}, { xs: 80, sm: 80, md: 60 }]}>
      <Col xs={24} sm={24} md={24}>
        <div className="title">
          {t("booking-online.title")}
        </div>
        <BookingCalendar data={data} />
      </Col>
      <Col xs={24} sm={24} md={24}>
        <div className="title">
          {t("clinic-detail.title")}
        </div>
        <ClinicDetail dataInfomation={data} />
      </Col>
      <Col xs={24} sm={24} md={24}>
        <div className="title">
          {t("booking-time.title")}
        </div>
        <BookingTime dataInfomation={data} />
      </Col>
      <Col xs={24} sm={24} md={24}>
        <UrlWebsite dataInfomation={data} />
      </Col>
    </Row>
  );
};

export default BookingOnline;
import './styles.css';
import {
  Button,
  Col,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Tag,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { changeModalRegister, selectModalRegister } from '../Header/slice';
import { useTranslation } from 'react-i18next';
import FormInput from '../common/form/FormInput';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import arrow_left from '../../assets/image/arrow-left.svg';
import { useEffect, useState } from 'react';
import FormInputCalendar from '../common/form/FormInputCalendar';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  RegExp,
  formatYYYYMMDD,
  getPrefList,
  placeholderyyyymmdd,
} from 'src/constant';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useMPrefsQuery } from 'src/Network/Home/HomeClinicApi';
import { changeDataRegister } from './slice';
import ConfirmRegister from '../ConfirmRegister';
import {
  changeConfirmRegister,
  selectIsResetRegister,
} from '../ConfirmRegister/slice';
import { Link } from 'react-router-dom';
import {
  isFullWidth,
  lengthFullAndHalfWidth,
  replaceWithBr,
} from 'src/Utils/textUtils';
import PostalCode from '../PostalCode';

dayjs.extend(customParseFormat);

const Register = () => {
  const { t } = useTranslation();
  const [valueGender, setValueGender] = useState(2);
  const defaultValues = {
    user_name: '',
    tel: '',
    mailaddress: '',
    address: '',
    birthday: '',
    birth_year: 0,
    birth_month: 0,
    birth_day: 0,
    postal_code: '',
    pref_id: 0,
    city_id: 0,
    sex: 2,
  };
  const onChangeGender = (e: RadioChangeEvent) => {
    setValueGender(e.target.value);
    setValue('sex', e.target.value);
  };
  const dispatch = useAppDispatch();
  const statusFormReg = useAppSelector(selectModalRegister);
  const hideModal = () => {
    dispatch(changeModalRegister(false));
    setErrCode('');
    if (errConfirm !== '') {
      setErrConfirm('');
    }
    setCityList([{ value: 0, label: t('register.text-default-city') }]);
  };
  const isResetRegister = useAppSelector(selectIsResetRegister);
  const [cityList, setCityList] = useState<Array<{ value: number; label: string }>>([{ value: 0, label: t('register.text-default-city') }]);
  useEffect(() => {
    if (statusFormReg && isResetRegister) {
      setChecked(false);
      setValueGender(2);
      reset();
    }
  }, [statusFormReg]);
  const [checked, setChecked] = useState(false);
  const [errConfirm, setErrConfirm] = useState('');
  const onSubmit = (data: any) => {
    if (!errCode) {
      if (checked) {
        dispatch(
          changeDataRegister({
            data: data,
          }),
        );
        dispatch(changeModalRegister(false));
        dispatch(changeConfirmRegister(true));
      } else {
        setErrConfirm(t('register.confirm-required'));
      }
    }
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const onChangeCheckBox = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setErrConfirm('');
    }
  };
  const mPrefsAll = useMPrefsQuery({})?.data;
  const [prefList, setPrefList] = useState<Array<{ value: number; label: string }>>([]);
  useEffect(() => {
    if (mPrefsAll) {
      const newPrefList = getPrefList(
        mPrefsAll,
        t('register.text-default-pref'),
      );
      setPrefList(newPrefList);
    }
  }, [mPrefsAll]);
  const [errCode, setErrCode] = useState('');
  const schema = yup.object({
    user_name: yup
      .string()
      .test(
        'lengthFullAndHalfWidth',
        t('register.label-maxlength')
          .replace('{0}', t('register.user_name'))
          .replace('{1}', '50'),
        (value) => {
          return lengthFullAndHalfWidth(value) <= 50;
        },
      )
      .required(
        t('register.input-required').replace('{0}', t('register.user_name')),
      ),
    tel: yup
      .string()
      .required(t('register.input-required').replace('{0}', t('register.tel')))
      .test(
        'isFullWidth',
        t('register.fullwidth-invalid').replace('{0}', t('register.tel')),
        (value) => {
          return !isFullWidth(value);
        },
      )
      .max(
        16,
        t('register.label-maxlength')
          .replace('{0}', t('register.tel'))
          .replace('{1}', '16'),
      ),
    mailaddress: yup
      .string()
      .required(
        t('register.input-required').replace('{0}', t('register.mailaddress')),
      )
      .matches(RegExp, t('register.email-invalid'))
      .max(
        100,
        t('register.label-maxlength')
          .replace('{0}', t('register.mailaddress'))
          .replace('{1}', '100'),
      )
      .min(
        6,
        t('register.label-minlength')
          .replace('{0}', t('register.mailaddress'))
          .replace('{1}', '6'),
      ),
    pref_id: yup
      .number()
      .required()
      .oneOf(
        prefList.filter((item) => item.value !== 0).map((item) => item.value),
        t('register.select-required').replace(
          '{0}',
          t('register.text-default-pref'),
        ),
      ),
    city_id: yup
      .number()
      .required()
      .oneOf(
        cityList.filter((item) => item.value !== 0).map((item) => item.value),
        t('register.select-required').replace(
          '{0}',
          t('register.text-default-city'),
        ),
      ),
    _birthday: yup
      .string()
      .required(
        t('register.input-required').replace('{0}', t('register.birthday')),
      ),
    address: yup
      .string()
      .required(
        t('register.input-required').replace('{0}', t('register.address')),
      )
      .test(
        'lengthFullAndHalfWidth',
        t('register.label-maxlength')
          .replace('{0}', t('register.address'))
          .replace('{1}', '64'),
        (value) => {
          return lengthFullAndHalfWidth(value) <= 64;
        },
      ),
    postal_code: yup
      .string()
      .required(
        t('register.input-required').replace('{0}', t('register.postal_code')),
      )
      .test(
        'isFullWidth',
        t('register.fullwidth-invalid').replace(
          '{0}',
          t('register.postal_code'),
        ),
        (value) => {
          return !isFullWidth(value);
        },
      ),
    sex: yup.number(),
    birth_year: yup.number(),
    birth_month: yup.number(),
    birth_day: yup.number(),
  });
  type FormData = yup.InferType<typeof schema>;
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { reset, setValue, watch, clearErrors } = methods;
  const _birthday = new Date(watch('_birthday'));
  const year = _birthday.getFullYear();
  const month = _birthday.getMonth() + 1;
  const date = _birthday.getDate();
  useEffect(() => {
    if (_birthday) {
      setValue('birth_year', year);
      setValue('birth_month', month);
      setValue('birth_day', date);
    }
  }, [_birthday]);
  const handleKeyDown = (e: any) => {
    const regex = /^[0-9]+$/;
    if (e.key === 'Backspace') return;
    if (e.key === 'Delete') return;
    if (e.key === 'Tab') return;
    if (e.key === 'ArrowLeft') return;
    if (e.key === 'ArrowRight') return;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div>
        <Modal
          maskClosable={false}
          title={t('register.title')}
          onOk={hideModal}
          onCancel={hideModal}
          open={statusFormReg}
          footer={null}
          className="register-wrapper"
          style={{ top: 0, paddingTop: 20, maxHeight: 'calc(75vh - 50px)' }}
          zIndex={998}
        >
          <div className="register-container">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div>
                  <div className="info-register">
                    <span className="text-normal-styles">
                      {t('register.user_name')}
                    </span>
                    <Tag className="text-bold-styles need">
                      {t('register.tag')}
                    </Tag>
                  </div>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormInput
                        name="user_name"
                        placeholder={t('register.pla-user')}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="info-register">
                    <span className="text-normal-styles">
                      {t('register.tel')}
                    </span>
                    <Tag className="text-bold-styles need">
                      {t('register.tag')}
                    </Tag>
                  </div>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormInput
                        type="number"
                        name="tel"
                        placeholder="例：09012345678"
                        onKeyDown={handleKeyDown}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="info-register">
                    <span className="text-normal-styles">
                      {t('register.mailaddress')}
                    </span>
                    <Tag className="text-bold-styles need">
                      {t('register.tag')}
                    </Tag>
                  </div>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormInput
                        name="mailaddress"
                        placeholder={t('register.pla-email')}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="info-domain">
                  <Row>
                    <span
                      className="text-normal-styles"
                      dangerouslySetInnerHTML={replaceWithBr(
                        t('register.text-calceldoman'),
                      )}
                    ></span>
                  </Row>
                </div>
                <div className="info-domain">
                  <Row>
                    <span
                      className="text-normal-styles"
                      dangerouslySetInnerHTML={replaceWithBr(
                        t('register.text-dedesignatedomain'),
                      )}
                    ></span>
                  </Row>
                </div>
                <PostalCode
                  setValue={setValue}
                  watch={watch}
                  clearErrors={clearErrors}
                  setErrCode={setErrCode}
                  errCode={errCode}
                  prefList={prefList}
                  cityList={cityList}
                  setCityList={setCityList}
                />
                <div>
                  <div className="info-register">
                    <span className="text-normal-styles">
                      {t('register.gender')}
                    </span>
                    <Tag className="text-bold-styles need">
                      {t('register.tag')}
                    </Tag>
                  </div>
                  <div>
                    <Radio.Group
                      className="btn-radio"
                      name="sex"
                      onChange={onChangeGender}
                      value={valueGender}
                    >
                      <Radio value={2}>{t('register.male')}</Radio>
                      <Radio value={1}>{t('register.woman')}</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div>
                  <div className="info-register">
                    <span className="text-normal-styles">
                      {t('register.birthday')}
                    </span>
                    <Tag className="text-bold-styles need">
                      {t('register.tag')}
                    </Tag>
                  </div>
                  <div>
                    <Space direction="vertical">
                      <FormInputCalendar
                        name="_birthday"
                        placeholder={placeholderyyyymmdd}
                        format={formatYYYYMMDD}
                        disabledDate={disabledDate}
                        inputProps={{
                          changeOnBlur: true,
                        }}
                      />
                    </Space>
                  </div>
                </div>
                <div className="confirm">
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Checkbox
                        name="confirm"
                        checked={checked}
                        onChange={onChangeCheckBox}
                      >
                        <Link
                          to={
                            process.env
                              .REACT_APP_ISHACHOKU_JVIT_AGREEMENT_USER || ''
                          }
                          style={{ color: '#308AE6' }}
                          target="_blank"
                        >
                          {t('register.terms-of-service')}
                        </Link>
                        {t('register.text-and')}
                        <Link
                          to={
                            process.env.REACT_APP_ISHACHOKU_JVIT_PRIVACY || ''
                          }
                          style={{ color: '#308AE6' }}
                          target="_blank"
                        >
                          {t('register.privacy-policy')}
                        </Link>
                        {t('register.agree')}
                      </Checkbox>
                    </Col>
                  </Row>
                  {errConfirm !== '' && (
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <span className="error">
                          {t('register.confirm-required')}
                        </span>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="btn-step">
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Input
                        type="submit"
                        className="buttonPt10-left text-bold-styles"
                        value={t('register.step-next')}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="btn-step">
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Link to={process.env.REACT_APP_WP_URL || ''}>
                        <Button
                          type="text"
                          className="buttonPt10-right text-bold-styles"
                        >
                          <img src={arrow_left} />
                          {t('register.step-prev')}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </form>
            </FormProvider>
          </div>
        </Modal>
      </div>
      <div>
        <ConfirmRegister />
      </div>
    </div>
  );
};

export default Register;

import { Modal } from 'antd';
import ModalHeader from './ModalHeader';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  changeIsCancelModalOpen,
  changeOpenDeleteSuccessModal,
  selectIsCancelModalOpen,
  selectSelectedDeleteId
} from './store/slice';
import ModalFooter from './ModalFooter';
import { ReactComponent as XCircleFillIcon } from '../../../../assets/image/x_circle_fill_icon.svg';
import { useDeleteConsentFormMutation } from 'src/Network/Home/DoctorSettingApi';

const DeleteModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteItem] = useDeleteConsentFormMutation();
  const handleCloseModal = () => {
    dispatch(changeIsCancelModalOpen(false));
  };
  const isCancelModalOpen = useAppSelector(selectIsCancelModalOpen);
  const selectedId = useAppSelector(selectSelectedDeleteId);

  const handleDeleteItem = () => {
    deleteItem(selectedId).then((res: any) => {
      if (res?.data?.code === 200) {
        dispatch(changeIsCancelModalOpen(false));
        dispatch(changeOpenDeleteSuccessModal(true))
      }
    });
  };

  return (
    <Modal
      open={isCancelModalOpen}
      title={
        <ModalHeader
          handleCloseModal={handleCloseModal}
          title={t('reExamCode.deleteAgreement')}
        />
      }
      footer={
        <ModalFooter
          okTitle={t('reExamCode.delete')}
          handleOK={handleDeleteItem}
          cancelTitle={t('reExamCode.cancel')}
          handleCancel={handleCloseModal}
        />
      }
      closeIcon={null}
      centered
      styles={{
        body: {
          marginTop: 24,
          marginBottom: 24
        }
      }}
    >
      <div className="delete-agreement-modal_icon">
        <XCircleFillIcon />
      </div>
      <div className="delete-agreement-modal_content">
        <h2>{t('reExamCode.deleteAgreementContentTitle')}</h2>
        <p>{t('reExamCode.deleteAgreementContent')}</p>
      </div>
    </Modal>
  );
};

export default DeleteModal;

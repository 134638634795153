import { Spin } from "antd";
import './styles.css';

const Loading = () => {
    return (
        <div className='loading-content'>
            <Spin size="large">
                <div className="content" />
            </Spin>
        </div> 
    )
}

export default Loading;
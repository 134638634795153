import './styles-modal.css';
import './styles.css';
import { useState } from 'react';
import { Button, Modal, Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';


const APP = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleClose = () => {
      setIsModalOpen(false);
    };

    return (
      <div>
        <Button onClick={showModal} style={{backgroundColor: '#FF1493', color: '#FFFFFF'}}>
            ボタン
        </Button>
        <Modal maskClosable={false} title closeIcon={null} centered open={isModalOpen} footer={null}>
          <Row gutter={[{ xs: 24, sm: 24, md: 32 }, { xs: 24, sm: 24, md: 32 }]}>
            <Col span={24}>
              <div className='headerModal'>
                <span className='headerModal-title text-bold-styles'>イシャチョク診療予約のキャンセル</span>
                <span>
                  <Button type='text' icon={<CloseOutlined />} onClick={handleClose} style={{ color: '#878E9B' }}></Button>
                </span>
              </div>
            </Col>
            <Col span={24} className='bodyModal text-normal-styles' style={{ paddingTop: 24, paddingBottom: 24, textAlign: 'center' }}>
                クリニックへの予約をキャンセルしますか？
            </Col>
            <Col span={24} className='footerModal'>
              <Row gutter={[{ xs: 20, sm: 20 }, { xs: 20, sm: 20 }]}>
                <Col xs={12} sm={12}>
                  <Button onClick={handleOk} className='buttonPt10-left text-bold-styles'>
                    はい
                  </Button>
                </Col>
                <Col xs={12} sm={12}>
                  <Button type='text' onClick={handleCancel} className='buttonPt10-right text-bold-styles'>
                    いいえ
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    )
}

export default APP;
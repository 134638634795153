import './styles.css';
import { replaceWithBr } from 'src/Utils/textUtils';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/Redux/hooks';
import { selectDataAddress } from 'src/Screen/Booking/store/slice';
import { PrescriptionAddress } from 'src/Network/Model/PrescriptionAddress';
import { PrescriptionPharmacyNew } from 'src/Network/Model/PrescriptionPharmacyNew';
import { RECEIVE_ADDRESS_PHARMACY, RECEIVE_PHARMACY } from 'src/constant';
import { PharmacyInfo } from 'src/Network/Model/PharmacyInfo';

//PrescriptionReceiveConfirm: PrescriptionReceive at step 3, 4
const PrescriptionReceiveConfirm = () => {
    const { t } = useTranslation();
    const addressStore = useAppSelector(selectDataAddress);
    const methodReceive = addressStore?.methodReceive;
    const address:PrescriptionAddress = methodReceive == RECEIVE_PHARMACY ? undefined : addressStore?.prescriptionAddresses;
    const pharmacy:PrescriptionPharmacyNew = addressStore?.prescriptionPharmacy;
    const pharmacyInfo:(PharmacyInfo | undefined) = pharmacy?.pharmacy_info;

    return (
        <div className='receptioninfo prescriptionReceiveConfirm'>
            <Row className="prescription-row" gutter={[{}, { xs: 24, sm: 24, md: 24 }]}>
                <Col span={24} className='prescription-title-row'>
                    <span className="text-bold-styles prescription-title">{t('PrescriptionReceive.title')}</span>
                </Col>
                <Col span={24} className='prescription-title-row'>
                    <span className="text-bold-styles prescription-method">
                        {methodReceive == RECEIVE_ADDRESS_PHARMACY ? t('PrescriptionReceive.radio1') : t('PrescriptionReceive.radio2')}
                    </span>
                </Col>
                {
                    address &&
                    <Col span={24}>
                        <Col span={24} className='result-header' style={{paddingBottom: '10px'}}>
                            <span className="text-bold-styles">{t('PrescriptionReceiveNew.info1.title')}</span>
                        </Col>
                        <Col span={24} className="prescription-info">
                            <Row className='detailPharmacy' gutter={[{ xs: 16, sm: 16, md: 16 }, { xs: 16, sm: 16, md: 16 }]}>
                                <Col xs={24} sm={24} md={12} className='detailPharmacy-left'>
                                    <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.address.regist-name"), content: address.regist_name }} />
                                    <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.address.postal-code"), content: address.postal_code }} />
                                </Col>
                                <Col xs={24} sm={24} md={12} className='detailPharmacy-right'>
                                    <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.address.location"), content: address.city_name + '' + address.prefectures_name + '' + address.address }} />
                                    <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.address.send-name"), content: address.send_name }} />
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                }
                {
                    pharmacy &&
                    <Col span={24}>
                        <Col span={24} className='result-header' style={{paddingBottom: '10px'}}>
                            <span className="text-bold-styles">{t('PrescriptionReceiveNew.info2.title')}</span>
                        </Col>
                        <Col span={24} className="prescription-info">
                            <Row className='detailPharmacy' gutter={[{ xs: 16, sm: 16, md: 16 }, { xs: 16, sm: 16, md: 16 }]}>
                                <Col xs={24} sm={24} md={12} className='detailPharmacy-left'>
                                    {
                                        pharmacyInfo ?
                                        (
                                            <>
                                            {
                                                pharmacyInfo?.title &&
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.pharmacy-name"), content: pharmacyInfo.title }} />
                                            }
                                            {
                                                pharmacyInfo?.tel &&
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.tel"), content: pharmacyInfo.tel }} />
                                            }
                                            {
                                                pharmacyInfo?.fax &&
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.fax"), content: pharmacyInfo.fax }} />
                                            }
                                            {
                                                pharmacyInfo?.zip &&
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.postal-code"), content: pharmacyInfo.zip }} />
                                            }
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.location"), content: pharmacyInfo.prefecture + '' + pharmacyInfo.city + '' + pharmacyInfo.address }} />
                                            </>
                                        ) :
                                        (
                                            <>
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.pharmacy-name"), content: pharmacy.pharmacy_name }} />
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.postal-code"), content: pharmacy.postal_code }} />
                                            </>
                                        )
                                    }
                                </Col>
                                <Col xs={24} sm={24} md={12} className='detailPharmacy-right'>
                                    {
                                            pharmacyInfo ?
                                            (
                                                <>
                                                {
                                                    pharmacyInfo?.open &&
                                                    <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.open"), content: pharmacyInfo.open }} />
                                                }
                                                {
                                                    pharmacyInfo?.holiday &&
                                                    <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.holiday"), content: pharmacyInfo.holiday }} />
                                                }
                                                </>
                                            ):
                                            (
                                                <>
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.location"), content: pharmacy.city_name + '' + pharmacy.prefectures_name + '' + pharmacy.address}} />
                                                <ItemInfo data={{ title: t("PrescriptionReceiveConfirm.pharmacy.fax"), content: pharmacy.fax }} />
                                                </>
                                            )
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                }
            </Row>
        </div>
    )
}

const ItemInfo = ({ data }: any) => {
    return (
        <div>
            <div className='itemTitle text-bold-styles'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.title)}></span>
            </div>
            <div className='itemContent'>
                <span dangerouslySetInnerHTML={replaceWithBr(data?.content)}></span>
            </div>
        </div>
    )
}

export default PrescriptionReceiveConfirm;
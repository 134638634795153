import { Col, Row } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import warning from 'src/assets/image/warning.svg';
import TabsRegisterAddress from '../TabsRegisterAddress';
import { useState } from 'react';
import PharmacyGuide from 'src/Component/Modal/PharmacyGuide';

const RegisterAddress = (props:any) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(props.tabIndex?props.tabIndex:1);
    const [showModalPharmacy, setShowModalPharmacy] = useState(false);
    const {
        prefList,
        cityList,
        setIsModalOpenNewPharmacy,
        methodReceive,
        setVisibleSuccess,
        prescriptionPharmacy,
        setIsModalOpenEditPharmacy,
        setCityList,
    } = props;

    return (
        <>
            <Row className={"tabs_register_address_container" + (props.typeModal && "tabs_register_address_container_modal")}
                gutter={[{}, { xs: 15, sm: 15, md: 15 }]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-bold-styles titleModalContent" >
                    {t("ReceptionInfo.register_adress_new.title")}
                </Col>
                {   currentTab !== 1 &&
                    <Col span={24}>
                    <span>{t("LetterPrescription.link1")}（</span>
                        <span onClick={() => setShowModalPharmacy(true)} style={{ color: '#308AE6', cursor: 'pointer' }}>{t("LetterPrescription.link2")}</span>
                        <span>）{t("LetterPrescription.link3")}</span>
                    </Col>
                }
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <TabsRegisterAddress
                        setCurrentTab = {setCurrentTab}
                        prefList={prefList}
                        cityList={cityList}
                        setVisibleSuccess={setVisibleSuccess}
                        setIsModalOpenNewPharmacy={setIsModalOpenNewPharmacy}
                        methodReceive={methodReceive}
                        currentTab={currentTab}
                        prescriptionPharmacy={prescriptionPharmacy}
                        setIsModalOpenEditPharmacy={setIsModalOpenEditPharmacy}
                        setCityList={setCityList}
                    />
                </Col>
            </Row>
            {
                showModalPharmacy &&
                <PharmacyGuide changeVisibleModal={() => setShowModalPharmacy(false)} />
            }
        </>
    )
}

export default RegisterAddress;
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Redux/store';
import { revertAll } from 'src/Redux/hooks';

const initialState = {
  patientName: '',
}

const slice = createSlice({
  name: "patientSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    changePatientName: (state, action) => {
      state.patientName = action.payload;
    },
  }
});

export const selectPatientName = (state:RootState) => state.common.patientSlice.patientName

export const {
    changePatientName,
} = slice.actions;

export const PatientReducer = slice.reducer;
import './styles.css';
import { Col, Row } from 'antd';
import img_footer from 'src/assets/image/img-footer.svg';
import { useTranslation } from "react-i18next";
import { useAppSelector } from 'src/Redux/hooks';
import { selectCheckStepFooter } from '../ArrowStepper/Slice/Slice';

const Footer = () => {
  const { t } = useTranslation();
  const checkStep = useAppSelector(selectCheckStepFooter);
  
  return (
    <>
    <Row className='footer'>
      <Col className='footerLogo'>
        <img src={img_footer} alt=''/>
      </Col>
      <Col className='footerText'>
        {t("footer.text")}
      </Col>
    </Row>
    {checkStep ? <div className='footer-mobile'></div> :  <></>}
    </>
  );
};
  
export default Footer;
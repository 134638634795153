import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useGetListConsentFormQuery } from 'src/Network/Home/DoctorSettingApi';
import { ConsentFormDataType } from 'src/Network/Model/DoctorSetting';
import { useAppDispatch } from 'src/Redux/hooks';
import { ReactComponent as EditIcon } from '../../../../assets/image/edit_icon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/image/trash_icon.svg';
import {
  changeIsCancelModalOpen,
  changeIsCreate,
  changeIsModalOpen,
  changeSelectedDeleteId,
  changeSelectedUpdateId,
  changeSelectedUpdateRecord
} from './store/slice';
import { getClinicIdFromLocalStorage } from 'src/common/Libs';

const AgreementManagementTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clinicId = getClinicIdFromLocalStorage();
  const { data: listConsentForm } = useGetListConsentFormQuery(clinicId, {
    skip: clinicId < 0
  });

  const formatDateJa = (date: string) =>
    dayjs(date).locale('ja').format('YYYY年MM月DD日');
  const formatTimeJa = (date: string) => dayjs(date).format('HH:mm');

  const handleEditItem = (record: ConsentFormDataType) => {
    dispatch(changeIsCreate(false));
    dispatch(changeSelectedUpdateRecord(record));
    dispatch(changeSelectedUpdateId(record.id));
    dispatch(changeIsModalOpen(true));
  };

  const handleDeleteItem = (value: number) => {
    dispatch(changeIsCancelModalOpen(true));
    dispatch(changeSelectedDeleteId(value));
  };

  const columns: ColumnsType<ConsentFormDataType> = [
    {
      title: (
        <div className="consent-form_table-title">
          {t('reExamCode.titleHospitalDisplay')}
        </div>
      ),
      dataIndex: 'title'
    },
    {
      title: (
        <div className="consent-form_table-title">
          {t('reExamCode.titleDisplayPatient')}
        </div>
      ),
      dataIndex: 'title_for_patient'
    },
    {
      title: (
        <div className="consent-form_table-title">
          {t('reExamCode.registedTime')}
        </div>
      ),
      dataIndex: 'created_datetime',
      colSpan: 2,
      align: 'left',
      render: (value) => <span>{value ? formatDateJa(value) : ''}</span>
    },
    {
      title: (
        <div className="consent-form_table-title">
          {t('reExamCode.registedTime')}
        </div>
      ),
      dataIndex: 'created_datetime',
      colSpan: 0,
      render: (value) => <span>{value ? formatTimeJa(value) : ''}</span>
    },
    {
      title: (
        <div className="consent-form_table-title">
          {t('reExamCode.updatedTime')}
        </div>
      ),
      dataIndex: 'updated_datetime',
      colSpan: 2,
      align: 'left',
      render: (value) => <span>{value ? formatDateJa(value) : ''}</span>
    },
    {
      title: (
        <div className="consent-form_table-title">
          {t('reExamCode.updatedTime')}
        </div>
      ),
      dataIndex: 'updated_datetime',
      colSpan: 0,
      render: (value) => <span>{value ? formatTimeJa(value) : ''}</span>
    },
    {
      title: '',
      dataIndex: 'id',
      width: 220,
      render: (value, record) => {
        return (
          <div className="agreement-item-action_container">
            <button
              onClick={() => handleEditItem(record)}
              className="agreement-item_button"
            >
              <EditIcon />
              <span>{t('reExamCode.edit')}</span>
            </button>
            <button
              onClick={() => handleDeleteItem(value)}
              className="agreement-item_button agreement-item_button_delete"
            >
              <TrashIcon />
              <span>{t('reExamCode.delete')}</span>
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="tabsetting-scrollbar-style">
      <Table
        className="agreement-management_table tabsetting-scrollbar-style"
        columns={columns}
        dataSource={listConsentForm || []}
        bordered
        pagination={false}
        rowKey={(record) => record.id}
        sticky
        scroll={{ y: 420 }}
        locale={{ emptyText: t('MESS_HAS_NO_DATA') }}
      />
    </div>
  );
};

export default AgreementManagementTable;

import { persistStore } from 'redux-persist';

// import reducer from './rootReducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { UserReducer } from './Slice/Slice';
import { QRSliceReducer } from 'src/Component/Modal/ModalQRCode/QRSlice';
import { QuestionReducer } from '../Screen/Booking/store/slice';
import { homeApi } from '../Network/Home/HomeClinicApi';
import persistReducer from 'redux-persist/es/persistReducer';
import { setupListeners } from '@reduxjs/toolkit/query';
import { PatientReducer } from '../Component/BookingInfoPatient/Slice/Slice';
import { TabsReducer } from '../Component/BookingContent/slice';
import { ShowModalUserReducer } from 'src/Component/Header/slice';
import { ReservationReducer } from 'src/Component/ArrowStepper/Slice/Slice';
import { RegisterReducer } from 'src/Component/Register/slice';
import { ConfirmRegisterReducer } from 'src/Component/ConfirmRegister/slice';
import { TimeBookingReducer } from 'src/Component/TimeBooking/slice';
import { CalendarBookingReducer } from 'src/Component/CalendarBooking/slice';
import { RegisterSuccessReducer } from 'src/Component/Modal/RegisterSuccess/slice';
import doctorApi from '../Network/Home/DoctorApi';
import { BiologicalDataReducer } from 'src/Component/PT-05_A/slice';
import zipcodeApi from 'src/Network/Home/ZipcodeApi';
import { MedicalDetailReceiveReducer } from 'src/Component/MedicalDetailReceive/slice';
import { MedicalPharmacySliceReducer } from 'src/Component/PrescriptionReceive/slice';
import { AgreementManagementReducer } from 'src/Component/Doctor/TabsSetting/agreementManagement/store/slice';
import { ShowModalCancelReducer } from 'src/Component/Doctor/Reservation/slice';
import { MedicalMenuSliceReducer } from 'src/Component/Doctor/TabsSetting/medicalMenu/store/slice';
import { RsvRegistElementSideBarReducer } from 'src/Screen/Doctor/DT-09/slice';
import doctorSettingApi from 'src/Network/Home/DoctorSettingApi';
import vitalSignalApi from 'src/Network/Home/VitalSignalApi';
import { PharmacyGuideReducer } from 'src/Component/Modal/PharmacyGuide/slice';

// const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const reducers = combineReducers({
  userSlice: UserReducer,
  questionSlice: QuestionReducer,
  patientSlice: PatientReducer,
  tabsSlice: TabsReducer,
  QRSlice: QRSliceReducer,
  reservationSlice: ReservationReducer,
  registerSlice: RegisterReducer,
  confirmRegisterSlice: ConfirmRegisterReducer,
  timeBookingSlice: TimeBookingReducer,
  calendarBookingSlice: CalendarBookingReducer,
  registerSuccessSlice: RegisterSuccessReducer,
  biologicalData: BiologicalDataReducer,
  medicalDetailReceiveSlice: MedicalDetailReceiveReducer,
  medicalPharmacySlice: MedicalPharmacySliceReducer,
  showModalCancelSlice: ShowModalCancelReducer,
  rsvRegistElementSideBar: RsvRegistElementSideBarReducer,
  pharmacyGuideSlice: PharmacyGuideReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: {
    common: persistedReducer,
    agreementManagementSlice: AgreementManagementReducer,
    medicalMenuSlice: MedicalMenuSliceReducer,
    showModalUserSlice: ShowModalUserReducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
    [zipcodeApi.reducerPath]: zipcodeApi.reducer,
    [doctorSettingApi.reducerPath]: doctorSettingApi.reducer,
    [vitalSignalApi.reducerPath]: vitalSignalApi.reducer,
  },
  devTools: { trace: true, traceLimit: 25 },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(
      // concat your middleware
      homeApi.middleware,
      doctorApi.middleware,
      zipcodeApi.middleware,
      doctorSettingApi.middleware,
      vitalSignalApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
setupListeners(store.dispatch);

export default store;

import './styles.css'
import HistoryBreadcrumb from '../../Component/HistoryBreadcrumb';
import HistoryContent from '../../Component/HistoryContent';
import SearchBookingHistory from '../../Component/SearchClinicsOnline';
import ListClinicsOnline from '../../Component/ListClinicsOnline';
import { useSearchClinicMutation } from 'src/Network/Home/HomeClinicApi';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface DataTypeResult {
    key: number;
    nameClinic: string;
    address: string;
    doctor: string;
    clinic_id: string;
}

const History = () => {
    const [searchClinic] = useSearchClinicMutation();
    const [dataSearch, setDataSearch] = useState<DataTypeResult[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResult, setTotalResult] = useState(0);
    const [prefIdValue, setPrefIdValue] = useState('');
    const [cityIdValue, setCityIdValue] = useState('');
    const [departmentIdValue, setDepartmentIdValue] = useState('');
    const [freetxtValue, setFreetxtValue] = useState('');
    const [loading, setLoading] = useState(false);
    const pageSize = 100;
    const { t } = useTranslation();
    const [isClickSearch, setIsClickSearch] = useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);

    const onClickSubmit = (prefIdValue: string, cityIdValue: string, departmentIdValue: string, inputValueFreetxt: string, latitude: number, longitude: number) => {
        setPrefIdValue(prefIdValue);
        setCityIdValue(cityIdValue);
        setDepartmentIdValue(departmentIdValue);
        setFreetxtValue(inputValueFreetxt);
        setIsClickSearch(true);
        setCurrentPage(1);
        setLatitude(latitude);
        setLongitude(longitude);
    }
    useEffect(() => {
        if (currentPage && departmentIdValue) {
            setLoading(true);
            searchClinic({pref_id: prefIdValue, city_id: cityIdValue, department_id: departmentIdValue,
                freetxt: freetxtValue, latitude: latitude, longitude: longitude, page: currentPage, limit : pageSize}).then((res: any) => {
                const dataList = res?.data?.data;
                const pagination = res?.data.paginate
                setTotalResult(pagination.total)
                
                const data: DataTypeResult[] = dataList?.map(
                    (item: any, index: number) => {
                      return {
                        key: (currentPage - 1) * pageSize + (index + 1),
                        nameClinic: item?.clinic_name,
                        address: item?.prefectures_name + item?.city_name + item?.address,
                        doctor: item?.doctor_name??'',
                        clinic_id: item?.clinic_id
                      };
                    }
                );
                setDataSearch(data);
                setLoading(false);
            })
        }
    }, [currentPage, prefIdValue, cityIdValue, departmentIdValue, freetxtValue, latitude, longitude])
    return (
        <div className="history-container">
            <HistoryBreadcrumb />
            <div className="header-h1 text-bold-styles">
                新規予約申し込み
            </div>
            <div className="header-wrapper text-bold-styles">
                {t("reservation.header")}
            </div>
            <HistoryContent />
            <div className="title-search text-bold-styles">
            {t("reservation.title")}
            </div>
            <SearchBookingHistory
                onClickSubmit={onClickSubmit}
            />
            <Spin spinning={loading}>
                <ListClinicsOnline
                    dataSearch={dataSearch}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalResult={totalResult}
                    pageSize={pageSize}
                    isClickSearch={isClickSearch}
                />
            </Spin>
            
        </div>
    )
};

export default History
import { Radio, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import SearchBar from '../searchBar';
import { CustomClinicUserResponse } from 'src/Network/Model/User';
import { CustomTreatmentMenuByDateResponse} from 'src/Network/Model/TreatmentMenu';
import { ReactComponent as InfoOutlineIcon } from '../../../../assets/image/info_outline.svg';
import { useSelector } from 'react-redux';
import { selectClinicChoose, selectSelectionRegistList, selectUserChoose } from '../slice';
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {lengthFullAndHalfWidth } from 'src/Utils/textUtils';
import FormInputTextArea from 'src/Component/common/form/FormInputTextArea';

interface typeOption {
    label: string;
    value: string;
}

interface typeExmTime {
    label1_1: number;
    label1_2: number;
    label2_1: number;
    label2_2: number;
}

interface typeExmMethod {
    method: number;
}
interface Props {
    treatmentMenuTableData: CustomTreatmentMenuByDateResponse[];
    clinicUsers: any[];
    onRegist: (treatment_menu_id: number, treatment_menu_name: string, rsv_div: number, exm_time: number, doctor_id: number, doctor_name: string, day_note: string, rsv_type: number) => void;
    isOutsideClicked: boolean;
}

const TabContent1: React.FC<Props> = ({treatmentMenuTableData, clinicUsers, onRegist, isOutsideClicked}) => {
    const { t } = useTranslation();
    const [radioValue, setRadioValue] = useState(0);
    const [radioRsvValue, setRadioRsvValue] = useState('');
    const [userName, setUserName] = useState('-');
    const [gender, setGender] = useState('-');
    const [birth, setBirth] = useState('-');
    const [karuteId, setKaruteId] = useState('-');
    const [statusCardId, setStatusCardId] = useState('-');
    const [clinicUserArr, setClinicUserArr] = useState<CustomClinicUserResponse[]>([]);
    const [optionClinicUser, setOptionClinicUser] = useState<typeOption[]>([]);
    const [treatmentMenuArr, setTreatmentMenuArr] = useState<CustomTreatmentMenuByDateResponse[]>([]);
    const [optionTreatmentMenu, setOptionTreatmentMenu] = useState<typeOption[]>();
    const [treatmentMenuExaminationTime, setTreatmentMenuExaminationTime] = useState<typeExmTime[]>();
    const [exmMethod, setExmMethod] = useState<typeExmMethod[]>();
    const [treatmentMenu, setTreatmentMenu] = useState<number | null>();
    const [doctor, setDoctor] = useState<number | null>();
    const [indexTreatmentMenu, setIndexTreatmentMenu] = useState(0);
    const [doctorChoose, setDoctorChoose] = useState(0);
    const [treatmentMenuChoose, setTreatmentMenuChoose] = useState(0);
    const [dayNote, setDayNote] = useState('');
    const CheckSelectionRegist = useSelector(selectSelectionRegistList);
  
    useEffect(() => {
        if (!CheckSelectionRegist.user_name) {
            setDayNote('');
            methods.reset(defaultValues);
        }
    }, [CheckSelectionRegist]);

    const renderCount = ({ value, count }: { value: string; count: number; maxLength?: number }) => {
        return <span>{`${count} / 2000文字`}</span>;
    };
    const handleSearch = (valueRadio: number) => {
        setRadioValue(valueRadio);
    }
    const handleChangeRsvType = (valueRadio: string) => {
        setRadioRsvValue(valueRadio);
    }

    const handleSelectedOptionLabel = (karute_id: string, name: string, gender: string, birth: string, status: string) => {
        setKaruteId(karute_id);
        setUserName(name);
        setGender(gender);
        setBirth(birth);
        setStatusCardId(status);
    };

    useEffect(() => {
        setClinicUserArr(clinicUsers || []);
        setTreatmentMenuArr(treatmentMenuTableData || []);
    }, [clinicUsers, treatmentMenuTableData]);

    useEffect(() => {
        setOptionTreatmentMenu(getTreatmentMenuOptions());
        setTreatmentMenuExaminationTime(getTreatmentMenuExaminationTime());
        setExmMethod(getExmMethod());
    }, [treatmentMenuArr])

    useEffect(() => {
        if (clinicUserArr) {
            setOptionClinicUser(getClinicUserOptions());
        }
    }, [clinicUserArr])

    const numberOfClinicChoose = useSelector(selectClinicChoose ?? 0);
    useEffect(() => {
        if (numberOfClinicChoose || !isNaN(numberOfClinicChoose)) {
            setTreatmentMenuChoose(numberOfClinicChoose);
        }
    }, [numberOfClinicChoose])

    useEffect(() => {
        const selectedIndex = optionTreatmentMenu?.findIndex(item => Number(item?.value) === treatmentMenuChoose);
        if (optionTreatmentMenu) {
            if (selectedIndex !== -1) {
                handleChangeTreatmentMenu(treatmentMenuChoose);
            } else {
                handleChangeTreatmentMenu(Number(treatmentMenuArr[0]?.id));
            }
        }
    }, [optionTreatmentMenu])

    const numberOfDoctorChoose = useSelector(selectUserChoose ?? 0);
    useEffect(() => {
        if (numberOfDoctorChoose || !isNaN(numberOfDoctorChoose)) {
            setDoctorChoose(numberOfDoctorChoose);
        }
    }, [numberOfDoctorChoose])
    
    useEffect(() => {
        const selectedIndex = optionClinicUser?.findIndex(item => Number(item?.value) === doctorChoose);
        if (optionClinicUser) {
            if (selectedIndex !== -1) {
                handleChangeDoctor(doctorChoose);
            } else {
                handleChangeDoctor(Number(optionClinicUser[0]?.value));
            }
        }
    }, [optionClinicUser])

    const getTreatmentMenuOptions = () => {
        let result: { label: string, value: string }[] = [];
        const uniqueTreatmentMenus = new Set();
        treatmentMenuArr.forEach((tm) => {
            const option = {
                value: tm.id,
                label: tm.treatment_menu_name
            };
            const key = `${option.value}-${option.label}`;
            if (!uniqueTreatmentMenus.has(key)) {
                uniqueTreatmentMenus.add(key);
                result.push(option);
            }
        });
    
        return result;
    };

    const getClinicUserOptions = () => {
        let result: { label: string, value: string }[] = [];
        return result.concat(clinicUserArr.map((tm) => ({
            value: tm.user_id,
            label: tm.user_name
        })));
    };

    const getTreatmentMenuExaminationTime = () => {
        let result: { label1_1: number, label1_2: number, label2_1: number, label2_2: number }[] = [];
        return result.concat(treatmentMenuArr.map((tm) => ({
          label1_1: tm.consul_time_first_exm !== undefined ? tm.consul_time_first_exm : 0,
          label1_2: tm.consul_time_re_exm !== undefined ? tm.consul_time_re_exm : 0,
          label2_1: tm.f_consul_time_first_exm !== undefined ? tm.f_consul_time_first_exm : 0,
          label2_2: tm.f_consul_time_re_exm !== undefined ? tm.f_consul_time_re_exm : 0,
        })));
    };

    const getExmMethod = () => {
        let result: { method: number }[] = [];
        return result.concat(treatmentMenuArr.map((tm) => ({
          method: tm.exm_method !== undefined ? tm.exm_method : 0,
        })));
    };
    

    const handleChangeTreatmentMenu = (clinic: number) => {
        setTreatmentMenu(clinic);
        const selectedIndex = optionTreatmentMenu?.findIndex(item => Number(item?.value) === clinic);
        setIndexTreatmentMenu(selectedIndex ?? 0);
        const elementMethodcheck = exmMethod?.[selectedIndex || 0]?.method;
        if (elementMethodcheck === 1 || elementMethodcheck === 2) {
            setRadioRsvValue(t("ishachoku-member-tab.radio1"));
        } else if(elementMethodcheck === 0){
            setRadioRsvValue(t("ishachoku-member-tab.radio2"));
        }

        if (treatmentMenuArr[selectedIndex ?? 0]?.clinic_users) {

            if (treatmentMenuArr[selectedIndex ?? 0]?.clinic_users?.length !== 0) {
                
                const clinicUsersJson = treatmentMenuArr[selectedIndex ?? 0]?.clinic_users || "";
                const clinicUserArr2 = JSON.parse(clinicUsersJson);
                const modifiedClinicUserArr = clinicUserArr2.map((user: any) => {
                    return {
                        user_id: user.clinic_user_id,
                        user_name: user.clinic_user_name
                    };
                });
                setClinicUserArr(modifiedClinicUserArr);
            }
        }

    }

    const handleChangeDoctor = (doctor: number) => {
        setDoctor(doctor);
    }

    useEffect(() => {
        if (isOutsideClicked === true) {
            const exmMethod = radioRsvValue === t("ishachoku-member-tab.radio1") ? 1 : 0;
            const exmTimeRegist = radioRsvValue === (t("ishachoku-member-tab.radio1")) ? 
                (radioValue === 0 ? elementExaminationTime?.label2_1 : elementExaminationTime?.label2_2 ) 
                : 
                (radioValue === 0 ? elementExaminationTime?.label1_1 : elementExaminationTime?.label1_2);
            const selectedIndexDoctor = optionClinicUser?.findIndex(item => Number(item?.value) === doctor);
            const selectedIndexTreatmentMenu = optionTreatmentMenu?.findIndex(item => Number(item?.value) === treatmentMenu);

            onRegist(treatmentMenu ?? 0,  optionTreatmentMenu?.[selectedIndexTreatmentMenu ?? 0]?.label ?? '', exmMethod, exmTimeRegist ?? 0, doctor ?? 0, optionClinicUser[selectedIndexDoctor].label, dayNote, radioValue);
        }
    }, [isOutsideClicked]);

    const elementMethod = exmMethod?.[indexTreatmentMenu]?.method ?? exmMethod?.[0]?.method;
    const elementExaminationTime = treatmentMenuExaminationTime?.[indexTreatmentMenu] ?? treatmentMenuExaminationTime?.[0];
    const elementMethodOutOfValue = elementMethod !== 0 && elementMethod !== 1 && elementMethod !== 2;

    const defaultValues = {
        dayNote: "",
    };
    const schema = yup.object().shape({
        dayNote: yup
            .string()
            .test(
                'lengthFullAndHalfWidth',
                t('register.label-maxlength')
                    .replace('{0}', t("ishachoku-member-tab.label10"))
                    .replace('{1}', '2000'),
                (value) => {
                    return lengthFullAndHalfWidth(value) <= 2000;
                },
            )
    });
    type FormData = yup.InferType<typeof schema>;
    const methods = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(schema),
        mode: "all",
    });

    return (
        <FormProvider {...methods}>
            <div className='tab1-element'>
                <div>
                    <SearchBar setSelectedOptionLabel={handleSelectedOptionLabel} />
                </div>
            </div>
            <div className='tab1-element'>
                <div>
                    <label>{t("ishachoku-member-tab.label1")}</label>
                </div>
                <div>
                    <span className='text-normal-styles'>{karuteId === '' ? '-' : karuteId}</span>
                </div>
            </div>
            <div className='tab1-element'>
                <div>
                    <label>{t("ishachoku-member-tab.label2")}</label>
                </div>

                <div>
                    <span className='text-normal-styles'>{userName === '' ? '-' : userName}</span>
                </div>
            </div>
            <div className='tab1-element'>
                <div>
                    <label>{t("ishachoku-member-tab.label3")}</label>
                </div>
                <div>
                    <span className='text-normal-styles'>{gender === '' ? '-' : gender}</span>
                </div>
            </div>
            <div className='tab1-element'>
                <div>
                    <label>{t("ishachoku-member-tab.label4")}</label>
                </div>
                <div>
                    <span className='text-normal-styles'>{birth === '' ? '-' : birth}</span>
                </div>
            </div>
            <div className='tab1-element'>
                <div>
                    <label>{t("ishachoku-member-tab.label5")}</label>
                </div>
                <div>
                    <span className='text-normal-styles'>{statusCardId === '' ? '-' : statusCardId}</span>
                </div>
            </div>
            <div className='tab1-element'>
                <div>
                    <label className='text-normal-styles'>{t("ishachoku-member-tab.label6")}</label>
                </div>
                <Select placeholder={treatmentMenu ? treatmentMenu : null} style={{ width: '50%' }} disabled={userName === '-'} value={treatmentMenu ? treatmentMenu : null} onChange={handleChangeTreatmentMenu} options={optionTreatmentMenu}></Select>
            </div>
            <div className='tab1-element'>
                <label className='text-normal-styles'>{t("ishachoku-member-tab.label7")}</label>
                <div>
                    <Radio.Group onChange={(e) => { handleChangeRsvType(e.target.value) }} value={radioRsvValue} className="text-normal-styles searchRadio">
                        <Radio value={t("ishachoku-member-tab.radio1")} disabled={elementMethod === 0 || elementMethodOutOfValue || userName === '-'}>{t("ishachoku-member-tab.radio1")}</Radio>
                        <Radio value={t("ishachoku-member-tab.radio2")} disabled={elementMethod === 1 || elementMethodOutOfValue || userName === '-'}>{t("ishachoku-member-tab.radio2")}</Radio>
                    </Radio.Group>
                </div>
            </div>

            <div className='tab1-element'>
                <label className='text-normal-styles'>{t("ishachoku-member-tab.label8")}</label>
                <div>
                    <Radio.Group onChange={(e) => { handleSearch(e.target.value) }} defaultValue={radioValue} className="text-normal-styles searchRadio" disabled={userName === '-'}>
                        <Radio value={0}>{radioRsvValue === (t("ishachoku-member-tab.radio1")) ? 
                        elementExaminationTime?.label2_1
                        :
                        elementExaminationTime?.label1_1}{t("ishachoku-member-tab.radio3")}</Radio>
                        <Radio value={1}>{radioRsvValue === (t("ishachoku-member-tab.radio1")) ? 
                        elementExaminationTime?.label2_2
                        :
                        elementExaminationTime?.label1_2}{t("ishachoku-member-tab.radio4")}</Radio>
                    </Radio.Group>
                </div>
                <p className="reservation-accept-time_note" style={(userName === '-') ? {display: 'none'} : {}}>
                    <InfoOutlineIcon />
                        <span>{t("ishachoku-member-tab.notice")}</span>
                </p>
            </div>
            <div className='tab1-element'>
                <div>
                    <label className='text-normal-styles'>{t("ishachoku-member-tab.label9")}</label>
                </div>
                <div>
                    <Select placeholder={doctor ? doctor : null} style={{ width: '50%' }} disabled={userName === '-'} value={doctor ? doctor : null} onChange={handleChangeDoctor} options={optionClinicUser}></Select>
                </div>
            </div>
            <div className='text-area-regist-medical-tab1 tab1-element'>
                <div>
                    <label className='text-normal-styles'>{t("ishachoku-member-tab.label10")}</label>
                </div>
                {/* // Day note - text area - max length 2000 bytes (\1.docs\04_Design\Detail Design\Yost_詳細仕様書_DT-01 DT-02 DT-09（診療予約管理）.xlsx)  */}
                <FormInputTextArea
                    showCount={{ formatter: renderCount }}
                    maxLength={2000}
                    name="dayNote"
                    row={5}
                    onChange={setDayNote}
                    value={dayNote}
                    placeholder={t("ishachoku-member-tab.placeholder4")}
                    style={{ height: 120, resize: 'none' }}
                    disabled={userName === '-'}
                />
            </div>
        </FormProvider>
    );
};

export default TabContent1;
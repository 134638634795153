import {Button, Modal} from "antd";
import React, {useState} from "react";
import './styles.css';
import {CardData} from "../../../Network/Model/Card";
import {useTranslation} from "react-i18next";
import {useRegisterCardMutation} from "../../../Network/Home/HomeClinicApi";
import {KEY_USER} from "../../../common/Constant";
import ModalWarning from "../../Modal/ModalWarning";
import {BUTTON_CLOSE, MODAL_CONFIRM, MODAL_NEGATIVE} from "../../../constant";
import visa from "../../../assets/image/visaCard.png";
import masterCard from "../../../assets/image/masterCard.png";
import jcbCard from "../../../assets/image/jcbCard.png";
import americanCard from "../../../assets/image/americanExpressCard.png";
import dinerCard from "../../../assets/image/dinersClubCard.png";
import infoIcon from "../../../assets/image/info2.svg";
import Loading from "../../Modal/Loading";


const APP = ({
							 isOpen,
							 onClose,
							 cardData,
							 onRegisterFinish
						 }: {
	isOpen: boolean,
	onClose: Function,
	cardData: CardData,
	onRegisterFinish: (event: CustomEvent) => void
}) => {
	const {t} = useTranslation();
	const handleClose = () => {
		onClose();
	};
	const [modalMsg, setModalMsg] = useState<string>('');
	const [modalType, setModalType] = useState<number>(1);
	const [modalBtnType, setModalBtnType] = useState<number>(1);
	const [modalTitle, setModalTitle] = useState<string>();
	const [registCard] = useRegisterCardMutation();
	const [isLoading, setIsLoading] = useState(false);
	const handleRegisterNewCard = () => {
		setIsLoading(true);
		const param = {
			user_id: JSON.parse(localStorage.getItem(KEY_USER) ?? '{}').user_id,
			card_number: cardData.cardNumbers,
			limit_month: cardData.limitMonth,
			limit_year: cardData.limitYear,
			cc_csc: cardData.cvvNumbers
		};
		registCard(param).then((res: any) => {
			if (res?.data?.code && res?.data?.code === 200) {
				setModalMsg(t("MESS_INFO_REGIST_CREDIT_CARD_SUCCESS"));
				setModalType(MODAL_CONFIRM);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_SUCCESS"));
				setIsLoading(false);
			} else {
				setModalMsg(res?.data?.message || t("MESS_ERR_CANNOT_REGIST_CREDIT_CARD"));
				setModalType(MODAL_NEGATIVE);
				setModalBtnType(BUTTON_CLOSE);
				setModalTitle(t("message.TITLE_FAIL"));
				setIsLoading(false);
			}
		});
	}

	return <Modal
		className={'cfRegisterCard'}
		centered
		open={isOpen}
		title={t("card_payment.register-title")}
		closeIcon={true}
		footer={<div style={{display: "flex", justifyContent: "center"}}>
			<Button type={"text"} className={'confirmBtn'}
							onClick={handleRegisterNewCard}>{t("card_payment.register-btn")}</Button>
		</div>} onCancel={handleClose}>
		<p style={{marginBottom: 0}}>{t('card_payment.info3')}</p>
		<div className={'cardList'}>
			<img className={'cardImg'} src={visa} alt={''}/>
			<img className={'cardImg'} src={masterCard} alt={''}/>
			<img className={'cardImg'} src={jcbCard} alt={''}/>
			<img className={'cardImg'} src={americanCard} alt={''}/>
			<img className={'cardImg'} src={dinerCard} alt={''}/>
		</div>
		<table style={{width: "100%"}}>
			<tr>
				<td>{t("card_payment.card-numbers")}</td>
				<td>{cardData?.cardNumbers}</td>
			</tr>
			<tr>
				<td>
					{t("card_payment.limit-date")}
				</td>
				<td>
					{cardData?.limitMonth}{t('card_payment.month')}・{cardData?.limitYear}{t('card_payment.year')}
				</td>
			</tr>
			<tr>
				<td style={{width: "50%"}}>
					{t("card_payment.cvv-numbers")}
				</td>
				<td>
					{cardData?.cvvNumbers}
				</td>
			</tr>
		</table>
		<p style={{marginTop: 24}}>
			<img src={infoIcon} alt={''}/><span className={'info1'}>{t('card_payment.info1')}</span>
			<br/>
			<span className={'info2'}>{t('card_payment.info2')}</span>
		</p>
		{modalMsg && modalMsg !== '' && (
			<ModalWarning
				message={modalMsg}
				typeModal={modalType}
				typeButton={modalBtnType}
				title={modalTitle}
				changeVisibleModal={() => {
					setModalMsg('');
					if (modalType == MODAL_CONFIRM) {
						onRegisterFinish(new CustomEvent(''));
					}
				}
				}
			/>
		)}
		{isLoading &&
    <Loading/>
		}

	</Modal>
}

export default APP;
import FormRadioGroup from 'src/Component/common/form/FormRadioGroup';

type OptionType = {
  value: string | number;
  label: string;
};

type Props = {
  inputTitle: string;
  name: string;
  options: OptionType[];
  inputNote?: string;
  defaultValue?: string | number;
};

const RadioGroupForm: React.FC<Props> = ({
  inputTitle,
  name,
  options,
  inputNote,
  defaultValue
}) => {
  return (
    <div className="medical-menu-form_item_container">
      <div className="medical-menu-form_radio-title_wrapper">
        <p>{inputTitle}</p>
      </div>
      {!!inputNote && (
        <p className="medical-menu-form_radio-note">{inputNote}</p>
      )}
      <FormRadioGroup
        name={name}
        options={options}
        radioGroupProps={{
          defaultValue: defaultValue
        }}
        radioProps={{
          className: 'medical-menu-form_radio-button'
        }}
      />
    </div>
  );
};

export default RadioGroupForm;

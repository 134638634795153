import { Breadcrumb, ConfigProvider } from 'antd'
import './styles.css'

const HistoryBreadcrumb = () => {
    return (
        <div className="breadcrumb-wrapper">
            <ConfigProvider
                theme={{
                    components: {
                        Breadcrumb: {
                            lastItemColor: '#308AE6',
                            itemColor: '#565C67',
                        },
                    },
                    token: {
                        fontFamily: 'Meiryo',
                        fontSize: 14,
                    }
                }}
            >
                <Breadcrumb
                    className=""
                    separator=">"
                    items={[
                        {
                            title: 'TOP',
                        },
                        {
                            title: 'マイページ',
                        },
                        {
                            title: '新規診療予約',
                        },
                    ]}
                />
            </ConfigProvider>
        </div>
    )
}

export default HistoryBreadcrumb
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronsLeftIcon } from '../../../../../assets/image/chevron_left_icon.svg';
import { ReactComponent as InfoOutlineIcon } from '../../../../../assets/image/info_outline.svg';
import MedicalFormContent from './medicalFormContent';
import { useAppDispatch } from 'src/Redux/hooks';
import { changeIsCreate, changeIsListMode } from '../store/slice';
import ConfirmModal from './ConfirmModal';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const MedicalMenuForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleBackIcon = () => {
    dispatch(changeIsListMode(true));
    dispatch(changeIsCreate(true));
  };

  return (
    <div className="medical-menu-form_container">
      <div className="medical-menu-form_back_container">
        <button
          onClick={handleBackIcon}
          className="medical-menu-form_back_button"
        >
          <ChevronsLeftIcon />
          <span>{t('reExamCode.back')}</span>
        </button>
      </div>
      <div className="medical-menu-form_note_container">
        <InfoOutlineIcon />
        <span>{t('reExamCode.medicalMenuFormNote')}</span>
      </div>
      <MedicalFormContent />
      <ConfirmModal />
      <ErrorModal />
      <SuccessModal />
    </div>
  );
};

export default MedicalMenuForm;

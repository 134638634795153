import './styles.css'
import { useEffect, useState } from 'react';
import { Col, Row, Tag, Button } from 'antd';
import { useTranslation } from "react-i18next";
import QRCode from "src/assets/image/qr-code.svg";
import Location from 'src/assets/image/location.svg';
import Train from 'src/assets/image/train.svg';
import Close from 'src/assets/image/calender-close.svg';
import { Clinic } from "src/Network/Model/Clinic";
import ModalEnterCode from 'src/Component/Modal/ModalQRCode/EnterCode';
import ModalScanQR from 'src/Component/Modal/ModalQRCode/ScanQR';
import ModalWarning from "src/Component/Modal/ModalWarning";
import { MODAL_WARNING, BUTTON_CLOSE } from 'src/constant';
import { setQRValidate } from '../ArrowStepper/Slice/Slice';
import { useAppDispatch } from 'src/Redux/hooks';

type Props = {
  data: Clinic | undefined;
};

const App: React.FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const openInfos = data?.open_infos;
  const departments = data?.departments;

  const [visibleEnterCode, setVisibleEnterCode] = useState(false);
  const [visibleScanQR, setVisibleScanQR] = useState(false);
  const [visibleWarning, setVisibleWarning] = useState(false);
  const [isScanner, setIsScanner] = useState(false);
  // const qrcode = searchParams.get('qrcode');
  const qrcode = localStorage.getItem("qrCode");

  useEffect(() => {
    if (!qrcode) {
      dispatch(setQRValidate(''));
    } else {
      dispatch(setQRValidate(qrcode));
    }
  }, []);

  const changeVisibleModal = (
    enterCode = false,
    scanQR = false,
    isScanner = false
  ) => {
    setVisibleEnterCode(enterCode);
    setVisibleScanQR(scanQR);
    if (isScanner) setIsScanner(isScanner);
  };
  const scrollToBookFirst = () => {
    const bookFirst = document.getElementById("bookFirst");
    if (bookFirst) {
      const rect = bookFirst.getBoundingClientRect();
      window.scroll({
        top: rect.top - 80,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="cardInfoHospital">
      <Row gutter={[{}, { xs: 36, sm: 36 }]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={14}>
          <div className="clinic-content">
            <div className="info location">
              <img src={Location} alt="" />
              <span className="text-normal-styles">{data?.address}</span>
            </div>
            <div className="info train">
              <img src={Train} alt="" />
              <span className="text-normal-styles">{data?.transportation}</span>
            </div>
            <div className="info calendar-close">
              <img src={Close} alt="" />
              <span className="text-normal-styles">
                <OpenInfo openInfos={openInfos} />
              </span>
            </div>
            <div>
              {departments?.map((item: any, index: any) => (
                <Tag
                  key={index}
                  className="text-normal-styles pediatrics"
                  bordered={false}
                >
                  {item.department_name}
                </Tag>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10} className="cardInfoHospital-right">
          <Button
            className="text-bold-styles reservation-onl"
            onClick={scrollToBookFirst}
          >
            {t("scroll.text")}
          </Button>
          {!qrcode ? (
            <Button
              className="btnOpenModalQR"
              onClick={() => changeVisibleModal(true)}
            >
              <img src={QRCode} alt="" />
              <span className="text-bold-styles">{t("booking-QRCode.text")}</span>
            </Button>
          ) : (
            <div>
              {t('booking-QRCode.modal-title')}:
              <span className="qrcode" onClick={() => changeVisibleModal(true)}>
                {qrcode}
              </span>
            </div>
          )}
        </Col>
      </Row>
      {visibleEnterCode && (
        <ModalEnterCode
          changeVisibleModal={(enterCode: boolean, scanQR: boolean) => {
            changeVisibleModal(enterCode, scanQR);
          }}
          changeVisibleModalWarning={() => {setVisibleWarning(true)}}
          isScanner={isScanner}
        />
      )}
      {visibleScanQR && (
        <ModalScanQR
          changeVisibleModal={(
            enterCode: boolean,
            scanQR: boolean,
            isScanner: boolean
          ) => {
            changeVisibleModal(enterCode, scanQR, isScanner);
          }}
        />
      )}
      {
        visibleWarning && 
        <ModalWarning
        message={t("message.MESS_NO_HSP_CLC_FOUND.text")}
        typeModal={MODAL_WARNING}
        typeButton={BUTTON_CLOSE}
        changeVisibleModal={() => {setVisibleWarning(false)}}
        />
      }
    </div>
  );
}


function OpenInfo({ openInfos }: any) {
  let clinicOpenInfo = ''
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday']
  const clinicOpens = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜', '祝日']
  for (let i = 0; i < weekDays.length; i++) {
    if (openInfos?.length) {
      let checkEmpty = true
      for (let j = 0; j < openInfos.length; j++) {
        if (openInfos[j][weekDays[i]] != '-' && openInfos[j][weekDays[i]] != 'ー') {
          checkEmpty = false
          break
        }
      }
      if (checkEmpty) {
        clinicOpenInfo += clinicOpens[i] + '・'
      }
    }
  }
  return <>{clinicOpenInfo.length ? (clinicOpenInfo.slice(0, clinicOpenInfo.length - 1) + '休み') : clinicOpenInfo}</>
}

export default App;

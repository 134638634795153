import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import {
  changeIsModalOpen,
  selectIsCreate,
  selectIsModalOpen
} from '../store/slice';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import ModalBody from './ModalBody';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const AgreementManagementModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isCreate = useAppSelector(selectIsCreate);
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const handleCloseModal = () => {
    dispatch(changeIsModalOpen(false));
  };
  const handleOkAgreeForm = () => {
    const submitButtonAgreeFormEl =
      document.getElementById('submitAgreeFormBtn');
    submitButtonAgreeFormEl?.click();
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('openModal');
    } else {
      document.body.classList.remove('openModal');
    }
  }, [isModalOpen]);

  return (
    <Modal
      title={
        <ModalHeader
          handleCloseModal={handleCloseModal}
          title={
            isCreate
              ? t('reExamCode.createAgreeForm')
              : t('reExamCode.editAgreeForm')
          }
        />
      }
      footer={
        <ModalFooter
          okTitle={isCreate ? t('reExamCode.create') : t('reExamCode.update')}
          cancelTitle={t('reExamCode.cancel')}
          handleCancel={handleCloseModal}
          handleOK={handleOkAgreeForm}
        />
      }
      width={'50%'}
      style={{
        minWidth: 700,
        top: 40,
        height: 'calc(100% - 80px)',
        minHeight: 400
      }}
      closeIcon={false}
      open={isModalOpen}
      destroyOnClose={true}
      wrapClassName="consent-form_modal-wrapper"
      styles={{
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        body: {
          marginTop: 24,
          marginBottom: 24,
          flex: 1,
          paddingRight: 16
        }
      }}
    >
      <ModalBody />
    </Modal>
  );
};

export default AgreementManagementModal;

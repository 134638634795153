import { useTranslation } from 'react-i18next';
import FormInputSelect from 'src/Component/common/form/FormInputSelect';
import {
  DEFAULT_PERIOD_TO_OPTION,
  periodFromOption,
  periodToOption
} from '../../constants';

type Props = {
  inputNames: {
    firstFrom: string;
    firstTo: string;
    reFrom: string;
    reTo: string;
  };
};

const SelectAcceptTimeForm: React.FC<Props> = ({ inputNames }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="medical-menu-form_wrapper">
          <div className="medical-menu-form_input_wrapper">
            <span className="medical-menu-form_title-before">
              {t('reExamCode.firstExam')}
            </span>
            <span className="normal-text mx-4 mb-24px">
              {t('reExamCode.reservationDate')}
            </span>
            <div className="medical-menu-wrap-input-select">
              <FormInputSelect
                inputProps={{
                  className:
                    'medical-menu-form_input medical-menu-form_input-select'
                }}
                name={inputNames.firstFrom}
                defaultValue={periodFromOption[0].value}
                options={periodFromOption}
              />
            </div>

            <span className="normal-text mx-4 mb-24px">
              {t('reExamCode.from')}
            </span>
            <div className="medical-menu-wrap-input-select">
              <FormInputSelect
                inputProps={{
                  className:
                    'medical-menu-form_input medical-menu-form_input-select'
                }}
                name={inputNames.firstTo}
                defaultValue={DEFAULT_PERIOD_TO_OPTION}
                options={periodToOption}
              />
            </div>
            <span className="normal-text ml-4 mb-24px">
              {t('reExamCode.to')}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="medical-menu-form_wrapper">
          <div className="medical-menu-form_input_wrapper">
            <span className="medical-menu-form_title-before">
              {t('reExamCode.reExam')}
            </span>
            <span className="normal-text mx-4 mb-24px">
              {t('reExamCode.reservationDate')}
            </span>
            <div className="medical-menu-wrap-input-select">
              <FormInputSelect
                inputProps={{
                  className:
                    'medical-menu-form_input medical-menu-form_input-select'
                }}
                name={inputNames.reFrom}
                defaultValue={periodFromOption[0].value}
                options={periodFromOption}
              />
            </div>
            <span className="normal-text mx-4 mb-24px">
              {t('reExamCode.from')}
            </span>
            <div className="medical-menu-wrap-input-select">
              <FormInputSelect
                inputProps={{
                  className:
                    'medical-menu-form_input medical-menu-form_input-select'
                }}
                name={inputNames.reTo}
                defaultValue={DEFAULT_PERIOD_TO_OPTION}
                options={periodToOption}
              />
            </div>
            <span className="normal-text ml-4 mb-24px">
              {t('reExamCode.to')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAcceptTimeForm;
